/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Grid, MenuItem } from '@material-ui/core';
import FASFieldTitle from '../CustomFormComponents/FASFieldTitle';
import FASCheckboxField from '../CustomFormComponents/FASCheckboxField/FASCheckboxField';
import FASSelectField from '../CustomFormComponents/FASSelectField';
import MDTextInputField from '../FormComponents/MDTextInput/MDTextInputField';
import { COUNTRIES, CANADIAN_PROVINCES, US_STATES, MEXICAN_STATES } from '../../../utils/consts';

const validate = (values, props) => {
  let errors = {
    address: {},
  };
  let requiredFields = [];
  let integerFields = [];

  // Non-address fields
  requiredFields = ['name', 'territory'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  integerFields = [];
  integerFields.forEach((field) => {
    if (values[field] && !Number.isInteger(parseFloat(values[field]))) {
      errors[field] = 'Must be an Integer';
    }
  });

  // Address Validation
  // Fields: address1, address2, city, state, country, zip
  if (!values.address?.address1) {
    errors.address.address1 = 'Required';
  }
  if (!values.address?.city) {
    errors.address.city = 'Required';
  }
  if (!values.address?.state) {
    errors.address.state = 'Required';
  }
  if (!values.address?.country) {
    errors.address.country = 'Required';
  }
  if (!values.address?.zip) {
    errors.address.zip = 'Required';
  }

  return errors;
};

const LocationSettingsForm = (props) => {
  const { curValues, dispatch } = props;

  const countrySelected = curValues?.address?.country;
  let selectedStates = [];

  const stateProvSwitch = (param) => {
    switch (param) {
      case 'USA':
        return US_STATES;
      case 'CAN':
        return CANADIAN_PROVINCES;
      case 'MEX':
        return MEXICAN_STATES;
      default:
        return [];
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={6} md={4}>
        <FASFieldTitle title="Name *" />
        <Field
          id="name"
          name="name"
          component={MDTextInputField}
          size="small"
          type="text"
          variant="outlined"
          noBorderRadius
          placeholder="Name"
        />
      </Grid>
      <Grid item sm={6} md={4}>
        <FASFieldTitle title="FAS Location *" />
        <Field
          variant="outlined"
          placeholder="FAS Location"
          id="territory"
          name="territory"
          component={MDTextInputField}
          size="small"
          noBorderRadius
        />
      </Grid>
      <Grid item sm={6} md={4}>
        <FASFieldTitle title="Status" />
        <Field name="active" component={FASCheckboxField} label="Active" />
      </Grid>
      <Grid item sm={6} md={4}>
        <FASFieldTitle title="Address Line 1 *" />
        <Field
          variant="outlined"
          placeholder="Address"
          id="address1"
          name="address.address1"
          component={MDTextInputField}
          type="text"
          size="small"
          noBorderRadius
          autocomplete="address-line1"
        />
      </Grid>
      <Grid item sm={6} md={4}>
        <FASFieldTitle title="Address Line 2" />
        <Field
          variant="outlined"
          placeholder="Apt, suite, etc."
          id="address2"
          name="address.address2"
          component={MDTextInputField}
          type="text"
          size="small"
          noBorderRadius
          autocomplete="address-line2"
        />
      </Grid>
      <Grid item sm={6} md={4}>
        <FASFieldTitle title="Postal Code *" />
        <Field
          variant="outlined"
          placeholder="Zip Code"
          id="zip"
          name="address.zip"
          component={MDTextInputField}
          type="number"
          size="small"
          noBorderRadius
          autocomplete="postal-code"
        />
      </Grid>
      <Grid item sm={6} md={4}>
        <FASFieldTitle title="Country *" />
        <Field
          variant="outlined"
          placeholder="Country"
          id="country"
          name="address.country"
          component={FASSelectField}
          displayEmpty
          defaultValue={{ label: 'Country', value: 'default' }}
        >
          <MenuItem disabled value="">
            <span style={{ color: 'rgba(133,133,133)' }}>Select Country</span>
          </MenuItem>
          {_.map(COUNTRIES, (country) => (
            <MenuItem value={country.code} key={country.code}>
              {country.name}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item sm={6} md={4}>
        <FASFieldTitle title="State/Province *" />
        <Field
          variant="outlined"
          placeholder="State/Province"
          id="state"
          name="address.state"
          component={FASSelectField}
          displayEmpty
          disabled={!countrySelected}
          defaultValue={{ label: 'State/Province', value: 'default' }}
        >
          <MenuItem disabled value="">
            <span style={{ color: 'rgba(133,133,133)' }}>Select State/Province</span>
          </MenuItem>
          {_.map(stateProvSwitch(countrySelected), (state) => (
            <MenuItem value={state.code} key={state.code}>
              {state.name}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item sm={6} md={4}>
        <FASFieldTitle title="City *" />
        <Field
          variant="outlined"
          placeholder="City"
          id="city"
          name="address.city"
          component={MDTextInputField}
          type="text"
          size="small"
          noBorderRadius
          autocomplete="address-level2"
        />
      </Grid>
    </Grid>
  );
};

export default _.flow([
  connect((state) => {
    const curValues = getFormValues('LocationSettingsForm')(state);
    return {
      curValues,
    };
  }),
  reduxForm({
    form: 'LocationSettingsForm',
    initialValues: {
      active: true,
    },
    validate,
  }),
])(LocationSettingsForm);
