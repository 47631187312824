/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchEventById } from '../../../../redux/actions/events.actions';
import Spinner from '../../../SpinnerOverlay/Spinner';
import BaseEventsListTile, { BaseEventsListEntry } from '../EventPopper/BaseEventsListTile';

const CancelledEventsPopperList = ({ cancelledEventsInfo, onClosePopper }) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();

  const groupEventsByOrder = (list) => {
    // get all events that have been cancelled because removed from the order BUT the order isnt cancelled
    const standAloneEvents = (list ?? []).filter((ev) => _.isEmpty(ev.orderID ?? ''));
    const orderBoundEvents = (list ?? []).filter((ev) => !_.isEmpty(ev.orderID ?? ''));
    const groupped = orderBoundEvents.reduce(
      (red, ev) => ({
        ...red,
        [ev.orderID]: [...(red[ev.orderID] ?? []), ev].sort((a, b) => new Date(a.startTime) - new Date(b.startTime)),
      }),
      {},
    );

    const orderBoundGroupped = Object.keys(groupped).map((orderID) => {
      const firstEvent = groupped[orderID]?.[0];
      return {
        orderID,
        eventID: firstEvent?.eventID,
        fee: firstEvent?.fee ?? 0,
        startTime: firstEvent?.startTime,
      };
    });

    const result = [...orderBoundGroupped, ...standAloneEvents];

    result.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
    return result;
  };

  const loadEvents = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const loaded = await Promise.allSettled([
        ...groupEventsByOrder(cancelledEventsInfo.cancelledIDs ?? []).map((idInfo) =>
          dispatch(fetchEventById(idInfo.eventID, true)),
        ),
      ]);
      setEvents(loaded);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading && events.length === 0) {
      loadEvents();
    }
  }, []);

  const spinner = () => <Spinner customStyle={{ marginTop: '20%' }} />;

  return loading ? (
    spinner()
  ) : (
    <>
      {events?.map((event) => (
        <BaseEventsListTile entry={BaseEventsListEntry.fromEvent(event.value)} clickCallback={onClosePopper} />
      ))}
    </>
  );
};

export default _.flow([connect()])(CancelledEventsPopperList);
