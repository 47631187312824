/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { reduxForm, submit, reset, change, getFormValues } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import MuiDialogActions from '@material-ui/core/DialogActions';
import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { cleanRows, addRowWithId, deleteRow } from './EventDialogForm/FormComponent/CartSummaryForm';
import {
  BOOKING_MODE,
  EVENT_STEPS,
  EVENT_TYPE,
  EVENT_TYPE_DISPLAY,
  MATERIAL_TYPE,
  OEC_CUSTOMER,
  COLORS,
  DATE_PICKER_FOMAT,
  ROLE_ACCESSES,
} from '../../../../utils/consts';
import DeleteConfirmAlert from '../EventPopper/DeleteConfirmAlert';
import EventDialogWrapper from './EventDialogWrapper';
import EventSummaryForm from './EventDialogForm/EventSummaryForm';
import EventTypeRadioGroup from './EventDialogControl/EventTypeRadioGroup';
import FASTitle from '../../../Forms/CustomFormComponents/FASTtle';
import OnsiteForm from './EventDialogForm/OnsiteForm';
import OpenEnrollmentForm from './EventDialogForm/OpenEnrollmentForm';
import StyledButton from '../../../Button/StyledButton';
import TimeBlockForm from './EventDialogForm/TimeBlockForm';
import TimePickForm from './EventDialogForm/TimePickForm';
import { removeLeadingZeros } from '../../../../utils/helpers';
import * as EventActions from '../../../../redux/actions/events.actions';
import * as ModalsActions from '../../../../redux/actions/modals.actions';
import * as PricingActions from '../../../../redux/actions/pricing.actions';
import * as OrdersActions from '../../../../redux/actions/orders.actions';
import Spinner from '../../../SpinnerOverlay/Spinner';
import { hasUserAccessSelector } from '../../../../redux/selectors/user.selectors';
import { materialsDataSelector } from '../../../../redux/selectors/materials.selector';
import { addSkillCheckCourses, makeDayDiscountCalculator } from '../../../../utils/event.utils';
import {
  validateOnsite,
  validateOpenEnrollment,
  validateQuote,
  validateTimeblock,
} from '../../../../redux/validators/events.validators';
import { deleteSeries, getRecurringSeries } from '../../../../redux/actions/recurringSeries.actions';
import ConfirmSeriesAlert from '../EventPopper/ConfirmSeriesAlert';
import CancelOrderButton from '../../../Button/CancelOrderButton';
import SimpleText from '../../../Text/SimpleText';
import { CLOSING_FEEDBACK_MODAL } from '../../../../redux/reducers/modals.reducer';

const useStyles = makeStyles(() => ({
  rightGird: {
    borderLeft: '1px solid #DEDEDE',
  },
}));

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// All validation check in here, sub component validation check will overrride this validation check
const validate = (values, props) => {
  const formName = 'AddEventDialog';
  let errors = {};
  if (values.eventType === EVENT_TYPE.TIME_BLOCK) {
    errors = validateTimeblock(values, props, formName);
  } else if (values.eventType === EVENT_TYPE.OPEN_ENROLLMENT) {
    errors = validateOpenEnrollment(values, props, formName);
  } else if (values.eventType === EVENT_TYPE.ON_SITE) {
    errors = validateOnsite(values, props, formName);
  } else if (values.eventType === EVENT_TYPE.QUOTE) {
    errors = validateQuote(values, props, formName);
  }

  return errors;
};

const EventDialog = ({
  dispatch,
  handleSubmit,
  modalName,
  formFieldsInfo,
  bookingQuote,
  curEvent,
  updatedEvent,
  mode,
  initialDateValues,
  submitFailed, // listings,
}) => {
  const classes = useStyles();
  const [eventType, setEventType] = useState(mode === BOOKING_MODE.quote ? EVENT_TYPE.QUOTE : null);
  // const [cancelPrice, setCancelPrice] = useState(2);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [tempTransactions, setTempTransactions] = useState([]);
  // const [isUpdateLocal, setIsUpdateLocal] = useState(isUpdateLayout);
  const [bookingMode, setBookingMode] = useState(mode);
  const isUpdateLayout = [
    BOOKING_MODE.editing,
    BOOKING_MODE.rebooking,
    BOOKING_MODE.quote,
    BOOKING_MODE.rescheduling,
    BOOKING_MODE.duplicate,
  ].includes(bookingMode);
  const isQuote = [BOOKING_MODE.quote].includes(bookingMode);
  const showLeftPanel = !isQuote || activeStep > 0;
  const showTimePicker = !isQuote;
  const materialsInfo = useSelector(materialsDataSelector);
  const errorHelper = curEvent?.errorHelper ?? false;
  const canDelete = useSelector(
    (state) => hasUserAccessSelector(state, [ROLE_ACCESSES.editTimeblock]) && eventType === EVENT_TYPE.TIME_BLOCK,
  );
  const hasEditAccess = useSelector((state) =>
    hasUserAccessSelector(state, [
      eventType === EVENT_TYPE.TIME_BLOCK ? ROLE_ACCESSES.editTimeblock : ROLE_ACCESSES.editEvent,
    ]),
  );
  // Add boolean constant for checking if editing a timeblock remove by requirement for now this should be util later
  const editingTimeblock = hasEditAccess && eventType === EVENT_TYPE.TIME_BLOCK && mode === BOOKING_MODE.editing;
  const canCancelOrders = useSelector(
    (state) => hasUserAccessSelector(state, [ROLE_ACCESSES.canCancel]) && !editingTimeblock,
  );
  const fieldsInfoRef = React.useRef();

  const editsWereMade = () => isUpdateLayout && JSON.stringify(fieldsInfoRef?.current).includes('"touched":true');

  const registerPopupCloseListener = () => {
    // this is a callback for when the dialog gets dismissed by clicking the close icon in the top bar
    if (modalName) {
      dispatch(
        ModalsActions.registerModalClosingValidation(modalName, () => {
          if (editsWereMade()) {
            return {
              canClose: false,
              feedbackData: {
                modalType: 'FAS_EVENT_DIALOG',
                modalProps: {
                  title: 'UNSAVED CHANGES',
                  content: (
                    <SimpleText
                      txt={
                        'You made some changes but forgot to save them.\nIf you exit now your changes will be lost.\nDo you want to exit without saving?'
                      }
                      divStyle={{ padding: 22 }}
                    />
                  ),
                  confirmText: 'EXIT',
                  onConfirm: () => {
                    // hide unsaved changes warning
                    dispatch(ModalsActions.hideModal(CLOSING_FEEDBACK_MODAL));
                    // unregister unsaved changes callback validation for dialog
                    dispatch(ModalsActions.unregisterModalClosingValidation(modalName));
                    // dismiss dialog
                    dispatch(ModalsActions.hideModal(modalName));
                  },
                },
              },
            };
          }
          return { canClose: true };
        }),
      );
    }
  };

  useEffect(async () => {
    if (isUpdateLayout) {
      setEventType(updatedEvent.desc.eventType);
    }
    await dispatch(change('AddEventDialog', 'bookingMode', bookingMode));
    registerPopupCloseListener();
  }, []);

  useEffect(() => {
    if (curEvent?.bookingMode && curEvent?.bookingMode !== bookingMode) {
      setBookingMode(curEvent.bookingMode);
    }
  }, [curEvent?.bookingMode ?? '']);

  useEffect(async () => {
    if (curEvent?.series) {
      const series = await getRecurringSeries(curEvent?.series);
      await dispatch(
        change('AddEventDialog', 'recurringSeries', {
          ...series,
          startDate: moment.utc(series.startDate).format(DATE_PICKER_FOMAT),
          endDate: moment.utc(series.endDate).format(DATE_PICKER_FOMAT),
        }),
      );
    }
  }, [curEvent?.bookingMode ?? '']);

  const getActionBtnLabel = () => {
    if (activeStep === EVENT_STEPS[eventType] - 1) {
      const def = 'Confirm';
      if (bookingQuote) return def;
      switch (bookingMode) {
        case BOOKING_MODE.editing:
          return 'Update';
        case BOOKING_MODE.rescheduling:
          return 'Reschedule';
        case BOOKING_MODE.rebooking:
          return 'Rebook';
        default:
          return def;
      }
    }

    return 'Next';
  };

  const switchEventTypeHandler = (event) => {
    // If reselected eventType, reset the AddEventDialog form
    if (eventType) {
      setActiveStep(0);
      dispatch(reset('AddEventDialog'));
      dispatch(change('AddEventDialog', 'date', curEvent.date));
      dispatch(change('AddEventDialog', 'startTime', curEvent.startTime));
      cleanRows();
    }
    setEventType(event.target.value);
  };

  const addCoursesToCart = () => {
    if ([EVENT_TYPE.ON_SITE, EVENT_TYPE.QUOTE].includes(curEvent.eventType)) {
      _.forEach(curEvent.courses, (course, idx) => {
        const discount = makeDayDiscountCalculator(
          course,
          [...curEvent.courses].filter((c) => c.course.type === MATERIAL_TYPE.COURSE).length,
          curEvent.customer,
        );
        const price = discount.isMakeDayDiscount ? discount.adjustedPrice : Number(course.customerPrice ?? 0);
        addRowWithId(
          course.course?.id,
          `${removeLeadingZeros(course.course?.code)} - ${course.course?.title}`,
          1,
          price,
          `${course.course?.title}${idx}`,
          course.currency,
        );
        if (course?.skillcheck) {
          const skillcheckParticipants = course.skillcheckFull ? course.participants : course.skillcheckParticipants;
          addRowWithId(
            course.skillcheck?.id,
            `${removeLeadingZeros(course.skillcheck?.code)} - ${course.skillcheck.title}`,
            parseInt(skillcheckParticipants, 10),
            Number(course.skillcheckPrice?.customerPrice) ?? 0,
            `${course.skillcheck.title}${idx}`,
            course.currency,
          );
        }
        setTempTransactions((oldCourse) => [...oldCourse, course.course]);
      });
    }
    const { fees } = curEvent;

    if (fees) {
      const { afterHoursFee, travelFee } = curEvent.fees;
      if (afterHoursFee?.Total > 0 && !isQuote) {
        addRowWithId(
          `order${curEvent?.order}OnSiteAfterHoursFee`,
          'Afterhours Fee',
          1,
          afterHoursFee.Total,
          '',
          afterHoursFee?.Price?.Currency,
        );
      }
      if (travelFee?.TotalFee > 0) {
        addRowWithId(`order${curEvent?.order}OnSiteTravelFee`, 'Travel Fee', 1, travelFee?.TotalFee, '', 'USD');
      }
    }
    if (curEvent.eventType === EVENT_TYPE.OPEN_ENROLLMENT) {
      addRowWithId(
        curEvent.openEnrollmentCourse.course.id,
        curEvent.openEnrollmentCourse.course.title,
        1,
        Number(curEvent.openEnrollmentCourse.price),
        curEvent.openEnrollmentCourse.course.title,
        curEvent.openEnrollmentCourse.currency,
      );
      setTempTransactions((oldCourse) => [...oldCourse, curEvent.openEnrollmentCourse.course]);
    }
  };
  const removeCoursesToCart = () => {
    _.map(tempTransactions, (course) => {
      deleteRow(course?.title);
    });
    setTempTransactions([]);
  };

  const onSubmitDeleteEvent = () => {
    dispatch(EventActions.cancelEvent(curEvent.id));
    dispatch(ModalsActions.hideModal('DELETE_ALERT'));
    dispatch(ModalsActions.hideModal('EDIT_EVENT_DIALOG'));
  };

  const onSubmitDeleteSeries = async (allSeries) => {
    const startTime = moment.utc(curEvent?.startTime);
    if (allSeries) {
      const result = await deleteSeries(
        curEvent.series,
        curEvent?.recurringSeries
          ? moment
              .utc(curEvent.recurringSeries.startDate)
              .set({
                hour: startTime.get('hour'),
                minute: startTime.get('minute'),
                second: startTime.get('second'),
              })
              .toISOString()
          : startTime.toISOString(),
      );
      if (result) {
        dispatch(OrdersActions.fetchOrders());
        dispatch(ModalsActions.hideModal('DELETE_ALERT'));
        dispatch(ModalsActions.hideModal('EDIT_EVENT_DIALOG'));
      }
    } else {
      onSubmitDeleteEvent();
    }
  };

  const showDeleteEventAlert = () => {
    dispatch(
      ModalsActions.showModal('DELETE_ALERT', {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          cancelText: curEvent.series ? 'All events' : 'cancel',
          confirmText: curEvent.series ? 'This event' : 'confirm',
          disableBackdropClick: true,
          maxWidth: 'lg',
          title: `DELETE ${curEvent.series ? 'SERIES' : 'EVENT'} CONFIRMATION`,
          content: curEvent.series ? <ConfirmSeriesAlert /> : <DeleteConfirmAlert />,
          ...(curEvent.series && { onCancel: () => onSubmitDeleteSeries(true) }),
          onConfirm: () => (curEvent.series ? onSubmitDeleteSeries(false) : onSubmitDeleteEvent()),
        },
      }),
    );
  };

  const showErrorAlert = (message) => {
    dispatch(
      ModalsActions.showModal('CREATE_EVENT_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: { message },
      }),
    );
  };

  const goToNextStep = () => {
    addCoursesToCart();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleOpenEnrollmentNext = async () => {
    const { addons } = curEvent.openEnrollmentCourse.course;
    const addOnsList = _.uniq(_.concat(addons.required || [], addons.optional || []));
    const addOnsListCodes = _.map(addOnsList, (e) => {
      const material = materialsInfo[MATERIAL_TYPE.ADD_ON][e] ?? {};
      return material.code;
    });
    await dispatch(PricingActions.fetchPricing(OEC_CUSTOMER, addOnsListCodes, true));
    goToNextStep();
  };

  const combineCourses = async () => {
    await dispatch(change('AddEventDialog', 'combinedCourses', addSkillCheckCourses(curEvent?.courses)));
  };

  const handleOnSiteNext = async () => {
    const arePricesValid = (curEvent.courses ?? []).filter((c) => c.marketPrice === undefined).length === 0;
    if (!arePricesValid) {
      showErrorAlert('One or more courses have invalid pricing');
    } else {
      const addons = _(curEvent.courses)
        .map((el) => {
          const courseAddons = el.course.addons ?? {};
          const addOnsList = _.concat(courseAddons.required ?? [], courseAddons.optional ?? []);
          return addOnsList;
        })
        .flatten()
        .uniq()
        .value();
      const addOnsListCodes = _.map(addons, (e) => {
        const material = materialsInfo[MATERIAL_TYPE.ADD_ON][e] ?? {};
        return material.code;
      });
      const additionalParticipants = _(curEvent.courses)
        .map((el) => el.course.additionalParticipants ?? {})
        .uniq()
        .value();
      const additionalParticipantsListCodes = _.map(additionalParticipants, (e) => {
        const material = materialsInfo[MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS][e.id] ?? {};
        return material?.code;
      }).filter((e) => e);
      // await dispatch(PricingActions.fetchPricing(curEvent.customer.id, addOnsListCodes, true));
      // eslint-disable-next-line max-len
      dispatch(PricingActions.resetAdditionalParticipantsPrices());
      if (mode === BOOKING_MODE.quote) dispatch(change('AddEventDialog', 'status', true));
      dispatch(change('AddEventDialog', 'addExternalCalendar', mode === BOOKING_MODE.booking));
      await Promise.all([
        ..._.map(additionalParticipantsListCodes, (material) =>
          dispatch(PricingActions.fetchPricing(curEvent.customer, material, false, true)),
        ),
      ]);

      dispatch(PricingActions.resetAddOnsPrices());
      await Promise.all([
        ..._.map(addOnsListCodes, (material) =>
          dispatch(PricingActions.fetchPricing(curEvent.customer, material, true)),
        ),
        combineCourses(),
      ]);
      goToNextStep();
    }
  };

  const handleNext = async () => {
    // Last page, need to confirm transaction
    if (EVENT_STEPS[eventType] - 1 === activeStep) {
      // handle confirm and add to calender
      // await dispatch(change('AddEventDialog', 'bookingMode', bookingMode));
      setLoading(true);
      if (modalName) dispatch(ModalsActions.unregisterModalClosingValidation(modalName));
      await handleSubmit();
      setLoading(false);
      // In middle, need to go forward or go back
    } else if (EVENT_STEPS[eventType] > activeStep) {
      if (eventType === EVENT_TYPE.OPEN_ENROLLMENT) {
        await handleOpenEnrollmentNext();
      } else if ([EVENT_TYPE.ON_SITE, EVENT_TYPE.QUOTE].includes(eventType)) {
        await handleOnSiteNext();
      } else {
        goToNextStep();
      }
    } else {
      setActiveStep(0);
      cleanRows();
    }
  };

  const handleBack = async () => {
    removeCoursesToCart();
    cleanRows();
    // if ([EVENT_TYPE.ON_SITE, EVENT_TYPE.QUOTE].includes(curEvent.eventType)) {
    //   _.each(curEvent.courses, async (course, idx) => {
    //     // await dispatch(change('AddEventDialog', `courses[${idx}].selectedAddons`, null));
    //   });
    // }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const addNewCourseField = () => {
    dispatch(change('AddEventDialog', 'courses', [...curEvent.courses, { new: true }]));
  };

  const EventTypeComponent = () => {
    switch (eventType) {
      case EVENT_TYPE.ON_SITE:
      case EVENT_TYPE.QUOTE:
        return <OnsiteForm mode={bookingMode} activeStep={activeStep} />;
      case EVENT_TYPE.OPEN_ENROLLMENT:
        return <OpenEnrollmentForm activeStep={activeStep} />;
      case EVENT_TYPE.TIME_BLOCK:
        return <TimeBlockForm activeStep={activeStep} />;
      default:
        return <div />;
    }
  };

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20,
        width: 20,
        padding: 0,
        margin: 0,
        color: COLORS.CINTAS_BLUE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        padding: 0,
      }}
    />
  );

  const cancelButton = () =>
    canCancelOrders && !bookingQuote ? <CancelOrderButton orderId={curEvent?.order} curEvent={curEvent} /> : <></>;

  const modifyEventHeader = () => (
    <Grid container spacing={0} style={{ padding: '0px 10px', justifyContent: 'space-between', alignItems: 'center' }}>
      <Grid item xs={4}>
        <FASTitle title={EVENT_TYPE_DISPLAY[updatedEvent.desc.eventType]} style={{ marginBottom: 30 }} />
      </Grid>
      {updatedEvent.desc.eventType !== EVENT_TYPE.TIME_BLOCK && !isQuote && (
        <Grid item xs="auto">
          {cancelButton()}
        </Grid>
      )}
    </Grid>
  );

  const backBtn = () => (
    <StyledButton
      variant="outlined"
      disabled={activeStep === 0}
      width="160px"
      handleButton={handleBack}
      buttonContent="Back"
    />
  );

  const deleteTimeblockBtn = () => (
    <StyledButton
      variant="contained"
      color="secondary"
      width="160px"
      handleButton={showDeleteEventAlert}
      buttonContent={activeStep === EVENT_STEPS[eventType] - 1 ? 'Delete' : 'Next'}
    />
  );

  const addCourseButton = () => (
    <StyledButton
      variant="contained"
      color="primary"
      handleButton={addNewCourseField}
      buttonContent={`Add Course To ${mode === BOOKING_MODE.quote ? 'Quote' : 'Event'}`}
    />
  );

  const mainActionButton = () => (
    <StyledButton
      variant="contained"
      color="primary"
      type="submit"
      width="160px"
      // handleButton is onClick function, handleSubmit is for triggering valiation check
      handleButton={handleSubmit(handleNext)}
      buttonContent={getActionBtnLabel()}
      disabled={loading}
      endIcon={loading && spinner()}
    />
  );

  const footerErrorMessage = () => (
    <Typography
      style={{
        color: COLORS.CINTAS_RED,
        textAlign: 'center',
        fontsize: 14,
      }}
    >
      Please Correct Errors Above.
    </Typography>
  );

  const footer = () => (
    <DialogActions style={{ justifyContent: editingTimeblock && canDelete ? 'space-between' : 'flex-end' }}>
      {activeStep !== 0 && backBtn()}
      {eventType === EVENT_TYPE.TIME_BLOCK && isUpdateLayout && canDelete && deleteTimeblockBtn()}
      {errorHelper && submitFailed && footerErrorMessage()}
      {activeStep === 0 &&
        [EVENT_TYPE.ON_SITE, EVENT_TYPE.QUOTE].includes(eventType) &&
        curEvent.customer &&
        addCourseButton()}
      {mainActionButton()}
    </DialogActions>
  );

  const timePickerForm = () => (
    <TimePickForm
      dispatch={dispatch}
      initialDateValues={initialDateValues}
      isConfirmationPage={activeStep + 1 === EVENT_STEPS[eventType]}
      mode={bookingMode}
    />
  );

  const eventSummaryForm = () => (
    <EventSummaryForm
      activeStep={activeStep}
      mode={bookingMode}
      presetDate={
        initialDateValues
          ? {
              start: initialDateValues.start?.format(DATE_PICKER_FOMAT),
              end: initialDateValues.end?.format(DATE_PICKER_FOMAT),
            }
          : null
      }
    />
  );

  const leftPanel = () => (
    <Grid item xs={12} sm={12} md={activeStep === 0 ? 4 : 3} style={{ overflowY: 'scroll' }}>
      {showTimePicker && timePickerForm()}
      {curEvent && eventSummaryForm()}
    </Grid>
  );

  const updateLayoutHeader = () => (
    <Grid container spacing={2} alignItems="center">
      {modifyEventHeader()}
    </Grid>
  );

  const bookingLayoutHeader = () => (
    <>
      <FASTitle title="Event Type" />
      <EventTypeRadioGroup onSwitchEventType={switchEventTypeHandler} />
    </>
  );

  const getHeader = (isFirstPage) => {
    if (!isFirstPage) return undefined;
    if (isUpdateLayout) return updateLayoutHeader();

    switch (bookingMode) {
      case BOOKING_MODE.booking:
        return bookingLayoutHeader();
      default:
        return <></>;
    }
  };

  const getPanelFlex = (isFirstPage) => {
    if (isFirstPage) {
      if (bookingMode === BOOKING_MODE.quote) return 12;
      return 8;
    }
    return 9;
  };

  const rightPanel = () => {
    const isFirstPage = activeStep === 0;
    return (
      <Grid
        item
        className={classes.rightGird}
        xs={12}
        sm={12}
        md={getPanelFlex(isFirstPage)}
        style={{
          maxHeight: 'calc(100vh - 170px)',
          maxWidth: '600px',
          overflow: 'hidden scroll',
        }}
      >
        {isFirstPage && (
          <Grid item xs={12}>
            {getHeader(isFirstPage)}
          </Grid>
        )}
        <Grid item xs={12}>
          {EventTypeComponent(eventType)}
        </Grid>
      </Grid>
    );
  };

  fieldsInfoRef.current = formFieldsInfo;

  return (
    <>
      <EventDialogWrapper maxWidth={200}>
        <form onSubmit={handleSubmit(handleNext)}>
          <Grid item xs={12}>
            {/* Spacing here adjusts padding on all event dialog components */}
            <Grid container spacing={2}>
              {showLeftPanel && leftPanel()}
              {rightPanel()}
            </Grid>
          </Grid>
        </form>
      </EventDialogWrapper>
      {footer()}
    </>
  );
};

export default _.flow([
  connect((state) => {
    const curEvent = getFormValues('AddEventDialog')(state);
    const formFieldsInfo = state?.form?.AddEventDialog?.fields ?? {};

    return {
      curEvent,
      formFieldsInfo,
    };
  }),
  reduxForm({
    form: 'AddEventDialog',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    touchOnBlur: false,
    validate,
    onSubmit: submit,
  }),
])(EventDialog);
