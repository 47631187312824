import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import auth from './auth.reducer';
import certifications from './certifications.reducer';
import cl from './commissionLevels.reducer';
import contacts from './contacts.reducer';
import courseCategories from './courseCategories.reducer';
import customers from './customers.reducer';
import events from './events.reducer';
import locations from './locations.reducer';
import materials from './materials.reducer';
import modals from './modals.reducer';
import orders from './orders.reducer';
import pricing from './pricing.reducer';
import sap from './sap.reducer';
import settings from './settings.reducer';
import notifications from './notifications.tasks.reducer';
import users from './users.reducer';
import utils from './utils.reducer';

export default combineReducers({
  form: formReducer,
  auth,
  materials,
  cl,
  contacts,
  courseCategories,
  customers,
  events,
  locations,
  certifications,
  modals,
  orders,
  pricing,
  sap,
  settings,
  notifications,
  users,
  utils,
});
