/* eslint-disable no-nested-ternary */
/* eslint-disable no-empty */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { change } from 'redux-form';
import { BOOKING_MODE, DATE_PICKER_FOMAT, EVENT_TYPE, OK_STATUS_CODES } from '../../utils/consts';

import {
  RESET_EVENTS_STATE,
  SAVE_EVENTS,
  ADD_EVENT,
  SAVE_UPCOMING_EVENTS,
  DELETE_TIMEBLOCK_EVENT,
  DELETE_UPCOMING_TIMEBLOCK,
  UPDATE_UPCOMING_EVENT,
  UPDATE_EVENT,
  SAVE_CANCELLED_EVENTS_COUNT,
  RESET_CANCELLED_EVENTS_COUNT,
  SAVE_UNBLOCKED_DAYS,
  UNBLOCK_DAY,
  BLOCK_DAY,
  SAVE_DATE_REVENUE,
} from './actionTypes/events';
import * as EventsAPI from '../../api/events.api';
import {
  instructorIdSelector,
  reportingTciById,
  selectedTciSelector,
  userInfoSelector,
} from '../selectors/user.selectors';
import { locationsViewportSelector, viewportSelector } from '../selectors/calendar.selectors';
import { timezoneSelector } from '../selectors/utils.selectors';
import { getDaysBetweenDates } from '../../utils/dateUtils';
import { createOrderPayload } from '../payload_builders/orders.payload';
import { NOTIFICATION_TYPE } from '../../utils/consts/notifications.consts';
import { postOrderNotification } from './notifications.actions';

export const mapEvents = (data) => {
  const mapped = {};
  (data ?? []).forEach((event) => {
    if (!event.id) return;
    mapped[event.order ?? ''] = {
      ...(mapped[event.order ?? ''] ?? {}),
      [event.id]: event,
    };
  });
  return mapped;
};

const saveEvents = (eventsData) => {
  const mapped = mapEvents(eventsData);

  return {
    type: SAVE_EVENTS,
    events: mapped,
  };
};

const saveDateRevenue = (revenueData) => ({
  type: SAVE_DATE_REVENUE,
  revenueData,
});

const saveUnblockedDays = (unblockedDays) => ({
  type: SAVE_UNBLOCKED_DAYS,
  unblockedDays,
});

const saveUpcomingEvents = (eventsData) => {
  const mapped = mapEvents(eventsData);
  return {
    type: SAVE_UPCOMING_EVENTS,
    events: mapped,
  };
};

const saveCancelledEventsCount = (cntInfo, tz) => {
  // remove cancelled events with no order (those events were removed from the order but the overall order was NOT cancelled)
  const countInfo = (cntInfo ?? []).map((c) => ({
    ...c,
    cancelledIDs: (c.cancelledIDs ?? []).filter((info) => !_.isEmpty(info.orderID ?? '')),
  }));
  // prepare map
  const mapped = (countInfo ?? []).reduce(
    (red, c) => ({
      ...red,
      [c.date]: {
        date: c.date,
        cancelledEvents: 0,
        cancelledIDs: [],
      },
    }),
    {},
  );

  // map cancelled events to timezone dates
  (countInfo ?? []).forEach((count) => {
    if (count.cancelledEvents === 0) {
      if (!mapped[count.date]) mapped[count.date] = count;
    } else {
      (count.cancelledIDs ?? []).forEach((ev) => {
        const tzDate = moment.utc(ev.startTime).tz(tz).format(DATE_PICKER_FOMAT);
        const info = { ...(mapped[tzDate] ?? {}) };
        info.cancelledEvents = (info.cancelledEvents ?? 0) + 1;
        info.cancelledIDs = [...(info.cancelledIDs ?? []), ev];
        mapped[tzDate] = { ...info };
      });
    }
  });

  // save in redux state
  return {
    type: SAVE_CANCELLED_EVENTS_COUNT,
    payload: mapped,
  };
};

const resetCancelledEventsCount = () => ({
  type: RESET_CANCELLED_EVENTS_COUNT,
});

export const updateEvent = (event) => ({
  type: UPDATE_EVENT,
  event,
});

export const updateUpcomingEvent = (event) => ({
  type: UPDATE_UPCOMING_EVENT,
  event,
});

export const resetState = () => ({
  type: RESET_EVENTS_STATE,
});

export const addEvent = (payload) => ({
  type: ADD_EVENT,
  payload,
});

export const addBlockedDay = (payload) => ({
  type: UNBLOCK_DAY,
  payload,
});

export const removeBlockedDay = (date) => ({
  type: BLOCK_DAY,
  date,
});

export const updateEventById = (eventId, event) => async (dispatch, getState) => {
  const payload = {
    startTime: moment.utc(event.start),
    endTime: moment.utc(event.end),
    allDay: event.allDay || false,
    eventType: EVENT_TYPE.TIME_BLOCK,
    instructorID: event.instructorID ?? instructorIdSelector(getState()),
    subcontracted: Boolean(event.subcontracted),
    notes: event.notes,
    blockType: event.blockType,
    reason: event.reason,
  };
  try {
    const response = await EventsAPI.updateEvent(eventId, payload, timezoneSelector(getState()));
    if (response && OK_STATUS_CODES.includes(response.status)) {
      await dispatch(updateEvent(response.data));
      await dispatch(updateUpcomingEvent(response.data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteEvent = (id) => ({
  type: DELETE_TIMEBLOCK_EVENT,
  id,
});

export const deleteUpcomingEvent = (id) => ({
  type: DELETE_UPCOMING_TIMEBLOCK,
  id,
});

export class EventOverlapError extends Error {
  constructor() {
    super('This event overlaps another event. Please, select a different time.');
    this.name = 'EventOverlapError';
  }
}

export const createEvent = (start, end, desc) => async (dispatch, getState) => {
  const newEventType = desc && desc.eventType;
  let payload;
  // TODO: need to fix this for duplicate orders. TCI while duplicating orders should pull from prev order data and not from redux state
  const selectedTci =
    desc.bookingMode === BOOKING_MODE.duplicate && !desc.subcontracted
      ? reportingTciById(getState(), desc.courses?.[0]?.instructorID) // if duplicating order, look at reporting TCIs to look for instructor data
      : selectedTciSelector(getState());
  const currentUser = userInfoSelector(getState());
  const defaultTruckNum = ((selectedTci || currentUser) ?? {}).defaultTruckNumber;

  try {
    switch (newEventType) {
      case EVENT_TYPE.ON_SITE:
        payload = await createOrderPayload(getState(), desc, selectedTci, defaultTruckNum);
        break;
      case EVENT_TYPE.OPEN_ENROLLMENT:
        payload = [
          {
            startTime: start,
            endTime: end,
            allDay: false,
            eventType: EVENT_TYPE.OPEN_ENROLLMENT,
            notes: desc.notes,
            instructorID: selectedTci ? selectedTci.uid : undefined,
            participants: parseInt(desc.participants, 10),
            location: desc.location.id,
            course: desc.openEnrollmentCourse.course.id,
            truckNumber: defaultTruckNum,
            subcontracted: Boolean(selectedTci?.subcontractor),
          },
        ];
        break;
      case EVENT_TYPE.TIME_BLOCK:
        payload = [
          {
            startTime: start,
            endTime: desc.allDay ? end.set({ hour: 23, minute: 59 }) : end,
            eventType: EVENT_TYPE.TIME_BLOCK,
            allDay: desc.allDay || false,
            notes: desc.notes,
            blockType: desc.blockType,
            instructorID: selectedTci ? selectedTci.uid : desc.series ? currentUser?.uid : undefined,
            reason: desc.reason,
            subcontracted: Boolean(selectedTci?.subcontractor),
            seriesPayload: desc.series,
          },
        ];
        break;
      default:
        payload = 'Bad params passed to createEvent';
    }
  } catch (e) {
    throw e;
  }

  try {
    const response = await EventsAPI.createEvent(payload, timezoneSelector(getState()));
    if (response && OK_STATUS_CODES.includes(response.status)) {
      const parentsIdsUpdate = await dispatch(updateParentIds(payload, response.data.eventIDs));
      if (parentsIdsUpdate) {
        const eventID = response?.data?.eventIDs?.[0];
        dispatch(postOrderNotification({ eventID, notificationType: NOTIFICATION_TYPE.BOOKED_EVENT }));
      }
      return response;
    }

    if (response.data.toLowerCase().includes('this event overlaps another event')) {
      throw new EventOverlapError();
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

const updateParentIds = (payload, ids) => async () => {
  try {
    const withSkillchecks = payload.filter((e) => e.isSkillcheck).length > 0;
    if (!withSkillchecks) return true;
    const newIds = ids ?? [];
    if (newIds.length < payload.length) throw new Error();
    const parentIdUpdatePayload = [];
    payload.forEach((e, i) => {
      if (e.isSkillcheck && i > 0) {
        parentIdUpdatePayload.push({
          eventID: newIds[i],
          parentID: newIds[i - 1],
        });
      }
    });
    const response = await EventsAPI.updateParentEventID(parentIdUpdatePayload);
    return response && OK_STATUS_CODES.includes(response.status);
  } catch (error) {
    throw error;
  }
};

export const updateCurrEvent = (values) => async (dispatch) => {
  const payload = {
    ...values.desc,
  };
  dispatch(updateEvent(payload));
  return payload;
};

export const fetchEvents = () => async (dispatch, getState) => {
  const timezone = timezoneSelector(getState());
  const selectedTci = getState().users?.data?.selectedTci;
  if (selectedTci) {
    return selectedTci.uid ? dispatch(fetchTciEvents(selectedTci.uid)) : null;
  }
  const viewport = viewportSelector(getState());
  const startTime = moment.utc(viewport.start).startOf('day');
  const endTime = moment.utc(viewport.end).endOf('day');
  try {
    const response = await EventsAPI.getEvents(startTime.toISOString(), endTime.toISOString(), timezone);
    if (response && response.data) {
      dispatch(saveEvents(response.data.events));
      dispatch(saveDateRevenue(response.data.revenueByDate));
      dispatch(saveUnblockedDays(response.data.unblockedDays));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchTciEvents = (id, dontSave, viewPortOverride) => async (dispatch, getState) => {
  const viewport = viewPortOverride ?? viewportSelector(getState());
  const timezone = timezoneSelector(getState());
  const startTime = moment(viewport.start).utc().startOf('day');
  const endTime = moment(viewport.end).utc().endOf('day');
  try {
    const response = await EventsAPI.getTciEvents(id, startTime.toISOString(), endTime.toISOString(), timezone);
    if (response && response.data) {
      if (!dontSave) dispatch(saveEvents(response.data.events));
      if (!dontSave) dispatch(saveDateRevenue(response.data.revenueByDate));
      dispatch(saveUnblockedDays(response.data.unblockedDays));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    if (!dontSave) dispatch(saveEvents([]));
    throw error;
  }
};

export const resetTciEvents = () => async (dispatch) => {
  try {
    dispatch(saveEvents([]));
    dispatch(resetCancelledEventsCount());
    dispatch(change('CalendarTable', 'openCancelledEventsPopover', false));
    return true;
  } catch (error) {
    dispatch(saveEvents([]));
    dispatch(resetCancelledEventsCount());
    return false;
  }
};

export const fetchLocationEvents =
  ({ locationTcis }) =>
  async (dispatch, getState) => {
    const future = async () => {
      try {
        const events = [];
        await Promise.all(
          (locationTcis.filter((t) => t.uid) ?? []).map(async (tci) => {
            const tciEvents = await dispatch(fetchTciEvents(tci.uid, true, locationsViewportSelector(getState())));
            if (tciEvents) {
              events.push(...tciEvents?.events.map((e) => ({ ...(e ?? {}), instructorID: tci.uid })));
            }
          }),
        );
        return events;
      } catch (error) {
        return [];
      }
    };

    return future();
  };

export const cancelEvent = (eventId) => async (dispatch) => {
  try {
    const response = await EventsAPI.deleteEvent(eventId);
    if (response && response.data) {
      dispatch(deleteEvent(eventId));
      dispatch(deleteUpcomingEvent(eventId));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchUpcomingEvents = () => async (dispatch, getState) => {
  const startTime = moment().utc().startOf('day');
  const endTime = startTime.clone().add(1, 'week');
  const timezone = timezoneSelector(getState());
  try {
    const response = await EventsAPI.getEvents(startTime.toISOString(), endTime.toISOString(), timezone);
    if (response?.data?.events) {
      const events = _.filter(response.data.events, (event) => event.eventType !== EVENT_TYPE.TIME_BLOCK);
      dispatch(saveUpcomingEvents(events));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchTciUpcomingEvents = (id) => async (dispatch, getState) => {
  const startTime = moment().startOf('day').utc();
  const endTime = startTime.clone().add(1, 'week').utc();
  const timezone = timezoneSelector(getState());
  try {
    const response = await EventsAPI.getTciEvents(id, startTime.toISOString(), endTime.toISOString(), timezone);
    if (response && response.data) {
      const events = _.filter(response.data.events, (event) => event.eventType !== EVENT_TYPE.TIME_BLOCK);
      dispatch(saveUpcomingEvents(events));
      return response.data.events;
    }
    throw new Error();
  } catch (error) {
    dispatch(saveUpcomingEvents([]));
    throw error;
  }
};

export const fetchSelectedDayEvents = (start, end, uid) => async (dispatch, getState) => {
  try {
    const selectedTciUid = uid ?? getState().users?.data?.selectedTci?.uid;
    if (selectedTciUid) {
      return await dispatch(fetchTciSelectedDayEvents(selectedTciUid, start, end));
    }

    const response = await EventsAPI.fetchSelectedDayEvents(start, end);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchTciSelectedDayEvents = (uid, start, end) => async () => {
  try {
    const response = await EventsAPI.fetchTciSelectedDayEvents(uid, start, end);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchCancelledEventsDailyCount = () => async (dispatch, getState) => {
  /*
   * DAYS LIST NEEDS TO BE IN FORMAT YYYY-MM-DD
   */

  try {
    const viewport = viewportSelector(getState());
    const daysList = getDaysBetweenDates(viewport.start.add(-1, 'day'), viewport.end.add(1, 'day'), DATE_PICKER_FOMAT);

    const selectedTci = getState().users?.data?.selectedTci;
    if (selectedTci) {
      return await dispatch(fetchTciCancelledEventsDailyCount(selectedTci.uid, daysList));
    }

    const response = await EventsAPI.fetchCancelledEventsDailyCount(daysList);
    if (response && response.data) {
      dispatch(saveCancelledEventsCount(response.data, timezoneSelector(getState())));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchTciCancelledEventsDailyCount = (uid, start, end) => async (dispatch, getState) => {
  try {
    const response = await EventsAPI.fetchTciCancelledEventsDailyCount(uid, start, end);
    if (response && response.data) {
      dispatch(saveCancelledEventsCount(response.data, timezoneSelector(getState())));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const resetTciUpcomingEvents = () => async (dispatch) => {
  try {
    dispatch(saveUpcomingEvents([]));
    return true;
  } catch (error) {
    dispatch(saveUpcomingEvents([]));
    return false;
  }
};

export const fetchEventById = (eventId, dontSave) => async (dispatch) => {
  try {
    const response = await EventsAPI.getEventById(eventId);
    if (response && response.data) {
      if (!dontSave) dispatch(updateEvent(response.data));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const updatePartecipant = (eventId, partecipant) => async () => {
  try {
    const response = await EventsAPI.updatePartecipant(eventId, partecipant);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const savePartecipants = (eventId, partecipantsList) => async () => {
  try {
    const response = await EventsAPI.savePartecipants(eventId, partecipantsList);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const saveRoster = (eventId, fromData) => async () => {
  try {
    const response = await EventsAPI.saveRoster(eventId, fromData);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const unblockDay = (payload) => async (dispatch) => {
  await dispatch(addBlockedDay(payload));
};

export const blockDay = (date) => async (dispatch) => {
  await dispatch(removeBlockedDay(date));
};
