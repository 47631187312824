/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import React from 'react';
import { DEFAULT_ROUTE_REGEX, NOTES_DISPLAY_BORDER } from '../../../../../../../utils/consts';
import { removeLeadingZeros } from '../../../../../../../utils/helpers';
import FASFieldTitle from '../../../../../../Forms/CustomFormComponents/FASFieldTitle';
import Container from '../../../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../../../LayoutBuilders/ContainerItem';
import SimpleText from '../../../../../../Text/SimpleText';
import { NATIONAL_ACCOUNT_NOTE_TYPE } from '../../../../../../../utils/consts/nationalAccount.consts';
import NationalAccountCourseDisplayHook from '../../../../../../NationalAccountDashboard/NationalAccountCourseDisplay';

export const buildAddress = (option, args) =>
  `${`${
    _.startCase(_.toLower(option.street ?? '')) +
    (option.street ? ((args ?? {}).linebreak ? '\n' : ', ') : '') +
    _.startCase(_.toLower(option.city ?? ''))
  }, ${option.region ?? ''} ${option.postal_code ?? ''}`}`;

const CustomerInfo = ({ customer, boldLabels, flexValues, noNotes }) => {
  const route = customer.Route ?? '';
  const localCustomer = customer?.Local_Customer ?? '';
  const regionalCustomer = customer?.regional_customer ?? '';
  const nationalCustomer = customer?.national_customer ?? '';
  const hasZ = localCustomer || regionalCustomer || nationalCustomer;
  const natAccProgramNotes = NationalAccountCourseDisplayHook({
    noteType: NATIONAL_ACCOUNT_NOTE_TYPE.PROGRAM,
    customer,
  });

  const getCustomerZREG = () => _.startCase(_.toLower(customer?.regional_customer ?? '')) ?? '';
  const getCustomerZNAT = () => _.startCase(_.toLower(customer?.national_customer ?? '')) ?? '';
  const getCustomerZLOC = () => _.startCase(_.toLower(customer?.Local_Customer ?? '')) ?? '';

  const title = (txt) => <FASFieldTitle title={txt} style={{ fontWeight: boldLabels ? 'bold' : 'normal' }} />;

  return (
    <Container flex={12}>
      <ContainerItem flex={flexValues?.[0] ?? 5}>{title('Address')}</ContainerItem>
      <ContainerItem flex={flexValues?.[1] ?? 3}>{title('Route')}</ContainerItem>
      <ContainerItem flex={flexValues?.[2] ?? 4}>{hasZ && title('Higher Level Account(s)')}</ContainerItem>
      <ContainerItem flex={flexValues?.[0] ?? 5}>
        <SimpleText txt={buildAddress(customer, { linebreak: true, labelLines: true })} />
      </ContainerItem>
      <ContainerItem flex={flexValues?.[1] ?? 3}>
        <SimpleText txt={!DEFAULT_ROUTE_REGEX.exec(route) ? removeLeadingZeros(route) : 'Not Routed'} />
      </ContainerItem>
      {hasZ && (
        <ContainerItem flex={flexValues?.[2] ?? 4}>
          <SimpleText
            txt={`${customer?.Local_Customer ? `\nZLOC: ${getCustomerZLOC()}` : ''}
          ${customer?.regional_customer ? `\nZREG: ${getCustomerZREG()}` : ''}
          ${customer?.national_customer ? `\nZNAT: ${getCustomerZNAT()}` : ''}`}
          />
        </ContainerItem>
      )}
      {!noNotes && natAccProgramNotes.hasNotes && (
        <ContainerItem flex={12} style={{ border: NOTES_DISPLAY_BORDER, padding: '0 8px 8px 8px', marginTop: 8 }}>
          {title(natAccProgramNotes.label)}
          {natAccProgramNotes.noteComponent()}
        </ContainerItem>
      )}
    </Container>
  );
};

export default CustomerInfo;
