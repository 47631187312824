/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const getCourseCategories = () => baseInstance.get('/courseCategories');

export const updateCourseCategory = (id, payload) => baseInstance.put(`/courseCategories/${id}`, payload);

export const createCourseCategory = (payload) => baseInstance.post('/courseCategories', payload);

export const deleteCourseCategory = (uid) => baseInstance.delete(`/courseCategories/${uid}`);
