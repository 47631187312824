/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import StyledButton from './StyledButton';
import Spinner from '../SpinnerOverlay/Spinner';
import { BOOKING_MODE, COLORS, EVENT_TYPE } from '../../utils/consts';
import { hideModal, showModal } from '../../redux/actions/modals.actions';
import EventDialog from '../Calendar/CalendarComponents/EventDialog/EventDialog';
import { prepareOnSiteEditingForm } from '../../utils/event.utils';
import { createCustomerQuote, emailCustomerQuote } from '../../redux/actions/customers.actions';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import { cleanRows } from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CartSummaryForm';

const CreateCustomerQuoteButton = ({
  customer,
  tz,
  initialData,
  reference,
  appendEmptyEvent = true,
  onCreated,
  ...rest
}) => {
  const [loadingEdits, setLoadingEdits] = React.useState(false);
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const onSubmitQuote = async (values, modalName) => {
    const quoteData = await dispatch(createCustomerQuote(customer.sold_to, values));
    if (quoteData) {
      cleanRows();
      dispatch(hideModal(modalName));
      showEmailQuoteConfirmation(quoteData);
      if (onCreated) onCreated(quoteData);
    }
  };

  const sendEmailToCustomer = async (quoteData) => {
    const customerID = customer.sold_to;
    const success = await dispatch(
      emailCustomerQuote({
        email: quoteData?.contact?.email ?? '',
        customer: customerID,
        quote: quoteData.quoteID,
      }),
    );
    showToast(success);
  };

  const showToast = (success, msg) => {
    dispatch(
      showModal('COMPLETE_ORDER_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: msg ?? (success ? 'Email sent successfully' : 'Email failed to send'),
        },
      }),
    );
  };

  const showEmailQuoteConfirmation = (quoteData) => {
    const modalName = 'EMAIL_QUOTE_CONFIRM_DIALOG';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          confirmText: 'SEND',
          cancelText: "DON'T SEND",
          maxWidth: 'sm',
          title: 'EMAIL QUOTE',
          content: (
            <div style={{ padding: 22 }}>
              <Typography>Would you like to email this quote?</Typography>
              {container({
                spacing: 1,
                style: { justifyContent: 'flex-start' },
                children: [
                  item({
                    flex: 'auto',
                    children: [<Typography>Recepient:</Typography>],
                  }),
                  item({
                    flex: 'auto',
                    children: [
                      <Typography style={{ fontWeight: 'bold' }}>{`${quoteData.contact?.email ?? ''}`}</Typography>,
                    ],
                  }),
                ],
              })}
            </div>
          ),
          onConfirm: () => {
            sendEmailToCustomer(quoteData);
            dispatch(hideModal(modalName));
          },
        },
      }),
    );
  };

  const showQuoteCreationDialog = () => {
    const modalName = 'EDIT_QUOTE_DIALOG';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          deleteText: 'delete',
          disableBackdropClick: true,
          maxWidth: 'md',
          title: 'NEW QUOTE',
          nestedScrolling: true,
          content: (
            <EventDialog
              modalName={modalName}
              onSubmit={(values) => onSubmitQuote(values, modalName)}
              updatedEvent={{ desc: { eventType: EVENT_TYPE.QUOTE } }}
              mode={BOOKING_MODE.quote}
            />
          ),
        },
      }),
    );
  };

  const createNewQuote = async () => {
    setLoadingEdits(true);
    try {
      const id = `Q${`${Date.now().valueOf()}`.padEnd(9, '0').substring(0, 10)}`;
      await prepareOnSiteEditingForm({
        dispatch,
        tz,
        formName: 'AddEventDialog',
        noDefaultDateTime: true,
        appendEmptyEvent,
        initialData: { desc: { eventType: EVENT_TYPE.QUOTE, ...(initialData ?? {}), quoteID: id } },
        preloaded: {
          customerObj: customer,
          customer: customer.sold_to,
          eventType: EVENT_TYPE.QUOTE,
          ...(initialData ?? {}),
        },
      });
      showQuoteCreationDialog();
    } finally {
      setLoadingEdits(false);
    }
  };

  const container = ({ children, ...rst }) => <Container {...(rst ?? {})}>{children}</Container>;
  const item = ({ children, ...rst }) => <ContainerItem {...(rst ?? {})}>{children}</ContainerItem>;

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20,
        width: 20,
        padding: 0,
        margin: 0,
        color: COLORS.CINTAS_WHITE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        padding: 0,
      }}
    />
  );

  return (
    <StyledButton
      color="primary"
      variant="contained"
      startIcon={<AddIcon />}
      buttonContent={loadingEdits ? spinner() : 'Create Quote'}
      handleButton={createNewQuote}
      reference={reference}
      {...rest}
    />
  );
};

export default _.flow([connect((state) => ({ tz: timezoneSelector(state) }))])(CreateCustomerQuoteButton);
