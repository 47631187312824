/* eslint-disable max-len */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import SimpleText from '../../../../Text/SimpleText';
import { COLORS } from '../../../../../utils/consts';
import Spinner from '../../../../SpinnerOverlay/Spinner';
// import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';

const SimpleListSelectionDialogContent = withStyles(() => ({
  root: {
    minWidth: 350,
    maxWidth: 350,
    minHeight: 320,
    maxHeight: 320,
    padding: '20px 10px',
    textAlign: 'center',
  },
}))(MuiDialogContent);

const lineStyle = {
  borderBottom: `1px solid ${COLORS.CINTAS_GRAY}`,
  padding: '10px 0px',
};

// options param in form {label: <string>, id: <string>}
const SimpleListSelectionDialog = ({ options, selectable, onSelection }) => {
  const [loading, setLoading] = React.useState(false);
  const sortedOptions = [...options]
    .sort()
    .reduce((red, l) => [...red, ...(!red.filter((li) => li.id === l.id).length ? [l] : [])], []);

  const spinner = () => <Spinner customStyle={{ marginTop: '30%' }} />;

  return (
    <SimpleListSelectionDialogContent>
      {loading ? (
        spinner()
      ) : (
        <SimpleText
          txt={sortedOptions.reduce((reduced, opt) => `${reduced}${reduced ? '\n' : ''}${opt.label}`, '')}
          getLineProps={
            selectable
              ? (__, i) => ({
                  onClick: () => onSelection(sortedOptions[i], setLoading),
                })
              : undefined
          }
          withSubtitle
          style={lineStyle}
          subtitleStyle={lineStyle}
        />
      )}
    </SimpleListSelectionDialogContent>
  );
};
export default SimpleListSelectionDialog;
