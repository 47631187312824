/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { connect } from 'react-redux';
import React from 'react';
import MaterialsTable from '../../../components/Settings/Materials/MaterialsTable';

import * as MaterialsActions from '../../../redux/actions/materials.actions';
import * as SAPActions from '../../../redux/actions/sap.actions';
import * as CertificationsActions from '../../../redux/actions/certifications.actions';
import SettingsFrame from './SettingsFrame';
import { allMaterialsListSelector } from '../../../redux/selectors/materials.selector';

class MaterialsView extends React.Component {
  async componentDidMount() {
    const { dispatch } = this.props;

    await dispatch(SAPActions.fetchSAPMaterials());
    await dispatch(MaterialsActions.fetchMaterials());
    await dispatch(CertificationsActions.fetchCertifications());
  }

  render() {
    const {
      sapMaterials,
      materials: { data: materialsData },
      certifications: { data: certificationsData },
      match: { path },
    } = this.props;
    return (
      <SettingsFrame path={path}>
        <MaterialsTable sapMaterials={sapMaterials} materials={materialsData} certifications={certificationsData} />
      </SettingsFrame>
    );
  }
}

const mapStateToProps = (state) => ({
  sapMaterials: state.sap.materials,
  materials: { data: allMaterialsListSelector(state) },
  certifications: state.certifications,
});

export default _.flow([connect(mapStateToProps)])(MaterialsView);
