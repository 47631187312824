import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { hideModal, showModal } from '../../redux/actions/modals.actions';
import { COLORS, SIGNATURE_PAD_HEIGHT, SIGNATURE_PAD_WIDTH } from '../../utils/consts';
import StyledButton from '../Button/StyledButton';
import DeleteConfirmAlert from '../Calendar/CalendarComponents/EventPopper/DeleteConfirmAlert';
import FASImage from '../Forms/CustomFormComponents/FASImage';

const useStyles = makeStyles({
  sigCanvasContainer: {
    height: 245,
    width: ({ width }) => width ?? '80%',
    border: `1px solid ${COLORS.CINTAS_LIGHT_GRAY}`,
    margin: ({ noMargin }) => (noMargin ? 0 : 'auto'),
  },
  alignedCenter: {
    width: ({ width }) => width ?? '80%',
    margin: ({ noMargin }) => (noMargin ? 0 : 'auto'),
  },
  sigCanvas: {
    height: `${SIGNATURE_PAD_HEIGHT}px`,
    width: `${SIGNATURE_PAD_WIDTH}px`,
  },
  img: {
    height: '98%',
    maxHeight: '98%',
    width: 'ihnerit',
    objectFit: 'cover',
  },
});

const CintasSignPad = ({ imgUrl, setSigPad, getSigPad, onEnd, onCleared, noMargin, width, clearConfItemType }) => {
  const classes = useStyles({ noMargin, width });
  const dispatch = useDispatch();

  const handleClear = () => {
    const modalName = 'DELETE_SIGNATURE_CONF';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          confirmText: 'confirm',
          disableBackdropClick: true,
          maxWidth: 'md',
          title: 'DELETE SIGNATURE',
          content: <DeleteConfirmAlert itemTypeName={clearConfItemType ?? "customer's signature"} action="clear" />,
          onConfirm: () => {
            dispatch(hideModal(modalName));
            getSigPad().clear();
            if (onCleared) {
              onCleared();
            }
          },
        },
      }),
    );
  };

  const container = ({ children, ...rest }) => <div {...rest}>{children}</div>;

  const staticSignature = () => <FASImage height="245" alt="Signature" src={imgUrl} className={classes.img} />;

  const signaturePad = () => (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <SignatureCanvas
        penColor="black"
        canvasProps={{ className: classes.sigCanvas }}
        ref={(ref) => {
          setSigPad(ref);
        }}
        onEnd={onEnd}
        backgroundColor="rgba(255,255,255,255)"
        clearOnResize={false}
      />
    </>
  );

  const btn = ({ label, onClick, color, variant, disabled }) => (
    <StyledButton
      variant={variant ?? 'text'}
      buttonContent={label}
      handleButton={onClick}
      color={color}
      disabled={disabled}
    />
  );

  return (
    <>
      {container({
        key: 1,
        className: classes.sigCanvasContainer,
        children: imgUrl ? staticSignature() : signaturePad(),
      })}
      {container({
        key: 2,
        className: classes.alignedCenter,
        style: { marginTop: '1vh', textAlign: 'right', alignContent: 'right' },
        children: !imgUrl ? btn({ label: 'clear', onClick: handleClear }) : <></>,
      })}
    </>
  );
};

export default CintasSignPad;
