/* eslint-disable max-len */
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { mainSections } from '../../routes/sections';
import TopBarButton from './TopBarButton';

import * as AuthActions from '../../redux/actions/auth.actions';
import cintasLogo from '../../assets/images/cintas-logo.png';
import tcLogo from '../../assets/images/training_central_logo.png';
import { hasUserAccessSelector, userInfoSelector } from '../../redux/selectors/user.selectors';
import NotificationsIconButton from '../Button/NotificationsIconButton';
import { panelTypes } from '../../redux/selectors/notifications.tasks.selector';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
  },
  rightSection: {
    display: 'flex',
  },
  leftSection: {
    fontFamily: 'proxima-nova',
    fontWeight: 'Bold',
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

const TopBar = (props) => {
  const { dispatch } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const history = useHistory();
  const user = useSelector((s) => userInfoSelector(s) ?? {});

  const displaySections = useSelector((state) =>
    _.filter(mainSections, (section) => hasUserAccessSelector(state, section.matrixAccesskeys)),
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navToAccount = async () => {
    handleMenuClose();
    history.push('/myAccount');
  };

  const logout = async () => {
    handleMenuClose();
    await dispatch(AuthActions.logout());
    history.push('/');
  };

  const topbuttons = (
    <div className={classes.buttons}>
      <NotificationsIconButton type={panelTypes.task} />
      <NotificationsIconButton />
      {_.map(displaySections, (section, index) => (
        <TopBarButton
          title={section.title}
          icon={section.icon}
          path={section.path}
          key={index + 1}
          index={index + 1}
          whitelist={section.whitelist}
        />
      ))}
    </div>
  );

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={navToAccount}>My account</MenuItem>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  /*
z-index of AppBar is 400; z-index for event_dialog is 500, so AppBar will be under event_dialog;
z-index of timepicker dropdown is 1070, so event_dialog will be under timepicker dropdown
*/
  return (
    <div className={classes.grow}>
      <AppBar color="inherit" position="fixed" elevation={4} style={{ zIndex: 400 }}>
        <Toolbar>
          <div className={classes.leftSection}>
            <img src={cintasLogo} alt="Cintas Logo" width="60%" />
          </div>
          <div className={classes.leftSection}>
            <img src={tcLogo} alt="Training Central Logo" width="22%" />
          </div>
          <div className={classes.grow} />
          <div className={classes.rightSection}>
            {/* ----- START TEMP USER INFO IN TOPBAR ----- */}
            <Typography
              color="primary"
              style={{
                fontFamily: 'proxima-nova, sans-serif',
                margin: 'auto',
                marginLeft: 16,
                fontWeight: 'bold',
              }}
            >
              {`${user.firstName} ${user.lastName} - ${user.usrid}`}
            </Typography>
            {/* ----- END TEMP USER INFO IN TOPBAR ----- */}
            {topbuttons}
            <Tooltip title="Profile" arrow>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="primary"
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
};

export default connect()(TopBar);
