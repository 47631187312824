import Immutable from 'seamless-immutable';
import {
  ADD_COURSE_CATEGORY,
  SAVE_COURSE_CATEGORIES,
  UPDATE_COURSE_CATEGORY,
} from '../actions/actionTypes/courseCategories';

const initialState = Immutable({
  data: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_COURSE_CATEGORIES:
      return state.set('data', action.categories);
    case UPDATE_COURSE_CATEGORY:
      return state.set(
        'data',
        state.data.map((el) => (el.id === action.category.id ? action.category : el)),
      );
    case ADD_COURSE_CATEGORY:
      return state.set('data', [...state.data, action.category]);
    default:
      return state;
  }
};
