/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import styled from 'styled-components';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Container from '../../../../utils/LayoutBuilders/Container';
import ContainerItem from '../../../../utils/LayoutBuilders/ContainerItem';
import SimpleText from '../../../Text/SimpleText';
import { COLORS } from '../../../../utils/consts';
import StyledButton from '../../../Button/StyledButton';
import { hideModal, showModal } from '../../../../redux/actions/modals.actions';
import { unblockDay } from '../../../../redux/actions/users.actions';
import { userInfoSelector } from '../../../../redux/selectors/user.selectors';
import MDAutoComplete from '../../../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import { timezoneSelector } from '../../../../redux/selectors/utils.selectors';

const StyledTimePicker = styled(MDAutoComplete)`
  & .rc-time-picker-input {
    background: #f1f3f4;
    border: none;
    text-align: center;
    border-radius: 8;

    &:disabled {
      cursor: auto;
      background: #f1f3f4;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #000;
      opacity: 1; /* Firefox */
    }
  }
`;

const formName = 'UnblockDayHoursForm';
const dayFieldID = 'day';
const startTimeFieldID = 'startTime';
const endTimeFieldID = 'endTime';

const UnblockDayDialog = ({ day, dispatch, formData, user }) => {
  const userTz = useSelector(timezoneSelector);

  React.useEffect(async () => {
    dispatch(change(formName, dayFieldID, moment(day).format('YYYY-MM-DD')));
    return () => {};
  }, []);

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;

  const getOptions = () => {
    const currentTime = moment('12:00 AM', 'h:mm A');
    return Array.from(Array(48)).map((__, i) =>
      currentTime
        .clone()
        .add(60 * i, 'minutes')
        .format('h:mm A'),
    );
  };

  const timePicker = (id) => (
    <Field
      id={id}
      name={id}
      component={StyledTimePicker}
      options={getOptions()}
      required
      size="small"
      allowEmpty={false}
      autoComplete="date"
      noHelperText
      noErrorLabel
      popupIcon={<ArrowDropDownIcon color="primary" />}
      disableClearable
    />
  );

  const showToast = (success, msg, warning) => {
    dispatch(
      showModal('UNBLOCK_DAY_STATUS', {
        modalType: warning ? 'WARNING_ALERT' : success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: msg ?? '',
        },
      }),
    );
  };

  const submit = async () => {
    if (moment(formData[startTimeFieldID], 'hh:mm A').isSameOrAfter(moment(formData[endTimeFieldID], 'hh:mm A'))) {
      showToast(false, 'Start time should be greater than end time');
      return;
    }
    const dayToUnblock = formData[dayFieldID];
    const startTime = moment.utc(
      moment.tz(`${dayToUnblock} ${formData[startTimeFieldID]}`, 'YYYY-MM-DD h:mm A', userTz),
    );
    const endTime = moment.utc(moment.tz(`${dayToUnblock} ${formData[endTimeFieldID]}`, 'YYYY-MM-DD h:mm A', userTz));
    const payload = {
      startTime: startTime.format('h:mm A'),
      endTime: endTime.format('h:mm A'),
    };
    try {
      const result = await dispatch(unblockDay(user.uid, startTime.format('YYYY-MM-DD'), payload));
      if (!result) throw new Error();
      showToast(true, 'Unblocked day succesfully');
      dispatch(hideModal('UNBLOCK_DAY_DIALOG'));
    } catch (error) {
      showToast(false, 'Error unblocking day');
    }
  };

  const saveButton = () => (
    <StyledButton
      variant="contained"
      color="primary"
      buttonContent="SAVE"
      style={{ height: 40 }}
      handleButton={submit}
    />
  );

  return container({
    style: { padding: 16, width: 500 },
    children: [
      item({
        flex: 12,
        children: text('Select the hours of availabilty for the day:', {
          style: {
            paddingLeft: 10,
            fontSize: 16,
            fontWeight: 'bold',
            color: COLORS.CINTAS_BLUE,
            paddingBottom: 20,
          },
        }),
      }),
      item({ flex: 4, style: { marginLeft: 10 }, children: timePicker(startTimeFieldID) }),
      item({ flex: 1, style: { padding: 10 }, children: text('to') }),
      item({ flex: 4, children: timePicker(endTimeFieldID) }),
      item({ flex: 2, style: { marginLeft: 10 }, children: saveButton() }),
    ],
  });
};

export default _.flow([
  connect((state) => ({
    formData: getFormValues(formName)(state),
    user: userInfoSelector(state),
  })),
  reduxForm({
    form: formName,
    initialValues: {
      startTime: '8:00 AM',
      endTime: '1:00 PM',
    },
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  }),
])(UnblockDayDialog);
