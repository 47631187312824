/* eslint-disable import/prefer-default-export */
import { baseInstance } from './instances/baseInstance';

export const fetchUserUnreadNotifications = (userID, page) =>
  baseInstance.get(`/getUnreadNotifications?userID=${userID}&page=${page}`);

export const fetchUserReadNotifications = (userID, page) =>
  baseInstance.get(`/getReadNotifications?userID=${userID}&page=${page}`);

export const fetchUserNotificationsForDate = (userID, date, unread) =>
  baseInstance.get(
    `/getNotificationsForDate?userID=${userID}&date=${date}&read=${Boolean(!unread).toString().toLowerCase()}`,
  );

export const generateOrderNotification = (payload) => baseInstance.post('/generateOrderNotification', payload);

export const clearScheduledNotification = (type, orderID) =>
  baseInstance.delete(`/clearScheduledNotifications?type=${type}&orderID=${orderID}`);

export const markNotificationRead = (notificationID) =>
  baseInstance.put(`/readNotification?notificationID=${notificationID}`);

export const markAllNotificationsRead = (ids) => baseInstance.post('/markAllNotificationsRead', { ids });
