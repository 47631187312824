import * as DATA_API from '../../api/recurringSeries.api';
import { OK_STATUS_CODES } from '../../utils/consts';
import { updateEvent, updateUpcomingEvent } from './events.actions';

export const getRecurringSeries = async (seriesID) => {
  try {
    const response = await DATA_API.getRecurringSeries(seriesID);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const unlinkSeries = (eventID) => async (dispatch) => {
  try {
    const response = await DATA_API.unlinkSeries(eventID);
    if (response && response.data) {
      await dispatch(updateEvent(response.data[0]));
      await dispatch(updateUpcomingEvent(response.data[0]));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updateSeries = async (seriesID, startDate, payload) => {
  try {
    const response = await DATA_API.updateSeries(seriesID, startDate, payload);
    if (response && (response.data || response.data === 0)) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const deleteSeries = async (seriesID, startDate) => {
  try {
    const response = await DATA_API.deleteSeries(seriesID, startDate);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
