/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import _ from 'lodash';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import * as ModalsActions from '../../../redux/actions/modals.actions';

import FASMUIDataTable from '../CustomMUIDataTable/FASMUIDataTable';
import CategoryModal from '../../../utils/Modals/SettingsModals/CategoryModal';
import { courseCategoriesSelector } from '../../../redux/selectors/courseCategories.selector';
import { deleteCourseCategory } from '../../../redux/actions/courseCategories.actions';
import { materialsDataSelector } from '../../../redux/selectors/materials.selector';
import { MATERIAL_STATUS, MATERIAL_TYPE } from '../../../utils/consts';

const searchOptions = {
  filter: true,
  filterType: 'dropdown',
  searchOpen: true,
  selectableRows: 'none',
  sortOrder: {
    name: 'Name',
    direction: 'desc',
  },
  download: false,
  print: false,
};

const columns = [
  'Name',
  'Status',
  'Color',
  {
    name: '',
    options: {
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: false,
    },
  },
  {
    name: '',
    options: {
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: false,
    },
  },
  {
    name: '',
    options: {
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: false,
    },
  },
];

const CourseCategoriesTable = (props) => {
  const { courseCategories, courses, dispatch } = props;

  const showCategoryModal = (category) => {
    const categoryInUse = category ? Boolean(_.find(courses, { category: category.id })) : false;

    dispatch(
      ModalsActions.showModal('CATEGORY_MODAL', {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          disableBackdropClick: true,
          maxWidth: 'lg',
          title: `${category ? 'EDIT' : 'ADD NEW'} CATEGORY`,
          content: <CategoryModal category={category} dispatch={dispatch} inUse={categoryInUse} />,
        },
      }),
    );
  };

  const showDeleteModal = (category) => {
    dispatch(
      ModalsActions.showModal('DELETE_CATEGORY_MODAL', {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          confirmText: 'DELETE',
          cancelText: 'CANCEL',
          maxWidth: 'sm',
          title: 'DELETE CATEGORY',
          content: <div style={{ padding: '20px 0 0 5px' }}>Are you sure you want to delete this category?</div>,
          onConfirm: async () => {
            try {
              await dispatch(deleteCourseCategory(category.id));
              dispatch(ModalsActions.hideModal('DELETE_CATEGORY_MODAL'));
            } catch (error) {
              const message = 'Error deleting category. Please try again!';
              dispatch(
                ModalsActions.showModal('CATEGORY_ERROR', {
                  modalType: 'ERROR_ALERT',
                  modalProps: { message },
                }),
              );
            }
          },
        },
      }),
    );
  };

  const showInUseModal = () => {
    dispatch(
      ModalsActions.showModal('CATEGORY_IN_USE_MODAL', {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          cancelText: 'OKAY',
          maxWidth: 'sm',
          title: 'CATEGORY IN USE',
          content: (
            <div style={{ padding: '20px 0 0 5px', maxWidth: 350 }}>
              <Typography>The category is currently linked to a course and cannot be deleted.</Typography>
              <Typography>Unlink from all courses to allow deletion.</Typography>
            </div>
          ),
        },
      }),
    );
  };

  const handleDeleteClick = (category) => {
    const categoryInUse = _.find(courses, { category: category.id });

    if (categoryInUse) {
      showInUseModal();
    } else {
      showDeleteModal(category);
    }
  };

  const addButton = () => (
    <Tooltip title="New category">
      <IconButton color="primary" aria-label="Add new category" onClick={() => showCategoryModal()}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );

  const colorDisplay = (color) => <CircleRoundedIcon sx={{ color }} />;

  const editButton = (category) => (
    <IconButton aria-label="edit" onClick={() => showCategoryModal(category)}>
      <EditIcon />
    </IconButton>
  );

  const deleteButton = (category) => (
    <IconButton aria-label="delete" onClick={() => handleDeleteClick(category)}>
      <DeleteIcon />
    </IconButton>
  );

  const rows = _.map(courseCategories, (category) => [
    category.label,
    category.active ? 'Active' : 'Inactive',
    category.color,
    colorDisplay(category.color),
    editButton(category),
    deleteButton(category),
  ]);

  return (
    <FASMUIDataTable
      data={rows}
      columns={columns}
      options={{
        ...searchOptions,
        customToolbar: addButton,
      }}
    />
  );
};
const mapStateToProps = (state) => ({
  courseCategories: courseCategoriesSelector(state),
  courses: _.filter(
    Object.values(materialsDataSelector(state)[MATERIAL_TYPE.COURSE] ?? {}),
    (item) => item.status !== MATERIAL_STATUS.HIDDEN,
  ),
});
export default _.flow([connect(mapStateToProps)])(CourseCategoriesTable);
