import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import MDTextInputField from '../../Forms/FormComponents/MDTextInput/MDTextInputField';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import * as SettingsActions from '../../../redux/actions/settings.actions';
import * as CustomersActions from '../../../redux/actions/customers.actions';
import SettingsTile from './SettingsTile';

const LocationSettings = ({ dispatch, handleSubmit }) => {
  const submitLocation = async (values) => {
    try {
      dispatch(submit('LocationSettings'));
      dispatch(SettingsActions.updateLocation(values.location));
      await dispatch(CustomersActions.fetchCustomers(values.location));
      dispatch(
        ModalsActions.showModal('UPDATE_LOCATION_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: { message: 'Location successfully updated' },
        }),
      );
    } catch (error) {
      const message = 'Error updating location. Please try again!';
      dispatch(
        ModalsActions.showModal('UPDATE_LOCATION_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { message },
        }),
      );
    }
  };

  return (
    <SettingsTile title="Location Settings" onSubmit={handleSubmit(submitLocation)}>
      <Field
        name="location"
        component={MDTextInputField}
        size="small"
        variant="outlined"
        placeholder="Location"
        style={{
          width: 200,
          marginRight: 24,
        }}
      />
    </SettingsTile>
  );
};

export default _.flow([
  connect(),
  reduxForm({
    form: 'LocationSettings',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    onSubmit: submit,
  }),
])(LocationSettings);
