import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { Field } from 'redux-form';
import { COLORS } from '../../../utils/consts';
import Spinner from '../../SpinnerOverlay/Spinner';
import FASFieldTitle from '../CustomFormComponents/FASFieldTitle';
import FASImage from '../CustomFormComponents/FASImage';

const UploadImageInput = ({ onUpload, loading, hideLabel, imageFieldName = 'image' }) => (
  <Grid item sm={12} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
    {!hideLabel && <FASFieldTitle title="Image" />}
    {!loading ? (
      <Field name={imageFieldName} component={FASImage} material />
    ) : (
      <div style={{ display: 'flex', height: 200, margin: '0 auto' }}>
        <Spinner spinnerStyle={{ margin: 'auto' }} />
      </div>
    )}
    <input
      accept="image/*"
      style={{ display: 'none' }}
      id="raised-button-file"
      type="file"
      onChange={(e) => onUpload(e)}
    />
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label htmlFor="raised-button-file" style={{ margin: 'auto', marginTop: 10 }}>
      <Button
        variant="contained"
        style={{
          backgroundColor: COLORS.CINTAS_BLUE,
          border: 'hidden',
          color: 'white',
          borderRadius: '0px',
          fontFamily: 'proxima-nova, sans-serif',
          minWidth: '20px',
        }}
        component="span"
      >
        Upload image
      </Button>
    </label>
  </Grid>
);

export default UploadImageInput;
