/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import { COLORS } from '../../utils/consts';
import { openCancelledEventsPopoverSelector } from '../../redux/selectors/calendar.selectors';
import CancelledEventsPopper from './CalendarComponents/CancelledEventsPopper/CancelledEventsPopper';
import CancelledCourseIcon from './CancelledCourseIcon';

const CancelledEventsHeader = ({ cancelledEventsInfo, fontSize, alignment, formName, spaceBetween, withLineBreak }) => {
  const textSize = fontSize ?? 10;
  const key = cancelledEventsInfo?.date;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(React.createRef());
  const openPopover = useSelector((state) => openCancelledEventsPopoverSelector(state, formName)) === key;

  const popperCloseHandler = () => {
    dispatch(change(formName, 'openCancelledEventsPopover', false));
  };

  const text = (txt) => (
    <Typography style={{ fontSize: textSize, overflow: 'hidden', whiteSpace: 'break-spaces', marginLeft: 2 }}>
      {txt}
    </Typography>
  );

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const icon = () => <CancelledCourseIcon size={11} />;

  return (
    <div
      key={key}
      ref={setAnchorEl}
      style={{ right: 0, textAlign: alignment ?? 'end', alignContent: alignment ?? 'end', width: '100%' }}
    >
      {container({
        spacing: 1,
        style: {
          justifyContent: alignment ?? 'end',
          alignItems: 'baseline',
          paddingRight: 5,
          position: 'relative',
          cursor: 'pointer',
          zIndex: 9,
        },
        children: [
          item({ flex: 1, children: [icon()] }),
          <Box width={spaceBetween ? 5 : 0} />,
          item({
            flex: 'auto',
            children: withLineBreak ? [text('Cancelled'), text('Course(s)')] : [text('Cancelled Course(s)')],
          }),
        ],
      })}
      <CancelledEventsPopper
        open={openPopover}
        anchorEl={anchorEl}
        onClosePopper={popperCloseHandler}
        cancelledEventsInfo={cancelledEventsInfo}
      />
    </div>
  );
};

export default _.flow([connect()])(CancelledEventsHeader);
