import _ from 'lodash';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import React from 'react';
import { useStyles } from './EventSummaryView/EventSummaryView';
import logo from '../../assets/images/logo_ready_for_workday.png';
import Container from '../../components/LayoutBuilders/Container';
import ContainerItem from '../../components/LayoutBuilders/ContainerItem';
import FASTitle from '../../components/Forms/CustomFormComponents/FASTtle';
import LegalText from '../../utils/consts/StaticText/LegalText';
import SimpleText from '../../components/Text/SimpleText';

const TermsAndConditionsView = () => {
  const classes = useStyles();

  const logoImg = () => (
    <ContainerItem flex={12} style={{ textAlign: 'center' }}>
      <img src={logo} alt="Cintas Logo" width="20%" />
    </ContainerItem>
  );

  const title = () => (
    <FASTitle
      title={LegalText.termsAndConditions.title}
      customStyle={{
        fontSize: 24,
        textAlign: 'center',
        paddingTop: 20,
      }}
    />
  );

  const text = (txt, s) => <SimpleText txt={txt} style={{ fontSize: 14, textAlign: 'justify', ...s }} />;

  const body = () => text(LegalText.termsAndConditions.body);

  const termLine = (term, i) => (
    <Container justifyContent="start" spacing={0} style={{ padding: '12px 0px' }}>
      <ContainerItem flex={1}>{text(`${i + 1}.`)}</ContainerItem>
      <ContainerItem flex={11}>{text(term)}</ContainerItem>
    </Container>
  );

  return (
    <Container className={classes.root} maxWidth="md" style={{ justifyContent: 'center' }}>
      <Container style={{ justifyContent: 'center', width: '60%', padding: '5%' }}>
        <Paper className={classes.paper} elevation={3} style={{ width: '100%', marginBottom: 0 }}>
          {logoImg()}
          {title()}
          {body()}
          {LegalText.termsAndConditions.listPoints.map((term, i) => termLine(term, i))}
        </Paper>
      </Container>
    </Container>
  );
};

export default _.flow([withRouter, connect()])(TermsAndConditionsView);
