/* eslint-disable import/no-cycle */
import _ from 'lodash';

import { SAVE_SELECTED_CUSTOMERS_CONTACTS, SAVE_CONTACTS } from './actionTypes/contacts';

import * as ContactsAPI from '../../api/contacts.api';
import { EVENT_TYPE } from '../../utils/consts';
import { allEventsListSelector } from '../selectors/events.selectors';

const saveSelectedCustomerContacts = (payload) => ({
  type: SAVE_SELECTED_CUSTOMERS_CONTACTS,
  payload,
});

const saveContacts = (payload) => ({
  type: SAVE_CONTACTS,
  payload,
});

export const fetchContactsByCustomerId = (id, dontSave) => async (dispatch) => {
  try {
    const response = await ContactsAPI.fetchContactsByCustomerId(id);
    if (response && response.data) {
      if (!dontSave) dispatch(saveSelectedCustomerContacts(response.data));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchContacts = () => async (dispatch, getState) => {
  const eventsData = allEventsListSelector(getState());
  const contacts = _(eventsData)
    .filter((event) => event.eventType !== EVENT_TYPE.TIME_BLOCK)
    .map((event) => ({
      contactId: event.contactID,
      customerId: event.customerID,
    }))
    .value();
  try {
    const res = await Promise.all(
      _.map(contacts, (contact) => ContactsAPI.fetchContact(contact.contactId, contact.customerId)),
    );
    const data = _.map(res, (s) => s.data);
    if (data) {
      dispatch(saveContacts(data));
      return data;
    }
    throw new Error();
  } catch (error) {
    return false;
  }
};

export const fastFetchContacts = () => async (dispatch, getState) => {
  const {
    orders: { upcoming: ordersData },
  } = getState();

  // Filter orders with unique contactID and return contact object
  // TODO: Test with multiple different contacts. At implementation, all orders
  // have the same contact object.
  const contacts = _(ordersData)
    .map((order) => order.contact)
    .uniqBy('SAPContactID')
    .value();

  dispatch(saveContacts(contacts));
  return contacts;
};

export const getContactById = (customerID, contactID) => async () => {
  try {
    const response = await ContactsAPI.fetchContact(contactID, customerID);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};
