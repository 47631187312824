import React from 'react';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { FieldArray, Field, reduxForm, submit } from 'redux-form';
import { Grid, InputAdornment } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
// import EditIcon from '@material-ui/icons/Edit';
import StyledButton from '../../Button/StyledButton';
import FASFieldTitle from '../../Forms/CustomFormComponents/FASFieldTitle';
import MDTextInputField from '../../Forms/FormComponents/MDTextInput/MDTextInputField';
import * as CommissionLevelsActions from '../../../redux/actions/commissionLevels.actions';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import SettingsTile from './SettingsTile';

const validate = (values) => {
  const errors = {
    commissionLevels: [],
  };
  if (!values.commissionLevels || !values.commissionLevels.length) {
    errors.commissionLevels = { _error: 'At least one course must be entered' };
  } else {
    values.commissionLevels.forEach((cl, clIndex) => {
      if (cl === '' || Number.isNaN(cl) || !cl) {
        errors.commissionLevels[clIndex] = 'Required';
      }
      if (clIndex !== 0 && Number(cl) < values.commissionLevels[clIndex - 1]) {
        errors.commissionLevels[clIndex] = 'Must larger than previous level';
      }
      if (Number(cl) < 0 || Number(cl) > 100) {
        errors.commissionLevels[clIndex] = 'Not a valid number';
      }
    });
  }
  return errors;
};

const renderMembers = ({ fields, commissionPercentages }) => {
  const [CL4, setCL4] = React.useState(false);
  React.useEffect(() => {
    if (commissionPercentages) {
      setCL4(commissionPercentages.length === 4);
      fields.removeAll();
      _.forEach(commissionPercentages.map(String), (cl) => fields.push(cl));
    }
  }, [commissionPercentages]);

  const disableCLHandler = () => {
    if (CL4) {
      fields.remove(fields.length - 1);
    } else {
      fields.push(commissionPercentages[3] ?? '100');
    }
    setCL4(!CL4);
  };

  return (
    <Grid>
      <Grid container spacing={3}>
        {_.map(fields, (member, idx) => (
          <React.Fragment key={`commissionLevels[${idx}]`}>
            <Grid item xs={3}>
              <FASFieldTitle title={`Commission Level ${idx + 1}`} />
              <Field
                variant="outlined"
                placeholder={`CL${idx + 1}`}
                id={`commissionLevels[${idx}]`}
                name={`commissionLevels[${idx}]`}
                component={MDTextInputField}
                type="number"
                min={0}
                max={100}
                size="small"
                noBorderRadius
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <DialogActions style={{ paddingTop: '20px', justifyContent: 'space-between' }}>
        <StyledButton
          variant="outlined"
          color="primary"
          buttonContent={CL4 ? 'Disable CL4' : 'Enable CL4'}
          handleButton={disableCLHandler}
        />
      </DialogActions>
    </Grid>
  );
};

const CommissionLevelEditTable = ({ dispatch, handleSubmit }) => {
  const commissionPercentages = useSelector((state) => state.cl.data.levels);

  const submitCommissionLevelsHandler = async (values) => {
    try {
      dispatch(submit('CommissionLevelEditTable'));
      // console.log('event', values);
      const payload = {
        levels: values.commissionLevels.map(Number),
        created: new Date().toISOString(),
      };
      // console.log('payloddd', payload);
      await dispatch(CommissionLevelsActions.createCommissionLevels(payload));
      dispatch(
        ModalsActions.showModal('CREATE_COMMISSIONLEVEL_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: { message: 'Commission Level successfully created' },
        }),
      );
    } catch (error) {
      const message = 'Error creating new Commission Level. Please try again!';
      dispatch(
        ModalsActions.showModal('CREATE_COMMISSIONLEVEL_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { message },
        }),
      );
    }
  };

  return (
    <SettingsTile
      title="Commission Levels"
      onSubmit={handleSubmit(submitCommissionLevelsHandler)}
      getActionButton={(ActionBtn) => <DialogActions>{ActionBtn}</DialogActions>}
    >
      <FieldArray name="commissionLevels" component={renderMembers} commissionPercentages={commissionPercentages} />
    </SettingsTile>
  );
};

export default _.flow([
  connect(),
  reduxForm({
    form: 'CommissionLevelEditTable',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
    onSubmit: submit,
  }),
])(CommissionLevelEditTable);
