import { SAVE_COURSE_CATEGORIES, UPDATE_COURSE_CATEGORY, ADD_COURSE_CATEGORY } from './actionTypes/courseCategories';

import * as CourseCategoriesAPI from '../../api/courseCategories.api';

export const saveCategories = (categories) => ({
  type: SAVE_COURSE_CATEGORIES,
  categories,
});

const updateCategory = (category) => ({
  type: UPDATE_COURSE_CATEGORY,
  category,
});

const addCategory = (category) => ({
  type: ADD_COURSE_CATEGORY,
  category,
});

export const createCourseCategory = (payload) => async (dispatch) => {
  try {
    const response = await CourseCategoriesAPI.createCourseCategory(payload);
    if (response && response.data) {
      await dispatch(addCategory(response.data));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchCourseCategories = () => async (dispatch) => {
  try {
    const response = await CourseCategoriesAPI.getCourseCategories();
    if (response) {
      const categories = response.data || [];
      dispatch(saveCategories(categories));
      return categories;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const updateCourseCategory = (id, payload) => async (dispatch) => {
  try {
    const response = await CourseCategoriesAPI.updateCourseCategory(id, payload);
    if (response && response.data) {
      dispatch(updateCategory(response.data));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const deleteCourseCategory = (uid) => async (dispatch) => {
  try {
    const response = await CourseCategoriesAPI.deleteCourseCategory(uid);
    if (response.status === 204) {
      await dispatch(fetchCourseCategories());
      return true;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};
