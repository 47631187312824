/* eslint-disable max-len */
import _ from 'lodash';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import FASMUIDataTable from '../CustomMUIDataTable/FASMUIDataTable';

const searchOptions = {
  filter: true,
  filterType: 'dropdown',
  selectableRows: 'none',
  sortOrder: {
    name: 'Title',
    direction: 'asc',
  },
  downloadOptions: {
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  },
};

// const boolIcon = (value) => (value ? <CheckIcon color="primary" /> : <ClearIcon color="secondary" />);

const columns = [
  {
    name: 'Title',
  },
  // {
  //   name: 'Status',
  //   options: {
  //     filter: true,
  //   },
  // },
  {
    name: '',
    options: {
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
      download: false,
    },
  },
];

const MaterialClassificationTable = ({ classifications }) => {
  const history = useHistory();

  const editButton = (id) => (
    <IconButton aria-label="edit" onClick={() => history.push(`/admin/addonClassifications/${id}`)}>
      <EditIcon />
    </IconButton>
  );

  const rows = _.map(classifications, (classification) => [
    // const activeDisplay = classification.enabled ? 'Active' : 'Inactive';
    classification.title,
    // activeDisplay,
    editButton(classification.id),
  ]);

  return (
    <FASMUIDataTable
      data={rows}
      columns={columns}
      options={searchOptions}
      root={{
        padding: '5px 15px 5px',
        '&:nth-child(1)': {
          width: '80%',
        },
        '&:nth-child(2)': {
          width: '10%',
        },
      }}
    />
  );
};

export default MaterialClassificationTable;
