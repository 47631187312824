/* eslint-disable arrow-body-style */
import React from 'react';
import { COLORS } from '../../utils/consts';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import SimpleText from '../Text/SimpleText';

const DrawerSection = ({ header, title, actions, content }) => {
  const withSubtitle = Boolean(header);
  const container = ({ children, ...rst }) => <Container {...(rst ?? {})}>{children}</Container>;
  const item = ({ children, ...rst }) => <ContainerItem {...(rst ?? {})}>{children}</ContainerItem>;

  return (
    <div style={{ marginBottom: 20 }}>
      {container({
        style: { justifyContent: 'space-between', alignItems: 'center', marginBottom: 14 },
        children: [
          item({
            flex: 'auto',
            children: [
              <SimpleText
                txt={`${header ? `${header}\n` : ''}${title ?? ''}`}
                color={withSubtitle ? COLORS.CINTAS_BLACK : COLORS.CINTAS_MEDIUM_GRAY}
                fontWeight={withSubtitle ? 'bold' : 'normal'}
                fontSize={13}
                withSubtitle={withSubtitle}
                subtitleFontSize={10}
                subtitleStyle={{ color: COLORS.CINTAS_MEDIUM_GRAY }}
              />,
            ],
          }),
          item({ flex: 'auto', children: actions ?? [] }),
        ],
      })}
      {content}
    </div>
  );
};

export default DrawerSection;
