/* eslint-disable max-len */
import React from 'react';
import BackIcon from '@material-ui/icons/ArrowBack';
import { COLORS } from '../../utils/consts';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import StyledButton from './StyledButton';
import SimpleText from '../Text/SimpleText';

const BackStepButton = ({ label, onClick }) => {
  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt, style) => <SimpleText txt={txt} style={{ ...(style ?? {}) }} />;

  return (
    <StyledButton
      handleButton={onClick}
      variant="text"
      color="primary"
      disableCaps
      style={{ paddingLeft: 0, marginBottom: 20 }}
      buttonContent={container({
        style: { justifyContent: 'flex-start' },
        children: [
          item({
            style: { marginTop: 3, marginRight: 4 },
            children: [<BackIcon style={{ color: COLORS.CINTAS_BLUE }} />],
          }),
          item({
            children: [text(label, { color: COLORS.CINTAS_BLUE, fontSize: 20, fontWeight: 'bold' })],
          }),
        ],
      })}
    />
  );
};

export default BackStepButton;
