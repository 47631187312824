import _ from 'lodash';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import { Grid, Button, makeStyles } from '@material-ui/core';
import React from 'react';

import MDTextInputField from '../FormComponents/MDTextInput/MDTextInputField';
import ForgotPasswordBtn from '../../../routes/MainViews/Login/ForgotPasswordBtn';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validate = (values) => {
  // eslint-disable-next-line prefer-const
  let errors = {};
  let requiredFields = [];

  requiredFields = ['password'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

const PasswordForm = (props) => {
  const { dispatch } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={2} style={{ width: '100%', marginTop: 50, padding: 0 }}>
      <Grid item xs={12}>
        <Field
          id="password"
          name="password"
          variant="outlined"
          component={MDTextInputField}
          placeholder="Password"
          label="Password"
          required
          autoComplete="password"
          type="password"
          autoFocus
        />
        <ForgotPasswordBtn dispatch={dispatch} />
      </Grid>
      <Grid item xs={12}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => dispatch(submit('PasswordForm'))}
        >
          Link Azure ID account
        </Button>
      </Grid>
    </Grid>
  );
};

export default _.flow([
  connect(),
  reduxForm({
    form: 'PasswordForm',
    validate,
  }),
])(PasswordForm);
