/* eslint-disable no-use-before-define */

/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import SummaryIcon from '@material-ui/icons/ListAlt';
import { Typography } from '@material-ui/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { materialsDataSelector } from '../../redux/selectors/materials.selector';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import { COLORS, DATE_STANDARD_SHORT_FORMAT } from '../../utils/consts';
import ConfirmationNavButton from '../Button/ConfirmationNavButton';
import FASMUIDataTable from '../Settings/CustomMUIDataTable/FASMUIDataTable';
import Spinner from '../SpinnerOverlay/Spinner';
import CopyQuoteButton from '../Button/CopyQuoteButton';
import BookQuoteButton from '../Button/BookQuoteButton';
import QuotesTableData from '../../models/QuotesTableData';
import SwitchQuoteStatusButton from '../Button/SwitchQuoteStatusButton';

const rowsPerPage = 20;

const CustomerQuotesTable = (props) => {
  const { loading, customer, page, onChangePage, totalRecords, reloadPageData } = props;
  const [numVisibleRecords, setNumVisibleRecords] = React.useState(totalRecords);
  const [filtersList, setFiltersList] = React.useState(
    (() => {
      const list = Array(11).fill([]);
      list.splice(7, 1, ['active']);
      return list;
    })(),
  );

  React.useEffect(() => {
    if (numVisibleRecords !== totalRecords) {
      setNumVisibleRecords(totalRecords);
    }
  }, [totalRecords]);

  const onTableInit = (action, state) => {
    if (action === 'tableInitialized') {
      setFiltersList(state.filterList ?? []);
      // setNumVisibleRecords((state.displayData ?? []).length);
    }
  };

  const onTableChange = (action, state) => {
    if (action === 'filterChange') {
      setFiltersList(state.filterList ?? []);
      if ((state.filterList ?? []).every((val) => _.isEmpty(val ?? []))) {
        // if all filters have been manually removed (without clicking 'clear filters')
        setNumVisibleRecords(totalRecords);
      } else {
        setNumVisibleRecords((state.displayData ?? []).length);
      }
    }
    if (action === 'resetFilters') {
      setFiltersList(state.filterList ?? []);
      setNumVisibleRecords(totalRecords); // on click of 'clear filters' btn}
    }
  };

  const setRowProps = (row) => {
    const expired = moment.utc(row[2], DATE_STANDARD_SHORT_FORMAT, true).isBefore(moment.utc());
    if (expired) {
      return { style: { backgroundColor: COLORS.CINTAS_GRAY_OPAQUE_RGBA } };
    }
    return null;
  };

  const quoteConfirmationButton = (id) => (
    <ConfirmationNavButton
      noBackground
      padding={4}
      margin="auto"
      orderId={id}
      noFloat
      quote
      tooltip="Quote Details"
      customerId={customer.sold_to}
      iconOverride={<SummaryIcon style={{ color: COLORS.CINTAS_BLUE }} />}
    />
  );

  const bookQuoteButton = (id, data) => <BookQuoteButton customer={customer} quoteID={id} preloaded={data} />;

  const copyQuoteButton = (id, data) => (
    <CopyQuoteButton customer={customer} quoteID={id} preloaded={data} onCopied={reloadPageData} />
  );

  const switchStatusBtn = (quote) => (
    <SwitchQuoteStatusButton
      customerID={customer.sold_to}
      quoteID={quote.quoteID}
      status={quote.status}
      onSwitched={reloadPageData}
    />
  );

  const expiredLabel = () => (
    <Typography
      style={{
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 13,
        color: COLORS.CINTAS_DARK_GRAY,
      }}
    >
      EXPIRED
    </Typography>
  );

  const tableData = new QuotesTableData(
    {
      ...props,
      filtersList,
    },
    {
      book: bookQuoteButton,
      copy: copyQuoteButton,
      view: quoteConfirmationButton,
      switchStatus: switchStatusBtn,
      expiredLabel,
    },
  );

  const tableConfigs = {
    pagination: true,
    page,
    rowsPerPage,
    count: numVisibleRecords,
    rowsPerPageOptions: false,
    onChangePage,
    selectableRows: 'none',
    caseSensitive: false,
    sortOrder: {
      name: 'Creation Date',
      direction: 'desc',
    },
    setRowProps,
    onTableInit,
    onTableChange,
  };

  const spinner = () => <Spinner customStyle={{ marginTop: '10%' }} />;

  return loading ? (
    spinner()
  ) : (
    <FASMUIDataTable data={tableData.rows} columns={tableData.columns} options={tableConfigs} root={tableStyle} />
  );
};

export default _.flow([
  connect((state) => ({
    tz: timezoneSelector(state),
    materialsData: materialsDataSelector(state),
  })),
])(CustomerQuotesTable);

const tableStyle = {
  padding: '5px 15px 5px',
  '&:nth-child(1)': {
    width: '10%',
    padding: 0,
    paddingLeft: 16,
  },
  '&:nth-child(2)': {
    width: '5%',
    textAlign: 'start',
    padding: 0,
    paddingLeft: 16,
  },
  '&:nth-child(3)': {
    width: '5%',
    textAlign: 'start',
    padding: 0,
    paddingLeft: 16,
  },
  '&:nth-child(4)': {
    width: '12%',
    padding: 0,
    paddingLeft: 16,
  },
  '&:nth-child(5)': {
    width: '40%',
    padding: 0,
    paddingLeft: 16,
  },
  '&:nth-child(6)': {
    width: '20%',
    padding: 0,
    paddingLeft: 16,
  },
  '&:nth-child(7)': {
    width: '10%',
    padding: 0,
    paddingLeft: 16,
  },
  '&:nth-child(8)': {
    width: '15%',
    textAlign: 'center',
    padding: 0,
    paddingLeft: '2.5%',
  },
  '&:nth-child(9)': {
    width: '2%',
    padding: 0,
    paddingLeft: 16,
  },
  '&:nth-child(10)': {
    width: '2%',
    padding: 0,
    paddingLeft: 16,
  },
  '&:nth-child(11)': {
    width: '2%',
    padding: 0,
    paddingLeft: 16,
  },
};
