import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import SimpleText from '../../Text/SimpleText';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    fontWeight: (props) => props.weight,
    fontSize: (props) => props.size,
    lineHeight: '22px',
    padding: '10px',
    // textTransform: (props) => (props.weight > 600 ? 'uppercase' : ''),
    color: '#000000',
  },
}));

const FASBlackFont = (props) => {
  const { className, title, size, customStyle, weight, ...rest } = props;
  const classes = useStyles(props);

  return (
    <SimpleText
      txt={`${title ?? ''}`}
      size={'18px' || size}
      weight={500 || weight}
      className={`${classes.title} ${className}`}
      style={{ padding: '4px 0px', ...(customStyle ?? {}) }}
      {...rest}
    />
  );
};

export default FASBlackFont;
