/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import { BOOKING_MODE } from '..';
import { buildRule, isEditable, isVisible } from './LayoutRulesHelper';

const rules = {
  customer: buildRule({
    editable: [BOOKING_MODE.booking],
  }),
};

export const BookingLayoutRules = {
  rules,
  isVisible,
  isEditable,
};
