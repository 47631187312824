import React from 'react';
import { Grid } from '@material-ui/core';

const RowCell = ({ children, style, flex = 4, ...rest }) => (
  <Grid item xs={flex} style={{ display: 'table-cell', ...(style ?? {}) }} {...rest}>
    {children}
  </Grid>
);

export default RowCell;
