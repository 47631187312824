/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
import React from 'react';
import { COLORS } from '../../utils/consts';
import Container from '../LayoutBuilders/Container';
import ExpansionTile from '../LayoutBuilders/ExpansionTile';
import SimpleText from '../Text/SimpleText';

const PanelTile = ({
  icon,
  unread,
  header,
  subheader,
  headerActions,
  onExpansionChange,
  noExpanding,
  children,
  onClick,
  padding,
  noContentPadding,
}) => {
  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;
  const container = ({ child, ...rst }) => <Container {...(rst ?? {})}>{child}</Container>;

  const leading = () =>
    container({
      style: { justifyContent: 'center', alignItems: 'center', height: '100%' },
      child: [
        <div
          style={{
            height: 8,
            width: 8,
            background: unread ? COLORS.CINTAS_RED : 'transparent',
            borderRadius: '50%',
            marginRight: 6,
          }}
        />,
        icon,
      ],
    });

  const headerWidget = () =>
    text(header ?? '', {
      style: {
        color: 'black',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
      },
    });

  return (
    <ExpansionTile
      noExpanding={noExpanding}
      expanded={noExpanding ? false : undefined}
      noBorder
      elevated={2}
      marginBottom={2}
      padding={padding ?? { paddingLeft: 4 }}
      leading={leading()}
      leadingFlex={3}
      leadingSpacing={2}
      bodyFlex={9}
      header={headerWidget()}
      headerFlex="100%"
      subheader={subheader}
      headerActions={headerActions}
      subheaderStyle={{ color: 'black', fontSize: 12 }}
      onExpansionChange={onExpansionChange}
      onClick={onClick}
      noContentPadding={noContentPadding}
    >
      {children}
    </ExpansionTile>
  );
};

export default PanelTile;
