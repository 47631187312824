/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { NATIONAL_ACCOUNT_NOTE_TYPE } from '../../utils/consts/nationalAccount.consts';
import BackStepButton from '../Button/BackStepButton';
import FASFieldTitle from '../Forms/CustomFormComponents/FASFieldTitle';
import Card from '../LayoutBuilders/Card';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import SimpleText from '../Text/SimpleText';
import HigherLevelAccountNote from './HigherLevelAccountNote';
import NationalAccountRosterConfig from './NationalAccountRosterConfig';
import FASFilteredOrdersTable from '../Settings/FASOrdersFilteredTable.jsx/FASFilteredOrdersTable';
import { ORDERS_VIEWS } from '../../utils/consts';

const formName = 'NationalAccountForm';

const NationalAccountDetails = ({
  onPrevStep,
  values: {
    id,
    name,
    distribution_channel: dc,
    programNotes,
    courseConfirmationNotes,
    rosterNotes,
    poNotes,
    rosterConfig,
  },
}) => {
  const title = (txt, style) => <FASFieldTitle title={txt} style={{ fontWeight: 'bold', ...(style ?? {}) }} />;
  const text = (txt, style) => <SimpleText txt={txt} style={{ ...(style ?? {}) }} />;

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const backBtn = () => <BackStepButton label="Back To Account Search" onClick={onPrevStep} />;

  const infoEntry = (label, value) => (
    <>
      {title(label)}
      {text(!value || _.isEmpty(value) ? 'N/A' : value)}
    </>
  );

  const card = ({ header, children }) => (
    <Card title={header} style={{ minHeight: 180, maxHeight: 320, height: '100%' }}>
      {children}
    </Card>
  );

  const accountInfo = () =>
    card({
      header: 'Account Information',
      children: [
        container({
          style: { justifyContent: 'flex-start' },
          children: [
            item({ flex: 4, children: [infoEntry('Account Name', _.startCase((name ?? '').toLowerCase()))] }),
            item({ flex: 4, children: [infoEntry('Account Number', id ?? '')] }),
            item({ flex: 4, children: [infoEntry('Distribution Channel', `${dc ?? ''}`)] }),
          ],
        }),
      ],
    });

  const programNotesCard = () =>
    card({
      header: 'Program Requirements Note',
      children: [
        <HigherLevelAccountNote
          account={id}
          noteType={NATIONAL_ACCOUNT_NOTE_TYPE.PROGRAM}
          noteData={programNotes ?? {}}
        />,
      ],
    });

  const courseConfirmationNotesCard = () =>
    card({
      header: 'Course Confirmation Note',
      children: [
        <HigherLevelAccountNote
          account={id}
          noteType={NATIONAL_ACCOUNT_NOTE_TYPE.COURSE}
          noteData={courseConfirmationNotes ?? {}}
        />,
      ],
    });

  const rosterNotesCard = () =>
    card({
      header: 'Participants / Roster Note',
      children: [
        <HigherLevelAccountNote
          account={id}
          noteType={NATIONAL_ACCOUNT_NOTE_TYPE.ROSTER}
          noteData={rosterNotes ?? {}}
        />,
      ],
    });

  const poNotesCard = () =>
    card({
      header: 'PO Note',
      children: [
        <HigherLevelAccountNote account={id} noteType={NATIONAL_ACCOUNT_NOTE_TYPE.PO} noteData={poNotes ?? {}} />,
      ],
    });

  const rosterConfigCard = () =>
    card({
      header: 'Roster Configuration',
      children: [<NationalAccountRosterConfig account={id} configs={rosterConfig ?? {}} />],
    });

  const nationalAccountOrders = () => (
    <FASFilteredOrdersTable higherLevelAccount={id} view={ORDERS_VIEWS.nationalAccount} />
  );

  const rowOne = () =>
    container({
      style: { justifyContent: 'flex-start' },
      spacing: 2,
      children: [item({ flex: 6, children: [accountInfo()] }), item({ flex: 6, children: [rosterConfigCard()] })],
    });

  const rowTwo = () =>
    container({
      style: { justifyContent: 'flex-start', marginTop: 8 },
      spacing: 2,
      children: [
        item({ flex: 6, children: [programNotesCard()] }),
        item({ flex: 6, children: [courseConfirmationNotesCard()] }),
      ],
    });

  const rowThree = () =>
    container({
      style: { justifyContent: 'flex-start', marginTop: 8, marginBottom: 16 },
      spacing: 2,
      children: [item({ flex: 6, children: [rosterNotesCard()] }), item({ flex: 6, children: [poNotesCard()] })],
    });

  return (
    <div>
      {backBtn()}
      {rowOne()}
      {rowTwo()}
      {rowThree()}
      {nationalAccountOrders()}
    </div>
  );
};

export default _.flow([
  connect((state) => ({
    values: getFormValues(formName)(state),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  }),
])(NationalAccountDetails);
