import React from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@material-ui/core';
import VideoPlayer from '../cintasComponents/Medias/VideoPlayer';
import { COLORS } from '../../utils/consts';
// import Spinner from '../SpinnerOverlay/Spinner';

const DroppableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  max-width: 790px;
  min-height: 172px;
  border: 1px solid #ddd;
  padding: 8px;
`;

const StyledItem = styled.div`
  position: relative;
  background: rgba(1, 33, 105, 0.1);
  border-radius: 8px;
  margin: 10px;
`;

const CloseBtn = styled(CloseIcon)`
  position: absolute;
  background-color: ${COLORS.CINTAS_BLUE};
  border-radius: 50%;
  border: 2px solid ${COLORS.CINTAS_BLUE};
  color: ${COLORS.CINTAS_WHITE};
  right: -5px;
  top: -5px;
  cursor: pointer;
  z-index: 1;
`;

const ElementType = {
  image: 'image',
  video: 'video',
};

const DNDList = ({
  elements, // List of objects like { id, type, url } unique id is needed to drap-n-drop elements
  elementWidth: width = 150,
  elementHeight: height = 150,
  handleDragEnd,
  onElementDeleted,
  isLoading,
}) => {
  const viewer = (element, videoProps) => {
    switch (element.contentType?.substring(0, 5)) {
      case ElementType.image:
        return (
          <img
            src={element?.url}
            alt="Draggable media"
            style={{
              objectFit: 'cover',
              ...{ height, width },
            }}
          />
        );
      case ElementType.video:
        return <VideoPlayer url={element?.url} {...(videoProps ?? {})} />;
      default:
        return <div>Unknown File Type</div>;
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="horizontal-list" direction="horizontal">
        {(provided) => (
          <DroppableContainer {...provided.droppableProps} ref={provided.innerRef}>
            {elements?.map((el, index) => (
              <Draggable key={el.id} draggableId={el.id} index={index}>
                {(item) => (
                  <StyledItem {...item.draggableProps} {...item.dragHandleProps} ref={item.innerRef}>
                    <CloseBtn onClick={() => onElementDeleted(el.id)} />
                    {viewer(el, {
                      height,
                      width,
                      avoidPlay: true,
                    })}
                  </StyledItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </DroppableContainer>
        )}
      </Droppable>
      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}
    </DragDropContext>
  );
};

export default DNDList;
