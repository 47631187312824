import Immutable from 'seamless-immutable';

import { SAVE_SELECTED_CUSTOMERS_CONTACTS, SAVE_CONTACTS } from '../actions/actionTypes/contacts';

const initialState = Immutable({
  selectedCustomer: [],
  data: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SELECTED_CUSTOMERS_CONTACTS:
      return state.set('selectedCustomer', action.payload);
    case SAVE_CONTACTS:
      return state.set('data', action.payload);
    default:
      return state;
  }
};
