/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { ADD_CERTIFICATION, UPDATE_CERTIFICATION, SAVE_CERTIFICATIONS } from './actionTypes/certifications';

import * as CertificationsAPI from '../../api/certifications.api';

const saveCertifications = (certifications) => ({
  type: SAVE_CERTIFICATIONS,
  certifications,
});

const updateCertification = (certification) => ({
  type: UPDATE_CERTIFICATION,
  certification,
});

const addCertification = (payload) => ({
  type: ADD_CERTIFICATION,
  payload,
});

export const updateCertificationById = (certId, cert) => async (dispatch) => {
  // modify payload as needed to fit certification data model
  const payload = cert;

  try {
    const response = await CertificationsAPI.updateCertification(certId, payload);
    if (response && response.data) {
      await dispatch(updateCertification(response.data));
      return response.data;
    }
  } catch (error) {
    throw error;
  }
  return payload;
};

export const createCertification = (values) => async (dispatch) => {
  // modify payload as needed to fit certification data model
  const payload = values;

  try {
    const response = await CertificationsAPI.createCertification(payload);
    if (response && response.data) {
      await dispatch(addCertification(response.data));
      return response.data;
    }
  } catch (error) {
    throw error;
  }
  return payload;
};

export const fetchCertifications = () => async (dispatch) => {
  try {
    const response = await CertificationsAPI.getAllCertifications();
    if (response) {
      dispatch(saveCertifications(response.data || []));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};
