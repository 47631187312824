import * as DATA_API from '../../api/dataLookUp.api';

export const fetchPostgresTablesNames = async () => {
  try {
    const response = await DATA_API.fetchPostgresTablesNames();
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchPostgresColumnNames = async (tableName) => {
  try {
    const response = await DATA_API.fetchPostgresColumnNames(tableName);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchPostgresDataCount = async (tableName, payload) => {
  try {
    const response = await DATA_API.fetchPostgresDataCount(tableName, payload);
    if (response && (response.data || response.data === 0)) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchPostgresDataLookup = async (tableName, payload) => {
  try {
    const response = await DATA_API.fetchPostgresDataLookup(tableName, payload);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};
