/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { changeMultipleTasksToRead, fetchSolvedTasks, fetchTasksData } from '../../../redux/actions/tasks.actions';
import { solvedTasksSelector, unsolvedTasksSelector } from '../../../redux/selectors/notifications.tasks.selector';
import { COLORS } from '../../../utils/consts';
import MarkAllReadButton from '../../Button/MarkAllReadButton';
import ViewMoreButton from '../../Button/ViewMoreButton';
import DrawerSection from '../DrawerSection';
import Spinner from '../../SpinnerOverlay/Spinner';
import TaskTile from './TaskTile';
import TaskPanelContentSection from './TaskPanelContentSection';

const maxNumRecordsPerPage = 20;
const maxNumRecordsPerUnsolvedPage = 8;

const TasksPanelContent = ({ unsolved, solved }) => {
  const dispatch = useDispatch();
  const [currentPage, setPage] = React.useState(1);
  const [loadingPage, setLoadingPage] = React.useState(false);
  const [markingRead, setMarkingRead] = React.useState(false);
  const [loadingInitialData, setLoadingInitialData] = React.useState(false);
  const pagesLoaded = Array(currentPage)
    .fill(0)
    .map((__, i) => i + 1);
  const solvedData = _.flatten((pagesLoaded ?? []).map((p) => solved?.[p] ?? []));
  const [noMorePages, setNoMorePages] = React.useState(false);
  const allUnsolved = _.flatten(Object.values(unsolved).map((typePaging) => _.flatten(Object.values(typePaging))));

  const unreadNum = allUnsolved.filter((n) => !n.read).length;

  const loadInitialData = async (noFeedback) => {
    try {
      if (!noFeedback) setLoadingInitialData(true);
      const [, { value: solvedTasks }] = await dispatch(fetchTasksData(1, true));
      if (!solvedTasks || _.isEmpty(solvedTasks) || solvedTasks?.length < maxNumRecordsPerPage) {
        setNoMorePages(true);
      }
    } finally {
      if (!noFeedback) setLoadingInitialData(false);
    }
  };

  React.useEffect(() => {
    loadInitialData();
  }, []);

  const loadPage = async (page) => {
    let success = false;
    try {
      setLoadingPage(true);
      const newPageData = await dispatch(fetchSolvedTasks(page));
      if (_.isEmpty(newPageData) || newPageData?.length < maxNumRecordsPerPage) {
        setNoMorePages(true);
      }
      success = true;
    } catch (e) {
      success = false;
    } finally {
      setLoadingPage(false);
    }
    return success;
  };

  const increasePage = async () => {
    const newPage = currentPage + 1;
    const pageLoaded = await loadPage(newPage);
    if (pageLoaded) {
      setPage(newPage);
    }
  };

  const onMarkAllRead = async () => {
    try {
      setMarkingRead(true);
      await dispatch(changeMultipleTasksToRead(allUnsolved.map((t) => t.taskID)));
      await loadInitialData(true);
    } finally {
      setMarkingRead(false);
    }
  };

  const markAllReadButton = () => (
    <MarkAllReadButton onClick={onMarkAllRead} loading={markingRead} disabled={unreadNum === 0} />
  );

  const viewMoreBtn = () => <ViewMoreButton onClick={increasePage} loading={loadingPage} noMorePages={noMorePages} />;

  const header = () => <DrawerSection actions={[markAllReadButton()]} />;

  const solvedTasksSection = () => (
    <DrawerSection
      title="Past"
      content={[
        tasksList(solvedData),
        _.isEmpty(solvedData) && noResolvedTasks(),
        !_.isEmpty(solvedData) && viewMoreBtn(),
      ]}
    />
  );

  const noResolvedTasks = () => (
    <Typography style={{ color: COLORS.CINTAS_GRAY, fontSize: 12, textAlign: 'center' }}>
      Your past tasks will appear here
    </Typography>
  );

  const tasksList = (tasks = []) => (
    <div>
      {tasks.map((t) => (
        <TaskTile task={t} />
      ))}
    </div>
  );

  const spinner = (sz, noMargin) => {
    const size = sz ?? 30;
    return (
      <Spinner
        spinnerStyle={{
          height: size,
          width: size,
          padding: 0,
          margin: 0,
          color: COLORS.CINTAS_BLUE,
        }}
        customStyle={{
          maxHeight: size,
          maxWidth: size,
          margin: 0,
          padding: 0,
          marginTop: noMargin ? 0 : '20%',
          marginLeft: noMargin ? 0 : '45%',
        }}
      />
    );
  };

  return (
    <div>
      {loadingInitialData
        ? [spinner()]
        : [
            header(),
            Object.keys(unsolved).map((type) => (
              <TaskPanelContentSection
                tasksType={type}
                maxNumRecordsPerPage={maxNumRecordsPerUnsolvedPage}
                pagedTasks={unsolved[type]}
              />
            )),
            solvedTasksSection(),
          ]}
    </div>
  );
};

export default _.flow([
  connect((state) => {
    const unsolved = _.cloneDeep(unsolvedTasksSelector(state));

    // Add the page and index values to each task
    Object.keys(unsolved).forEach((tType) => {
      const typePaging = unsolved[tType];
      Object.keys(typePaging).forEach((p) => {
        const typePageData = unsolved[tType]?.[p] ?? [];
        typePageData.forEach((t, i) => {
          unsolved[tType][p][i] = { ...t, i, page: p };
        });
      });
    });

    return {
      unsolved,
      solved: solvedTasksSelector(state),
    };
  }),
])(TasksPanelContent);
