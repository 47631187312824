import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import classes from './styles.module.css';

const SelectedDate = (props) => {
  const { dateToRender, dateOfMonth, curDay } = props;
  const isToday = dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');

  const inMonth = dateToRender.month() === dateOfMonth.month();

  const isCurDay = dateToRender.format('YYYY-MM-DD') === moment(curDay).format('YYYY-MM-DD');

  return (
    <button
      type="button"
      className={`${classes.date} ${isCurDay ? classes.curday : ''} ${isToday && inMonth ? classes.today : ''} ${inMonth ? classes['in-month'] : classes['next-or-prev-month']}`}
      onClick={() => props.onClick(dateToRender)}
    >
      {dateToRender.date()}
    </button>
  );
};

export default SelectedDate;
