/* eslint-disable object-curly-newline */
import _ from 'lodash';
import { Field, submit, change, reduxForm, getFormValues } from 'redux-form';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { connect, useDispatch } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState, useEffect } from 'react';
import RepeatIcon from '@mui/icons-material/Repeat';
import FASRadio from '../../../../Forms/CustomFormComponents/FASRadio';
import FASTitle from '../../../../Forms/CustomFormComponents/FASTtle';
import FASSelectField from '../../../../Forms/CustomFormComponents/FASSelectField';
import MDRadioGroupField from '../../../../Forms/FormComponents/MDRadioGroup/MDRadioGroupField';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';
import { TIME_BLOCK_REASONS, TIME_BLOCK_INTERVALS, TIME_BLOCK_TYPE, BOOKING_MODE } from '../../../../../utils/consts';
import IntervalForm from './IntervalForm';

const formName = 'AddEventDialog';
const fieldName = 'recurringSeries';

const TimeBlockForm = ({ startTime, endTime, recurrence, bookingMode }) => {
  const dispatch = useDispatch();
  const [interval, setInterval] = useState(recurrence ?? TIME_BLOCK_INTERVALS.NO_REPEAT);
  const showRecurrence = Boolean(bookingMode !== BOOKING_MODE.editing || recurrence);

  useEffect(() => {
    if (recurrence) {
      setInterval(recurrence);
      if (recurrence === TIME_BLOCK_INTERVALS.NO_REPEAT) {
        dispatch(change(formName, fieldName, { interval: recurrence }));
      }
    }
  }, [recurrence]);

  const handleReasonChange = async () => {
    await dispatch(change(formName, 'allDay', false));
    if (startTime && !endTime) {
      dispatch(change(formName, 'endTime', moment.utc(startTime).clone().add(60, 'minutes')));
    }
  };

  const intervalSelect = () => (
    <Grid container>
      <Grid item xs={1}>
        <RepeatIcon xs={3} style={{ marginTop: 10 }} />
      </Grid>
      <Grid item xs={6}>
        <Field
          name={`${fieldName}.interval`}
          id={`${fieldName}.interval`}
          component={FASSelectField}
          label="Recurrence"
          noErrorLabel
        >
          {_.map(TIME_BLOCK_INTERVALS, (value, key) => (
            <MenuItem key={key} value={value}>
              {value.charAt(0) + value.slice(1).toLowerCase()}
            </MenuItem>
          ))}
        </Field>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid item xs={12}>
        <Field
          variant="outlined"
          placeholder="Select reason"
          id="reason"
          name="reason"
          component={FASSelectField}
          label="Reason"
          onChange={handleReasonChange}
        >
          {_.map(TIME_BLOCK_REASONS, (value, key) => (
            <MenuItem key={key} value={key}>
              {value.toUpperCase()}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <FASFieldTitle title="Block Type" />
        <Field
          id="blockType"
          variant="outlined"
          name="blockType"
          required
          component={MDRadioGroupField}
          withHelperText={false}
          row
        >
          {_.map(TIME_BLOCK_TYPE, (value, key) => (
            <FormControlLabel key={key} value={key} control={<FASRadio />} label={value} />
          ))}
        </Field>
      </Grid>
      {showRecurrence && (
        <Grid item xs={12}>
          {intervalSelect()}
        </Grid>
      )}
      <Grid item xs={12}>
        {interval !== TIME_BLOCK_INTERVALS.NO_REPEAT && <IntervalForm interval={interval} />}
      </Grid>
      <Grid item xs={12}>
        <FASTitle title="Notes" />
        <Field
          id="notes"
          name="notes"
          component={MDTextInputField}
          multiline
          rows={4}
          type="text"
          size="small"
          variant="outlined"
          placeholder="No notes added"
          label="Notes"
          autoComplete="notes"
          noBorderRadius
        />
      </Grid>
    </>
  );
};

export default _.flow([
  connect((state) => ({
    startTime: (getFormValues(formName)(state) ?? {}).startTime,
    endTime: (getFormValues(formName)(state) ?? {}).endTime,
    recurrence: (getFormValues(formName)(state) ?? {}).recurringSeries?.interval,
    bookingMode: (getFormValues(formName)(state) ?? {}).bookingMode,
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: submit, // a unique identifier for this form
  }),
])(TimeBlockForm);
