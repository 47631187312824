/* eslint-disable import/no-cycle */
import { Box } from '@material-ui/core';
import React from 'react';
import { hideModal } from '../../../../redux/actions/modals.actions';
import { fetchOrderSummary } from '../../../../redux/actions/orders.actions';
import AddToCalendarBtn from '../../../Button/AddToCalendarBtn';
import Spinner from '../../../SpinnerOverlay/Spinner';
import SimpleText from '../../../Text/SimpleText';
import PersonalCalendarDialogWrapper from './PersonalCalendarDialogWrapper';

const PersonalCalendarDialog = ({ orderId, dispatch, modalName }) => {
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState({});

  const loadOrder = async () => {
    try {
      const ord = await dispatch(fetchOrderSummary(orderId));
      if (ord) setOrder(ord);
    } finally {
      setLoading(false);
    }
  };

  const dismiss = () => {
    dispatch(hideModal(modalName));
  };

  const onWindowFocused = () => {
    dismiss();
  };

  // const onWindowBlur = () => {
  // };

  React.useEffect(() => {
    loadOrder();
    window.addEventListener('focus', onWindowFocused);
    // window.addEventListener('blur', onWindowBlur);

    return () => {
      window.removeEventListener('focus', onWindowFocused);
      // window.removeEventListener('blur', onWindowBlur);
    };
  }, []);

  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;
  const spinner = () => <Spinner />;

  return (
    <PersonalCalendarDialogWrapper>
      {text('Add this order to your personal calendar using the button below')}
      <Box height={10} />
      {loading ? spinner() : <AddToCalendarBtn order={order} open />}
    </PersonalCalendarDialogWrapper>
  );
};

export default PersonalCalendarDialog;
