import red from '@material-ui/core/colors/red';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import MDSnackbarContent from './MDSnackbarContent';

const styles = (theme) => ({
  snackbarContent: {
    backgroundColor: red[200],
  },
  close: {
    padding: theme.spacing(1 / 2),
    color: red[800],
  },
  alertMessage: {
    color: red[800],
  },
});

const SnackbarContentWrapper = withStyles(styles)(MDSnackbarContent);

const MDErrorAlert = (props) => {
  const { anchorOrigin, message, autoHideDuration } = props;

  const handleClose = (event, reason) => {
    const { hideModal } = props;
    if (reason === 'clickaway') {
      return;
    }
    hideModal();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open
        autoHideDuration={autoHideDuration || 5000}
        onClose={handleClose}
        {...props}
      >
        <SnackbarContentWrapper onClose={handleClose} message={message} />
      </Snackbar>
    </div>
  );
};

MDErrorAlert.defaultProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
};

export default MDErrorAlert;
