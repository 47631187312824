import { Button, Container, Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { submit } from 'redux-form';
import StyledButton from '../../../components/Button/StyledButton';
import Spinner from '../../../components/SpinnerOverlay/Spinner';
import { COLORS } from '../../../utils/consts';

export const settingsDetailsFrameAction = {
  DELETE: 'Delete',
  SUBMIT: 'Submit',
};

const titleStyle = {
  fontFamily: 'proxima-nova',
  fontWeight: 'Bold',
  display: 'flex',
  paddingBottom: '24px',
  paddingTop: '24px',
};

const buttonStyle = {
  marginTop: 32,
  marginBottom: 32,
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
};

const SettingsDetailsFrame = ({
  title,
  children,
  backPath = '',
  backBtnTitle = 'back',
  formName,
  onDelete,
  submitting,
  actions = [settingsDetailsFrameAction.SUBMIT],
  history,
}) => {
  const dispatch = useDispatch();

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20,
        width: 20,
        padding: 0,
        margin: 0,
        color: COLORS.CINTAS_WHITE,
      }}
      customStyle={{
        margin: 0,
        padding: 3,
      }}
    />
  );

  return (
    <Container maxWidth="lg" style={{ paddingBottom: 64 }}>
      <Button
        // variant="outlined"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => history.push(backPath)}
      >
        {backBtnTitle}
      </Button>
      <Grid container spacing={4} alignItems="center" justify="center">
        <Grid item xs={12}>
          <Typography style={titleStyle} color="primary" variant="h4" gutterBottom>
            {title ?? ''}
          </Typography>
        </Grid>
      </Grid>
      {children}
      <Grid container style={{ justifyContent: 'center' }}>
        {actions.includes(settingsDetailsFrameAction.DELETE) && (
          <Grid item xs={2}>
            <StyledButton
              variant="outlined"
              color="primary"
              type="submit"
              width="160px"
              buttonContent={settingsDetailsFrameAction.DELETE}
              handleButton={onDelete}
              style={buttonStyle}
            />
          </Grid>
        )}
        {actions.includes(settingsDetailsFrameAction.SUBMIT) && (
          <Grid item xs={2}>
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              width="160px"
              buttonContent={submitting ? spinner() : settingsDetailsFrameAction.SUBMIT}
              handleButton={submitting ? null : () => dispatch(submit(formName))}
              style={buttonStyle}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default _.flow([withRouter])(SettingsDetailsFrame);
