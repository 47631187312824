/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const getAllMaterials = () => baseInstance.get('/materials');

export const uploadImage = (material, payload) => baseInstance.put(`/materials/${material}/image`, payload);

export const updateMaterial = (materialId, payload) => baseInstance.put(`/materials/${materialId}`, payload);

export const createMaterial = (payload) => baseInstance.post('/materials', payload);

export const updateMaterialSlug = (materialId, slug) =>
  baseInstance.put(`/materials/${materialId}/update-slug`, { slug });
