/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import DuplicateIcon from '../../assets/images/duplicate_icon.svg';

import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import Spinner from '../SpinnerOverlay/Spinner';
import { BOOKING_MODE, COLORS, EVENT_TYPE, ROLE_ACCESSES, TIME_BLOCK_REASONS } from '../../utils/consts';
import { prepareOnSiteEditingForm, showAddToCalendarPopup, showSendConfirmationPopup } from '../../utils/event.utils';
import { showModal } from '../../redux/actions/modals.actions';
import EventDialog from '../Calendar/CalendarComponents/EventDialog/EventDialog';
import * as EventsActions from '../../redux/actions/events.actions';
import * as OrdersActions from '../../redux/actions/orders.actions';
import * as ModalsActions from '../../redux/actions/modals.actions';
import { cleanRows } from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CartSummaryForm';
import { hasUserAccessSelector } from '../../redux/selectors/user.selectors';
import StyledButton from './StyledButton';

const DuplicateEventButton = ({ order, selectedEvent, iconSize = 20, callback }) => {
  const [loadingEdits, setLoadingEdits] = React.useState(false);
  const dispatch = useDispatch();
  const userTz = useSelector(timezoneSelector);
  const canBook = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.bookEvent]));

  const onSubmitCourse = async (values) => {
    const allDay =
      values.eventType === EVENT_TYPE.TIME_BLOCK && values.reason === TIME_BLOCK_REASONS.PTO && values.allDay;
    const start = moment.utc(allDay ? values.date : values.startTime);
    const end = moment.utc(allDay ? values.endDate : values.endTime);
    const { communication } = values;

    try {
      dispatch(submit('AddEventDialog'));
      const response = await dispatch(EventsActions.createEvent(start, end, { ...values, allDay }));
      dispatch(ModalsActions.hideModal('EDIT_EVENT_DIALOG'));
      if (!selectedEvent) dispatch(ModalsActions.hideModal('EVENT_DETAILS_DIALOG'));
      const eventIds = (response?.data ?? {}).eventIDs ?? [];
      const eventId = (_.isEmpty(eventIds) ? [''] : eventIds)[0];
      const newOrderID = (await dispatch(EventsActions.fetchEventById(eventId, true)))?.order;
      const editingModes = [BOOKING_MODE.editing, BOOKING_MODE.rescheduling];
      const shouldShowEmailConfirmation = !editingModes.includes(values.bookingMode) || values.confirmationsentDate;
      if (shouldShowEmailConfirmation) {
        showSendConfirmationPopup(dispatch, response?.data?.emailToken, newOrderID, values.bookingMode);
      }
      cleanRows();
      dispatch(
        ModalsActions.showModal('CREATE_EVENT_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: communication
              ? 'Event successfully created! Order confirmation email sent to customer!'
              : 'Event successfully created!',
          },
        }),
      );
      if (values.addExternalCalendar) {
        showAddToCalendarPopup(newOrderID, dispatch);
      }
      dispatch(OrdersActions.fetchOrders());
    } catch (error) {
      const message = 'Something happened while creating the event. Please, try again!';
      dispatch(
        ModalsActions.showModal('CREATE_EVENT_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { message },
        }),
      );
    }
  };

  const showDialog = () => {
    const modalName = 'EDIT_EVENT_DIALOG';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          deleteText: 'delete',
          disableBackdropClick: true,
          maxWidth: 'md',
          title: 'DUPLICATE EVENT',
          draggable: true,
          nestedScrolling: true,
          content: (
            <EventDialog
              modalName={modalName}
              onSubmit={onSubmitCourse}
              updatedEvent={selectedEvent ?? { ...order, desc: { ...order.events?.[0] } }}
              mode={BOOKING_MODE.duplicate}
            />
          ),
        },
      }),
    );
  };

  const requestEventDuplication = async () => {
    setLoadingEdits(true);
    try {
      const orderEventsWithoutParticipants = order.events.map((event) => ({
        ...event,
        participants_count: 1,
        additional_participants: [],
        addOns: event?.addOns?.map((addOn) => ({ ...addOn, qty: 1 })),
      }));
      await prepareOnSiteEditingForm({
        preloaded: {
          ...order,
          events: orderEventsWithoutParticipants,
        },
        dispatch,
        formName: 'AddEventDialog',
        initialData: selectedEvent ?? { ...order, desc: { ...order.events?.[0] } },
        tz: userTz,
        duplicateEvent: true,
      });
      showDialog();
    } finally {
      setLoadingEdits(false);
    }
    callback?.call();
  };

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20,
        width: 20,
        padding: 0,
        margin: 0,
        color: COLORS.CINTAS_BLUE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        padding: 0,
      }}
    />
  );

  return canBook ? (
    <StyledButton
      handleButton={loadingEdits ? null : requestEventDuplication}
      variant="text"
      buttonContent="Repeat Booking"
      startIcon={
        loadingEdits ? (
          spinner()
        ) : (
          <img
            style={{ height: iconSize, width: iconSize, color: COLORS.CINTAS_BLUE }}
            src={DuplicateIcon}
            alt="DuplicateIcon"
          />
        )
      }
    />
  ) : (
    <></>
  );
};

export default _.flow()(DuplicateEventButton);
