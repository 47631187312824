import { Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import * as Firebase from '../../utils/firebase';
import { setupTimeoutListener } from '../../utils/helpers';

const AppRoute = (props) => {
  const { component: Component, layout: Layout, title, maxWidth, dispatch, history, ...rest } = props;

  useEffect(() => {
    if (localStorage.authenticated && Firebase.getCurrentUser()) {
      setupTimeoutListener(Firebase.getCurrentUser(), dispatch, history);
    }
  }, Firebase.auth);

  return (
    <Route
      {...{ dispatch, history, ...rest }}
      render={(componentProps) => (
        <Layout maxWidth={maxWidth} title={title}>
          <Component {...componentProps} />
        </Layout>
      )}
    />
  );
};

export default AppRoute;
