/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import Container from '../../LayoutBuilders/Container';
import ContainerItem from '../../LayoutBuilders/ContainerItem';
import FASFieldTitle from '../CustomFormComponents/FASFieldTitle';
import MDTextInputField from '../FormComponents/MDTextInput/MDTextInputField';
import UploadImageInput from './UploadImageInput';

const formName = 'ClassificationSettingsForm';
const validate = (values) => {
  const errors = {};
  let requiredFields = [];

  requiredFields = ['title'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

const ClassificationSettingsForm = ({ dispatch }) => {
  const uploadImage = async (event) => {
    if (!event.target.files[0]) return;
    await dispatch(change(formName, 'imageObj', event.target.files[0]));
    await dispatch(change(formName, 'imgURL', URL.createObjectURL(event.target.files[0])));
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const imageField = () => (
    <UploadImageInput hideLabel loading={false} onUpload={uploadImage} imageFieldName="imgURL" />
  );

  const titleField = () => (
    <div style={{ marginLeft: 16 }}>
      <FASFieldTitle title="Title *" />
      <Field
        name="title"
        id="title"
        component={MDTextInputField}
        size="md"
        variant="outlined"
        placeholder="Title"
        type="text"
        required
      />
    </div>
  );

  return container({
    children: [
      imageField(),
      item({
        flex: 3,
        children: [titleField()],
      }),
    ],
  });
};

export default _.flow([
  connect((state) => ({
    values: getFormValues(formName)(state),
  })),
  reduxForm({
    form: formName,
    validate,
  }),
])(ClassificationSettingsForm);
