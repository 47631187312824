import { Drawer, IconButton } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../../../components/LayoutBuilders/Container';
import ContainerItem from '../../../components/LayoutBuilders/ContainerItem';
import { openPanelOfType } from '../../../redux/actions/notifications.actions';
import { openPanelSelector, panelTypes } from '../../../redux/selectors/notifications.tasks.selector';
import TasksPanelContent from '../../../components/Drawers/Tasks/TasksPanelContent';
import { COLORS } from '../../../utils/consts';
import FASBlackFont from '../../../components/Forms/CustomFormComponents/FASBlackFont';
import NotificationsPanelContent from '../../../components/Drawers/Notifications/NotificationsPanelContent';

const DrawerPanel = ({ type = panelTypes.notification }) => {
  const dispatch = useDispatch();
  const open = useSelector((state) => openPanelSelector(state, type));

  const toggleDrawer = (shouldOpen) => (event) => {
    if (event?.type === 'keydown' && (event?.key === 'Tab' || event?.key === 'Shift')) {
      return;
    }
    dispatch(openPanelOfType(type, shouldOpen));
  };

  const dismissPanel = () => {
    toggleDrawer(false)();
  };

  const container = ({ children, ...rst }) => <Container {...(rst ?? {})}>{children}</Container>;
  const item = ({ children, ...rst }) => <ContainerItem {...(rst ?? {})}>{children}</ContainerItem>;

  const closeButton = () => (
    <IconButton onClick={dismissPanel}>
      <CloseIcon />
    </IconButton>
  );

  const panelTitle = () => (
    <FASBlackFont size={16} weight="bold" title={_.startCase(type)} customStyle={{ color: COLORS.CINTAS_BLUE }} />
  );

  const header = () =>
    container({
      style: {
        justifyContent: 'space-between',
        alignItems: 'center',
        background: COLORS.CINTAS_WHITE,
        borderBottom: `1px solid ${COLORS.CINTAS_LIGHT_GRAY}`,
      },
      children: [
        item({ flex: 1, children: [closeButton()] }),
        item({ flex: 'auto', children: [panelTitle()] }),
        item({ flex: 1, children: [<></>] }),
      ],
    });

  const getPanelContent = () => {
    switch (type) {
      case panelTypes.notification:
        return <NotificationsPanelContent dismissPanel={dismissPanel} />;
      case panelTypes.task:
        return <TasksPanelContent dismissPanel={dismissPanel} />;
      default:
        return <></>;
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer(false)} style={{ zIndex: 500 }}>
      {header()}
      <div
        style={{
          padding: '0px 16px',
          paddingTop: 10,
          width: 400,
          height: '100%',
          background: COLORS.CINTAS_BLUE_OPAQUE_RGBA,
          overflowY: 'auto',
        }}
      >
        {getPanelContent()}
      </div>
    </Drawer>
  );
};

export default _.flow()(DrawerPanel);
