/* eslint-disable import/prefer-default-export */
// import qs from 'query-string';

import { baseInstance } from './instances/baseInstance';

export const forgotPassword = (email) => baseInstance.get(`/auth/forgotPassword?email=${email}`);

export const changePassword = (payload) => baseInstance.post('/auth/changePassword', payload);

export const verifyEmail = (email) => baseInstance.get(`/verifyUserData?email=${email}`);
