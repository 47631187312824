/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import AcceptIcon from '@material-ui/icons/Check';
import RejectIcon from '@material-ui/icons/Block';
import _ from 'lodash';
import { COLORS } from '../../../utils/consts';
import StyledButton from '../../Button/StyledButton';
import Container from '../../LayoutBuilders/Container';
import ContainerItem from '../../LayoutBuilders/ContainerItem';
import { TASK_RESOLUTIONS } from '../../../utils/consts/tasks.consts';
import { changeTaskToResolved } from '../../../redux/actions/tasks.actions';
import Spinner from '../../SpinnerOverlay/Spinner';
import { showModal } from '../../../redux/actions/modals.actions';

const resolutionsStyle = {
  [TASK_RESOLUTIONS.WAIVE_FEE.ACCEPTED]: {
    color: COLORS.CINTAS_GREEN,
    icon: AcceptIcon,
    actionLabel: 'Accept',
  },
  [TASK_RESOLUTIONS.WAIVE_FEE.REJECTED]: {
    color: COLORS.CINTAS_RED,
    icon: RejectIcon,
    actionLabel: 'Reject',
  },
};

const WaiveFeeActions = ({ task }) => {
  const dispatch = useDispatch();
  const { resolved, resolutionValue } = task;
  const [resolving, setResolving] = React.useState(false);

  const onAction = async (resolution) => {
    try {
      setResolving(resolution);
      const success = await dispatch(changeTaskToResolved(task, resolution));
      showToast(success);
    } finally {
      setResolving(false);
    }
  };

  const showToast = (success) => {
    dispatch(
      showModal('TASK_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: success ? 'Task Completed!' : 'Something went wrong completing this task!',
        },
      }),
    );
  };

  const container = ({ children, ...rst }) => <Container {...(rst ?? {})}>{children}</Container>;
  const item = ({ children, ...rst }) => <ContainerItem {...(rst ?? {})}>{children}</ContainerItem>;

  const actionButton = (resolutionOpt, label, disabled) => {
    const Icon = resolutionsStyle[resolutionOpt]?.icon;
    return (
      <StyledButton
        variant="text"
        disabled={disabled}
        color={resolutionsStyle[resolutionOpt]?.color}
        handleButton={() => onAction(resolutionOpt)}
        startIcon={<Icon style={{ fontSize: 14 }} />}
        style={{ width: '100%', height: '100%' }}
        buttonContent={label}
      />
    );
  };

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20,
        width: 20,
        padding: 0,
        margin: 0,
        color: resolutionsStyle[resolving]?.color ?? COLORS.CINTAS_BLUE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        marginTop: '2%',
        padding: 0,
      }}
    />
  );

  return container({
    style: { justifyContent: 'space-around', alignItems: 'center', textAlign: 'center' },
    children: resolving
      ? spinner()
      : [
          resolved &&
            item({
              flex: 12,
              style: { color: resolutionsStyle[resolutionValue]?.color },
              children: [
                resolutionsStyle[resolutionValue] ? actionButton(resolutionValue, resolutionValue, true) : 'RESOLVED',
              ],
            }),
          ...(!resolved
            ? [
                item({
                  flex: 6,
                  style: { padding: 0, borderRight: `1px solid ${COLORS.CINTAS_LIGHT_GRAY}` },
                  children: [
                    actionButton(
                      TASK_RESOLUTIONS.WAIVE_FEE.REJECTED,
                      resolutionsStyle[TASK_RESOLUTIONS.WAIVE_FEE.REJECTED]?.actionLabel,
                    ),
                  ],
                }),
                item({
                  flex: 6,
                  children: [
                    actionButton(
                      TASK_RESOLUTIONS.WAIVE_FEE.ACCEPTED,
                      resolutionsStyle[TASK_RESOLUTIONS.WAIVE_FEE.ACCEPTED]?.actionLabel,
                    ),
                  ],
                }),
              ]
            : []),
        ],
  });
};

export default _.flow([connect()])(WaiveFeeActions);
