/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { NOTIFICATION_DATETIME_FORMAT } from './consts';
import { TASK_TYPE } from './consts/tasks.consts';
import { ccyFormat, getBrowserTimezone } from './helpers';
import { formatName } from '../models/Notification';

const getDateTimeLabel = (bodyData = {}, effectiveDate) => {
  if (!bodyData.datetime) return '';
  const browserTz = getBrowserTimezone();
  const tz = browserTz; // (bodyData.tz ?? browserTz) ?? {};
  const date = effectiveDate; // bodyData.datetime
  const datetimeFormatted = moment(date).format(NOTIFICATION_DATETIME_FORMAT); // moment.tz(date, tz.value).format(NOTIFICATION_DATETIME_FORMAT);

  return `${datetimeFormatted} ${tz.abbrev}`;
};

const getCreatedByLabel = (bodyData = {}) => {
  if (!bodyData.createdBy) return '';
  return `by ${bodyData.createdBy}`;
};

const getInstructorLabel = (detailsData = {}) => {
  if (!detailsData.instructor) return '';

  return `Instructor: ${detailsData.instructor.firstName ?? ''} ${detailsData.instructor.lastName ?? ''}`;
};

const getTransferName = (st) => formatName(`${st}`.split('::').pop());
export const getStatusLabel = (status, type) =>
  [TASK_TYPE.TERRITORY_CHANGE, TASK_TYPE.MISSING_TRAINER].includes(type)
    ? `Event transferred to ${getTransferName(status)}`
    : status ?? '';

export const buildTaskBody = (task = {}) => {
  switch (task.type) {
    case TASK_TYPE.WAIVE_FEE:
      return `${task.body?.title ?? ''}\n${getDateTimeLabel(task.body, task.effectiveDate)}\n${task.subType ? _.startCase(task.subType.toLowerCase()) : ''} Reason: ${task.details?.waiveReason ?? 'N/A'}\n Waived Fee: ${ccyFormat(task.details?.fee ?? 0)}\n${getCreatedByLabel(task.body)}`;
    case TASK_TYPE.TERRITORY_CHANGE:
      return `${task.body?.title ?? ''}\n${getDateTimeLabel(task.body, task.effectiveDate)}\nCustomer Number: ${task.details?.customer?.soldTo}\n${getCreatedByLabel(task.body)}`;
    case TASK_TYPE.MISSING_TRAINER:
      return `${task.body?.title ?? ''}\n${getDateTimeLabel(task.body, task.effectiveDate)}\nCustomer Number: ${task.details?.customer?.soldTo}\n${getInstructorLabel(task.details)}`;
    default:
      return task.body?.title ?? '';
  }
};
