/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const getEvents = (start, end, timezone) =>
  baseInstance.get(`/calendarData?start=${start}&end=${end}&timezone=${timezone}`);

export const getTciEvents = (id, start, end, timezone) =>
  baseInstance.get(`/calendarData/tci/${id}?start=${start}&end=${end}&timezone=${timezone}`);

export const fetchSelectedDayEvents = (start, end) => baseInstance.get(`/calendarDataMini?start=${start}&end=${end}`);
export const fetchTciSelectedDayEvents = (id, start, end) =>
  baseInstance.get(`/calendarDataMini/tci/${id}?start=${start}&end=${end}`);

export const fetchCancelledEventsDailyCount = (daysList) => baseInstance.post('/cancelledDaily', daysList);
export const fetchTciCancelledEventsDailyCount = (id, daysList) =>
  baseInstance.post(`/cancelledDaily/tci/${id}`, daysList);

export const getEventById = (eventId) => baseInstance.get(`/events/${eventId}`);

export const createEvent = (payload, tz) =>
  baseInstance.post(
    `/events?tz=${tz}&ignoreConflict=${Boolean(payload?.[0]?.subcontracted).toString().toLowerCase()}`,
    payload,
    {
      validateStatus: () => true,
    },
  );

export const updateEvent = (eventId, payload, tz) =>
  baseInstance.put(
    `/events/${eventId}?tz=${tz}&ignoreConflict=${Boolean(payload.subcontracted).toString().toLowerCase()}`,
    payload,
  );

export const deleteEvent = (eventId) => baseInstance.delete(`/events/${eventId}`);

const mapPartecipant = (p, eventId) => ({
  email: p.email ?? '',
  employeeID: p.employee_id ?? '',
  firstName: p.first_name ?? '',
  index: p.index,
  eventID: eventId,
  lastName: p.last_name ?? '',
  present: Boolean(p.present),
  location: p.location ?? '',
});

export const updatePartecipant = (eventId, partecipant) =>
  baseInstance.put(`/participants/${partecipant.id}`, mapPartecipant(partecipant ?? {}, eventId));

export const updateParentEventID = (payload) => baseInstance.put('/events/updateEventParent', payload);

export const savePartecipants = (eventId, partecipantsList) =>
  baseInstance.post(
    `/participants?eventid=${eventId}`,
    partecipantsList.map((p) => mapPartecipant(p ?? {}, eventId)),
  );

export const saveRoster = (eventId, formData) => baseInstance.put(`/events/${eventId}/customerRoster`, formData);
