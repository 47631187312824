/* eslint-disable import/prefer-default-export */
export const SAVE_OPEN_PANEL_FLAG = 'SAVE_OPEN_PANEL_FLAG';
export const SAVE_SHOW_NOTICE_DOT = 'SAVE_SHOW_NOTICE_DOT';
export const SAVE_NOTIFICATIONS_DATA = 'SAVE_NOTIFICATIONS_DATA';
export const SAVE_UNREAD_NOTIFICATIONS = 'SAVE_UNREAD_NOTIFICATIONS';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const CLEAR_NOTIFICATIONS_DATA = 'CLEAR_NOTIFICATIONS_DATA';

export const SAVE_TASKS_DATA = 'SAVE_TASKS_DATA';
export const CLEAR_TASKS_DATA = 'CLEAR_TASKS_DATA';
export const CLEAR_UNSOLVED_TASKS_DATA = 'CLEAR_UNSOLVED_TASKS_DATA';
export const SAVE_UNSOLVED_TASKS = 'SAVE_UNSOLVED_TASKS';
export const READ_TASK = 'READ_TASK';
export const RESOLVE_TASK = 'RESOLVE_TASK';
