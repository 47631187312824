import { Grid } from '@material-ui/core';
import React from 'react';

const CenteredContainer = ({ children, height, width, verticalOnly, horizontalOnly, style, ...rest }) => (
  <Grid
    container
    style={{
      width: width ?? '100%',
      height: height ?? '100%',
      alignItems: horizontalOnly ? null : 'center',
      justifyContent: verticalOnly ? null : 'center',
      ...(style ?? {}),
    }}
    {...(rest ?? {})}
  >
    {children}
  </Grid>
);

export default CenteredContainer;
