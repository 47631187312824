/* eslint-disable import/prefer-default-export */
import Papa from 'papaparse';

const CsvFactory = {
  downloadCsv: ({ headers, getHeaderLabel, dataList, fileName, normalize, forceStringHeaders = [] }) => {
    try {
      const headersIndex = {};
      headers.forEach((h, i) => {
        headersIndex[h] = i;
      });
      const data = dataList.map((t) => {
        const row = Array(headers.length).fill('');
        headers.forEach((h) => {
          const defaultVal = `${t[h] ?? ''}`;
          const val = normalize ? normalize(h, t[h]) ?? defaultVal : defaultVal;
          row[headersIndex[h]] = forceStringHeaders.includes(h) ? `\t${val}` : val;
        });
        return row;
      });
      let headerLabels = headers;
      if (getHeaderLabel) {
        headerLabels = (headers ?? []).map((h) => getHeaderLabel(h));
      }
      data.unshift(headerLabels);
      const csvString = Papa.unparse(JSON.stringify(data));
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvString)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = `${fileName}.csv`;
      hiddenElement.click();
      return csvString;
    } catch (e) {
      throw e;
    }
  },
};

export default CsvFactory;
