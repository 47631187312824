/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import { Grid, FormControlLabel } from '@material-ui/core';
import FASFieldTitle from '../CustomFormComponents/FASFieldTitle';
import FASCheckboxField from '../CustomFormComponents/FASCheckboxField/FASCheckboxField';
import MDTextInputField from '../FormComponents/MDTextInput/MDTextInputField';
import MDRadioGroupField from '../FormComponents/MDRadioGroup/MDRadioGroupField';
import FASRadio from '../CustomFormComponents/FASRadio';
import { MATERIAL_STATUS, MATERIAL_TYPE } from '../../../utils/consts';
import { materialsDataSelector } from '../../../redux/selectors/materials.selector';

const validate = (values, props) => {
  const { dispatch } = props;
  let errors = {};
  let requiredFields = [];

  // Non-address fields
  requiredFields = ['title', 'type'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

const CertificationSettingsForm = (props) => {
  const { dispatch, curValues, courseMaterials } = props;
  const curMaterialID = curValues?.id;

  const isUsingThisCert = _.find(
    courseMaterials,
    (course) =>
      course.certGroup === curMaterialID || course.recertGroup === curMaterialID || course.family === curMaterialID,
  );

  const inUse = Boolean(isUsingThisCert);

  React.useEffect(() => {
    dispatch(change('CertificationSettingsForm', 'certInUse', inUse));
  }, [curValues?.enabled]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} sm={4}>
        <FASFieldTitle title="Name" />
        <Field
          id="title"
          name="title"
          component={MDTextInputField}
          size="small"
          type="text"
          variant="outlined"
          noBorderRadius
          placeholder="Name"
        />
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={6} sm={3}>
        <FASFieldTitle title="Status" />
        <Field name="enabled" component={FASCheckboxField} label="Active" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field id="options" name="options" required component={MDRadioGroupField} row>
          <FormControlLabel value="certification" control={<FASRadio />} label="Certification" />
          <FormControlLabel value="recertification" control={<FASRadio />} label="Recertification" />
          <FormControlLabel value="family" control={<FASRadio />} label="Family" />
        </Field>
      </Grid>
    </Grid>
  );
};

export default _.flow([
  connect((state) => {
    const curValues = getFormValues('CertificationSettingsForm')(state);
    const courseMaterials = _.filter(
      Object.values(materialsDataSelector(state)[MATERIAL_TYPE.COURSE]),
      (item) => item.status !== MATERIAL_STATUS.HIDDEN,
    );
    return {
      curValues,
      courseMaterials,
    };
  }),
  reduxForm({
    form: 'CertificationSettingsForm',
    initialValues: {
      enabled: true,
    },
    validate,
  }),
])(CertificationSettingsForm);
