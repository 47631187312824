/* eslint-disable import/prefer-default-export */

import { SAVE_SAP_MATERIALS, SAVE_SAP_LISTINGS } from './actionTypes/sap';

import * as SAPAPI from '../../api/sap.api';

const saveSAPMaterials = (payload) => ({
  type: SAVE_SAP_MATERIALS,
  payload,
});

export const saveSAPListings = (payload) => ({
  type: SAVE_SAP_LISTINGS,
  payload,
});

// const saveSAPCustomers = (payload) => ({
//   type: SAVE_SAP_CUSTOMERS,
//   payload,
// });

export const fetchSAPMaterials = () => async (dispatch) => {
  try {
    const response = await SAPAPI.fetchSAPMaterials();
    if (response && response.data) {
      dispatch(saveSAPMaterials(response.data));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchSAPListings = (customer, salesOrg, dc) => async (dispatch) => {
  try {
    const response = await SAPAPI.fetchSAPListings(customer, salesOrg, dc);
    if (response && response.data) {
      dispatch(saveSAPListings(response.data));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const resetSAPListings = () => async (dispatch) => {
  try {
    dispatch(saveSAPListings([]));
    throw new Error();
  } catch (error) {
    throw error;
  }
};

// export const fetchSAPCustomers = () => async (dispatch) => {
//   try {
//     const response = await SAPAPI.fetchSAPCustomers();
//     if (response && response.data) {
//       dispatch(saveSAPCustomers(response.data));
//       return response.data;
//     }
//     throw new Error();
//   } catch (error) {
//     throw error;
//   }
// };
