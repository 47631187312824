/* eslint-disable no-nested-ternary */
import { IconButton } from '@material-ui/core';
// import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import React from 'react';
// import Papa from 'papaparse';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../../redux/actions/modals.actions';
import CsvFactory from '../../../../../utils/csvFactory';

const DownloadParticipantsBtn = ({ participants, headers, fileName }) => {
  const dispatch = useDispatch();

  const download = () => {
    try {
      CsvFactory.downloadCsv({
        headers,
        fileName,
        dataList: participants,
      });
    } catch (e) {
      dispatch(
        showModal('DOWNLOAD_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: { message: 'Something went wrong creating the CSV file. Please, try again.' },
        }),
      );
    }
  };

  return (
    <IconButton onClick={download} color="primary" style={{ padding: 0, margin: 0 }}>
      <DownloadForOfflineIcon style={{ fontSize: 38 }} />
    </IconButton>
  );
};

export default DownloadParticipantsBtn;
