/* eslint-disable import/no-cycle */
/* eslint-disable no-case-declarations */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* eslint-disable max-len */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { mapMaterials } from '../redux/actions/materials.actions';
import { DATE_SHORT_FORMAT, NOTIFICATION_EXPIRATION_DAYS, TIME_FORMAT_NO_SPACE } from '../utils/consts';
import { NOTIFICATIONS_HEADERS, NOTIFICATION_TYPE } from '../utils/consts/notifications.consts';
import { calculateEventTotalPrice } from '../utils/event.utils';
import { ccyFormat, getBrowserTimezone } from '../utils/helpers';
import { TASKS_LABELS, TASK_SUBTYPE_LABELS } from '../utils/consts/tasks.consts';
import { getStatusLabel } from '../utils/tasks.utils';

export const formatName = (name) => _.startCase((name ?? '').toLowerCase());

export default class Notification {
  constructor({
    notificationID,
    type,
    read = false,
    assigneeID,
    effectiveDate,
    creationDate,
    expirationDate,
    header,
    body,
    details,
    description,
    contact,
    customer,
  }) {
    this.notificationID = notificationID;
    this.type = type;
    this.read = read;
    this.assigneeID = assigneeID;
    this.creationDate = moment.utc(creationDate);
    this.expirationDate = expirationDate
      ? moment.utc(expirationDate)
      : this.creationDate.clone().add(NOTIFICATION_EXPIRATION_DAYS, 'days');
    this.effectiveDate = effectiveDate ?? moment.utc();
    this.header = header ?? NOTIFICATIONS_HEADERS?.[this.type] ?? '';
    this.body = new NotificationBody(body);
    this.details = new NotificationDetails({
      ...(details ?? {}),
      description,
      contact: new NotificationContact(contact),
      customer: new NotificationCustomer(customer),
    });
  }

  static descriptionFromOrder(type, timezone, orderSummary, actionInfo) {
    const tz = timezone?.value;
    const events = orderSummary?.events ?? [];
    const startTime = moment.utc(events?.[0]?.startTime).tz(tz);
    const endTime = moment.utc(events?.[events?.length - 1]?.endTime).tz(tz);
    const formatTimeInterval = (s, e, withDate) =>
      `${withDate ? `${s.format(DATE_SHORT_FORMAT)} ` : ''}${s.format(TIME_FORMAT_NO_SPACE)} - ${e.format(TIME_FORMAT_NO_SPACE)} ${timezone?.abbrev ?? ''}`;
    const classInfoStr = (withTime) =>
      [
        // 'Course(s):',
        ...events.map((ev) => {
          const start = moment.utc(ev.startTime).tz(tz);
          const end = moment.utc(ev.endTime).tz(tz);
          return `${events.length > 1 ? '\u2022 ' : ''}${(ev.courseObj?.title ?? '').toUpperCase()}${withTime ? `: ${formatTimeInterval(start, end)}` : ''} `;
        }),
      ].join('\n');

    const totalRevenue = events.reduce(
      (red, ev) => red + calculateEventTotalPrice(ev, ev.addOns ?? [], mapMaterials([ev.courseObj])),
      0,
    );

    switch (type) {
      case NOTIFICATION_TYPE.BOOKED_EVENT:
      case NOTIFICATION_TYPE.UPCOMING_EVENT:
      case NOTIFICATION_TYPE.COMPLETED_EVENTS:
      case NOTIFICATION_TYPE.CONFIRMATION_SIGN:
      case NOTIFICATION_TYPE.EVENT_NOT_STARTED:
      case NOTIFICATION_TYPE.SECONDARY_ADDED:
      case NOTIFICATION_TYPE.ESCALATED_EVENT:
      case NOTIFICATION_TYPE.E_OUTSIDE_LOCATION:
        return `${startTime.format(DATE_SHORT_FORMAT)}\n${classInfoStr(true)}\n${ccyFormat(totalRevenue)} `; // `Date: ${startTime.format(DATE_SHORT_FORMAT)}\n${classInfoStr(true)}\nTotal Revenue: ${ccyFormat(totalRevenue)} `;
      case NOTIFICATION_TYPE.CANCELED_EVENT:
        return `${classInfoStr(false)} \nTotal Booked Revenue: ${ccyFormat(totalRevenue)}\nCancellation Fee: ${ccyFormat((actionInfo ?? {}).fee ?? 0)} `;
      case NOTIFICATION_TYPE.RESCHEDULED_EVENT:
        const { originalStart, originalEnd } = actionInfo ?? {};
        const originalStartMom = originalStart ? moment.utc(originalStart).tz(tz) : undefined;
        const originalEndMom = originalEnd ? moment.utc(originalEnd).tz(tz) : undefined;
        return `${classInfoStr(false)} \n${originalStart && originalEnd ? `Original Date/Time: ${formatTimeInterval(originalStartMom, originalEndMom, true)}` : ''}\nNew Date / Time: ${formatTimeInterval(startTime, endTime, true)} `;
      case NOTIFICATION_TYPE.EDITED_EVENTS:
        const changesLabels = (actionInfo ?? {}).changesLabels ?? [];
        return `${classInfoStr(false)} \n${changesLabels.map((lb) => formatName(lb)).join('\n')} `;
      default:
        return undefined;
    }
  }

  static fromOrderDetails(type, effectiveDate, initiator, tz, orderSummary, actionInfo) {
    return new Notification({
      type,
      effectiveDate,
      body: NotificationBody.fromOrder(type, initiator, tz, orderSummary, actionInfo),
      details: NotificationDetails.fromOrder(orderSummary, actionInfo),
      description: this.descriptionFromOrder(type, tz, orderSummary, actionInfo),
      contact: NotificationContact.fromOrder(orderSummary),
      customer: NotificationCustomer.fromOrder(orderSummary),
    });
  }

  static fromTask(task, resolution, effectiveDate, initiator, tz, orderSummary) {
    return Notification.fromOrderDetails(
      NOTIFICATION_TYPE.ESCALATED_EVENT,
      effectiveDate,
      initiator,
      tz,
      orderSummary,
      {
        taskID: task.taskID,
        type: TASKS_LABELS[task.type] ?? '',
        subType: TASK_SUBTYPE_LABELS[task.subType],
        status: getStatusLabel(resolution ?? '', task.type),
      },
    );
  }
}

class NotificationBody {
  constructor({ title, createdBy, text, datetime, tz }) {
    this.title = title ?? '';
    this.createdBy = createdBy ?? '';
    this.text = text;
    this.datetime = moment.utc(datetime);
    this.tz = tz ?? getBrowserTimezone();
  }

  static fromOrder(type, initiator, tz, orderSummary, actionInfo) {
    let text;

    switch (type) {
      case NOTIFICATION_TYPE.EDITED_EVENTS:
        text = 'Required new confirmation';
        break;
      case NOTIFICATION_TYPE.ESCALATED_EVENT:
        text = `Escalation Type: ${actionInfo?.subType ? formatName(actionInfo?.subType) : ''} ${actionInfo?.type ? formatName(actionInfo?.type) : 'N/A'} \nEscalation Status: ${actionInfo?.status ? formatName(actionInfo.status) : 'N/A'} `;
        break;
      case NOTIFICATION_TYPE.COE_REVIEW_LISTING:
        text = 'Material not listed';
        break;
      case NOTIFICATION_TYPE.REVIEW_MAT_DEACTIVATED:
        text = 'Material deactivated in SAP';
        break;
      case NOTIFICATION_TYPE.COE_REVIEW_ORDER_BLOCK:
        text = 'Customer order blocked';
        break;
      default:
        break;
    }

    return new NotificationBody({
      title: formatName(orderSummary?.customerObj?.name),
      createdBy: formatName(`${initiator?.firstName ?? ''} ${initiator?.lastName ?? ''} `),
      datetime: orderSummary?.events?.[0]?.startTime,
      tz,
      text,
    });
  }
}

class NotificationDetails {
  constructor({ orderID, linkedTask, location, description, contact, customer }) {
    this.orderID = orderID;
    this.description = description ?? '';
    this.contact = contact;
    this.customer = customer;
    this.linkedTask = linkedTask;
    this.location = new NotificationLocationDetails(location ?? {});
  }

  static fromOrder(orderSummary, actionInfo) {
    return new NotificationDetails({
      orderID: orderSummary?.id,
      linkedTask: actionInfo?.taskID,
      location: { city: orderSummary?.events?.[0]?.locationDisplay, zip: orderSummary?.customerObj?.postal_code },
    });
  }
}

class NotificationLocationDetails {
  constructor({ city, zip }) {
    this.city = city;
    this.zip = zip;
  }
}

export class NotificationContact {
  constructor({ contactID, name, phone, email }) {
    this.contactID = contactID;
    this.name = name ?? '';
    this.phone = phone ?? '';
    this.email = email ?? '';
  }

  get firstName() {
    if (_.isEmpty(this.name)) return '';
    return this.name.split(' ')?.[0] ?? '';
  }

  get lastName() {
    if (_.isEmpty(this.name)) return '';
    return this.name.split(' ')?.[1] ?? '';
  }

  static fromOrder(orderSummary) {
    const tempContact = orderSummary?.temporaryContact;
    const contact = orderSummary?.contact ?? tempContact;
    const first = contact?.FirstName ?? '';
    const last = contact?.LastName ?? '';
    const email = (contact?.Email ?? '').toLowerCase();
    const isTemp = !orderSummary?.contact;
    const phone = (isTemp ? contact?.Phone : (contact.Phones ?? [{}])[0].Phone) ?? '';
    return new NotificationContact({
      contactID: orderSummary?.contactID,
      name: formatName(`${first} ${last} `),
      phone,
      email,
    });
  }
}

export class NotificationCustomer {
  constructor({ name, customerID, soldTo }) {
    this.name = name;
    this.customerID = customerID;
    this.soldTo = soldTo;
  }

  static fromOrder(orderSummary) {
    return new NotificationCustomer({
      name: formatName(orderSummary?.customerObj?.name),
      customerID: orderSummary?.customer,
      soldTo: orderSummary?.customerObj?.sold_to,
    });
  }
}
