/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const fetchSAPPricing = (material, sorg, plnt, customer) =>
  baseInstance.get(
    `/sap/pricing?${(material ?? '')
      .split(',')
      .map((mat) => `material=${mat}`)
      .join('&')}&sorg=${sorg}&plnt=${plnt}&customer=${customer}`,
  );

// export const fetchSAPPricing = (material, sorg, plnt, customer) => (
//   baseInstance.post(
//     `/sap/pricing?sorg=${sorg}&plnt=${plnt}&customer=${customer}`,
//     { materials: (material ?? '').split(',') },
//   )
// );
