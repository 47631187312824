/* eslint-disable max-len */
import React from 'react';
import { COLORS } from '../../utils/consts';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import SimpleText from '../Text/SimpleText';
import Spinner from './Spinner';

const defaultSize = 20;

const LoadingDataSpinner = ({ spinnerSize }) => (
  <Container spacing={1} style={{ justifyContent: 'end', marginTop: 6 }}>
    <ContainerItem flex="auto" style={{ verticalAlign: 'center' }}>
      <SimpleText txt="Loading data" color={COLORS.CINTAS_BLUE} style={{ fontWeight: 'bold' }} />
    </ContainerItem>
    <ContainerItem flex="auto" style={{ verticalAlign: 'center' }}>
      <Spinner
        margin={0}
        spinnerProps={{ color: 'primary' }}
        customStyle={{ maxHeight: spinnerSize ?? defaultSize, maxWidth: spinnerSize ?? defaultSize }}
        spinnerStyle={{ height: spinnerSize ?? defaultSize, width: spinnerSize ?? defaultSize }}
      />
    </ContainerItem>
  </Container>
);

export default LoadingDataSpinner;
