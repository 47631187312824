/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { Typography } from '@material-ui/core';
import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { useStyles } from '../EventPopper/EventPopperWrapper';
import { COLORS, DAY_LONG_FOMAT } from '../../../../utils/consts';
import { usePopperHeaderStyles } from '../EventPopper/EventPopperHeader';
import CancelledEventsPopperList from './CancelledEventsPopperList';
import CancelledCourseIcon from '../../CancelledCourseIcon';
import BaseEventsListPopper from '../EventPopper/BaseEventsListPopper';

const CancelledEventsPopper = ({ open, anchorEl, onClosePopper, cancelledEventsInfo }) => {
  const classes = useStyles({ maxWidth: '700px' })();
  const headerClasses = usePopperHeaderStyles();

  const dateLabel = () => (
    <Typography
      variant="h6"
      style={{
        color: COLORS.CINTAS_GRAY,
        fontWeight: 'normal',
        paddingLeft: 12,
        marginTop: 24,
      }}
    >
      {moment.utc(cancelledEventsInfo?.date).format(DAY_LONG_FOMAT)}
    </Typography>
  );

  const cancelIcon = () => <CancelledCourseIcon size={25} />;

  const cancelledEventsList = () => (
    <CancelledEventsPopperList cancelledEventsInfo={cancelledEventsInfo} onClosePopper={onClosePopper} />
  );

  return (
    <BaseEventsListPopper
      onClosePopper={onClosePopper}
      open={open}
      anchorEl={anchorEl}
      headerClasses={headerClasses}
      classes={classes}
      titleIcon={cancelIcon()}
      title="Cancelled Course(s)"
      header={dateLabel()}
      customListRender={cancelledEventsList}
    />
  );
};

export default CancelledEventsPopper;
