/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import { useSelector, connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';

import {
  EVENT_TYPE,
  MATERIAL_TYPE,
  ROLE_ACCESSES,
  SUBCONTRACTED_INSTRUCTOR_LABEL,
  TIME_BLOCK_REASONS,
  TIME_BLOCK_TYPE,
} from '../../../../utils/consts';
import PopperFormContent from './PopperFormContent';
import PopperFormTitle from './PopperFormTitle';
import { formatLocation, formatLocationTwoLines, removeLeadingZeros } from '../../../../utils/helpers';
import Container from '../../../LayoutBuilders/Container';
import ContainerItem from '../../../LayoutBuilders/ContainerItem';
import { timezoneNameSelector, timezoneSelector } from '../../../../redux/selectors/utils.selectors';
import { materialsDataSelector, materialSelectorOfMultipleTypes } from '../../../../redux/selectors/materials.selector';
import { hasUserAccessSelector } from '../../../../redux/selectors/user.selectors';
import SwapInstructorButton from '../../../Button/SwapInstructorButton';

const EventPopperForm = (props) => {
  // Destructure props
  const { selectedEvent, order, forceInstructorDisplay } = props;

  const canSwapInstructor = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.swapInstructor]));
  const hasOwnCalendar = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.ownCalendar]));
  const subcontracted = order?.subcontracted || order?.events?.[0]?.subcontracted;
  const inDelivery = order?.delivery;
  const materialsInfo = useSelector(materialsDataSelector);
  const instructors = subcontracted
    ? [SUBCONTRACTED_INSTRUCTOR_LABEL]
    : [
        _.startCase(
          `${(order?.events ?? [{}])[0]?.instructor?.firstName ?? ''} ${(order?.events ?? [{}])[0]?.instructor?.lastName ?? ''}`.toLowerCase(),
        ),
        ...((order?.events ?? [{}])[0]?.secondaryInstructor
          ? [
              _.startCase(
                `${(order?.events ?? [{}])[0]?.secondaryInstructor?.firstName ?? ''} ${(order?.events ?? [{}])[0]?.secondaryInstructor?.lastName ?? ''}`.toLowerCase(),
              ),
            ]
          : []),
      ];

  // Define constants for all event types
  const event = selectedEvent || '';
  // Destructure selectedEvent prop
  const {
    desc: { participants_count: participantsCount, location, reason, blockType },
  } = event;
  const selectedTimezoneAbbrev = useSelector(timezoneNameSelector);
  const selectedTimezone = useSelector(timezoneSelector);
  const duration =
    `${moment.utc(event.start).tz(selectedTimezone).format('LT')} - ` +
    `${moment.utc(event.end).tz(selectedTimezone).format('LT')}`;
  const locationsData = useSelector((state) => state.locations.data);
  const additionalParticipantsCount = event?.desc?.additional_participants?.[0]?.qty ?? 0;
  const combinedParticipantsCount = (additionalParticipantsCount ?? 0) + (participantsCount ?? 0);

  // Define constants for On-Site information
  const onsiteCustomer = order?.customerObj;
  const onsiteContact = order
    ? order.contact
      ? order.contact
      : order.temporaryContact
        ? { ...order.temporaryContact, isTemporary: true }
        : {}
    : {};

  const onsiteLoc = onsiteContact && !_.isEmpty(onsiteContact.Addresses) && onsiteContact.Addresses[0];

  const onsiteLocation = onsiteLoc && formatLocation(onsiteLoc);

  // Define constants for Open-Enrollment information
  const oeLocation = location && _.find(locationsData, { id: location });
  const oeLocationAddress = oeLocation && formatLocationTwoLines(oeLocation);
  const oeLocationName = oeLocation && oeLocation.name;

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const itemComponent = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const EventPopForm = () => {
    const eventDesc = event.desc && typeof event.desc === 'object' ? event.desc : '';

    const swapInstructorBtn = () => <SwapInstructorButton order={order} />;

    const instructorDisplay = () =>
      itemComponent({
        flex: 12,
        children: container({
          spacing: 0,
          style: { justifyContent: 'start', alignItems: 'center' },
          children: [
            itemComponent({
              flex: 12,
              children: [<PopperFormTitle title="Instructor" />],
            }),
            itemComponent({
              flex: 'auto',
              children: instructors.map((ins) => <PopperFormContent content={ins} />),
            }),
            !inDelivery &&
              canSwapInstructor &&
              !subcontracted &&
              !forceInstructorDisplay &&
              itemComponent({
                flex: 'auto',
                style: { marginLeft: 10 },
                children: swapInstructorBtn(),
              }),
          ],
        }),
      });

    const timeBlockForm = () => {
      if (reason && blockType) {
        return (
          <>
            {event.desc.reason && (
              <Grid item xs={6}>
                <PopperFormTitle title="Reason" />
                <PopperFormContent content={TIME_BLOCK_REASONS?.[event.desc.reason]} />
              </Grid>
            )}
            {event.desc.blockType && (
              <Grid item xs={6}>
                <PopperFormTitle title="Type" />
                <PopperFormContent content={TIME_BLOCK_TYPE?.[event.desc.blockType]} />
              </Grid>
            )}
            {event.desc.notes && (
              <Grid item xs={12}>
                <PopperFormTitle title="Notes" />
                <PopperFormContent content={event.desc.notes} />
              </Grid>
            )}
          </>
        );
      }
      return null;
    };

    const onSiteForm = () => {
      const course = materialSelectorOfMultipleTypes({
        materialsInfo,
        types: [MATERIAL_TYPE.COURSE, MATERIAL_TYPE.SKILL_CHECK],
        id: event.desc.course,
      });

      return (
        <>
          <Grid item xs={12}>
            <PopperFormTitle title="Customer" />
            {onsiteCustomer && <PopperFormContent content={_.startCase(_.toLower(onsiteCustomer.name))} />}
            {onsiteLocation && <PopperFormContent content={`${onsiteCustomer?.street}`} small />}
            {onsiteLocation && (
              <PopperFormContent
                content={`${onsiteCustomer?.city}, ${onsiteCustomer?.region} ${onsiteCustomer?.postal_code}`}
                small
              />
            )}
          </Grid>
          <Grid item xs={8}>
            {course && (
              <>
                <PopperFormTitle title="Course" />
                <PopperFormContent content={course.title} />
                <PopperFormContent content={removeLeadingZeros(course.code)} small />
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <>
              <PopperFormTitle title="Participants" />
              <PopperFormContent content={combinedParticipantsCount ?? '0'} />
            </>
          </Grid>
          {!hasOwnCalendar || forceInstructorDisplay ? instructorDisplay() : <></>}
        </>
      );
    };

    const openEnrollmentForm = () => (
      <>
        {oeLocation && participantsCount && (
          <>
            <Grid item xs={12}>
              <PopperFormTitle title="Location" />
              {oeLocationName && <PopperFormContent content={oeLocationName} />}
              <PopperFormContent content={oeLocationAddress[0]} small />
              <PopperFormContent content={oeLocationAddress[1]} small />
            </Grid>
            <Grid item xs={12}>
              <PopperFormTitle title="Participants" />
              <PopperFormContent content={participantsCount} />
            </Grid>
          </>
        )}
      </>
    );

    const defaultForm = () =>
      Object.entries(eventDesc).map(([key, value]) => (
        <Grid item xs={12} md={key === 'Location' || key === 'Customers Contact' || key === 'Customer' ? 12 : 6}>
          <PopperFormTitle title={JSON.stringify(key)} />
          <PopperFormContent
            content={
              value === 'array'
                ? value.map((item) =>
                    item === 'object' ? JSON.stringify(Object.values(value)) : JSON.stringify(Object.values(value)),
                  )
                : JSON.stringify(value)
            }
          />
        </Grid>
      ));

    switch (eventDesc.eventType) {
      case EVENT_TYPE.TIME_BLOCK:
        return timeBlockForm();
      case EVENT_TYPE.ON_SITE:
      case EVENT_TYPE.QUOTE:
        return onSiteForm();
      case EVENT_TYPE.OPEN_ENROLLMENT:
        return openEnrollmentForm();
      default:
        return defaultForm();
    }
  };

  const startDate = event.allDay
    ? moment.utc(`${event.desc.startTime}`)
    : moment.utc(`${event.desc.startTime}`).tz(selectedTimezone);

  return (
    <>
      <Grid item xs={6}>
        <PopperFormTitle title={event.allDay ? `Start Date (${selectedTimezoneAbbrev})` : 'Date'} />
        <PopperFormContent content={startDate.format('ddd, M/D')} />
      </Grid>
      {event.allDay ? (
        <Grid item xs={6}>
          <PopperFormTitle title={`End Date (${selectedTimezoneAbbrev})`} />
          <PopperFormContent content={moment.utc(event.desc.endTime).tz(selectedTimezone).format('ddd, M/D')} />
        </Grid>
      ) : (
        <Grid item xs={6}>
          <PopperFormTitle title={`Time (${selectedTimezoneAbbrev})`} />
          <PopperFormContent content={duration} />
        </Grid>
      )}
      <EventPopForm />
    </>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  contacts: state.contacts,
});

export default connect(mapStateToProps)(EventPopperForm);
