/* eslint-disable no-use-before-define */
import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { COLORS } from '../../utils/consts';
import { showModal } from '../../redux/actions/modals.actions';
import { fetchOrderSummary } from '../../redux/actions/orders.actions';
import { fetchTaskById } from '../../redux/actions/tasks.actions';
import Spinner from '../SpinnerOverlay/Spinner';
import EscalationSummary from '../Escalations/EscalationSummary';

const EscalationSummaryButton = ({ taskID, resolutionDate, clickCallback, marginLeft, fontSize }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [, setLoadedData] = useState({});
  const size = fontSize ?? '1.2em';

  const loadTaskAndOpenEscalationSummary = async () => {
    try {
      setLoading(true);
      const task = await dispatch(fetchTaskById(taskID));
      if (!task) return;
      const order = await dispatch(fetchOrderSummary(task.details.orderID));
      setLoadedData({ task, order });
      showEscalationHistoryDialog(task, order);
    } finally {
      setLoading(false);
    }
  };

  const showEscalationHistoryDialog = (task, order) => {
    const modalName = 'ESCALATION_HISTORY_DIALOG';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          disableBackdropClick: true,
          maxWidth: 'md',
          title: 'ESCALATION SUMMARY',
          content: <EscalationSummary task={task} resolutionDate={resolutionDate} order={order} />,
        },
      }),
    );

    if (clickCallback) clickCallback();
  };

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: size,
        width: size,
        padding: 0,
        margin: 0,
        color: COLORS.CINTAS_BLUE,
      }}
      customStyle={{
        maxHeight: size,
        maxWidth: size,
        margin: 0,
        padding: 0,
      }}
    />
  );

  return (
    <Typography
      variant="outlined"
      style={{
        font: 'Proxima Nova',
        marginLeft,
        color: COLORS.CINTAS_BLUE,
        fontWeight: 'bold',
        fontSize: size,
        cursor: 'pointer',
      }}
      onClick={loadTaskAndOpenEscalationSummary}
    >
      {loading ? spinner() : 'Escalation Summary'}
    </Typography>
  );
};

export default EscalationSummaryButton;
