/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable object-curly-newline */
import { Field } from 'redux-form';
import _ from 'lodash';
import { Box, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import ArrowRightAltSharp from '@material-ui/icons/ArrowRightAltSharp';
import SimpleText from '../../../Text/SimpleText';
import { BELOW_MIN_PRICE_CL, COLORS, ENTER_KEY_CODE } from '../../../../utils/consts';
import FASFieldTitle from '../../../Forms/CustomFormComponents/FASFieldTitle';
import MDTextInputField from '../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import StyledButton from '../../../Button/StyledButton';
import CenteredContainer from '../../../LayoutBuilders/CenteredContainer';
import { ccyFormat, showTwoDecimal } from '../../../../utils/helpers';
import FASCheckboxField from '../../../Forms/CustomFormComponents/FASCheckboxField/FASCheckboxField';
import { makeDayPriceDiscountCalculator } from '../../../../utils/event.utils';

const inputHeight = 40;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    whiteSpace: 'normal',
    marginBottom: theme.spacing(1),
  },
  button: {
    backgroundColor: COLORS.CINTAS_ORANGE,
    border: 'hidden',
    color: 'white',
    fontSize: 12,
    whiteSpace: 'nowrap',
    borderRadius: '0px',
    fontFamily: 'proxima-nova, sans-serif',
    '&:hover': {
      background: COLORS.CINTAS_ORANGE,
    },
    // marginTop: '65px',
  },
  formControl: {
    width: '100%',
  },
  chips: {
    borderRadius: '0px',
    margin: '1px',
    color: 'white',
    minWidth: '40px',
    fontWeight: 'bold',
  },
  level: {
    display: 'inline-block',
    fontSize: '12px',
  },
}));

const CoursePriceComponent = ({
  marketPrice,
  customer,
  initialCustomerPrice,
  allowEditingCustomer,
  commissionLevels,
  coursesNum,
  course,
  onCustomerPriceChanged,
  validator,
  material,
}) => {
  const classes = useStyles();
  const hasMarketPrice = Boolean(marketPrice);
  const [customerPrice, setCustomerPrice] = useState(showTwoDecimal(Number(initialCustomerPrice)));
  const [valid, setValid] = useState(true);

  const minCommissions = commissionLevels?.[commissionLevels?.length - 1] ?? {};

  const priceToLevel = (price) => {
    if (price < Number(minCommissions.min)) {
      return BELOW_MIN_PRICE_CL;
    }
    if (price >= Number(commissionLevels[0].min)) {
      return commissionLevels[0];
    }
    const cl = _.find(commissionLevels, (level) => price >= Number(level.min) && price <= Number(level.max));
    return cl ?? minCommissions;
  };

  const [selectedLevel, setSelectedLevels] = useState(
    commissionLevels
      ? (priceToLevel(Number(customerPrice ?? initialCustomerPrice)) ?? {}).name
      : commissionLevels
        ? commissionLevels[0].name
        : undefined,
  );
  const [activeIndex, setActiveIndex] = useState((commissionLevels.find((l) => l.name === selectedLevel) ?? {}).id);

  const calcDiscount = (price) =>
    makeDayPriceDiscountCalculator(
      course?.courseObj?.type,
      coursesNum ?? 1,
      Number(price ?? 0),
      Number(marketPrice ?? 0),
      course?.courseObj?.discount ?? 0,
      customer,
    ) ?? {};

  const { isMakeDayDiscount, adjustedPrice, makeDayPercentage } = calcDiscount(Number(customerPrice));

  const renderFromHelper = ({ touched, error }) => {
    if (touched || error) {
      return <FormHelperText>{touched && error}</FormHelperText>;
    }
    return '';
  };

  const onPriceChange = (price, allowNull) => {
    const newPrice = Number(price ?? 0);
    const level = priceToLevel(newPrice);
    setActiveIndex(level.id);
    setSelectedLevels(level.name);
    setValid(validator(newPrice));
    if (!price && allowNull) {
      setCustomerPrice('');
      if (onCustomerPriceChanged) {
        onCustomerPriceChanged(0);
      }
    } else {
      setCustomerPrice(allowNull ? newPrice : newPrice.toFixed(2));
      if (onCustomerPriceChanged) {
        onCustomerPriceChanged(newPrice, calcDiscount(newPrice));
      }
    }
  };

  const onEnterCustomerPrice = () => {
    if (!allowEditingCustomer) return;
    let minLevel = parseFloat(minCommissions.min);
    minLevel = Number.isNaN(minLevel) ? 0 : minLevel;
    if (minLevel > Number(customerPrice ?? 0)) {
      onPriceChange(minLevel);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const ChipGroup = ({ input, label, meta: { touched, error }, ...rest }) => {
    // Function for clicking the chip
    const handleClick = async (level) => {
      setSelectedLevels(level.name);
      setActiveIndex(level.id);
      onPriceChange(commissionLevels[level.id].min);
      input.onChange(level);
      input.onBlur(level);
    };

    const addLevel = () => {
      if (activeIndex >= 0 && activeIndex < commissionLevels.length) {
        const curLevel = priceToLevel(Number(customerPrice ?? 0));
        setActiveIndex(curLevel.id - 1);
        setSelectedLevels(commissionLevels[curLevel.id - 1].name);
        input.onChange(commissionLevels[curLevel.id - 1].name);
        input.onBlur(commissionLevels[curLevel.id - 1].name);
        onPriceChange(commissionLevels[curLevel.id - 1].min);
      } else if (activeIndex === -1) {
        const curLevel = minCommissions;
        setActiveIndex(curLevel.id);
        setSelectedLevels(curLevel.name);
        input.onChange(curLevel.name);
        input.onBlur(curLevel.name);
        onPriceChange(curLevel.min);
      }
    };

    return (
      <>
        <FormControl error={!!(touched && error)} variant="outlined" className={classes.formControl}>
          <Grid container spacing={0} style={{ textAlign: 'start', paddingBottom: 10 }}>
            <Grid item xs={6}>
              {commissionLevels &&
                commissionLevels.map((level, index) => (
                  <div key={level.id}>
                    <Chip
                      size="small"
                      label={level.name}
                      name={level.name}
                      id={level.id}
                      {...rest}
                      className={classes.chips}
                      style={{
                        backgroundColor: index === activeIndex ? '#012169' : '#DEDEDE',
                        color: index === activeIndex ? 'white' : 'black',
                      }}
                      clickable={allowEditingCustomer}
                      onClick={allowEditingCustomer ? () => handleClick(level) : null}
                    />
                    {index !== 0 ? (
                      <div className={classes.level}>{`-  $${level.min} - $${level.max}`}</div>
                    ) : (
                      <div className={classes.level}>{`-  $${level.min} +`}</div>
                    )}
                    <br />
                  </div>
                ))}
            </Grid>
            {allowEditingCustomer && (
              <Grid item xs={4}>
                <StyledButton
                  variant="contained"
                  buttonContent="NEXT LEVEL"
                  className={classes.button}
                  handleButton={addLevel}
                  disabled={activeIndex === 0}
                  startIcon={<ArrowUpwardIcon />}
                />
              </Grid>
            )}
          </Grid>
          {renderFromHelper({ touched, error })}
        </FormControl>
      </>
    );
  };

  const grid = ({ children, ...rest }) => <Grid {...(rest ?? {})}>{children}</Grid>;
  const container = ({ children, ...rest }) => grid({ children, container: true, ...(rest ?? {}) });
  const item = ({ children, ...rest }) => grid({ children, item: true, ...(rest ?? {}) });
  const title = (txt) => <FASFieldTitle title={txt} style={{ fontWeight: 'bold' }} />;

  const textField = (txt, rest) => (
    <CenteredContainer {...(rest ?? {})} height={inputHeight}>
      <SimpleText txt={txt} fontSize={16} {...(rest.textStyle ?? {})} />
    </CenteredContainer>
  );

  const marketPriceField = () => textField(`$ ${(marketPrice ?? 0).toFixed(2)}`, { verticalOnly: true });

  const customerPriceField = () => (
    <MDTextInputField
      noBorderRadius
      disabled={!allowEditingCustomer}
      size="small"
      type="number"
      variant="outlined"
      meta={{ error: !valid }}
      helperText={valid ? undefined : 'Below the mininum value allowed'}
      value={customerPrice ?? 0}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      FormHelperTextProps={{
        style: { color: COLORS.CINTAS_RED },
      }}
      required
      min={`${minCommissions.min ?? 0}`}
      onHandleChange={(value) => onPriceChange(value, true)}
      onKeyDown={(k) => (k.code === ENTER_KEY_CODE ? onEnterCustomerPrice() : null)}
      onBlurCapture={() => onEnterCustomerPrice()}
      autoComplete="customerPrice"
    />
  );

  const subheaderText = (txt, value) => (
    <div style={{ fontWeight: 'bold' }}>
      <span style={{ fontWeight: 'normal' }}>{txt}</span>
      <span style={{ fontWeight: 'bold' }}>{value}</span>
    </div>
  );

  const currentPriceLevel = () => (
    <Field id="priceLevel" name="priceLevel" component={ChipGroup} required commissionLevels={commissionLevels} />
  );

  const makeDayDiscountSubtitle = () =>
    subheaderText('Subtotal with Make It A Day discount: ', ccyFormat(adjustedPrice, course.currency));

  const makeDayDiscountPercentageSubtitle = () => subheaderText('Make It A Day discount: ', `${makeDayPercentage}%`);

  const setNewCustomerPrice = (value) => {
    if (value) {
      setCustomerPrice(0);
      if (onCustomerPriceChanged) {
        onCustomerPriceChanged(0);
      }
    } else {
      onPriceChange(initialCustomerPrice);
    }
  };

  const waiveCostCheckbox = () => (
    <Field
      component={FASCheckboxField}
      label={`Change price to ${ccyFormat(0, course?.price?.currency, true)}`}
      onChange={setNewCustomerPrice}
    />
  );

  const customerPriceComponent = () =>
    item({
      xs: 8,
      style: {
        paddingTop: 0,
        textAlign: 'start',
      },
      children: [
        title('Customer Price'),
        item({
          xs: 8,
          children:
            !allowEditingCustomer || !hasMarketPrice
              ? textField(`$ ${Number(customerPrice ?? 0).toFixed(2)}`, { verticalOnly: true })
              : [
                  customerPriceField(),
                  // !valid && validationError(),
                ],
        }),
        material.waiveCost && waiveCostCheckbox(),
        isMakeDayDiscount && makeDayDiscountSubtitle(),
        isMakeDayDiscount && makeDayDiscountPercentageSubtitle(),
        hasMarketPrice && (
          <>
            <Box height={20} />
            {subheaderText('Commission level: ', selectedLevel)}
            {item({ xs: 12, children: currentPriceLevel() })}
          </>
        ),
      ],
    });

  const arrow = () =>
    item({
      xs: 1,
      style: { padding: 0, margin: 0, paddingTop: 42 },
      children: (
        <CenteredContainer height={inputHeight}>
          <ArrowRightAltSharp />
        </CenteredContainer>
      ),
    });

  // const divider = () => <Divider style={{ margin: '2px 12px' }} />;

  return (
    <>
      {container({
        spacing: 3,
        children: [
          // item({xs: 12, style: {padding: 0 }, children: divider() }),
          item({
            style: { margin: 0, paddingTop: 0 },
            children: [title('Market Price'), marketPriceField()],
          }),
          arrow(),
          customerPriceComponent(),
        ],
      })}
    </>
  );
};

export default CoursePriceComponent;
