/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { EditorState, convertFromRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// recieves string version of raw content to be parsed and set to editor state
// set textOnly = true to display only the editor text without styling
const HTMLVisualizer = ({ content }) => {
  const [editorState, setEditorState] = useState();

  useEffect(() => {
    if (content) {
      try {
        const editorContent = convertFromRaw(JSON.parse(content));
        setEditorState(EditorState.createWithContent(editorContent));
      } catch {
        setEditorState(EditorState.createWithContent(ContentState.createFromText('Invalid content')));
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [content]);

  return <Editor editorState={editorState} readOnly toolbarClassName="hiddenToolbar" />;
};

export default HTMLVisualizer;
