import _ from 'lodash';
import { connect } from 'react-redux';
import React from 'react';

import MDDialog from './MDDialog';
import MDErrorAlert from './MDErrorAlert';
import MDSuccessAlert from './MDSuccessAlert';
import MDWarningAlert from './MDWarningAlert';
import FASInputDialog from './FASInputDialog';
import FASConfirmAlert from './FASConfirmAlert';
import * as ModalsActions from '../../redux/actions/modals.actions';
import FASEventDialog from './FASEventDialog';

const modalComponents = {
  MODAL_DIALOG: MDDialog,
  FAS_INPUT_DIALOG: FASInputDialog,
  FAS_EVENT_DIALOG: FASEventDialog,
  FAS_CONFIRM_ALERT: FASConfirmAlert,
  SUCCESS_ALERT: MDSuccessAlert,
  ERROR_ALERT: MDErrorAlert,
  WARNING_ALERT: MDWarningAlert,
};

const BaseModal = ({ modals, dispatch }) => {
  if (!_.size(modals)) {
    return null;
  }
  const components = _.map(modals, (modal, idx) => {
    if (!modal.id || !modal.data) return null;

    const { modalType, modalProps } = modal.data;
    const Component = modalComponents[modalType];

    return (
      <Component
        key={modal.id}
        {...modalProps}
        dispatch={dispatch}
        style={
          idx > 0 &&
          Object.keys(modalComponents)
            .filter((__, i) => i > 3)
            .find((component) => component === modalType)
            ? { bottom: idx * 82 }
            : {}
        }
        hideModal={() => dispatch(ModalsActions.hideModal(modal.id))}
      />
    );
  });
  return _.size(components) ? components : null;
};

export default connect((state) => ({ modals: state.modals.data }))(BaseModal);
