import React from 'react';
import { Grid } from '@material-ui/core';

const RowLayout = ({ children, style, spacing = 3, ...rest }) => (
  <Grid container spacing={spacing} style={{ ...(style ?? {}) }} {...rest}>
    {children}
  </Grid>
);

export default RowLayout;
