import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  //   Field,
  reduxForm,
  submit,
  // formValueSelector,
  // getFormValues,
} from 'redux-form';

const EventDialogContent = withStyles((theme) => ({
  root: {
    // minWidth: 800,
    maxWidth: 935,
    overflow: 'hidden',
    maxHeight: 'calc(100vh - 160px)',
    [theme.breakpoints.down('sm')]: {
      minWidth: 50,
      maxWidth: 935,
    },
  },
}))(MuiDialogContent);

const EventDialogWrapper = ({ children }) => <EventDialogContent dividers>{children}</EventDialogContent>;

export default _.flow([
  connect(),
  reduxForm({
    form: 'AddEventDialog',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    //   validate,
    initialValues: {
      // eventType: EVENT_TYPE.ON_SITE,
    },
    onSubmit: submit,
  }),
])(EventDialogWrapper);
