/* eslint-disable max-len */
import _ from 'lodash';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';

import React, { useEffect } from 'react';
import { reduxForm, Field, getFormValues, change } from 'redux-form';
import * as CourseCategoriesActions from '../../../redux/actions/courseCategories.actions';
import FASFieldTitle from '../../../components/Forms/CustomFormComponents/FASFieldTitle';
import FASCheckboxField from '../../../components/Forms/CustomFormComponents/FASCheckboxField/FASCheckboxField';
import MDTextInputField from '../../../components/Forms/FormComponents/MDTextInput/MDTextInputField';
import StyledButton from '../../../components/Button/StyledButton';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import ColorPicker from '../../../components/Forms/FormComponents/ColorPicker/ColorPicker';
import { COLORS } from '../../consts';

const CategoryActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}))(MuiDialogActions);

const formName = 'CategoryForm';

const validate = (values) => {
  const errors = {};
  // 'date', 'startTime', 'endTime',
  const requiredFields = ['name'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

const CategoryModal = (props) => {
  const { dispatch, category, curValues, valid, inUse } = props;

  useEffect(() => {
    if (category) {
      dispatch(change(formName, 'label', category.label));
      dispatch(change(formName, 'color', category.color));
      dispatch(change(formName, 'active', category.active));
    }
  }, []);

  const onSubmitCategory = async () => {
    const deactivatingInUse = !curValues.active && inUse;
    try {
      if (deactivatingInUse) throw new Error();
      if (!curValues.color) curValues.color = '#ffffff';
      if (category) await dispatch(CourseCategoriesActions.updateCourseCategory(category.id, curValues));
      else await dispatch(CourseCategoriesActions.createCourseCategory(curValues));
      dispatch(ModalsActions.hideModal('CATEGORY_MODAL'));
      dispatch(
        ModalsActions.showModal('CATEGORY_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: { message: `Category successfully ${category ? 'updated' : 'created'}` },
        }),
      );
    } catch (error) {
      const message = deactivatingInUse
        ? 'Category currently in use by course. Cannot be made inactive.'
        : `Error ${category ? 'updating' : 'creating new'} category. Please try again!`;
      dispatch(
        ModalsActions.showModal('CATEGORY_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { message },
        }),
      );
    }
  };

  const categoryForm = () => (
    <Grid container spacing={0} style={{ justifyContent: 'flex-start' }}>
      {/* Label */}
      <Grid item sm={12}>
        <FASFieldTitle title="Name *" />
        <Field
          id="label"
          name="label"
          component={MDTextInputField}
          helperText="Required"
          size="small"
          type="text"
          variant="outlined"
          required
          autoComplete="name"
          noBorderRadius
          placeholder="Name"
        />
      </Grid>
      {/* Color picker */}
      {(curValues?.color || !category) && (
        <Grid item sm={6}>
          <FASFieldTitle title="Color *" />
          <Field
            id="color"
            name="color"
            component={ColorPicker}
            hexColor={curValues?.color ?? '#ffffff'}
            colorChanged={(val) => dispatch(change(formName, 'color', val))}
          />
        </Grid>
      )}
      {/* Active */}
      <Grid item sm={6} style={{ padding: 0 }}>
        <FASFieldTitle title="Is Active? *" />
        <Field
          id="active"
          name="active"
          component={FASCheckboxField}
          label="Y/N"
          disabled={curValues?.active && inUse}
          required
        />
        {curValues?.active && inUse && (
          <Typography variant="subtitle2" style={{ color: COLORS.CINTAS_GRAY_43, fontStyle: 'italic' }}>
            The category is currently linked to a course and cannot be deactivated.
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  return (
    <div style={{ padding: 25, maxWidth: 550 }}>
      {curValues && categoryForm()}
      <CategoryActions>
        <StyledButton
          variant="contained"
          color="primary"
          type="button"
          width="160px"
          handleButton={() => onSubmitCategory()}
          disabled={!valid}
          buttonContent="Submit"
        />
      </CategoryActions>
    </div>
  );
};

export default _.flow([
  connect((state) => {
    const curValues = getFormValues(formName)(state);
    return {
      curValues,
    };
  }),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
    initialValues: {
      label: '',
      color: null,
      active: false,
    },
  }),
])(CategoryModal);
