/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { MenuItem, Typography, IconButton, Tooltip } from '@material-ui/core';
// eslint-disable-next-line import/no-cycle
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsFrame from './SettingsFrame';
import FASSelectField from '../../../components/Forms/CustomFormComponents/FASSelectField';
import BoldTitle from '../../../components/Text/BoldTitle';
import RowCell from '../../../components/LayoutBuilders/RowCell';
import RowLayout from '../../../components/LayoutBuilders/RowLayout';
import CustomSearchFilters from '../../../components/Settings/DataLookUp/CustomSearchFilters';
import {
  fetchPostgresTablesNames,
  fetchPostgresColumnNames,
  fetchPostgresDataCount,
  fetchPostgresDataLookup,
} from '../../../redux/actions/dataLookUp.actions';
import { showModal } from '../../../redux/actions/modals.actions';
import FASMUIDataTable from '../../../components/Settings/CustomMUIDataTable/FASMUIDataTable';
import Spinner from '../../../components/SpinnerOverlay/Spinner';
import { DATA_LOOKUP_RESULTS_TRESHOLD } from '../../../utils/consts';
import { showTresholdReachedPopup } from '../../../utils/event.utils';

const formName = 'DataLookUpDashboardSearch';

const DataLookUpView = (props) => {
  const {
    values,
    match: { path },
    dispatch,
  } = props;

  const [isLoading, setLoading] = React.useState(false);
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [tableColumns, setTableColums] = useState(null);
  const [rows, setRows] = useState(null);
  const [columns, setColumns] = useState(null);
  const [filtersHolder, setFiltersHolder] = useState(null);
  const emptyTableMessage = isLoading ? 'Loading. Please wait' : 'Sorry, no matching records found';
  const header = (h) => <BoldTitle title={h} fontSize={18} />;

  const options = {
    filter: true,
    filterType: 'dropdown',
    customSearch: (searchQuery, currentRow) => {
      let isFound = false;
      currentRow.forEach((col) => {
        if (col && col.toString().indexOf(searchQuery) >= 0) {
          isFound = true;
        }
      });
      return isFound;
    },
    customToolbar: () => (
      <Tooltip title="Refresh">
        <IconButton onClick={() => handleApplyFilters(filtersHolder)}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    selectableRows: 'none',
    sortOrder: {
      name: 'Start Date',
      direction: 'desc',
    },
    downloadOptions: {
      filename: `${selectedTable ?? 'dataLookUp'}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columnHeaders, data) => {
      const printedData = [];

      data.map((obj) => {
        // take each row of data, for string type, push to printed data
        const stringifiedData = obj.data?.map(String);

        printedData.push({ index: undefined, data: stringifiedData });

        return -1;
      });

      const val = `${buildHead(columnHeaders)}${buildBody(printedData)}`.trim();
      return val;
    },
    textLabels: {
      body: {
        noMatch: emptyTableMessage,
      },
    },
  };

  useEffect(async () => {
    setLoading(true);
    const tableNames = await fetchPostgresTablesNames();
    setTables(tableNames);
    setLoading(false);
  }, []);

  useEffect(async () => {
    if (selectedTable) {
      setLoading(true);
      const columnNames = await fetchPostgresColumnNames(selectedTable);
      setTableColums(columnNames.sort());
      setLoading(false);
    }
  }, [selectedTable]);

  const row = ({ children, withPadding = false, style, key, ...rest }) => (
    <RowLayout key={key} {...rest} style={{ paddingTop: withPadding ? 14 : 0, ...(style ?? {}) }}>
      {children}
    </RowLayout>
  );

  const rowCell = ({ child, flex, key, ...rest }) => (
    <RowCell key={key} flex={flex} {...(rest ?? {})}>
      {child}
    </RowCell>
  );

  const rowOneHeaders = () =>
    row({
      withPadding: true,
      children: [rowCell({ key: 0, child: header('Table'), flex: 4 })],
    });

  const onTableChange = (value) => {
    setSelectedTable(value);
    setTableColums(null);
    setColumns(null);
    setRows(null);
  };

  const showToast = (success, msg, warning) => {
    dispatch(
      showModal('DATA_LOOKUP_STATUS', {
        modalType: warning ? 'WARNING_ALERT' : success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: msg ?? '',
        },
      }),
    );
  };

  const tableSelectField = () => (
    <FASSelectField
      variant="outlined"
      placeholder="Select table"
      id="table"
      name="table"
      meta
      input={{
        value: selectedTable,
        onChange: onTableChange,
      }}
      displayEmpty
      style={{ textAlign: 'start', borderRadius: 0 }}
      disabled={isLoading}
    >
      {tables.sort().map((r) => (
        <MenuItem key={r} value={r}>
          {r}
        </MenuItem>
      ))}
    </FASSelectField>
  );

  const rowOne = () =>
    row({
      children: [rowCell({ key: 0, flex: 6, child: tableSelectField() })],
    });

  const getValueLabel = (field) =>
    (field.id ?? []).length > 0
      ? field.type === 'select'
        ? ((values?.filters ?? {})[field.id] ?? {}).label
        : (values?.filters ?? {})[field.id] ?? null
      : undefined;

  const getValue = (field) => (field.id ? (values?.filters ?? {})[field.id] ?? undefined : undefined);

  const handleApplyFilters = async (filters) => {
    const filtersToApply = filters.filter((f) => f.applied && f.type !== 'submit' && f.type !== 'clear');
    if (_.isEmpty(filtersToApply)) return;
    setLoading(true);
    setFiltersHolder(filtersToApply);
    const payload = {};
    try {
      filtersToApply.forEach((f) => {
        payload[f.id] = f.applied ?? getValue(f) ?? '';
      });
      const dataCount = await fetchPostgresDataCount(selectedTable, payload);
      if (dataCount <= DATA_LOOKUP_RESULTS_TRESHOLD) {
        setRows([]);
        setColumns([]);
        const results = await fetchPostgresDataLookup(selectedTable, payload);
        setColumns(Object.keys(results?.[0] ?? {}));
        setRows(results);
      } else {
        // if result greater than 200 set error count
        showTresholdReachedPopup(dispatch);
      }
    } catch (error) {
      showToast(false, 'Failed to Retrieve Data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const filters = () =>
    tableColumns?.length > 0 && (
      <div style={{ padding: 20 }}>
        <CustomSearchFilters
          key="key"
          noEmptyValues
          fields={tableColumns}
          handleApplyFilters={handleApplyFilters}
          dispatch={dispatch}
          formName={formName}
          getValue={getValue}
          getValueLabel={getValueLabel}
        />
      </div>
    );

  const infoText = (txt) => <Typography style={{ padding: 22, paddingTop: 30 }}>{txt}</Typography>;

  const spinner = () => <Spinner customStyle={{ marginTop: '5%' }} />;

  const dataTable = () => <FASMUIDataTable data={rows} columns={columns} options={options} />;

  return (
    <SettingsFrame path={path}>
      <div style={{ paddingLeft: 16, paddingRight: 16, width: '98%' }}>
        {rowOneHeaders()}
        {rowOne()}
      </div>
      {filters()}
      {isLoading
        ? spinner()
        : !rows
          ? infoText('No filters found for search.')
          : rows.length
            ? dataTable()
            : infoText('No results found.')}
    </SettingsFrame>
  );
};

export default _.flow([
  connect((state) => ({
    values: getFormValues(formName)(state),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    asyncBlurFields: [],
    initialValues: {
      filters: {},
    },
  }),
])(DataLookUpView);
