/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import _ from 'lodash';

/* eslint-disable import/prefer-default-export */
export const materialsDataSelector = (state) => state.materials?.data ?? {};
export const allMaterialsListSelector = (state) =>
  _.flatten(_.flatten(Object.values(state.materials?.data ?? {})).map((v) => Object.values(v)));

export const materialSelector = ({ state, type, id }) =>
  !type || !id || !state ? {} : materialsDataSelector(state)[type][id] ?? {};
export const materialsOfMultipleTypes = ({ state, materialsInfo, types }) => {
  if (!types || (!state && !materialsInfo)) return {};
  const data = state ? materialsDataSelector(state) : materialsInfo;
  return _.flatten((types ?? []).map((t) => Object.values(data[t] ?? {})));
};

export const materialSelectorOfMultipleTypes = ({ state, materialsInfo, types, id }) => {
  if (!types || !id || (!state && !materialsInfo)) return {};
  const data = state ? materialsDataSelector(state) : materialsInfo;
  for (let i = 0; i < types.length; i++) {
    const type = types[i];
    const typeMats = data[type];
    if (typeMats[id]) {
      return typeMats[id];
    }
  }
  return {};
};
