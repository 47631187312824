/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { change, getFormValues, reduxForm, submit } from 'redux-form';
import { makeStyles, TextField } from '@material-ui/core';
import { showModal } from '../../../redux/actions/modals.actions';
import { fetchConfigs, updateCancelReasons } from '../../../redux/actions/settings.actions';
import SettingsTile from './SettingsTile';

const formName = 'CancellationSettings';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '0px',
      },
      marginBottom: 24,
      maxWidth: 900,
    },
  },
});

const CancelReasonsSettings = ({ dispatch, handleSubmit, reasonsValues }) => {
  const classes = useStyles();

  React.useEffect(async () => {
    const configs = await dispatch(fetchConfigs());
    dispatch(change(formName, 'reasons', configs.CancelReasons ?? []));
  }, []);

  const submitReasons = async (values) => {
    try {
      dispatch(submit(formName));
      await dispatch(updateCancelReasons(values?.reasons ?? []));
      dispatch(
        showModal('UPDATE_REASONS_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: { message: 'Cancellation reasons successfully updated' },
        }),
      );
    } catch (error) {
      const message = 'Error updating cancellation reasons. Please try again!';
      dispatch(
        showModal('UPDATE_REASONS_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { message },
        }),
      );
    }
  };

  return (
    <SettingsTile title="Cancellation Settings" onSubmit={handleSubmit(submitReasons)}>
      <Autocomplete
        multiple
        id="reasons"
        options={[]}
        freeSolo
        value={reasonsValues}
        onChange={(event, values) => dispatch(change(formName, 'reasons', values))}
        ChipProps={{
          size: 'small',
          color: 'primary',
        }}
        renderInput={(params) => (
          <TextField
            classes={{
              root: classes.root,
            }}
            {...params}
            variant="outlined"
            placeholder=" Fee-waive Reasons"
            size="small"
          />
        )}
      />
    </SettingsTile>
  );
};

export default _.flow([
  connect((state) => ({
    reasonsValues: getFormValues(formName)(state)?.reasons,
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    initialValues: {
      reasons: [],
    },
    onSubmit: submit,
  }),
])(CancelReasonsSettings);
