/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { FieldArray, reduxForm, submit } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import React from 'react';

import AddContactDialog from './FormComponent/AddContactDialog';
import AddCustomerDialog from './FormComponent/AddCustomerDialog';
import CourseSelect from '../EventDialogControl/CourseSelect';
import { BOOKING_MODE, ROLE_ACCESSES } from '../../../../../utils/consts';
import {
  reportingTciLocationsIds,
  reportingTciLocations,
  selectedTciSelector,
  currentUserLocationsSelector,
  currentUserLocationsDataSelector,
  userInfoSelector,
  hasUserAccessSelector,
  instructorSelector,
} from '../../../../../redux/selectors/user.selectors';
import { customersListSelector } from '../../../../../redux/selectors/customers.selectors';
import SecondaryTciSelection from './FormComponent/SecondaryTciSelection';
import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';
import Container from '../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../LayoutBuilders/ContainerItem';
import { normalizeInstructor } from '../../../../../utils/event.utils';

const otherContactOption = {
  SAPContactID: 'Other',
  FirstName: 'Other',
  Email: 'Add a temporary contact',
};

const formName = 'AddEventDialog';
const renderMembers = (props) => {
  const { fields, mode } = props;

  return (
    <>
      <Grid item xs={12}>
        {fields.map((member, i) => (
          <CourseSelect formName={formName} mode={mode} key={member} member={member} i={i} fields={fields} />
        ))}
      </Grid>
    </>
  );
};

const OnsiteSubForm = ({ mode }) => {
  const customersData = useSelector((state) => {
    const customers = customersListSelector(state);
    const bookableUser = hasUserAccessSelector(state, [ROLE_ACCESSES.tciProfile, ROLE_ACCESSES.ownCalendar]);
    const selectedTci = !bookableUser ? selectedTciSelector(state) : userInfoSelector(state);
    if (!selectedTci) return { needsLocSelection: true, data: customers, locations: [] };
    const locationsIds = selectedTci?.subcontractor
      ? [...Object.keys(selectedTci.locations ?? {})]
      : _.uniq(bookableUser ? currentUserLocationsSelector(state) : reportingTciLocationsIds(state, selectedTci.pernr));
    const locations = bookableUser
      ? currentUserLocationsDataSelector(state)
      : reportingTciLocations(state, selectedTci?.pernr, selectedTci?.subcontractor ? [selectedTci] : null);

    if (locationsIds.length > 1) {
      return { needsLocSelection: true, data: customers, locations };
    }
    return {
      needsLocSelection: false,
      salesOffice: (locations[0] ?? {}).id,
      locations,
      data: !bookableUser
        ? (_.isEmpty(customers) ? [] : customers).filter((c) => locationsIds.includes(c.sales_office))
        : customers,
    };
  });
  const contactsData = useSelector((state) => state.contacts.selectedCustomer);
  const contactOptions = [...(contactsData ?? [])];
  const formData = useSelector((state) => state.form.AddEventDialog.values);

  const instructor = useSelector((state) => {
    const { instructorObj, instructor: instr } = formData;
    if (instructorObj) {
      return normalizeInstructor(instructorObj);
    }
    if (instr?.firstName || instr?.first_name) {
      return normalizeInstructor(instr);
    }
    return instructorSelector(state);
  });
  const secondaryInstructor = formData?.courses?.[0]?.secondaryInstructor;

  if (mode === BOOKING_MODE.booking || !formData?.contactID) {
    contactOptions.push(otherContactOption);
  }

  const title = (txt) => <FASFieldTitle title={txt} style={{ margin: 0, padding: 0 }} />;
  const text = (txt) => <FASFieldTitle title={txt} style={{ margin: 0, padding: 0, fontWeight: 'normal' }} />;

  const customerSelection = () => (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <AddCustomerDialog
          mode={mode}
          locationsOptions={customersData?.locations}
          needsLocSelection={customersData?.needsLocSelection}
          initialOptions={customersData.data}
          salesOffice={customersData.salesOffice}
        />
      </Grid>
    </Grid>
  );

  const contactSelection = () => (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <AddContactDialog formName={formName} constomerContacts={contactOptions} mode={mode} />
      </Grid>
    </Grid>
  );

  const container = ({ children, ...rst }) => <Container {...(rst ?? {})}>{children}</Container>;
  const item = ({ children, ...rst }) => <ContainerItem {...(rst ?? {})}>{children}</ContainerItem>;

  const primaryTciLabel = () =>
    [BOOKING_MODE.quote].includes(mode) ? (
      <></>
    ) : (
      item({
        flex: 4,
        children: [
          title('Primary Instructor'),
          item({
            style: { marginTop: 10 },
            children: [
              text(_.startCase(`${instructor?.first_name ?? ''} ${instructor?.last_name ?? ''}`.toLowerCase())),
            ],
          }),
        ],
      })
    );

  const secondaryTCISelection = () =>
    item({
      flex: 8,
      children: [
        <SecondaryTciSelection
          location={formData?.customer?.sales_office}
          mode={mode}
          initialValue={
            secondaryInstructor &&
            _.startCase(`${secondaryInstructor?.firstName ?? ''} ${secondaryInstructor?.lastName ?? ''}`.toLowerCase())
          }
        />,
      ],
    });

  return (
    <div>
      {customerSelection()}
      {contactSelection()}
      {container({
        spacing: 0,
        style: { alignItems: 'top', marginTop: 6, marginBottom: 24 },
        children: [primaryTciLabel(), secondaryTCISelection()],
      })}
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Grid container spacing={3}>
          <FieldArray name="courses" component={renderMembers} mode={mode} content={2} customer={formData.customer} />
        </Grid>
      </Grid>
    </div>
  );
};

export default _.flow([
  connect(),
  reduxForm({
    form: 'AddEventDialog',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: submit, // a unique identifier for this form
    initialValues: {},
  }),
])(OnsiteSubForm);
