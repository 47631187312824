/* eslint-disable import/no-cycle */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

import _ from 'lodash';
import { change } from 'redux-form';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { SAVE_CUSTOMERS } from './actionTypes/customers';
import * as ExternalActions from './externalsite.actions';

import * as CustomersAPI from '../../api/customers.api';
import {
  CUSTOMER_ORDERS_TABS,
  EVENT_TYPE,
  OK_STATUS_CODES,
  QUOTES_STATUS,
  QUOTE_EXPIRATION_DAYS,
} from '../../utils/consts';
import { userInfoSelector } from '../selectors/user.selectors';
import { createCreateQuotePayload } from '../payload_builders/orders.payload';

export const saveCustomers = (customers) => ({
  type: SAVE_CUSTOMERS,
  customers,
});

export const searchCustomerCount = (payload) => async () => {
  try {
    const res = await CustomersAPI.searchCustomerCount(payload);
    const { data } = res;
    if (data) return data;
    return 0;
  } catch (error) {
    return 0;
  }
};

export const searchCustomer = (payload) => async () => {
  try {
    const res = await CustomersAPI.searchCustomer(payload);
    const { data } = res;
    if (data) return data;
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchCustomerQuotes = (payload) => async (dispatch) => {
  const { formName, status, customer, page } = payload;

  try {
    const response = await CustomersAPI.fetchCustomerQuotes(customer, page + 1, status);
    if (response && response.data) {
      const data = { loaded: true, data: {} };
      const dataList = response.data?.data ?? response.data;
      dataList.forEach((quote) => {
        data.data[quote.quoteID] = quote;
      });
      const tab = CUSTOMER_ORDERS_TABS.quotes;
      await dispatch(
        change(formName, `totalPages.${tab}`, parseInt(`${response.data.recordsCount ?? dataList?.length ?? 1}`)),
      );
      await dispatch(change(formName, `orders.${tab}.${page}`, data));
      return data;
    }
    return {};
  } catch (e) {
    return {};
  }
};

export const fetchCustomerQuoteById = (customerID, quoteID, anonymous) => async () => {
  try {
    const response = await CustomersAPI.fetchCustomerQuoteById(customerID, quoteID, anonymous);
    if (response && response.data) {
      return response.data;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const emailCustomerQuote =
  ({ customer, quote, email }) =>
  async () => {
    try {
      const response = await CustomersAPI.emailCustomerQuote(email, customer, quote);
      return Boolean(response && OK_STATUS_CODES.includes(response.status));
    } catch (e) {
      return false;
    }
  };

export const updateCustomerQuoteStatus =
  ({ customer, quote, status }) =>
  async () => {
    try {
      const response = await CustomersAPI.updateCustomerQuoteStatus(customer, quote, status);
      return Boolean(response && OK_STATUS_CODES.includes(response.status));
    } catch (e) {
      return false;
    }
  };

export const createCustomerQuote = (customerID, values) => async (dispatch, getState) => {
  const contact = values.temporaryContact ?? values.customerContact;
  const phone = values.temporaryContact ? contact?.Phone : contact?.Phones?.[0]?.Phone;
  const state = getState();
  const user = userInfoSelector(state);
  const { travelInfo } = ExternalActions.getFeesFromEventsState(values);
  const newQuote = {
    travelInfo,
    quoteID: values.quoteID,
    creationDate: moment.utc().toISOString(),
    expirationDate: moment.utc().add(QUOTE_EXPIRATION_DAYS, 'days').toISOString(),
    status: values.status ? QUOTES_STATUS.active : QUOTES_STATUS.inactive,
    createdBy: `${user.firstName} ${user.lastName}`,
    contact: {
      name: `${contact?.FirstName ?? ''} ${contact?.LastName ?? ''}`,
      email: contact?.Email ?? '',
      phone: phone ?? '',
      contactID: contact?.SAPContactID,
      isTemporary: Boolean(values.temporaryContact),
    },

    data: createCreateQuotePayload(state, values, { uid: values.instructorID }, undefined, {
      typeOverride: EVENT_TYPE.QUOTE,
      unscheduled: true,
    }),
  };

  try {
    const response = await CustomersAPI.createCustomerQuote(customerID, newQuote);
    return response && OK_STATUS_CODES.includes(response.status) ? newQuote : false;
  } catch (e) {
    return false;
  }
};

export const fetchCustomerOrders = (payload) => async (dispatch) => {
  const run = async () => {
    const { formName, upcoming, customer, page } = payload;
    try {
      const response = await CustomersAPI.fetchCustomerOrders(customer, page + 1, upcoming);
      if (response && response.data?.Data) {
        const data = { loaded: true, data: {} };
        response.data.Data.forEach((ord) => {
          data.data[`${ord.orderId}-${page}`] = ord;
        });
        const tab = upcoming ? CUSTOMER_ORDERS_TABS.upcoming : CUSTOMER_ORDERS_TABS.past;
        await dispatch(change(formName, `totalPages.${tab}`, parseInt(`${response.data.RecordsCount ?? 1}`)));
        await dispatch(change(formName, `orders.${tab}.${page}`, data));
        return data;
      }
      return {};
    } catch (e) {
      return { error: true };
    }
  };
  return run();
};

export const fetchCustomers =
  (locations, save = true) =>
  async (dispatch, getState) => {
    try {
      const locs = locations ?? getState().settings.location;
      const res = await Promise.all(_.map(locs, (loc) => CustomersAPI.getAllCustomers(loc)));
      const data = _.flatten(_.map(res, (s) => s.data));
      if (data) {
        if (save) dispatch(saveCustomers(data));
        return data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };

export const fetchContactById = (customerID, contactID) => async () => {
  try {
    const response = await CustomersAPI.getContactById(customerID, contactID);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};
