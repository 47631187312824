/* eslint-disable max-len */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { change, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { Box, Typography } from '@material-ui/core';
import { adminTypeSelector, userInfoSelector } from '../../redux/selectors/user.selectors';
import { DATE_TIME_YYYY_SHORT_FORMAT_HISTORY, ROLE_ACCESSES } from '../../utils/consts';
import EditableLabel from '../Forms/FormComponents/ComplexLabels/EditableLabel';
import { getBrowserTimezone } from '../../utils/helpers';
import { showModal } from '../../redux/actions/modals.actions';
import { getInstructName } from '../../redux/actions/users.actions';
import { editNationalAccountNotes } from '../../redux/actions/nationalAccount.actions';
import { NATIONAL_ACCOUNT_NOTE_TYPE } from '../../utils/consts/nationalAccount.consts';

const formName = 'NationalAccountForm';

const HigherLevelAccountNote = ({
  values,
  account,
  canEdit,
  userUid,
  noteType,
  noteData: { note, lastEditedBy, lastEditedAt },
}) => {
  const dispatch = useDispatch();
  const noteFormFieldId = `${noteType === NATIONAL_ACCOUNT_NOTE_TYPE.COURSE ? 'courseConfirmation' : noteType.toLowerCase()}Notes`;
  const [instructorName, setInstructorName] = useState();

  useEffect(async () => {
    if (lastEditedBy) {
      const instructName = await dispatch(getInstructName(lastEditedBy));
      setInstructorName(_.startCase(instructName.toLowerCase()));
    }
  }, [lastEditedBy]);

  const showToast = (msg) => {
    dispatch(
      showModal('COURSE_NOTE_ERROR', {
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: msg ?? '',
        },
      }),
    );
  };

  const onEditNote = async () => {
    try {
      const notePayload = {
        type: noteType,
        data: values[noteFormFieldId].note,
        editedBy: userUid,
      };
      const editedNote = await editNationalAccountNotes(account, notePayload);
      if (editedNote) dispatch(change(formName, noteFormFieldId, editedNote[noteFormFieldId]));
      else {
        await dispatch(change(formName, noteFormFieldId, { note, lastEditedBy, lastEditedAt }));
        throw new Error();
      }
    } catch (e) {
      showToast('Error while updating note. Please try again.');
    }
  };

  const label = () => (
    <EditableLabel
      id={`${noteFormFieldId}.note`}
      path={`${noteFormFieldId}.note`}
      type="textarea"
      spacing={3}
      textStyle={{
        overflow: 'wrap',
        fontSize: '1em',
        textOverflow: 'ellipsis',
        flexWrap: 'wrap',
        wordBreak: 'break-all',
        textAlign: 'start',
      }}
      multiline
      multilineRows={5}
      values={values}
      placeholder="Notes"
      disabled={!canEdit}
      allowEditing={canEdit}
      onSave={onEditNote}
      saveButton
      inputFlex={10}
      labelFlex={11}
      hideEditIcon={!canEdit}
    />
  );

  const editedBy = () => (
    <Box mt={2}>
      <Typography variant="subtitle2" bold>
        <strong>Last updated by: </strong>
        {`${instructorName} ${moment.utc(lastEditedAt).tz(getBrowserTimezone()?.value).format(DATE_TIME_YYYY_SHORT_FORMAT_HISTORY)}`}
      </Typography>
    </Box>
  );

  return (
    <>
      {label()}
      {values[noteFormFieldId] && editedBy()}
    </>
  );
};

export default _.flow([
  connect((state) => ({
    values: getFormValues(formName)(state) ?? {},
    canEdit: adminTypeSelector(state) === ROLE_ACCESSES.advancedAdmin,
    userUid: userInfoSelector(state)?.uid ?? '',
  })),
  reduxForm({
    form: formName,
  }),
])(HigherLevelAccountNote);
