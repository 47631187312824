/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { Field, change } from 'redux-form';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Container from '../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../LayoutBuilders/ContainerItem';
import BoldTitle from '../../../../Text/BoldTitle';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
// import * as EventActions from '../../../../../redux/actions/events.actions';
import SimpleText from '../../../../Text/SimpleText';
// import FASCheckboxField from '../../../../Forms/CustomFormComponents/FASCheckboxField/FASCheckboxField';
import EditBoxButton from './EditBoxButton';
import { hideModal, showModal } from '../../../../../redux/actions/modals.actions';
import DeleteConfirmAlert from '../../EventPopper/DeleteConfirmAlert';
import FASCheckboxField from '../../../../Forms/CustomFormComponents/FASCheckboxField/FASCheckboxField';

const PartecipantsRow = ({
  i,
  values,
  isFullyEditable,
  fieldNames,
  formName,
  onChange,
  dispatch,
  eventId,
  courseId,
  repostParticipants,
  errors,
  isComplete,
  rosterConfig,
  onSelected,
}) => {
  const partecipant =
    values && values.partecipants && values.partecipants[courseId] && i < values.partecipants[courseId].length
      ? { ...values.partecipants[courseId][i] }
      : { index: i };
  const isEmpty =
    partecipant &&
    !partecipant[fieldNames.firstName] &&
    !partecipant[fieldNames.lastName] &&
    !partecipant[fieldNames.email] &&
    !partecipant[fieldNames.employeeId];
  const isValid =
    partecipant &&
    partecipant[fieldNames.firstName] &&
    partecipant[fieldNames.lastName] &&
    (rosterConfig?.emailRequired ? partecipant[fieldNames.email] : true) &&
    (rosterConfig?.employeeIdRequired ? partecipant[fieldNames.employeeId] : true) &&
    (rosterConfig?.locRequired ? partecipant[fieldNames.locRequired] : true);
  const shouldEdit =
    (isFullyEditable || (partecipant && partecipant[fieldNames.isBeingEdited]) || !isValid) && !isComplete;

  useEffect(() => () => {}, [partecipant, eventId, courseId]);

  const participantFormPath = `${fieldNames.partecipants}.${courseId}.${i}`;
  const disableSave = !_.isEmpty((errors[courseId] ?? [])[i] ?? {}) || !isValid;
  const buildParticipantFieldId = (field) => `${participantFormPath}.${field}`;

  // const onPesenceChanged = (val) => {
  //   if (partecipant) {
  //     dispatch(EventActions.updatePartecipant(eventId, {
  //       ...partecipant,
  //       present: val,
  //       index: i,
  //     }));
  //   }
  // };

  const markParticipantEditable = () => {
    const pts = values.partecipants[courseId] ?? [];
    if (i >= pts.length) {
      return;
    }
    dispatch(change(formName, `partecipants.${courseId}[${i}].${fieldNames.isBeingEdited}`, true));
  };

  // const updatePartecipantDetails = () => {
  //   const pts = values.partecipants[courseId] ?? [];
  //   if (i >= pts.length) { return; }
  //   const toUpdate = { ...(pts[i] ?? {}), index: i, [fieldNames.isPresent]: true };
  //   dispatch(change(formName, `partecipants.${courseId}[${i}].${fieldNames.isPresent}`, true));

  //   if (toUpdate.id) {
  //     dispatch(EventActions.updatePartecipant(eventId, toUpdate));
  //   } else {
  //     const updatedValues = {
  //       ...values,
  //       partecipants: {
  //         ...(values.partecipants ?? []),
  //         [courseId]: (values.partecipants[courseId] ?? []).map(
  //           (p, idx) => (
  //             idx === i
  //               ? ({ ...(toUpdate ?? {}) })
  //               : p
  //           ),
  //         ),
  //       },
  //     };
  //     repostParticipants({ updated: updatedValues });
  //   }
  // };

  const handleEditPartecipant = () => {
    const field = buildParticipantFieldId(fieldNames.isBeingEdited);
    const isBeingEdited = Boolean(partecipant[fieldNames.isBeingEdited] ?? false);

    // if (isBeingEdited) {
    //   updatePartecipantDetails();
    // }
    if (!isFullyEditable && onChange) {
      onChange(); // this tells the parent component to activate SAVE button if in pre-delivery
    }
    dispatch(change(formName, field, !isBeingEdited));
  };

  const clearRow = async () => {
    await dispatch(change(formName, participantFormPath, undefined));
    const updatedValues = {
      ...values,
      partecipants: {
        ...(values.partecipants ?? []),
        [courseId]: (values.partecipants[courseId] ?? []).filter((__, idx) => i !== idx),
      },
    };

    if (isFullyEditable) {
      if (onChange) {
        onChange(); // this tells the parent component to activate SAVE button if in pre-delivery
      }
    } else {
      repostParticipants({ updated: updatedValues }); // this tells the parent to repost the participants with using the new values passed
    }
  };

  const requestClearRow = () => {
    const modalName = 'DELETE_AERLT';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          confirmText: 'confirm',
          maxWidth: 'sm',
          title: 'DELETE PARTICIPANT',
          content: <DeleteConfirmAlert itemTypeName="participant information" action="remove" />,
          onConfirm: () => {
            dispatch(hideModal(modalName));
            clearRow();
          },
        },
      }),
    );
  };

  // Components Builders:
  const row = ({ children, key, ...rest }) => (
    <Container key={key} {...(rest ?? {})}>
      {children}
    </Container>
  );

  const rowCell = ({ child, flex, key, style, ...rest }) => (
    <ContainerItem key={key} flex={flex} {...(rest ?? {})} style={{ justifyContent: 'center', ...(style ?? {}) }}>
      {child}
    </ContainerItem>
  );

  const rowNumber = (num) => (
    <div style={{ minWidth: 35 }}>
      <BoldTitle title={num} />
    </div>
  );

  const inputField = ({ id, placeholder, required = false, initialValue }) => (
    <Field
      id={id}
      name={id}
      component={MDTextInputField}
      props={{
        value: initialValue,
      }}
      size="small"
      type="text"
      variant="outlined"
      required={required}
      noBorderRadius
      noErrorLabel
      initiallyValidate
      placeholder={placeholder}
      onChange={onChange}
    />
  );

  const editRowBtn = (isEditing = false) => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        padding: 0,
      }}
    >
      <EditBoxButton disableSave={disableSave} hideWhileEditing isEditing={isEditing} onClick={handleEditPartecipant} />
    </div>
  );

  const clearRowBtn = () => (
    <IconButton onClick={requestClearRow} disabled={isEmpty} style={{ padding: 0 }}>
      <ClearIcon />
    </IconButton>
  );

  const displayField = (txt) => <SimpleText txt={txt} fontSize={16} style={{ paddingTop: 0 }} />;

  const nameDisplay = () => {
    const missingLabel = '---- ----';
    let nameLabel = '';
    let emailLabel = '';
    let employeeIdLabel = '';
    let locationIdLabel = '';
    const missing = !(
      partecipant[fieldNames.firstName] ||
      partecipant[fieldNames.lastName] ||
      partecipant[fieldNames.email] ||
      partecipant[fieldNames.employeeId] ||
      partecipant[fieldNames.location]
    );

    if (!missing) {
      nameLabel = `${partecipant[fieldNames.firstName] ?? ''} ${partecipant[fieldNames.lastName] ?? ''}`;
      employeeIdLabel = `${partecipant[fieldNames.employeeId] ?? ''}`;
      emailLabel = `${partecipant[fieldNames.email] ?? ''}`;
      locationIdLabel = `${partecipant[fieldNames.location] ?? ''}`;
    }
    return row({
      spacing: 1,
      children: [
        rowCell({ key: 0, child: displayField(missing ? missingLabel : nameLabel), flex: 3 }),
        rowCell({ key: 1, child: displayField(missing ? missingLabel : emailLabel), flex: 5 }),
        rowCell({ key: 2, child: displayField(missing ? missingLabel : employeeIdLabel), flex: 2 }),
        rowCell({ key: 2, child: displayField(missing ? missingLabel : locationIdLabel), flex: 2 }),
      ],
    });
  };

  const selectionBox = () => {
    const id = buildParticipantFieldId(fieldNames.isSelected);
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginLeft: '10%',
        }}
      >
        <Field
          id={id}
          name={id}
          component={FASCheckboxField}
          onChange={onSelected}
          disabled={isComplete || !isValid}
          hideErrorHelper
        />
      </div>
    );
  };
  return row({
    key: `${i}-${i}`,
    spacing: 1,
    style: {
      textAlign: 'center',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
      paddingLeft: 10,
    },
    children: [
      !isComplete &&
        rowCell({
          key: '00',
          flex: 'auto',
          child: selectionBox(),
        }),
      rowCell({
        key: 0,
        child: rowNumber(i + 1),
        flex: 'auto',
      }),
      ...(shouldEdit
        ? [
            rowCell({
              key: 1,
              child: inputField({
                id: buildParticipantFieldId(fieldNames.firstName),
                placeholder: 'First name',
                initialValue: partecipant[fieldNames.firstName],
              }),
              flex: 2,
              onChange: !partecipant[fieldNames.isBeingEdited] ? markParticipantEditable : null,
            }),
            rowCell({
              key: 2,
              child: inputField({
                id: buildParticipantFieldId(fieldNames.lastName),
                placeholder: 'Last name',
                initialValue: partecipant[fieldNames.lastName],
              }),
              flex: 2,
            }),
            rowCell({
              key: 3,
              child: inputField({
                id: buildParticipantFieldId(fieldNames.email),
                placeholder: 'Email address',
                initialValue: partecipant[fieldNames.email],
              }),
              flex: 2,
            }),
            rowCell({
              key: 4,
              child: inputField({
                id: buildParticipantFieldId(fieldNames.employeeId),
                placeholder: 'Employee ID',
                initialValue: partecipant[fieldNames.employeeId],
              }),
              flex: 2,
            }),
            rowCell({
              key: 5,
              child: inputField({
                id: buildParticipantFieldId(fieldNames.location),
                placeholder: 'Site or Location ID',
                initialValue: partecipant[fieldNames.location],
              }),
              flex: 2,
            }),
            ...(!isFullyEditable ? [] : [<div key={11} />]),
          ]
        : [
            rowCell({ key: 6, child: nameDisplay(), flex: isComplete ? 11 : 9 }),
            // rowCell({ key: 8, child: <></>, flex: 1 }),
            !isComplete && rowCell({ key: 9, child: editRowBtn(), flex: 1 }),
          ]),
      !isComplete && rowCell({ key: 10, child: clearRowBtn(), flex: 'auto' }),
    ],
  });
};

export default PartecipantsRow;
