/* eslint-disable max-len */
import React from 'react';
import { Field } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EVENT_TYPE } from '../../../../../utils/consts';
import MDRadioGroupField from '../../../../Forms/FormComponents/MDRadioGroup/MDRadioGroupField';
import FASRadio from '../../../../Forms/CustomFormComponents/FASRadio';

const EventTypeRadioGroup = ({ onSwitchEventType }) => (
  <Field id="eventType" variant="outlined" name="eventType" required component={MDRadioGroupField} row>
    <FormControlLabel
      value={EVENT_TYPE.ON_SITE}
      control={<FASRadio />}
      label="On-site course"
      onClick={onSwitchEventType}
    />
    {/* <FormControlLabel value={EVENT_TYPE.OPEN_ENROLLMENT} control={<FASRadio />} label="Open enrollment course" onClick={onSwitchEventType} /> */}
    <FormControlLabel
      value={EVENT_TYPE.TIME_BLOCK}
      control={<FASRadio />}
      label="Time block"
      onClick={onSwitchEventType}
    />
  </Field>
);

export default EventTypeRadioGroup;
