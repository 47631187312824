/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import { IconButton } from '@material-ui/core';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import CreateIcon from '@material-ui/icons/Create';
import { connect, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { change, initialize, submit } from 'redux-form';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { BOOKING_MODE, CALENDAR_TYPE, COLORS, EVENT_TYPE, TIME_BLOCK_REASONS } from '../../utils/consts';

import { updateOrderPayload } from '../../redux/payload_builders/orders.payload';
import Spinner from '../SpinnerOverlay/Spinner';
import * as ModalsActions from '../../redux/actions/modals.actions';
import * as EventActions from '../../redux/actions/events.actions';
import * as OrdersActions from '../../redux/actions/orders.actions';
import { cleanRows } from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CartSummaryForm';
import { usePopperHeaderStyles } from '../Calendar/CalendarComponents/EventPopper/EventPopperHeader';
import EventDialog from '../Calendar/CalendarComponents/EventDialog/EventDialog';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import { fetchOrderSummary, updateOrder } from '../../redux/actions/orders.actions';
import ProgressDialog from '../Modals/ProgressDialog';
import { calendarTypeSelector } from '../../redux/selectors/calendar.selectors';
import { instructorSelector } from '../../redux/selectors/user.selectors';
import CancelOrderAlert from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/Alerts/CancelOrderAlert/CancelOrderAlert';
import { listingsSelector } from '../../redux/selectors/sap.selectors';
import {
  getCancelPrice,
  prepareOnSiteEditingForm,
  showAddToCalendarPopup,
  showSendConfirmationPopup,
} from '../../utils/event.utils';
import ConfirmSeriesAlert from '../Calendar/CalendarComponents/EventPopper/ConfirmSeriesAlert';
import { deleteSeries, unlinkSeries } from '../../redux/actions/recurringSeries.actions';
import { orderMaterialExclusionCheck } from '../../utils/helpers';

const EditOrderButton = ({ orderId, selectedEvent, userTz, callback, clickCallback, onEventEdited, listings }) => {
  const [loadingEdits, setLoadingEdits] = React.useState(false);
  const classes = usePopperHeaderStyles();
  const dispatch = useDispatch();
  const sessionInstructor = useSelector((state) => {
    const calendarType = calendarTypeSelector(state);
    return calendarType === CALENDAR_TYPE.tci ? instructorSelector(state) : undefined;
  });

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20,
        width: 20,
        padding: 0,
        margin: 0,
        color: COLORS.CINTAS_BLUE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        padding: 0,
      }}
    />
  );

  const showToast = (success) => {
    dispatch(
      ModalsActions.showModal('UPDATE_ORDER_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: success
            ? 'Event successfully updated!'
            : 'Something went wrong updating the order. Please, try again!',
        },
      }),
    );
  };

  const showReschedulingPopup = async (show) => {
    const modalName = 'reschedule_proggress_modal';
    if (!show) {
      await dispatch(ModalsActions.hideModal(modalName));
    } else {
      await dispatch(
        ModalsActions.showModal(modalName, {
          modalType: 'MODAL_DIALOG',
          modalProps: {
            bodyTextStyle: { fontSize: 18 },
            hideCancel: true,
            disableClosing: true,
            disableBackdropClick: true,
            maxWidth: 'xs',
            title: '',
            content: <ProgressDialog title="Rescheduling..." />,
          },
        }),
      );
    }
  };

  const editTimeBlock = async (values) => {
    const allDay = values.reason === TIME_BLOCK_REASONS.PTO && values.allDay;
    const start = moment.utc(allDay ? values.date : values.startTime);
    let end = moment.utc(allDay ? values.endDate : values.endTime);
    if (allDay) {
      end = end.set({ hour: 23, minute: 59 });
    }
    const eventId = values.id;
    const result = await dispatch(
      EventActions.updateEventById(eventId, {
        ...values,
        allDay,
        start,
        end,
        subcontracted: Boolean(sessionInstructor?.subcontractor),
      }),
    );
    return result;
  };

  const onSubmitEditSeries = async (allSeries, values, onDone) => {
    const startDateWithTime = moment.utc(values.recurringSeries?.startDate).set({
      hour: moment.utc(values.startTime).get('hour'),
      minute: moment.utc(values.startTime).get('minute'),
      second: moment.utc(values.startTime).get('second'),
    });
    const allDay =
      values.eventType === EVENT_TYPE.TIME_BLOCK && values.reason === TIME_BLOCK_REASONS.PTO && values.allDay;
    const start = moment.utc(allDay ? startDateWithTime.startOf('day') : startDateWithTime);
    const end = moment.utc(allDay ? values.endDate : values.endTime);
    const endDate = moment.utc(values.recurringSeries?.endDate).endOf('day').toISOString();
    let success = true;
    if (allSeries) {
      await deleteSeries(values.series, startDateWithTime.startOf('day').toISOString());
      const series = {
        ...values.recurringSeries,
        seriesID: uuidv4(),
        startDate: start,
        endDate,
      };
      success = await dispatch(EventActions.createEvent(start, end, { ...values, allDay, series }));
      dispatch(OrdersActions.fetchOrders());
    } else {
      await dispatch(unlinkSeries(values.id));
      success = await editTimeBlock(values);
    }
    dispatch(ModalsActions.hideModal('EDIT_ALERT'));
    onDone(success);
  };

  const showEditSeriesAlert = async (values, onDone) => {
    await dispatch(
      ModalsActions.showModal('EDIT_ALERT', {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          cancelText: 'All events',
          confirmText: 'This event',
          disableBackdropClick: true,
          maxWidth: 'lg',
          title: 'UPDATE SERIES CONFIRMATION',
          content: <ConfirmSeriesAlert />,
          onCancel: () => onSubmitEditSeries(true, values, onDone),
          onConfirm: () => onSubmitEditSeries(false, values, onDone),
        },
      }),
    );
  };

  const onSubmitCourse = async (vals, initialOrderFormData) => {
    const values = { ...(vals ?? {}) };
    dispatch(submit('AddEventDialog'));
    const onDone = (result, token, newOrderId) => {
      if (result) {
        dispatch(ModalsActions.hideModal('EDIT_EVENT_DIALOG'));
        cleanRows();
        if (values.eventType === EVENT_TYPE.ON_SITE) {
          const editingModes = [BOOKING_MODE.editing, BOOKING_MODE.rescheduling];
          const shouldShowEmailConfirmation = !editingModes.includes(values.bookingMode) || values.confirmationsentDate;
          if (shouldShowEmailConfirmation) {
            showSendConfirmationPopup(dispatch, token, newOrderId ?? values.order, values.bookingMode);
          }
        }
        if (values.addExternalCalendar) {
          showAddToCalendarPopup(values.order, dispatch);
        }
        if (onEventEdited) onEventEdited();
      }
      showToast(result);
    };
    let success = true;
    if (values.series) {
      showEditSeriesAlert(values, onDone);
      return;
    }
    if (values.eventType === EVENT_TYPE.ON_SITE) {
      const saveNewValues = async (rescheduleInfo) => {
        const orderObject = updateOrderPayload(
          values,
          sessionInstructor?.uid ?? values?.instructorID ?? selectedEvent?.desc?.instructorID,
          selectedEvent?.desc?.eventType,
        );

        try {
          return await dispatch(
            updateOrder({
              payld: orderObject,
              userTzCode: userTz,
              subcontractor: Boolean(sessionInstructor?.subcontractor),
              rescheduleInfo,
            }),
          );
        } catch (error) {
          if (error instanceof EventActions.EventOverlapError) {
            return dispatch(
              ModalsActions.showModal('UPDATE_ORDER_STATUS', {
                modalType: 'ERROR_ALERT',
                modalProps: {
                  message: error.message,
                },
              }),
            );
          }
        }
        return showToast(false);
      };
      if (values.bookingMode === BOOKING_MODE.editing) {
        success = await saveNewValues();
      } else if (values.bookingMode === BOOKING_MODE.rescheduling) {
        showRescheduleOrderAlert({
          eventDialogValues: values,
          onFeeAccepted: async (rescheduleInfo) => {
            try {
              await showReschedulingPopup(true);
              const originalTimes = {
                originalStart: initialOrderFormData?.events?.[0]?.startTime,
                originalEnd: initialOrderFormData?.events?.[initialOrderFormData?.events.length - 1]?.endTime,
              };
              const updated = await saveNewValues({ ...rescheduleInfo, originalTimes });
              onDone(true, updated?.emailToken, updated?.id);
            } finally {
              await showReschedulingPopup(false);
            }
          },
        });
        return;
      }
    } else {
      const allDay =
        values.eventType === EVENT_TYPE.TIME_BLOCK && values.reason === TIME_BLOCK_REASONS.PTO && values.allDay;
      const start = moment.utc(allDay ? values.date : values.startTime);
      let end = moment.utc(allDay ? values.endDate : values.endTime);
      if (allDay) {
        end = end.set({ hour: 23, minute: 59 });
      }
      const eventId = values.id;
      success = await dispatch(
        EventActions.updateEventById(eventId, {
          ...values,
          allDay,
          start,
          end,
          subcontracted: Boolean(sessionInstructor?.subcontractor),
        }),
      );
    }
    onDone(success);
  };

  const onSubmitRescheduleOrder = async (ordId, isWaived, cost, currency, callbackFn, reason, notes, noTask) => {
    try {
      const rescheduleInfo = {
        orderId: ordId,
        reason,
        notes,
        isWaived,
        cost,
        price: {
          amount: Number(cost && !isWaived ? cost : 0.0),
          currency,
          option: 'rescheduleFee',
        },
        noTask,
      };
      await dispatch(change('AddEventDialog', 'rescheduleInfo', rescheduleInfo));
      await dispatch(ModalsActions.hideModal('RESCHEDULE_ORDER_ALERT'));
      callbackFn(rescheduleInfo);
    } catch (error) {
      const errMessage = 'Error Rescheduling Event. Please contact an Admin';
      dispatch(
        ModalsActions.showModal('RESCHEDULE_EVENT_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { errMessage },
        }),
      );
    }
  };

  const showRescheduleOrderAlert = async ({ onFeeAccepted, eventDialogValues }) => {
    const curOrderID = eventDialogValues?.order ?? '';
    const orderSum = await dispatch(fetchOrderSummary(curOrderID));
    const curOrder = curOrderID && orderSum;
    const currency =
      curOrder && curOrder.events && curOrder.events.length > 0 ? curOrder.events[0]?.price?.currency ?? '' : '';
    const cancelCost = await getCancelPrice(curOrder?.events ?? {}, curOrder?.customerObj, dispatch, listings, true);
    const isSigned = curOrder?.signature ?? false;
    const hasExcludedMaterial = orderMaterialExclusionCheck(curOrder ?? {}, listings);
    if (cancelCost !== 0 && !curOrder?.isRental) {
      const modalName = 'RESCHEDULE_ORDER_ALERT';
      dispatch(
        ModalsActions.showModal(modalName, {
          modalType: 'FAS_CONFIRM_ALERT',
          modalProps: {
            bodyTextStyle: { fontSize: 18 },
            hideCancel: true,
            disableBackdropClick: true,
            maxWidth: 'lg',
            title: 'RESCHEDULE COURSE',
            content: (
              <CancelOrderAlert
                confirmText="Reschedule"
                modalName={modalName}
                isReschedule
                cancelCost={cancelCost}
                curEvent={eventDialogValues}
                isSigned={isSigned}
                hasExcludedMaterial={hasExcludedMaterial}
                onSubmit={(isWeived, reason, notes, systemForced) =>
                  onSubmitRescheduleOrder(
                    curOrderID,
                    isWeived,
                    cancelCost,
                    currency,
                    onFeeAccepted,
                    reason,
                    notes,
                    systemForced,
                  )
                }
              />
            ),
          },
        }),
      );
    } else {
      await onSubmitRescheduleOrder(curOrderID, false, 0.0, currency, onFeeAccepted);
    }
  };

  const showEditEventDialog = (initialOrderFormData) => {
    const modalName = 'EDIT_EVENT_DIALOG';
    dispatch(
      ModalsActions.showModal(modalName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          deleteText: 'delete',
          disableBackdropClick: true,
          maxWidth: 'lg',
          title: 'EDIT EVENT',
          draggable: true,
          nestedScrolling: true,
          content: (
            <EventDialog
              modalName={modalName}
              onSubmit={(vals) => onSubmitCourse(vals, initialOrderFormData)}
              updatedEvent={selectedEvent}
              mode={BOOKING_MODE.editing}
            />
          ),
        },
      }),
    );
  };

  const prepareOnSiteForm = async () => {
    setLoadingEdits(true);
    try {
      const data = await prepareOnSiteEditingForm({
        orderId,
        dispatch,
        formName: 'AddEventDialog',
        initialData: selectedEvent,
        tz: userTz,
      });

      return data;
    } finally {
      setLoadingEdits(false);
    }
  };

  const editEventHandler = async () => {
    if (clickCallback) {
      const proceed = await clickCallback();
      if (!proceed) return;
    }
    let initialOrderFormData;
    if (selectedEvent) {
      const { eventType } = selectedEvent.desc;
      if (eventType === EVENT_TYPE.TIME_BLOCK) {
        const descData = {
          ...selectedEvent.desc,
          date: (selectedEvent.allDay
            ? moment.utc(selectedEvent.start)
            : moment.utc(selectedEvent?.desc?.startTime).tz(userTz)
          ).format('YYYY-MM-DD'),
          endDate: selectedEvent.allDay && moment.utc(selectedEvent?.desc?.endTime).tz(userTz).format('YYYY-MM-DD'),
          startTime: selectedEvent.allDay ? undefined : moment.utc(selectedEvent?.desc?.startTime),
          endTime: selectedEvent.allDay ? undefined : moment.utc(selectedEvent?.desc?.endTime),
        };
        dispatch(initialize('AddEventDialog', descData));
      }
      if (eventType === EVENT_TYPE.ON_SITE) {
        initialOrderFormData = await prepareOnSiteForm();
      }
      if (eventType === EVENT_TYPE.OPEN_ENROLLMENT) {
        const openEnrollmentData = {
          ...selectedEvent.desc,
          date: moment.utc(`${selectedEvent.start}`).format('YYYY-MM-DD'),
          startTime: moment.utc(selectedEvent.start),
          endTime: moment.utc(selectedEvent.end),
        };
        dispatch(initialize('AddEventDialog', openEnrollmentData));
      }
    }

    showEditEventDialog(initialOrderFormData);
    callback?.call();
  };

  return (
    <IconButton aria-label="close" edge="end" className={classes.closeButton} onClick={editEventHandler}>
      {loadingEdits ? spinner() : <CreateIcon />}
    </IconButton>
  );
};

export default _.flow([
  connect((state) => ({
    userTz: timezoneSelector(state),
    listings: listingsSelector(state),
  })),
])(EditOrderButton);
