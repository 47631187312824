import { YES_NO } from './notifications.consts';

/* eslint-disable import/prefer-default-export */
export const NATIONAL_ACCOUNT_NOTE_TYPE = {
  PROGRAM: 'PROGRAM',
  COURSE: 'COURSE',
  ROSTER: 'ROSTER',
  PO: 'PO',
};

export const NATIONAL_ACCOUNT_NOTE_LABELS = {
  [NATIONAL_ACCOUNT_NOTE_TYPE.PROGRAM]: 'Program Note',
  [NATIONAL_ACCOUNT_NOTE_TYPE.COURSE]: 'Course Confirmation Note',
  [NATIONAL_ACCOUNT_NOTE_TYPE.ROSTER]: 'Roster Note',
  [NATIONAL_ACCOUNT_NOTE_TYPE.PO]: 'PO Note',
};

export const ROSTER_CONFIGS = {
  emailRequired: YES_NO,
  employeeIdRequired: YES_NO,
  locRequired: YES_NO,
};

export const ROSTER_CONFIGS_HEADERS = {
  emailRequired: 'Email',
  employeeIdRequired: 'Employee ID',
  locRequired: 'Site or Location ID',
};
