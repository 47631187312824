/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import { Grid } from '@material-ui/core';
import FASFieldTitle from '../../CustomFormComponents/FASFieldTitle';
import HTMLEditor from '../../../HTMLComponents/HTMLEditor';
import FASCheckboxField from '../../CustomFormComponents/FASCheckboxField/FASCheckboxField';
import { hideModal, showModal } from '../../../../redux/actions/modals.actions';
import StyledButton from '../../../Button/StyledButton';
import { createCustomerFeedback, updateCustomerFeedback } from '../../../../redux/actions/feedback.actions';

const formName = 'CustomerFeedbackForm';

const validate = (values, props) => {
  const { dispatch } = props;
  let errors = {};
  let requiredFields = [];

  // Non-address fields
  requiredFields = ['header', 'body'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

const CustomerFeedbackForm = ({ feedback, dispatch, curValues, showToast }) => {
  const curMaterialID = curValues?.id;

  useEffect(() => {
    if (feedback) {
      dispatch(change(formName, 'header', feedback.Header));
      dispatch(change(formName, 'body', feedback.Body));
      dispatch(change(formName, 'visible', feedback.Visible));
    }
  }, []);

  const handleSubmit = async () => {
    try {
      // TODO: set datetimes ?
      if (feedback) await dispatch(updateCustomerFeedback(feedback.ID, curValues));
      else await dispatch(createCustomerFeedback(curValues));
      dispatch(hideModal('FEEDBACK_MODAL'));
      showToast(true, `Customer feedback successfully ${feedback ? 'updated' : 'created'}`);
    } catch (error) {
      const message = `Error ${feedback ? 'updating' : 'creating new'} category. Please try again!`;
      showToast(false, message);
    }
  };

  const submitButton = () => (
    <StyledButton
      variant="contained"
      color="primary"
      type="submit"
      width="160px"
      handleButton={handleSubmit}
      buttonContent="Submit"
    />
  );

  return (
    <Grid container spacing={0} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <FASFieldTitle title="Header" />
        <Field
          id="header"
          name="header"
          component={HTMLEditor}
          content={feedback?.Header}
          formName={formName}
          height={165}
          variant="outlined"
          required
          noBorderRadius
        />
      </Grid>
      <Grid item xs={12}>
        <FASFieldTitle title="Body" />
        <Field
          id="body"
          name="body"
          component={HTMLEditor}
          content={feedback?.Body}
          formName={formName}
          height={250}
          variant="outlined"
          required
          noBorderRadius
        />
      </Grid>
      <Grid item xs={4}>
        <FASFieldTitle title="Status" />
        <Field name="visible" component={FASCheckboxField} label="Visible" />
      </Grid>
      <Grid item xs={12}>
        {submitButton()}
      </Grid>
    </Grid>
  );
};

export default _.flow([
  connect((state) => {
    const curValues = getFormValues(formName)(state);
    return {
      curValues,
    };
  }),
  reduxForm({
    form: formName,
    initialValues: {
      visible: true,
    },
    validate,
    onSubmitFail: (errors, dispatch) => {
      const fieldName = Object.keys(errors)[0];
      const fieldError = errors[fieldName];
      const message = _.startCase(`Error: ${fieldName} ${fieldError}`.toLowerCase());
      dispatch(
        showModal('CUSTOMER_FEEDBACK_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { message },
        }),
      );
    },
  }),
])(CustomerFeedbackForm);
