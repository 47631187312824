import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { Autocomplete } from '@material-ui/core';
import React from 'react';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  option: {
    '&[aria-disabled="true"]': {
      opacity: 1,
    },
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  formControl: {
    minWidth: 220,
    width: '100%',
  },
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0px',
    },
    '.MuiOutlinedInput-root': {
      borderRadius: '0px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0px',
    },
  },
  input: {
    borderRadius: '0px',
  },
});

const getLabelDefault = (option) => {
  if (typeof option === 'object') {
    const opt = JSON.stringify({ ...option });

    if (option.id) return option.id;
    if (option.name) return option.name;

    return opt;
  }

  if (typeof option === 'string') {
    return option;
  }

  return option.toString();
};

const MDAutoComplete = ({
  props,
  options,
  input,
  label,
  children,
  ref,
  key,
  onValueChange,
  required,
  getLabel,
  disabled,
  meta: { touched, invalid, error },
  size,
  inputProps,
  forcePopupIcon,
  noBorderRadius,
  renderOption,
  getOptionDisabled,
  ...custom
}) => {
  const classes = useStyles();

  const handleAutocomplete = (event, newValue) => {
    document.activeElement.blur();
    if (newValue == null) {
      input.onChange(null);
      input.onBlur(null);
    }
    if (newValue != null) {
      input.onChange(newValue);
      input.onBlur(newValue);
      if (onValueChange) {
        onValueChange(newValue);
      }
    }
  };

  return (
    <Autocomplete
      classes={{
        option: classes.option,
        input: noBorderRadius && classes.input,
        inputRoot: noBorderRadius && classes.inputRoot,
      }}
      ref={ref}
      {...props}
      options={options}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getLabel || getLabelDefault}
      disabled={disabled}
      size={size || 'medium'}
      style={{ width: '100%' }}
      key={key}
      forcePopupIcon={typeof forcePopupIcon === 'boolean' ? forcePopupIcon : 'auto'}
      value={input.value || null}
      onChange={handleAutocomplete}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={touched && invalid}
          helperText={touched && error}
          required={required}
          variant="outlined"
          key={key}
          onBlur={() => {
            input.onBlur(input.value);
          }}
          InputProps={{
            ...params.InputProps,
            ...inputProps,
          }}
        />
      )}
      ListboxProps={{
        style: {
          maxHeight: '150px',
        },
      }}
      {...custom}
    />
  );
};
export default MDAutoComplete;
