import { Grid } from '@material-ui/core';
import React from 'react';

const ContainerItem = ({ children, flex, ...rest }) => {
  const grid = ({ ...r }) => <Grid {...(r ?? {})}>{children}</Grid>;

  return grid({
    xs: flex,
    item: true,
    ...(rest ?? {}),
  });
};

export default ContainerItem;
