import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTciByZip } from '../../redux/actions/users.actions';

const useCustomerTci = (customer) => {
  const [tci, setTci] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!customer) return;
    const loadTciInfo = async () => {
      try {
        setIsLoading(true);
        const { postal_code: postalCode } = customer;
        if (!postalCode) throw Error('no_postal_code');
        const info = await dispatch(getTciByZip(postalCode.split('-')[0]));
        setTci(info);
      } catch {
        setTci({});
      } finally {
        setIsLoading(false);
      }
    };
    loadTciInfo();
  }, []);

  return { tci, isLoading };
};

export default useCustomerTci;
