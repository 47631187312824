import React from 'react';
import Spinner from '../SpinnerOverlay/Spinner';
import SimpleText from '../Text/SimpleText';

const ProgressDialog = ({ title, titleStyle, spinnerStyle }) => {
  const titleComponent = () => <SimpleText txt={title} style={titleStyle} />;

  const spinner = () => <Spinner color="primary" customStyle={{ margin: '20%' }} spinnerStyle={spinnerStyle} />;

  return (
    <>
      {spinner()}
      {titleComponent()}
    </>
  );
};

export default ProgressDialog;
