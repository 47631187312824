import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { COLORS } from '../../../utils/consts';

const Overlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: ${COLORS.CINTAS_BLUE};
  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const VideoPlayer = ({ url, width = '100%', height = '100%', iconSize = 80, avoidPlay = false, onReset }) => {
  const [playing, setPlaying] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setPlaying(false);
    ref.current?.showPreview();
  }, [onReset]);

  const playVideo = () => {
    if (avoidPlay) {
      ref.current?.showPreview();
    }
    setPlaying(true);
  };

  return (
    <ReactPlayer
      url={url}
      playing={playing}
      onPlay={() => setPlaying(true)}
      onPause={() => setPlaying(false)}
      width={width}
      height={height}
      controls
      light
      ref={ref}
      playIcon={
        <Overlay>
          <PlayCircleIcon sx={{ color: COLORS.CINTAS_WHITE, fontSize: `${iconSize}px !important` }} />
        </Overlay>
      }
      onClickPreview={() => playVideo()}
    />
  );
};

export default VideoPlayer;
