/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { change, Field, getFormValues, reduxForm, submit } from 'redux-form';
import { Box, DialogActions, MenuItem } from '@material-ui/core';
import MDTextInputField from '../../Forms/FormComponents/MDTextInput/MDTextInputField';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import * as SettingsActions from '../../../redux/actions/settings.actions';
import SettingsTile from './SettingsTile';
import Container from '../../LayoutBuilders/Container';
import ContainerItem from '../../LayoutBuilders/ContainerItem';
import SimpleText from '../../Text/SimpleText';
import { configurationsSelector } from '../../../redux/selectors/settings.selectors';
import FASSelectField from '../../Forms/CustomFormComponents/FASSelectField';
import { unitsOfTime } from '../../../utils/consts';

const formName = 'ParticipantSettings';
const regularEventsPath = 'regularEvents';
const subcontractorEventsPath = 'subcontractorEvent';

const ParticipantSettings = ({ dispatch, values, handleSubmit }) => {
  const currentConfigs = useSelector(configurationsSelector);

  React.useEffect(() => {
    if (currentConfigs.participantsEditTime) {
      const { duration, unit } = currentConfigs.participantsEditTime;
      if (duration && unit) {
        dispatch(change(formName, `${regularEventsPath}.duration`, duration));
        dispatch(change(formName, `${regularEventsPath}.unit`, unit));
      }
    }

    if (currentConfigs.subcontractorParticipantsEditTime) {
      const { duration, unit } = currentConfigs.subcontractorParticipantsEditTime;
      if (duration && unit) {
        dispatch(change(formName, `${subcontractorEventsPath}.duration`, duration));
        dispatch(change(formName, `${subcontractorEventsPath}.unit`, unit));
      }
    }
    return () => {};
  }, [currentConfigs]);

  const showToast = (success, msg) => {
    dispatch(
      ModalsActions.showModal('UPDATE_CONFIGS_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message:
            msg ??
            (success ? 'Settings successfully updated!' : 'Something went wrong updating settings. Please, try again!'),
        },
      }),
    );
  };

  const submitConfigs = async (inputs) => {
    try {
      dispatch(submit('ParticipantSettings'));
      const success = await dispatch(
        SettingsActions.updateConfigs({
          ...(currentConfigs ?? {}),
          participantsEditTime: {
            duration: inputs[regularEventsPath]?.duration ?? 0,
            unit: inputs[regularEventsPath]?.unit ?? unitsOfTime.minutes,
          },
          subcontractorParticipantsEditTime: {
            duration: inputs[subcontractorEventsPath]?.duration ?? 7,
            unit: inputs[subcontractorEventsPath]?.unit ?? unitsOfTime.days,
          },
        }),
      );
      showToast(success);
    } catch (error) {
      showToast(false);
    }
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => (
    <ContainerItem flex="auto" {...(rest ?? {})}>
      {children}
    </ContainerItem>
  );

  const text = (txt, style, rest) => (
    <SimpleText
      txt={txt}
      {...(rest ?? {})}
      style={{
        textAlign: 'start',
        ...(style ?? {}),
      }}
    />
  );

  const durationInput = (basePath) => (
    <Field
      name={`${basePath}.duration`}
      component={MDTextInputField}
      defaultValue={unitsOfTime.days}
      size="small"
      variant="outlined"
      label="Duration"
      type="number"
      noHelperText
      noErrorLabel
      normalize={(v) => (!v ? v : Number(v))}
      onBlurCapture={() => {
        if (!values[basePath]?.duration || Number(values[basePath]?.duration) < 0) {
          dispatch(change(formName, `${basePath}.duration`, 0));
        }
      }}
      inputProps={{ min: '0' }}
      style={{
        width: 100,
        marginRight: 8,
      }}
    />
  );

  const unitDropdown = (basePath) => (
    <Field
      name={`${basePath}.unit`}
      component={FASSelectField}
      size="small"
      variant="outlined"
      label="Unit"
      noErrorLabel
      style={{ borderRadius: 4 }}
      options={['test']}
    >
      {Object.values(unitsOfTime).map((unit) => (
        <MenuItem key={unit} value={unit}>
          {unit}
        </MenuItem>
      ))}
    </Field>
  );

  const editableParticipantsDays = (basePath, text1, text2) =>
    container({
      spacing: 1,
      style: {
        justifyContent: 'start',
        alignItems: 'center',
      },
      children: [
        item({ children: text(text1) }),
        item({ children: durationInput(basePath) }),
        item({ children: unitDropdown(basePath) }),
        item({ children: text(text2) }),
      ],
    });

  return (
    <SettingsTile
      title="Participants Settings"
      onSubmit={handleSubmit(submitConfigs)}
      getActionButton={(ActionBtn) => <DialogActions>{ActionBtn}</DialogActions>}
    >
      {editableParticipantsDays(regularEventsPath, 'Allow participants to be editable for ', ' after the order date.')}
      <Box height={20} />
      {editableParticipantsDays(
        subcontractorEventsPath,
        'Allow subcontracted events participants to be editable for ',
        ' after the order date.',
      )}
    </SettingsTile>
  );
};

export default _.flow([
  connect((state) => ({
    values: getFormValues(formName)(state),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    onSubmit: submit,
  }),
])(ParticipantSettings);
