/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { Field, submit, reduxForm, change, getFormValues } from 'redux-form';
import { makeStyles, Typography, Tooltip } from '@material-ui/core';
import React from 'react';
import {
  addRowWithId,
  addAdditionalParticipantsRow,
  adjustRowQty,
  adjustRowPrice,
} from '../EventDialogForm/FormComponent/CartSummaryForm';
import { BOOKING_MODE, COLORS, CURRENCY_SYMBOLS, EVENT_TYPE, ROLE_ACCESSES } from '../../../../../utils/consts';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import { removeLeadingZeros } from '../../../../../utils/helpers';
import { showModal } from '../../../../../redux/actions/modals.actions';
import CoursePriceDialog from '../../CoursePriceDialog/CoursePriceDialog';
import { fetchPricing } from '../../../../../redux/actions/pricing.actions';
import Spinner from '../../../../SpinnerOverlay/Spinner';
import AddonItemImage from './AddonItemImage';
import RefreshPriceBtn from '../../../../../routes/MainViews/EventSummaryView/RefreshPriceBtn';
import { hasUserAccessSelector } from '../../../../../redux/selectors/user.selectors';
import FASCheckboxField from '../../../../Forms/CustomFormComponents/FASCheckboxField/FASCheckboxField';

export const useStyles = makeStyles(() => ({
  container: {
    margin: '8px',
    textAlign: 'center',
    minWidth: '130px',
    maxWidth: '130px',
  },
  box: {
    position: 'flex',
    flexDirection: 'column',
    border: ({ notAvailable }) => `1px solid ${notAvailable ? COLORS.CINTAS_RED : COLORS.CINTAS_GRAY_BORDER}`,
    textAlign: 'center',
    minWidth: '100px',
    fontStyle: ({ notAvailable }) => (notAvailable ? 'italic' : 'normal'),
    color: ({ notAvailable }) => (notAvailable ? COLORS.CINTAS_RED : 'black'),
  },
  itemtrashicon: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
  },
  image: {
    // maxWidth: '90px',
    width: '95%',
    alignItems: 'center',
    height: '70px',
    objectFit: 'contain',
  },
  addonname: {
    width: '100%',
    margin: 'auto',
    padding: '0px 6px',
    fontSize: 11,
    wordBreak: 'break-word',
    overflow: 'hidden',
    maxLines: 2,
    lineClamp: 2,
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
  },
  materialNumber: {
    width: '100%',
    margin: 'auto',
    padding: '0px 6px',
    fontSize: 11,
    overflow: 'hidden',
    opacity: 0.8,
    fontStyle: 'italic',
  },
  quantityBox: {
    position: 'flex',
    marginTop: '5px',
    padding: '8px 0px',
    textAlign: 'center',
    border: ({ notAvailable }) => `1px solid ${notAvailable ? COLORS.CINTAS_RED : COLORS.CINTAS_GRAY_BORDER}`,
    minWidth: '100px',
  },
  selectedBox: {
    position: 'flex',
    fontStyle: 'italic',
    borderTop: ({ notAvailable }) => `1px solid ${notAvailable ? COLORS.CINTAS_RED : COLORS.CINTAS_GRAY_BORDER}`,
    marginTop: '1px',
    padding: '2px 0px',
    textAlign: 'center',
    minWidth: '100px',
  },
  blueText: {
    color: COLORS.CINTAS_BLUE,
    fontSize: 14,
  },
}));

const AddonItem = (props) => {
  const {
    addonItem,
    selected,
    price,
    currency,
    dispatch,
    eventType,
    notEditable,
    isAdditionalParticipants,
    course,
    customer,
    values,
    mode,
    loadPrice,
    onPriceLoaded,
  } = props;
  const [checked, setChecked] = React.useState(selected);
  const [loadingPrice, setLoadingPrice] = React.useState();
  const subfieldName = `${addonItem.require ? 'REQ' : 'ZOPT'}${addonItem.id}`;
  const fieldName = () => {
    if (eventType === EVENT_TYPE.OPEN_ENROLLMENT) {
      return `selectedAddons.${subfieldName}`;
    }
    if (isAdditionalParticipants) {
      return `courses[${addonItem.courseidx}].additional_participants`;
    }
    return `courses[${addonItem.courseidx}].selectedAddons.${subfieldName}`;
  };
  const stateQty = _.get(values ?? {}, `${fieldName()}.qty`);
  const [qty, setQty] = React.useState(
    (addonItem.defaultParticipantsCount && ![BOOKING_MODE.editing, BOOKING_MODE.rebooking].includes(mode)) ||
      isAdditionalParticipants
      ? Number(addonItem.participants ?? 0)
      : String(stateQty) && !isNaN(stateQty)
        ? Number(stateQty)
        : 1,
    // (!isAdditionalParticipants && !addonItem.defaultParticipantsCount && !stateQty) ? 1 : Number(addonItem.participants ?? 0),
  );
  const [addonPrice, setAddonPrice] = React.useState(
    price ?? Number(_.get(values ?? {}, `${fieldName()}.price.amount`)),
  );

  const notAvailable = !isAdditionalParticipants && addonItem?.noAvailableListing;
  const classes = useStyles({ ...props, notAvailable });

  const hasEditAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.bookEvent]));

  const buildValues = (override) => ({
    id: addonItem.id,
    qty: qty ?? addonItem.participants ?? 0,
    price: {
      amount: Number(addonPrice),
      currency,
      option: 'MARKET',
    },
    ...(override ?? {}),
  });

  const init = () => {
    const addonCode = removeLeadingZeros(addonItem.code);
    if (selected && addonItem.require && !notEditable) {
      if (isAdditionalParticipants) {
        addAdditionalParticipantsRow(
          addonItem.id,
          `${addonCode} - Additional Participants`,
          qty,
          Number(addonPrice ?? 0),
          `${addonItem.course}`,
          currency,
        );
      } else {
        addRowWithId(
          addonItem.id,
          `${addonCode} - ${addonItem.title}`,
          qty,
          Number(addonPrice ?? 0),
          `${addonItem.course}REQ`,
          currency,
        );
      }
      if (eventType === EVENT_TYPE.OPEN_ENROLLMENT) {
        dispatch(change('AddEventDialog', `selectedAddons.${subfieldName}`, buildValues()));
      } else {
        dispatch(
          change('AddEventDialog', fieldName(), isAdditionalParticipants ? Array(buildValues()) : buildValues()),
        );
      }
    }
    if (selected && !addonItem.require && !notEditable) {
      addRowWithId(
        addonItem.id,
        `${addonCode} - ${addonItem.title}`,
        qty,
        Number(addonPrice ?? 0),
        `${addonItem.course}ZOPT`,
        currency,
      );
      if (eventType === EVENT_TYPE.OPEN_ENROLLMENT) {
        dispatch(change('AddEventDialog', `selectedAddons.${subfieldName}`, buildValues()));
      } else {
        dispatch(change('AddEventDialog', fieldName(), buildValues()));
      }
    }
  };

  React.useEffect(async () => {
    if (loadPrice && !loadingPrice) {
      try {
        setLoadingPrice(true);
        const pricingData = await dispatch(fetchPricing(customer, addonItem?.code, true));
        if (onPriceLoaded) {
          onPriceLoaded(pricingData);
        }
      } finally {
        init();
        setLoadingPrice(false);
      }
    } else {
      init();
    }
  }, []);

  const handleChangeSelect = () => {
    setChecked(false);
    adjustRowQty({
      pid: addonItem.id,
      subtract: qty,
    });
    if (mode !== BOOKING_MODE.booking) {
      const selectedAddons = values?.courses[addonItem.courseidx]?.selectedAddons;
      delete selectedAddons[subfieldName];
      dispatch(change('AddEventDialog', `courses[${addonItem.courseidx}].selectedAddons`, selectedAddons));
    }
    const optAddons = [...(values?.courses[addonItem.courseidx]?.course?.addons?.optional ?? [])];
    dispatch(
      change(
        'AddEventDialog',
        `courses[${addonItem.courseidx}].course.addons.optional`,
        optAddons.map((a) => {
          if (a !== addonItem.id) return a;
          return '';
        }),
      ),
    );
  };

  const handleChangeQty = (newVal) => {
    if (checked && Number(!newVal && newVal !== 0 ? -1 : newVal) >= 0) {
      const parsed = Number(newVal ?? 0);
      const addonCode = removeLeadingZeros(addonItem.code);
      adjustRowQty({
        pid: addonItem.id,
        desc: `${addonCode} - ${addonItem.title}`,
        unit: Number(addonPrice ?? 0),
        currency,
        course: `${addonItem.course}${addonItem.require ? 'REQ' : 'ZOPT'}`,
        add: parsed >= qty ? Math.abs(qty - parsed) : undefined,
        subtract: parsed < qty ? Math.abs(qty - parsed) : undefined,
      });
      setQty(parsed);
    } else {
      adjustRowQty({
        pid: addonItem.id,
        add: 0,
        subtract: qty ?? 0,
      });
      setQty(0);
    }
  };

  const onQuantityUnfocused = () => {
    if (!qty || qty === 0) {
      dispatch(change('AddEventDialog', `${fieldName()}.qty`, '0'));
      handleChangeQty(0);
    }
  };

  const handleChangePrice = ({ amount }) => {
    const parsed = Number(amount ?? 0);
    dispatch(change('AddEventDialog', `${fieldName()}${isAdditionalParticipants ? '[0]' : ''}.price.amount`, parsed));

    adjustRowPrice({
      pid: addonItem.id,
      subtract: addonPrice,
      add: parsed,
    });
    setAddonPrice(parsed);
  };

  const openPricingPopup = () => {
    const modalName = 'ADDON_PRICE_DIALOG';
    const addon = {
      ...addonItem,
      price: {
        amount: Number(addonPrice ?? '0'),
        currency: addonItem.currency,
      },
    };
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          fullWidth: true,
          disableBackdropClick: true,
          maxWidth: 'sm',
          title: `${addonItem.title ?? ''} Price`,
          content: (
            <CoursePriceDialog
              modalName={modalName}
              course={course}
              addonSpecific={addon}
              customer={customer}
              dispatch={dispatch}
              onSubmit={handleChangePrice}
            />
          ),
        },
      }),
    );
  };

  const box = ({ children, ...rest }) => (
    <div className={classes.box} {...(rest ?? {})}>
      {children}
    </div>
  );

  const trashIcon = () =>
    !isAdditionalParticipants && (
      <Field
        className={classes.itemtrashicon}
        name={fieldName()}
        component={FASCheckboxField}
        disabled={addonItem.require || eventType === EVENT_TYPE.OPEN_ENROLLMENT || !hasEditAccess}
        normalize={(v) => (v ? buildValues() : undefined)}
        label=""
        hideErrorHelper
        isDelete
        onClick={handleChangeSelect}
      />
    );

  const image = () => <AddonItemImage addonItem={addonItem} classes={classes} />;

  const title = () => (
    <div>
      <Tooltip title={`${addonItem.title ?? ''}`.toUpperCase()}>
        <Typography className={classes.addonname}>{`${addonItem.title ?? ''}`.toUpperCase()}</Typography>
      </Tooltip>
    </div>
  );

  const materialNumber = () => (
    <div>
      <Typography className={classes.materialNumber}>
        {`${removeLeadingZeros(addonItem.code) ?? ''}`.toUpperCase()}
      </Typography>
    </div>
  );

  const qtyInput = () =>
    checked &&
    !isAdditionalParticipants && (
      <Field
        id={`${fieldName()}.qty`}
        name={`${fieldName()}.qty`}
        component={MDTextInputField}
        InputProps={{ min: 0, style: { textAlign: 'center' } }}
        // onHandleChange={handleChangeQty}
        onChange={handleChangeQty} // triggered after form change
        onBlurCapture={onQuantityUnfocused}
        style={{ marginTop: 4, padding: 0, textAlign: 'center' }}
        noBorderRadius
        disabled={eventType === EVENT_TYPE.OPEN_ENROLLMENT || !checked || !hasEditAccess}
        size="small"
        type="number"
        variant="outlined"
        required
      />
    );

  const spinner = () => {
    const size = 14;
    return (
      <Spinner
        spinnerStyle={{
          height: size,
          width: size,
          padding: 0,
          margin: 0,
          color: COLORS.CINTAS_BLUE,
        }}
        customStyle={{
          maxHeight: size,
          maxWidth: size,
          padding: 0,
          margin: '0px 4px',
          marginLeft: '45%',
          textAlign: 'center',
        }}
      />
    );
  };

  const priceBtn = () => (
    <div
      onClick={notEditable || loadingPrice ? undefined : openPricingPopup}
      className={classes.selectedBox}
      style={{ cursor: notEditable || loadingPrice ? undefined : 'pointer' }}
    >
      {loadingPrice ? spinner() : `${CURRENCY_SYMBOLS[currency]}${Number(addonPrice).toFixed(2)}`}
    </div>
  );

  const refreshPriceBtn = (listingsPath) => (
    <RefreshPriceBtn
      formName="AddEventDialog"
      customer={customer}
      classes={classes}
      listingsPath={listingsPath}
      materialNum={addonItem.code}
      updatePriceFn={handleChangePrice}
    />
  );

  return (
    <>
      {checked && (
        <div className={classes.container}>
          {box({
            children: [
              <div style={{ position: 'relative', minHeight: 20 }}>{notEditable ? <></> : trashIcon()}</div>,
              image(),
              title(),
              materialNumber(),
              priceBtn(),
              mode !== BOOKING_MODE.booking && refreshPriceBtn(),
            ],
          })}
          {notEditable ? <></> : qtyInput()}
        </div>
      )}
    </>
  );
};

// export default AddonItem;
export default _.flow([
  connect((state) => ({
    values: getFormValues('AddEventDialog')(state),
  })),
  reduxForm({
    form: 'AddEventDialog',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: submit,
  }),
])(AddonItem);
