/* eslint-disable max-len */
import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import TextField from '@material-ui/core/TextField';

const ColorPicker = ({ hexColor, colorChanged }) => {
  const [color, setColor] = useState(hexColor ?? '#ffffff');

  const handleOnChange = (event) => {
    let { value } = event.target;
    if (value[0] !== '#') value = `#${value}`;
    setColor(value);
    colorChanged(value);
  };

  const hexColorPicker = () => (
    <HexColorPicker
      color={color}
      onChange={(newColor) => {
        setColor(newColor);
        colorChanged(newColor);
      }}
    />
  );

  const hexColorInput = () => (
    <TextField
      size="small"
      variant="outlined"
      value={color}
      style={{
        width: 200,
        marginTop: 5,
      }}
      onChange={handleOnChange}
    />
  );

  return (
    <>
      {hexColorPicker()}
      {hexColorInput()}
    </>
  );
};

export default ColorPicker;
