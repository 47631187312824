/* eslint-disable import/no-cycle */
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import AdminNav from '../../../components/AdminNav/AdminNav';
import { fetchUnreadNotifications } from '../../../redux/actions/notifications.actions';
import { fetchTasksData } from '../../../redux/actions/tasks.actions';
import { hasUserAccessSelector } from '../../../redux/selectors/user.selectors';
import { COLORS, ROLE_ACCESSES } from '../../../utils/consts';

const SettingsFrame = ({ path, actions, children, isAdmin }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchUnreadNotifications(1));
    dispatch(fetchTasksData());
  }, []);

  return (
    <div>
      <Typography
        variant="h4"
        style={{
          color: COLORS.CINTAS_BLUE,
          fontWeight: 'bold',
          paddingTop: 24,
          paddingBottom: 0,
        }}
        gutterBottom
      >
        {isAdmin ? 'ADMIN SETTINGS' : 'SETTINGS'}
      </Typography>
      <div
        style={{
          width: '96vw',
          position: 'relative',
          overflowX: 'scroll',
        }}
      >
        <AdminNav path={path} />
      </div>
      <div style={{ textAlign: 'end', display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ width: '25%' }}>{actions}</div>
      </div>
      {children}
    </div>
  );
};

export default _.flow([
  connect((state) => ({
    isAdmin: hasUserAccessSelector(state, [ROLE_ACCESSES.advancedAdmin]),
  })),
])(SettingsFrame);
