import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import _ from 'lodash';
import { reduxForm } from 'redux-form';

const BrowserCompabilityDialogContent = withStyles((theme) => ({
  root: {
    maxWidth: 500,
    minHeight: 100,
    zIndex: 500,
    [theme.breakpoints.down('sm')]: {
      minWidth: 500,
      maxWidth: 500,
    },
  },
}))(MuiDialogContent);

const BrowserCompabilityDialogWrapper = ({ children }) => (
  <BrowserCompabilityDialogContent dividers>{children}</BrowserCompabilityDialogContent>
);

export default _.flow([
  connect(),
  reduxForm({
    form: 'BrowserCompabilityDialog',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    initialValues: {},
  }),
])(BrowserCompabilityDialogWrapper);
