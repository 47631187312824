/* eslint-disable max-len */
import {
  SAVE_ADDONS_PRICES,
  RESET_ADDONS_PRICES,
  SAVE_ADDITIONAL_PARTICIPANTS_PRICES,
  RESET_ADDITIONAL_PARTICIPANTS_PRICES,
} from './actionTypes/pricing';

import * as PricingAPI from '../../api/pricing.api';

export const saveAddOnsPrices = (material, payload) => ({
  type: SAVE_ADDONS_PRICES,
  id: material,
  payload,
});

export const resetAddOnsPrices = () => ({
  type: RESET_ADDONS_PRICES,
});

const saveAdditionalParticipantsPrices = (material, payload) => ({
  type: SAVE_ADDITIONAL_PARTICIPANTS_PRICES,
  id: material,
  payload,
});

export const resetAdditionalParticipantsPrices = () => ({
  type: RESET_ADDITIONAL_PARTICIPANTS_PRICES,
});

export const fetchPricing =
  (customer, material, addons = false, additionalParticipants = false) =>
  async (dispatch) => {
    try {
      // const materialList = Array.isArray(materials) ? _.join(materials) : materials;
      const response = await PricingAPI.fetchSAPPricing(
        material,
        customer?.sales_organization,
        customer?.sales_office,
        customer?.sold_to,
      );
      if (response && response.data) {
        if (addons) {
          dispatch(saveAddOnsPrices(material, response.data));
        }
        if (additionalParticipants) {
          dispatch(saveAdditionalParticipantsPrices(material, response.data));
        }
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };

export const fetchPricingForMaterials = (customer, materials) => async () => {
  try {
    // const materialList = Array.isArray(materials) ? _.join(materials) : materials;
    const response = await Promise.allSettled(
      materials.map(async (material) => {
        const r = await PricingAPI.fetchSAPPricing(
          material,
          customer.sales_organization,
          customer.sales_office,
          customer.sold_to,
        );
        if (r && r.data) {
          return { [material]: [...(r.data ?? [])] };
        }
        return { [material]: [] };
      }),
    );

    return (response ?? []).map((r) => r.value).reduce((red, v) => ({ ...red, ...(v ?? {}) }), {});
  } catch (error) {
    throw error;
  }
};
