/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Close';
import { connect, useDispatch } from 'react-redux';
import { IconButton, Typography } from '@material-ui/core';
import { change, Field, getFormValues, initialize, reduxForm } from 'redux-form';
import TimezoneSelect from 'react-timezone-select';
import { locationDefaultsSelector } from '../../../redux/selectors/settings.selectors';
import ContainerItem from '../../LayoutBuilders/ContainerItem';
import Container from '../../LayoutBuilders/Container';
import SettingsTile from './SettingsTile';
import StyledButton from '../../Button/StyledButton';
import MDTextInputField from '../../Forms/FormComponents/MDTextInput/MDTextInputField';
import { updateLocationDefault } from '../../../redux/actions/settings.actions';
import { COLORS } from '../../../utils/consts';
import { hideModal, showModal } from '../../../redux/actions/modals.actions';
import DeleteConfirmAlert from '../../Calendar/CalendarComponents/EventPopper/DeleteConfirmAlert';
import { isTruckNumInvalid } from '../../../utils/event.utils';

const formName = 'LocationsDefaultsForm';
const initialState = {
  locId: '',
  truckNumber: undefined,
  tz: undefined,
};

const checkTruckNum = (loc, defaultVals) => {
  const errors = {};
  const num = defaultVals?.truckNumber;
  if (num) {
    const isInvalid = isTruckNumInvalid(num);
    if (isInvalid) {
      errors[loc] = {
        ...(errors[loc] ?? {}),
        truckNumber: 'Invalid',
      };
    }
  }

  return errors;
};

const checkLocId = (loc, defaultVals) => {
  const errors = {};
  const locId = defaultVals?.locId;
  if (!locId || (locId ?? '').trim() === '') {
    errors[loc] = {
      ...(errors[loc] ?? {}),
      locId: 'Invalid',
    };
  }
  return errors;
};

const validate = (vals) => {
  let errors = {};

  Object.keys(vals).forEach((val) => {
    if (val === 'new') {
      const locIdErrors = checkLocId(val, vals[val]);
      errors = { ...errors, ...locIdErrors };
    }
    const truckNumErrors = checkTruckNum(val, vals[val]);
    errors = { ...errors, ...truckNumErrors };
  });

  return errors;
};

const LocationsDefault = ({ data, formData }) => {
  const dispatch = useDispatch();
  const [addingNew, setAddingNew] = React.useState(false);

  React.useEffect(async () => {
    dispatch(initialize(formName, data));
    return () => {};
  }, []);

  const updateLocation = async (loc, newData, isNew) => {
    const dt = await dispatch(updateLocationDefault(loc, newData));
    dispatch(initialize(formName, dt?.locationDefaults ?? {}));
    if (isNew) {
      setAddingNew(false);
    }
  };

  const onDefaultChanged = (loc, field, val) => {
    const defData = { ...formData[loc] };
    defData[field] = val;
    const errors = validate({ [loc]: defData });
    if (_.isEmpty(errors)) {
      updateLocation(loc, defData);
    }
  };

  const onSaveNew = async (id) => {
    const newData = formData?.[id];
    const { locId } = newData;
    delete newData.locId;
    const errors = validate({ [locId]: { ...newData } });
    if (_.isEmpty(errors)) {
      const success = await updateLocation(locId, newData, true);
      if (success) {
        setAddingNew(false);
        dispatch(change(formName, 'new', undefined));
      }
    }
  };

  const handleRequestDelete = (id) => {
    const modalName = 'CONFIRM_DELETE_DEFAULT';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          confirmText: 'confirm',
          disableBackdropClick: true,
          maxWidth: 'md',
          title: 'DELETE LOCATION DEFAULT',
          content: <DeleteConfirmAlert itemTypeName="location default" />,
          onConfirm: () => {
            dispatch(hideModal(modalName));
            updateLocation(id, null);
          },
        },
      }),
    );
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt) => <Typography style={{}}>{txt}</Typography>;

  const locIdInput = (loc) => (
    <Field
      name={`${loc ?? ''}.locId`}
      component={MDTextInputField}
      noHelperText
      noErrorLabel
      withEnterTrigger
      label="Location"
      required
      type="text"
      size="small"
      margin="none"
      style={{ margin: 0, padding: 0 }}
    />
  );

  const defaultTrNumInput = (loc, isNew) => (
    <Field
      name={`${loc ?? ''}.truckNumber`}
      component={MDTextInputField}
      noHelperText
      noErrorLabel
      withEnterTrigger
      label="Truck Number"
      onChange={isNew ? null : (v) => onDefaultChanged(loc, 'truckNumber', v)}
      type="text"
      size="small"
      margin="none"
      style={{ margin: 0, padding: 0 }}
    />
  );

  const timezoneInput = (loc, isNew) => (
    <TimezoneSelect
      value={formData?.[loc]?.tz?.value ?? ''}
      onChange={(tz) => {
        dispatch(change(formName, `${loc}.tz`, tz));
        if (!isNew) onDefaultChanged(loc, 'tz', tz);
      }}
      placeholder="Timezone"
      styles={{
        control: (base, state) => ({
          ...base,
          backgroundColor: 'transparent',
          boxShadow: 'none',
          height: 40,
          borderWidth: state.isFocused ? 2 : 1,
          borderColor: state.isFocused ? COLORS.CINTAS_BLUE : COLORS.CINTAS_GRAY,
          '&:hover': {
            borderColor: state.isFocused ? COLORS.CINTAS_BLUE : 'black',
          },
        }),
      }}
    />
  );

  const deleteDefaultBtn = (id) => (
    <IconButton onClick={() => handleRequestDelete(id)}>
      <ClearIcon />
    </IconButton>
  );

  const locEntry = (id, isNew) =>
    container({
      spacing: 1,
      style: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,
      },
      children: [
        item({ flex: 1, children: [isNew ? locIdInput(id) : text(id)] }),
        item({ flex: 1, children: [defaultTrNumInput(id, isNew)] }),
        item({ flex: 2, children: [timezoneInput(id, isNew)] }),
        item({ flex: 1, children: [isNew ? saveDefaultBtn(id) : deleteDefaultBtn(id)] }),
      ],
    });

  const saveDefaultBtn = (id) => (
    <StyledButton
      color="primary"
      variant="contained"
      handleButton={_.isEmpty(validate({ [id]: formData?.[id] })) ? () => onSaveNew(id) : null}
      buttonContent="SAVE"
      disabled={!_.isEmpty(validate({ [id]: formData?.[id] }))}
    />
  );

  const addDefaultBtn = () => (
    <StyledButton
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      buttonContent="ADD"
      handleButton={() => {
        dispatch(change(formName, 'new', initialState));
        setAddingNew(true);
      }}
    />
  );

  return (
    <SettingsTile title="Location Defaults">
      {Object.keys(data).map((locId) => locEntry(locId))}
      {addingNew ? locEntry('new', true) : addDefaultBtn()}
    </SettingsTile>
  );
};

export default _.flow([
  connect((state) => ({
    data: locationDefaultsSelector(state),
    formData: getFormValues(formName)(state),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  }),
])(LocationsDefault);
