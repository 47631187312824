/* eslint-disable max-len */
const CommuncationTemplateText = {
  injectionPattern: {
    firstName: '$(fn)',
    courseName: '$(cn)',
    signature: '$(si)',
    day: '$(day)',
    month: '$(month)',
    time: '$(time)',
    tciEmail: '$(te)',
    confirmationUrl: '$(cfurl)',
    confirmationUrlPointer: '$(pntr)',
  },
  upcomingCourse: {
    id: 'upcomingCourse',
    body: 'Hi $(fn),<br>You have an upcoming training event with Cintas.<br><br>You are scheduled for <b>$(cn)</b> on <b>$(month) $(day)</b> at <b>$(time)</b>.<br><br>If you have any questions, please contact your instructor, <b>$(si)</b> at <b>$(te)</b>.',
    // 'Hi $(fn),<br>This is an upcoming reminder to you that the <b>$(cn)</b> you booked will take place on <b>$(month) $(day)</b> at <b>$(time)</b>. I look forward to speaking with you further and please do not hesitate to reach out if you have any questions.<br><br>Your Instructor,<br><br><b>$(si)</b><br><b>$(te)</b>',
  },
  updateSignature: {
    id: 'updateSignature',
    body: 'Hi $(fn),<br>One more step to confirm your class!<br><br>Please sign the electronic course confirmation to reserve <b>$(month) $(day)</b> at <b>$(time)</b> for your <b>$(cn)</b> training.<br><br>Follow this <a href="$(cfurl)" target="_private" style="color:blue;text-decoration:underline;pointer-events:$(pntr);">link</a> to sign.<br><br>Contact your instructor <b>$(si)</b> at <b>$(te)</b> if you have any questions.',
  },
  prepareClass: {
    id: 'prepareClass',
    body: 'Hi $(fn),<br>You have an upcoming training event with Cintas.<br>You are scheduled for <b>$(cn)</b> on <b>$(month) $(day)</b> at <b>$(time)</b>.<br>Here is how you can prepare:<ul><li>Make sure you have a room available with seating for all students</li><li>Students should bring a pencil or pen for note taking</li><li>Instructor will need access to an electrical outlet</li></ul>If you have any questions, please contact your instructor <b>$(si)</b> at <b>$(te)</b>.',
  },
};

export const templateOptions = [
  { label: 'Upcoming Course', id: 'upcomingCourse', subject: 'Cintas Training Course Reminder' },
  { label: 'Prepare for Class', id: 'prepareClass', subject: 'Cintas Training Course Reminder' },
  { label: 'Update Signature', id: 'updateSignature', subject: 'Your Signature Needed' },
];

export default CommuncationTemplateText;
