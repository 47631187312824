const API_VERSION = 'v1';

export const getDefaultAPIBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      return `http://localhost:8395/api/${API_VERSION}`;
    case 'develop':
      return `https://fastrainingcentraldev.cintas.com/api/${API_VERSION}`;
    // `http://dev-firebase-backend-template-qci6r6vcva-uc.a.run.app/api/${API_VERSION}`;
    case 'release':
      return `https://fastrainingcentralqa.cintas.com/api/${API_VERSION}`;
    case 'production':
      return `https://fastrainingcentral.cintas.com/api/${API_VERSION}`;
    case 'sandbox':
      return `https://trainingcentralsandbox.cintas.com/api/${API_VERSION}`;
    case 'pre-prod':
      return `https://fastrainingcentraltrainingmode.cintas.com/api/${API_VERSION}`;
    default:
      return `https://trainingcentraldev.cintas.com/api/${API_VERSION}`;
    // `https://dev-firebase-backend-template-qci6r6vcva-uc.a.run.app/api/${API_VERSION}`;
  }
};

const API_VERSION_EXTERNAL_SITE = 'v1';

export const getExternalSiteAPIBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      return `http://localhost:8395/api/${API_VERSION_EXTERNAL_SITE}`;
    case 'develop':
      return `https://trainingcentraldev.cintas.com/api/${API_VERSION_EXTERNAL_SITE}`;
    case 'release':
      return `https://trainingcentralqa.cintas.com/api/${API_VERSION_EXTERNAL_SITE}`;
    // return `http://localhost:8395/api/${API_VERSION_EXTERNAL_SITE}`;
    case 'production':
      return `https://trainingcentral.cintas.com/api/${API_VERSION_EXTERNAL_SITE}`;
    case 'sandbox':
      return `https://trainingcentralsandbox.cintas.com/api/${API_VERSION_EXTERNAL_SITE}`;
    case 'pre-prod':
      return `https://trainingcentraltrainingmode.cintas.com/api/${API_VERSION_EXTERNAL_SITE}`;
    default:
      return `https://trainingcentraldev.cintas.com/api/${API_VERSION_EXTERNAL_SITE}`;
  }
};

export const drawerWidth = 240;

export const companyName = 'FAS Training App';
