/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
// /* eslint-disable no-unused-vars */
import React from 'react';
import { Field, change } from 'redux-form';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { useSelector, connect } from 'react-redux';
import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';
import MDSlider from '../../../../Forms/FormComponents/MDSlider/MDSlider';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import { COLORS, MATERIAL_TYPE, ROLE_ACCESSES } from '../../../../../utils/consts';
import { materialSelector } from '../../../../../redux/selectors/materials.selector';
import { hasUserAccessSelector } from '../../../../../redux/selectors/user.selectors';
import { removeLeadingZeros } from '../../../../../utils/helpers';

const SkillCheckBooking = (props) => {
  const { member, changedFollowingCoursesTime, pricingBuilder, listings, i, dispatch } = props;

  // Pull values from state
  const formValues = useSelector((state) => state.form.AddEventDialog.values);
  const hasCreateAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.bookEvent]));

  // Find skillcheckObject
  const skillCheckID = formValues?.courses[i]?.course?.skillcheckID;
  const skillCheckObject = useSelector((state) =>
    materialSelector({
      state,
      type: MATERIAL_TYPE.SKILL_CHECK,
      id: skillCheckID,
    }),
  );

  const notAvailable = !((_.find(listings ?? [], { Material: skillCheckObject?.code ?? '' }) ?? {}).inlcuded ?? true);
  const skillCheckDuration = skillCheckObject?.duration;
  const courseDuration = formValues?.courses[i]?.course?.duration;
  // const courseParticipants = formValues?.courses[i]?.participants;

  // boolean reference value for conditional display
  const isFullParticipation = formValues?.courses[i]?.skillcheckFull;

  // Current number of skillcheck participants
  const curNumParticipants = formValues?.courses[i]?.skillcheckParticipants;

  // Max number of participants, plus additional participants
  const maxParticipants =
    formValues?.courses[i]?.participants + Number(formValues?.courses[i]?.additional_participants?.[0]?.qty ?? 0);

  const handleChange = (event, value) => {
    // Handle time change for skillcheck participants
    if (formValues?.courses[i]?.courseStartTime && Number(value) >= 0) {
      const totalDuration = value * skillCheckDuration + courseDuration;
      const courseEndTime = formValues?.courses[i]?.courseStartTime.clone().add(totalDuration, 'minutes');
      dispatch(change('AddEventDialog', `courses[${i}].courseEndTime`, courseEndTime));
      changedFollowingCoursesTime(i, courseEndTime);
    }
  };

  // const handleFullSkillcheck = async (event, value) => {
  //   if (value) {
  //     await dispatch(change('AddEventDialog', `courses[${i}].skillcheckParticipants`, courseParticipants));
  //     handleChange(event, courseParticipants);
  //   }
  // };

  const title = (text, style) => (
    <FASFieldTitle title={text} style={style ?? { color: notAvailable ? COLORS.CINTAS_GRAY : 'black' }} />
  );

  return (
    <>
      {title(`Required Skill Check - ${skillCheckObject?.title} - ${removeLeadingZeros(skillCheckObject.code)}`)}
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} style={{ color: notAvailable ? COLORS.CINTAS_GRAY : 'black' }}>
          {`Duration: ${skillCheckObject?.duration || -1} Minutes`}
        </Grid>
        {notAvailable &&
          title('Not available for selected customer', {
            color: COLORS.CINTAS_RED,
            fontStyle: 'italic',
            paddingLeft: 18,
          })}
        {pricingBuilder && !notAvailable && (
          <Grid item xs={12}>
            {pricingBuilder()}
          </Grid>
        )}
        {!isFullParticipation && (
          <>
            <Grid item xs={12}>
              {title(`${skillCheckObject?.title} Participants`)}
            </Grid>
            <Grid item xs={12} sm={7}>
              <Field
                variant="outlined"
                placeholder="0"
                id="skillcheckParticipantsSlider"
                name={`${member}.skillcheckParticipants`}
                component={MDSlider}
                label="Participants"
                disabled={notAvailable || (!formValues?.courses[i]?.course ?? false) || !hasCreateAccess}
                max={Math.max(maxParticipants, curNumParticipants)}
                min={0}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                variant="outlined"
                placeholder="Skill Check Participants"
                id={`skillcheckParticipants${i}${member}`}
                name={`${member}.skillcheckParticipants`}
                component={MDTextInputField}
                label="Skill Check Participants"
                type="number"
                max={Math.max(maxParticipants, curNumParticipants)}
                min={0}
                size="small"
                noBorderRadius
                disabled={notAvailable || (!formValues?.courses[i]?.course ?? false) || !hasCreateAccess}
                onChange={handleChange}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default connect()(SkillCheckBooking);
