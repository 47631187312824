/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import { Tooltip } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import React from 'react';

import { useSelector } from 'react-redux';
import { COLORS, ROLE_ACCESSES } from '../../../utils/consts';
import LocationsCalendarDialog from './LocationsCalendarDialog';
import StyledButton from '../../Button/StyledButton';

import { showModal } from '../../../redux/actions/modals.actions';
import { hasUserAccessSelector } from '../../../redux/selectors/user.selectors';

const LocationsCalendarTriggerButton = ({ dispatch, iconSize, iconColor, iconStyle }) => {
  const hasAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.locCalendar]));

  const handleShowLocationsCalendar = () => {
    const modalName = 'LOCATION_CALENDAR_DIALOG';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          disableBackdropClick: false,
          maxWidth: 'xl',
          fullWidth: true,
          title: 'LOCATION CALENDAR',
          content: <LocationsCalendarDialog modalName={modalName} dispatch={dispatch} />,
        },
      }),
    );
  };

  return hasAccess ? (
    <StyledButton
      color="primary"
      onClick={handleShowLocationsCalendar}
      buttonContent={
        <Tooltip title="Location Calendar" arrow>
          <MyLocationIcon
            style={{
              width: iconSize ?? 20,
              color: iconColor ?? COLORS.CINTAS_WHITE,
              ...(iconStyle ?? {}),
            }}
          />
        </Tooltip>
      }
    />
  ) : (
    <></>
  );
};

export default LocationsCalendarTriggerButton;
