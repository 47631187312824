/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Box, Paper } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Container from '../../../LayoutBuilders/Container';
import ContainerItem from '../../../LayoutBuilders/ContainerItem';
import SimpleText from '../../../Text/SimpleText';
import { useStyles } from '../../../../routes/MainViews/EventSummaryView/EventSummaryView';
import EventOverview from '../EventDetailsDialog/DialogSubcomponents/EventOverview';
import { COLORS } from '../../../../utils/consts';
import { removeLeadingZeros } from '../../../../utils/helpers';
import { allMaterialsListSelector } from '../../../../redux/selectors/materials.selector';

const RebookReasonDialog = ({ rebookingInfo, materialsData, history, dispatch }) => {
  const classes = useStyles();
  const { order } = rebookingInfo ?? {};

  const [rebookingData] = React.useState({
    ...(rebookingInfo ?? {}),
    materials: (rebookingInfo?.materials ?? []).map((mInfo) => {
      const materialData = _.find(materialsData ?? [], { id: mInfo.material });

      return {
        ...mInfo,
        reason: (mInfo.reason ?? '').replaceAll('_', ' '),
        title: materialData?.title ?? '',
        sapNumber: removeLeadingZeros(materialData?.code ?? ''),
      };
    }),
  });

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;

  const card = ({ children, color }) => (
    <Paper className={classes.paper} style={{ width: '100%', backgroundColor: color }} elevation={3}>
      {children}
    </Paper>
  );

  const eventSummary = () => (
    <EventOverview
      // hideCourses
      // hideStatus
      hideNotes
      hideInstructor
      alreadyLoaded
      // showOrderId={showOrderId}
      order={order}
      contact={order?.contact ?? order?.temporaryContact}
      history={history}
      dispatch={dispatch}
      isBilling={false}
      customerFacing
    />
  );

  // const reasonsCard = () => ;

  const verticalSpacer = () => item({ flex: 12, children: <Box height={20} /> });
  // const spinner = () => container({
  //   style: { justifyContent: 'center' },
  //   children: [
  //     item({
  //       flex: 12,
  //       children: <Spinner />,
  //     }),
  //     item({
  //       flex: 12,
  //       style: { textAlign: 'center' },
  //       children: <Typography style={{ textAlign: 'center', color: COLORS.CINTAS_BLUE }}>Preparing rebooking...</Typography>,
  //     }),
  //   ]
  // });

  return container({
    style: { padding: 16, width: 850 },
    children: [
      item({
        flex: 12,
        children: text('You are required to rebook this event because the materials below have changed:', {
          style: {
            paddingLeft: 10,
            fontSize: 16,
            fontWeight: 'bold',
            color: COLORS.CINTAS_BLUE,
          },
        }),
      }),
      verticalSpacer(),
      card({ children: eventSummary() }),
      verticalSpacer(),
      card({
        color: COLORS.CINTAS_RED_OPAQUE_RGBA,
        children: (rebookingData.materials ?? []).map((matRebooking) =>
          item({
            flex: 12,
            children: container({
              style: { justifyContent: 'start' },
              children: [
                item({
                  flex: 'auto',
                  children: text(`${matRebooking.sapNumber ?? ''} - ${matRebooking.title ?? ''}`, {
                    style: { fontWeight: 'bold' },
                  }),
                }),
                <Box width={8} />,
                item({ flex: 'auto', children: text(matRebooking.reason ?? '') }),
                item({ flex: 12, children: <Box height={8} /> }),
              ],
            }),
          }),
        ),
      }),
    ],
  });
};

export default _.flow([
  connect((state) => ({
    materialsData: allMaterialsListSelector(state),
  })),
  withRouter,
])(RebookReasonDialog);
