/* eslint-disable no-unused-vars */
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const FASDatePickerField = (props) => {
  const { selectedDate, handleDateChange, clearable, disabled } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        clearable={clearable || 'false'}
        value={selectedDate}
        placeholder="01/01/2022"
        onChange={(date) => handleDateChange(date)}
        format="MM/dd/yyyy"
        inputVariant="outlined"
        variant="inline"
        openTo="date"
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
};

export default FASDatePickerField;
