/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { userInfoSelector } from '../../../redux/selectors/user.selectors';
import OrdersFilters from '../../../components/Settings/Orders/OrdersFilters';
import OrdersTable from '../../../components/Settings/Orders/OrdersTable';
import SimpleText from '../../../components/Text/SimpleText';
import * as OrdersActions from '../../../redux/actions/orders.actions';
import * as UsersActions from '../../../redux/actions/users.actions';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import { fetchMaterials } from '../../../redux/actions/materials.actions';
// eslint-disable-next-line import/no-cycle
import SettingsFrame from './SettingsFrame';
import { FETCH_ORDERS_RESULTS_THRESHOLD, ORDERS_VIEWS } from '../../../utils/consts';
import FASFilteredOrdersTable from '../../../components/Settings/FASOrdersFilteredTable.jsx/FASFilteredOrdersTable';
import { fetchConfigs } from '../../../redux/actions/settings.actions';

const OrdersView = (props) => {
  const {
    match: { path },
    dispatch,
  } = props;

  const [isLoading, setLoading] = React.useState(false);
  const [allRegLocations, setAllRegLocations] = React.useState(false);
  const [filteredOrders, setFilteredOrders] = React.useState([]);
  const [hasQueried, setHasQueried] = React.useState(false);
  const [requestingCancelled, setRequestingCancelled] = React.useState(false);

  React.useEffect(async () => {
    setLoading(true);
    try {
      await dispatch(fetchMaterials());
      await dispatch(fetchConfigs());
      const userData = await dispatch(UsersActions.getRegionsLocations());
      if (userData) {
        setAllRegLocations(userData);
      }
    } catch {
      dispatch(
        ModalsActions.showModal('LOCATIONS_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: { message: 'Failed to load region/location data. Please contact an admin.' },
        }),
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const showToast = (success) => {
    dispatch(
      ModalsActions.showModal('GENERATE_ORDERS_CSV', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: success
            ? 'Successfully Generated Orders CSV. Please check your email'
            : 'Something went wrong generating Orders CSV. Please, try again!',
        },
      }),
    );
  };

  const handleCSVRequest = async (modalName, requestObj) => {
    setLoading(true);
    setFilteredOrders([]);
    try {
      dispatch(ModalsActions.hideModal(modalName));
      const generatedCSV = await dispatch(OrdersActions.getOrdersCSV(requestObj));
      showToast(generatedCSV);
    } catch (error) {
      showToast(false);
    } finally {
      setLoading(false);
    }
  };

  const showThresholdReachedPopup = async (requestObj) => {
    const modalName = 'TRESHOLD_DIALOG';
    dispatch(
      ModalsActions.showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          cancelText: 'Cancel',
          disableBackdropClick: false,
          maxWidth: 'sm',
          title: 'TOO MANY RESULTS',
          content: (
            <SimpleText
              divStyle={{ margin: 20 }}
              txt={
                'Your search produced too many results.\nPress Confirm to have a CSV generated and sent via email or Cancel to refine your search and try again.'
              }
            />
          ),
          confirmText: 'Confirm',
          onConfirm: () => {
            handleCSVRequest(modalName, requestObj);
          },
        },
      }),
    );
  };

  const getFilteredOrders = async (requestObj) => {
    setLoading(true);
    setHasQueried(true);

    try {
      const resultsCount = await dispatch(OrdersActions.fetchFilteredOrdersCount(requestObj));
      if (resultsCount > FETCH_ORDERS_RESULTS_THRESHOLD) {
        setHasQueried(false);
        showThresholdReachedPopup(requestObj);
        return;
      }
      const filtOrders = await dispatch(OrdersActions.fetchFilteredOrders(requestObj));
      if (filtOrders) {
        setFilteredOrders(filtOrders);
      }
      return false;
    } catch {
      dispatch(
        ModalsActions.showModal('FILTERED_ORDERS_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: { message: 'Failed to retrieve orders. Please contact an admin.' },
        }),
      );
    } finally {
      await setRequestingCancelled(requestObj.cancelled);
      setLoading(false);
    }
  };

  return (
    <SettingsFrame path={path}>
      {/* <OrdersFilters loading={isLoading} regionLocationData={filterRegionsLocations()} getOrders={getFilteredOrders} />
      <OrdersTable
        // eslint-disable-next-line max-len
        regionLocationData={allRegLocations}
        orders={filteredOrders}
        isLoading={isLoading}
        hasQueried={hasQueried}
        requestingCancelled={requestingCancelled}
      /> */}
      <FASFilteredOrdersTable view={ORDERS_VIEWS.ordersPanel} />
    </SettingsFrame>
  );
};

export default _.flow([connect()])(OrdersView);
