import Immutable from 'seamless-immutable';

import { SET_AUTH, RESET_AUTH, UPDATE_AUTH } from '../actions/actionTypes/auth';

const initialState = Immutable({
  data: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return state.set('data', action.userAuth);
    case RESET_AUTH:
      return initialState;
    case UPDATE_AUTH:
      return state.set('data', {
        ...(state.data ?? {}),
        [action.field]: action.value,
      });
    default:
      return state;
  }
};
