/* eslint-disable no-nested-ternary */
import { Paper } from '@material-ui/core';
import React from 'react';
import StyledButton from '../../Button/StyledButton';
import FASTitle from '../../Forms/CustomFormComponents/FASTtle';

const SettingsTile = ({ title, onSubmit, getActionButton, children }) => {
  const submitBtn = () => (
    <StyledButton variant="contained" color="primary" buttonContent="SUBMIT" type="submit" handleButton={onSubmit} />
  );
  return (
    <Paper style={{ padding: 16, marginTop: 16 }}>
      <form>
        <FASTitle title={title} customStyle={{ marginTop: 0 }} />
        {children}
        {getActionButton ? getActionButton(submitBtn()) : onSubmit ? submitBtn() : null}
      </form>
    </Paper>
  );
};
export default SettingsTile;
