/* eslint-disable max-len */
import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import _ from 'lodash';
import { connect } from 'react-redux';
// import { userInfoSelector } from '../../../redux/selectors/user.selectors';
import * as OrdersActions from '../../../redux/actions/orders.actions';
import * as UsersActions from '../../../redux/actions/users.actions';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import { fetchMaterials } from '../../../redux/actions/materials.actions';
import FASOrdersFilters from './FASOrdersFilters';
import FASOrdersTable from './FASOrdersTable';
import {
  DATE_PICKER_FOMAT,
  FETCH_ORDERS_RESULTS_THRESHOLD,
  ORDERS_FILTERS_ENUM,
  ORDERS_TABLE_COLUMNS,
  ORDERS_VIEWS,
} from '../../../utils/consts';
import SimpleText from '../../Text/SimpleText';

const FASFilteredOrdersTable = (props) => {
  const {
    dispatch,
    // send from parent
    view,
    higherLevelAccount,
    customerNumber,
    // coming from connect(state)
    // user,
    // reportingTcis,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [allRegLocations, setAllRegLocations] = React.useState(false);
  const [filteredOrders, setFilteredOrders] = React.useState([]);
  const [hasQueried, setHasQueried] = React.useState(false);
  const [requestingCancelled, setRequestingCancelled] = React.useState(false);

  React.useEffect(async () => {
    setLoading(true);
    try {
      await dispatch(fetchMaterials());
      const userData = await dispatch(UsersActions.getRegionsLocations());
      if (userData) {
        setAllRegLocations(userData);
      }
    } catch {
      dispatch(
        ModalsActions.showModal('LOCATIONS_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: { message: 'Failed to load region/location data. Please contact an admin.' },
        }),
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const layoutRules = {
    [ORDERS_VIEWS.ordersPanel]: {
      hiddenFilters: [ORDERS_FILTERS_ENUM.account, ORDERS_FILTERS_ENUM.customer],
      initialValues: {
        [ORDERS_FILTERS_ENUM.startTime]: moment().utc().startOf('week').format(DATE_PICKER_FOMAT),
        [ORDERS_FILTERS_ENUM.endTime]: moment().utc().format(DATE_PICKER_FOMAT),
        filterValue: 'None',
      },
      hiddenColumns: [
        ORDERS_TABLE_COLUMNS.startTime,
        ORDERS_TABLE_COLUMNS.timezone,
        ORDERS_TABLE_COLUMNS.storeNum,
        ORDERS_TABLE_COLUMNS.distributionChannel,
      ],
    },
    [ORDERS_VIEWS.upcomingCustomer]: {
      hiddenFilters: [
        ORDERS_FILTERS_ENUM.account,
        ORDERS_FILTERS_ENUM.customer,
        ORDERS_FILTERS_ENUM.cancelled,
        ORDERS_FILTERS_ENUM.tempContact,
        ORDERS_FILTERS_ENUM.unsigned,
        ORDERS_FILTERS_ENUM.region,
        ORDERS_FILTERS_ENUM.locations,
        ORDERS_FILTERS_ENUM.account,
      ],
      // disabledFilters: [ORDERS_FILTERS_ENUM.startTime],
      initialValues: {
        [ORDERS_FILTERS_ENUM.customer]: customerNumber,
        [ORDERS_FILTERS_ENUM.startTime]: moment().utc().startOf('day').format(DATE_PICKER_FOMAT),
        [ORDERS_FILTERS_ENUM.endTime]: moment().utc().add(1, 'year').format(DATE_PICKER_FOMAT),
      },
      minValues: {
        [ORDERS_FILTERS_ENUM.startTime]: moment().utc().startOf('day').format(DATE_PICKER_FOMAT),
      },
      hiddenColumns: [
        ORDERS_TABLE_COLUMNS.region,
        ORDERS_TABLE_COLUMNS.scheduledAmount,
        ORDERS_TABLE_COLUMNS.deliveredAmount,
        ORDERS_TABLE_COLUMNS.startTime,
        ORDERS_TABLE_COLUMNS.timezone,
        ORDERS_TABLE_COLUMNS.reason,
        ORDERS_TABLE_COLUMNS.contactLastName,
        ORDERS_TABLE_COLUMNS.contactPhone,
        ORDERS_TABLE_COLUMNS.contactEmail,
        ORDERS_TABLE_COLUMNS.storeNum,
        ORDERS_TABLE_COLUMNS.distributionChannel,
      ],
    },
    [ORDERS_VIEWS.pastCustomer]: {
      hiddenFilters: [
        ORDERS_FILTERS_ENUM.account,
        ORDERS_FILTERS_ENUM.customer,
        ORDERS_FILTERS_ENUM.cancelled,
        ORDERS_FILTERS_ENUM.tempContact,
        ORDERS_FILTERS_ENUM.unsigned,
        ORDERS_FILTERS_ENUM.region,
        ORDERS_FILTERS_ENUM.locations,
        ORDERS_FILTERS_ENUM.account,
      ],
      // disabledFilters: [ORDERS_FILTERS_ENUM.endTime],
      initialValues: {
        [ORDERS_FILTERS_ENUM.customer]: customerNumber,
        [ORDERS_FILTERS_ENUM.startTime]: moment().utc().subtract(1, 'year').format(DATE_PICKER_FOMAT),
        [ORDERS_FILTERS_ENUM.endTime]: moment().utc().startOf('day').format(DATE_PICKER_FOMAT),
      },
      maxValues: {
        [ORDERS_FILTERS_ENUM.endTime]: moment().utc().startOf('day').format(DATE_PICKER_FOMAT),
      },
      hiddenColumns: [
        ORDERS_TABLE_COLUMNS.region,
        ORDERS_TABLE_COLUMNS.scheduledAmount,
        ORDERS_TABLE_COLUMNS.deliveredAmount,
        ORDERS_TABLE_COLUMNS.startTime,
        ORDERS_TABLE_COLUMNS.timezone,
        ORDERS_TABLE_COLUMNS.reason,
        ORDERS_TABLE_COLUMNS.contactLastName,
        ORDERS_TABLE_COLUMNS.contactPhone,
        ORDERS_TABLE_COLUMNS.contactEmail,
        ORDERS_TABLE_COLUMNS.storeNum,
        ORDERS_TABLE_COLUMNS.distributionChannel,
      ],
    },
    [ORDERS_VIEWS.nationalAccount]: {
      hiddenFilters: [
        ORDERS_FILTERS_ENUM.cancelled,
        ORDERS_FILTERS_ENUM.customer,
        ORDERS_FILTERS_ENUM.tempContact,
        ORDERS_FILTERS_ENUM.unsigned,
        ORDERS_FILTERS_ENUM.region,
      ],
      disabledFilters: [ORDERS_FILTERS_ENUM.account],
      newLineIdxs: [2],
      initialValues: {
        [ORDERS_FILTERS_ENUM.account]: higherLevelAccount,
        [ORDERS_FILTERS_ENUM.region]: 'All',
        [ORDERS_FILTERS_ENUM.startTime]: moment().utc().subtract(1, 'month').format(DATE_PICKER_FOMAT),
        [ORDERS_FILTERS_ENUM.endTime]: moment().utc().format(DATE_PICKER_FOMAT),
      },
      hiddenColumns: [
        ORDERS_TABLE_COLUMNS.region,
        ORDERS_TABLE_COLUMNS.startTime,
        ORDERS_TABLE_COLUMNS.timezone,
        ORDERS_TABLE_COLUMNS.reason,
        ORDERS_TABLE_COLUMNS.contactPhone,
        ORDERS_TABLE_COLUMNS.contactEmail,
        ORDERS_TABLE_COLUMNS.distributionChannel,
      ],
    },
  };

  // Should this be in the Filters component instead of the parent?
  // const filterRegionsLocations = () => {
  //   const userLocations = user?.loc_ids ?? [];
  //   const tcisLocs = [...reportingTcis].reduce((prev, tci) => [...prev, ...(Object.keys(tci.locations ?? {}))], []);
  //   const allLocations = _(tcisLocs).concat(userLocations)
  //     .flatten()
  //     .uniq()
  //     .value();

  //   const allRegions = _.filter(allRegLocations, (reg) => _.includes(allLocations, reg.loc_id));
  //   return allRegions;
  // };

  const showToast = (success) => {
    dispatch(
      ModalsActions.showModal('GENERATE_ORDERS_CSV', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: success
            ? 'Successfully Generated Orders CSV. Please check your email'
            : 'Something went wrong generating Orders CSV. Please, try again!',
        },
      }),
    );
  };

  const handleCSVRequest = async (modalName, requestObj) => {
    setLoading(true);
    setFilteredOrders([]);
    try {
      dispatch(ModalsActions.hideModal(modalName));
      const generatedCSV = await dispatch(OrdersActions.getOrdersCSV(requestObj));
      showToast(generatedCSV);
    } catch (error) {
      showToast(false);
    } finally {
      setLoading(false);
    }
  };

  const showThresholdReachedPopup = async (requestObj) => {
    const modalName = 'TRESHOLD_DIALOG';
    dispatch(
      ModalsActions.showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          cancelText: 'Cancel',
          disableBackdropClick: false,
          maxWidth: 'sm',
          title: 'TOO MANY RESULTS',
          content: (
            <SimpleText
              divStyle={{ margin: 20 }}
              txt={
                'Your search produced too many results.\nPress Confirm to have a CSV generated and sent via email or Cancel to refine your search and try again.'
              }
            />
          ),
          confirmText: 'Confirm',
          onConfirm: () => {
            handleCSVRequest(modalName, requestObj);
          },
        },
      }),
    );
  };

  const getFilteredOrders = async (requestObj) => {
    setLoading(true);
    setHasQueried(true);

    try {
      const resultsCount = await dispatch(OrdersActions.fetchFilteredOrdersCount(requestObj));
      if (resultsCount > FETCH_ORDERS_RESULTS_THRESHOLD) {
        setHasQueried(false);
        showThresholdReachedPopup(requestObj);
        return;
      }
      const filtOrders = await dispatch(OrdersActions.fetchFilteredOrders(requestObj));
      if (filtOrders) {
        setFilteredOrders(filtOrders);
      }
      return;
    } catch {
      dispatch(
        ModalsActions.showModal('FILTERED_ORDERS_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: { message: 'Failed to retrieve orders. Please contact an admin.' },
        }),
      );
    } finally {
      await setRequestingCancelled(requestObj.cancelled);
      setLoading(false);
    }
  };

  const ordersFilters = () => (
    <FASOrdersFilters
      loading={loading}
      // overrideLocationData={ORDERS_VIEWS.nationalAccount === view ? calculateNationalAccountRegions() : undefined}
      filterInitialValues={layoutRules[view].initialValues}
      disabledFilters={layoutRules[view].disabledFilters}
      hiddenFilters={layoutRules[view].hiddenFilters}
      minValues={layoutRules[view].minValues}
      maxValues={layoutRules[view].maxValues}
      newLineIdxs={layoutRules[view].newLineIdxs}
      onFilter={getFilteredOrders}
      view={view}
    />
  );

  const ordersTable = () => (
    <FASOrdersTable
      regionLocationData={allRegLocations}
      orders={filteredOrders}
      isLoading={loading}
      hasQueried={hasQueried}
      requestingCancelled={requestingCancelled}
      hiddenColumns={layoutRules[view].hiddenColumns}
    />
  );

  return (
    <>
      {ordersFilters()}
      {ordersTable()}
    </>
  );
};

// const mapStateToProps = (state) => ({
//   user: userInfoSelector(state) ?? {},
//   reportingTcis: state?.users?.data?.reportingTcis ?? [],
// });

export default _.flow([connect()])(FASFilteredOrdersTable);
