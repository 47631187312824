/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { connect } from 'react-redux';
import React from 'react';
import * as CourseCategoriesActions from '../../../redux/actions/courseCategories.actions';
import SettingsFrame from './SettingsFrame';
import CourseCategoriesTable from '../../../components/Settings/CourseCategories/CourseCategoriesTable';

class CourseCategoriesView extends React.Component {
  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(CourseCategoriesActions.fetchCourseCategories());
  }

  render() {
    const {
      match: { path },
    } = this.props;
    return (
      <SettingsFrame path={path}>
        <CourseCategoriesTable />
      </SettingsFrame>
    );
  }
}

const mapStateToProps = (/* state */) => ({
  // materials: { data: courseCategoriesSelector(state) },
});

export default _.flow([connect(mapStateToProps)])(CourseCategoriesView);
