/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React from 'react';
import Typography from '@material-ui/core/Typography';

import Copyright from '../../components/Footer/Copyright';
import PasswordResetForm from '../../components/Forms/PasswordResetForm/PasswordResetForm';

import * as AuthActions from '../../redux/actions/auth.actions';
import * as ModalsActions from '../../redux/actions/modals.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
});

class PasswordResetView extends React.Component {
  async onPasswordResetSubmit(values) {
    const {
      history,
      dispatch,
      match: {
        params: { token },
      },
    } = this.props;
    const { password } = values;

    dispatch(UtilsActions.setSpinnerVisible(true));
    try {
      await dispatch(AuthActions.changePassword(password));
      history.push('/');
      const successMessage = 'Successfully reset password! Please login!';
      dispatch(
        ModalsActions.showModal('PASSRESET_SUCCESS_MODAL', {
          modalType: 'SUCCESS_ALERT',
          modalProps: { message: successMessage },
        }),
      );
      return;
    } catch (error) {
      const errMessage = 'Error resetting password! Please contact an Administrator.';
      dispatch(
        ModalsActions.showModal('PASSRESET_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: { message: errMessage },
        }),
      );
      throw error;
    } finally {
      dispatch(UtilsActions.setSpinnerVisible(false));
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Password Reset
          </Typography>
          <PasswordResetForm onSubmit={(values) => this.onPasswordResetSubmit(values)} />
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

export default _.flow([connect(), withStyles(styles), withRouter])(PasswordResetView);
