import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '17px',
    alignItems: 'center',
    color: '#989898',
  },
}));

const PopperFormTitle = (props) => {
  const { title, ...rest } = props;
  const classes = useStyles();

  return (
    <Typography className={classes.title} {...rest} color="textSecondary" gutterBottom>
      {title}
    </Typography>
  );
};

export default PopperFormTitle;
