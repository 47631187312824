/* eslint-disable no-use-before-define */
import React from 'react';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import MatrixTable from './MatrixTable';
import { ADMIN_ACCESSES, ADMIN_ACCESSES_LABELS, ROLE_ACCESSES, ROLE_ACCESSES_LABELS } from '../../../../utils/consts';
import { showModal } from '../../../../redux/actions/modals.actions';
import { updateAdminMatrix } from '../../../../redux/actions/settings.actions';

export const adminMatrixFormName = 'AdminMatrix';

const AdminMatrix = ({ adminMatrix }) => {
  const dispatch = useDispatch();

  const onSave = async () => {
    let success = false;
    try {
      success = await dispatch(updateAdminMatrix(adminMatrix, adminMatrixFormName));
    } finally {
      showToast(success, success ? 'Admin Matrix updated successfully!' : 'Something went wrong updating the matrix');
    }
    return success;
  };

  const showToast = (success, msg) => {
    dispatch(
      showModal('UPDATE_ADMIN_MATRIX_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: msg,
        },
      }),
    );
  };

  return (
    <MatrixTable
      titleText="ADMIN ACCESS MATRIX"
      excludeAppRole
      noRoleInput
      noNewRole
      noDelete
      matrix={adminMatrix}
      roles={ADMIN_ACCESSES}
      uneditableRoleAccesses={{ [ROLE_ACCESSES.advancedAdmin]: [ADMIN_ACCESSES.matrix] }}
      columnsOverride={['Admin Type', ...Object.values(ADMIN_ACCESSES_LABELS)]}
      formatRoleLabel={(role) => ROLE_ACCESSES_LABELS[role]}
      formName={adminMatrixFormName}
      saveChanges={onSave}
    />
  );
};

export default _.flow([
  connect((state) => ({
    adminMatrix: getFormValues(adminMatrixFormName)(state) ?? {},
  })),
  reduxForm({
    form: adminMatrixFormName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  }),
])(AdminMatrix);
