import { Helmet } from 'react-helmet';
import React from 'react';

import BaseModal from '../../components/Modals/BaseModal';
import SpinnerOverlay from '../../components/SpinnerOverlay/SpinnerOverlay';

const AppContainer = (props) => {
  const { children } = props;

  return (
    <div>
      <Helmet>
        {/* <body className="fixed-body" /> */}
        <body />
      </Helmet>
      {children}
      <BaseModal />
      <SpinnerOverlay />
    </div>
  );
};

export default AppContainer;
