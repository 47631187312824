/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
export const groupByZip = (instructorsData, locId, zipcode, isSelected) => {
  const instructors = [...(instructorsData ?? [])];
  const primary = instructors
    .filter((instr) => (instr.locations?.[locId]?.zip_codes ?? []).includes(zipcode))
    .map((inst) => ({ ...(inst ?? {}), selected: isSelected }))
    .sort((a, b) => (a.org_role ?? '').localeCompare(b.org_role ?? ''))[0];
  let locationInstructors = instructors.filter((instr) => Boolean((instr.locations ?? {})?.[locId]));
  locationInstructors = !primary
    ? locationInstructors
    : locationInstructors.filter((instr) => instr.usrid_long !== primary.usrid_long);
  return {
    primary,
    secondary: locationInstructors,
  };
};
