import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

export const getUnblockedDaysWithTimezone = (unblockedDays, timezone) =>
  unblockedDays?.map((day) => ({
    date: moment.utc(`${day.date} ${day.startTime}`, 'YYYY-MM-DD h:mm A').tz(timezone).format('YYYY-MM-DD'),
    startTime: moment.utc(`${day.date} ${day.startTime}`, 'YYYY-MM-DD h:mm A').tz(timezone).format('hh:mm A'),
    endTime: moment.utc(`${day.date} ${day.endTime}`, 'YYYY-MM-DD h:mm A').tz(timezone).format('hh:mm A'),
  }));

export const isTimeBlocked = ({ unblockedDays, timezone, date }) => {
  const isWeekendDay = [0, 6].includes(date.getDay());
  if (!isWeekendDay) return false;
  const unblockedDaysWithTimezone = getUnblockedDaysWithTimezone(unblockedDays, timezone);
  const unblockedDay = unblockedDaysWithTimezone?.find((el) => el.date === moment(date).format('YYYY-MM-DD'));
  if (!unblockedDay) return true;
  const unblockedStartTime = moment(`${unblockedDay.date} ${unblockedDay.startTime}`, 'YYYY-MM-DD hh:mm A').tz(
    timezone,
  );
  const unblockedEndTime = moment(`${unblockedDay.date} ${unblockedDay.endTime}`, 'YYYY-MM-DD hh:mm A').tz(timezone);
  const unblockedTime = moment(date).isBetween(unblockedStartTime, unblockedEndTime, 'minutes', '[)');
  if (unblockedTime) return false;
  return true;
};

export const isTimeRangeBlocked = ({ unblockedDays, timezone, start, end }) => {
  const isWeekendDay = [0, 6].includes(start.getDay());
  if (!isWeekendDay) return false;
  const unblockedDaysWithTimezone = getUnblockedDaysWithTimezone(unblockedDays, timezone);
  const unblockedDay = unblockedDaysWithTimezone?.find((el) => el.date === moment(start).format('YYYY-MM-DD'));
  if (!unblockedDay) return true;
  const unblockedStartTime = moment(`${unblockedDay.date} ${unblockedDay.startTime}`, 'YYYY-MM-DD hh:mm A').tz(
    timezone,
  );
  const unblockedEndTime = moment(`${unblockedDay.date} ${unblockedDay.endTime}`, 'YYYY-MM-DD hh:mm A').tz(timezone);
  const unblockedTime = moment(start).isSameOrAfter(unblockedStartTime) && moment(end).isSameOrBefore(unblockedEndTime);
  if (unblockedTime) return false;
  return true;
};
