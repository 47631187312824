/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { Box, IconButton } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/DownloadRounded';
import _ from 'lodash';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch } from 'react-redux';
import { change, submit, SubmissionError } from 'redux-form';
import StyledButton from '../../../../components/Button/StyledButton';
import Container from '../../../../components/LayoutBuilders/Container';
import { COLORS, ROLE_ACCESSES, ROLE_ACCESSES_LABELS } from '../../../../utils/consts';

import CollapsableHeader from './CollapsableHeader';
import ContainerItem from '../../../../components/LayoutBuilders/ContainerItem';
import MatrixRoleCard from './MatrixRoleCard';
import { hideModal, showModal } from '../../../../redux/actions/modals.actions';
import RoleAccessInfoModal, { roleAccessInfoFormName, validateRoleAccessForm } from './RoleAccessInfoModal';
import CsvFactory from '../../../../utils/csvFactory';

const MatrixRolesView = ({
  titleText,
  matrix,
  startOpen,
  onDeleteRole,
  formName,
  saveChanges,
  noNewRole,
  noDelete,
  noRoleInput,
  uneditableRoles = [],
  collapsed,
  allowDownload,
  csvFilename,
}) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = React.useState(false);

  const defaultUserRoleMatrix = Object.keys(ROLE_ACCESSES_LABELS).reduce(
    (red, col) => ({
      ...red,
      [col]: false,
      appRole: '',
    }),
    { role: 'NEW ROLE' },
  );

  const downloadCsvMatrix = () => {
    CsvFactory.downloadCsv({
      fileName: csvFilename ?? 'Access_Matrix',
      headers: [...(noRoleInput ? [] : ['Role']), ...Object.keys(ROLE_ACCESSES)],
      getHeaderLabel: (axs) => ROLE_ACCESSES_LABELS[axs] ?? axs,
      normalize: (header, val) => {
        if (header === ROLE_ACCESSES.appRole || header === 'Role') return undefined;
        return val ? 'Yes' : 'No';
      },
      dataList: Object.keys(matrix).map((role) => ({ Role: role, ...matrix[role] })),
    });
  };

  const onSave = async (roleKey, newValues, shouldDelete) => {
    try {
      setSaving(true);
      const success = await saveChanges(roleKey, newValues, shouldDelete);
      if (success) {
        // TODO: refresh UI
      }
    } finally {
      setSaving(false);
    }
  };

  const showToast = (success, msg) => {
    dispatch(
      showModal('UPDATE_ROLE_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: msg,
        },
      }),
    );
  };

  const showValidationErrors = (errors) => {
    showToast(false, 'Correct errors before proceeding');
  };

  const onSaveRole = async (newRole, roleKey) => {
    const errors = validateRoleAccessForm(newRole, { defaultUserRoleMatrix });
    if (!_.isEmpty(errors)) {
      showValidationErrors(errors);
      return;
    }
    dispatch(change(formName, roleKey, newRole));
    dispatch(hideModal(`${roleKey}_MODAL`));
    await onSave(roleKey, newRole);
  };

  const onDelete = (roleToDelete) => {
    onDeleteRole(roleToDelete, async (deletedRole, newValues) => {
      // when deletion confirmed and completed
      await onSave(deletedRole, undefined, true);
      dispatch(hideModal(`${deletedRole}_MODAL`));
    });
  };

  const openRoleInfoPopup = ({ roleKey, accessMatrix, isNewRole, notEditable }) => {
    dispatch(
      showModal(`${roleKey}_MODAL`, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          disableClosing: true,
          disableBackdropClick: true,
          maxWidth: 'xl',
          title: isNewRole ? 'Create New Role' : accessMatrix?.role ?? 'Edit Role',
          content: (
            <RoleAccessInfoModal
              defaultUserRoleMatrix={defaultUserRoleMatrix}
              initialMatrix={accessMatrix}
              onSubmit={(vals) => onSaveRole(vals, roleKey)}
              onDelete={() => onDelete(roleKey)}
              noDelete={isNewRole || notEditable || noDelete}
              notEditable={notEditable}
              noRoleInput={noRoleInput}
            />
          ),
          confirmText: 'SAVE',
          cancelText: notEditable ? 'CLOSE' : 'CANCEL',
          onConfirm: notEditable
            ? null
            : async () => {
                await dispatch(submit(roleAccessInfoFormName));
              },
        },
      }),
    );
  };

  const onAddRole = () =>
    openRoleInfoPopup({
      roleKey: defaultUserRoleMatrix.role,
      accessMatrix: defaultUserRoleMatrix,
      isNewRole: true,
    });

  const downloadMatrixButton = () => (
    <IconButton
      onClick={downloadCsvMatrix}
      color="primary"
      style={{
        padding: 2,
        margin: 0,
        backgroundColor: COLORS.CINTAS_BLUE,
        float: 'right',
      }}
    >
      <DownloadIcon style={{ color: COLORS.CINTAS_WHITE }} />
    </IconButton>
  );

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const grid = ({ children }) =>
    container({
      spacing: 2,
      children: children.map((child) =>
        item({
          flex: 'auto',
          children: [child],
        }),
      ),
    });

  const rolesCards = () => {
    const categorizedRoles = Object.keys(matrix).reduce(
      (prev, k) => ({
        ...prev,
        [matrix[k].role]: { ...(prev[matrix[k].role] ?? []), [k]: matrix[k] },
      }),
      {},
    );
    const toLoop = Object.keys(categorizedRoles).sort();
    return grid({
      children: toLoop.map((roleCat) => (
        <MatrixRoleCard
          collapsed={collapsed}
          notEditableRoles={uneditableRoles}
          roleCategory={roleCat}
          categoryRoles={categorizedRoles[roleCat]}
          onOpenRole={openRoleInfoPopup}
        />
      )),
    });
  };

  const addRoleBtn = () =>
    container({
      style: { justifyContent: 'flex-start' },
      children: [
        <StyledButton
          color="primary"
          variant="contained"
          buttonContent="add role"
          startIcon={<AddIcon />}
          disabled={matrix[defaultUserRoleMatrix.role]}
          handleButton={onAddRole}
        />,
      ],
    });

  const expansionTile = () => (
    <CollapsableHeader
      title={titleText}
      startOpen={startOpen}
      // boundActionToContent
      actions={[allowDownload ? downloadMatrixButton() : null]}
      content={[rolesCards(), <Box height={20} />, noNewRole ? <></> : addRoleBtn()]}
    />
  );

  return expansionTile();
};

export default MatrixRolesView;
