import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import classes from './styles.module.css';

const CalendarDate = (props) => {
  const { dateToRender, dateOfMonth } = props;
  const isToday = dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');

  const inMonth = dateToRender.month() === dateOfMonth.month();

  // const isCurDay = dateToRender;
  // console.log('data to render:', isCurDay);
  // `date${isToday && inMonth ? 'today' : ''} ${inMonth ? 'in-month' : 'next-or-prev-month'}`;
  return (
    <button
      type="button"
      disabled={!inMonth}
      className={`${classes.date} ${isToday && inMonth ? classes.today : ''} ${inMonth ? classes['in-month'] : classes['next-or-prev-month']}`}
      onClick={() => props.onClick(dateToRender)}
    >
      {dateToRender.date()}
    </button>
  );
};

export default CalendarDate;
