import * as DATA_API from '../../api/nationalAccount.api';

export const fetchNationalAccountById = async (id) => {
  try {
    const response = await DATA_API.fetchNationalAccountById(id);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    return false;
  }
};

export const editNationalAccountNotes = async (id, payload) => {
  try {
    const response = await DATA_API.editNationalAccountNotes(id, payload);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    return false;
  }
};

export const editRosterConfig = async (id, type, required) => {
  try {
    const response = await DATA_API.editRosterConfig(id, type, required);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    return false;
  }
};

export const getNationalAccountLocations = async (id) => {
  try {
    const response = await DATA_API.getNationalAccountLocations(id);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    return false;
  }
};

export const getNationalAccountOrders = async (id, locID) => {
  try {
    const response = await DATA_API.getNationalAccountOrders(id, locID);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    return false;
  }
};
