import { connect } from 'react-redux';
import React from 'react';

const requireAuthentication = (checkAuth) => (Component) => {
  class AuthenticatedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authorized: false,
      };
    }

    async componentDidMount() {
      if (checkAuth) {
        const authorized = await checkAuth(this.props);
        if (authorized) {
          this.setState({ authorized });
        }
      }
    }

    async componentDidUpdate(prevProps) {
      const { location } = this.props;
      const { location: oldLocation } = prevProps;
      const locationChanged = location !== oldLocation;
      if (locationChanged && checkAuth) {
        const authorized = await checkAuth(this.props);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ authorized });
      }
    }

    componentWillUnmount() {
      this.setState = () => {};
    }

    render() {
      const { authorized } = this.state;
      return <>{authorized ? <Component {...this.props} /> : null}</>;
    }
  }

  return connect()(AuthenticatedComponent);
};

export default requireAuthentication;
