/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import _ from 'lodash';

const styles = {
  root: {
    width: 'auto',
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    padding: 10,
    overflow: 'hidden',
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
};

const HTMLEditor = ({
  id,
  formName,
  content, // recieves string version of raw content to be parsed and set to editor state
  placeholder,
  height = 200,
  ...props
}) => {
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState();

  useEffect(() => {
    if (!_.isEmpty(content)) {
      const editorContent = convertFromRaw(JSON.parse(content));
      setEditorState(EditorState.createWithContent(editorContent));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  const handleEditorStateChange = (state) => {
    setEditorState(state);
    if (formName && id) {
      const rawContent = JSON.stringify(convertToRaw(state.getCurrentContent()));
      dispatch(change(formName, id, rawContent));
    }
  };

  return (
    <div style={styles.root}>
      <div style={{ ...styles.editor, height }}>
        <Editor
          {...props}
          editorStyle={{ maxHeight: height - 130 }}
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default HTMLEditor;
