/* eslint-disable no-unused-vars */
/* eslint-disable react/prefer-stateless-function */
import _ from 'lodash';
import { connect } from 'react-redux';
import { initialize, submit } from 'redux-form';
import { Link, withRouter } from 'react-router-dom';
import { Typography, Container, Grid, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import * as CertificationsActions from '../../../redux/actions/certifications.actions';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import StyledButton from '../../../components/Button/StyledButton';
import CertificationSettingsForm from '../../../components/Forms/SettingsForms/CertificationSettingsForm';
import SettingsDetailsFrame from './SettingsDetailsFrame';

const titleStyle = {
  fontFamily: 'proxima-nova',
  fontWeight: 'Bold',
  display: 'flex',
  paddingBottom: '24px',
  paddingTop: '24px',
};

const submitButtonStyle = {
  marginTop: 32,
  marginBottom: 32,
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
};

class CertificationDetailsView extends React.Component {
  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(CertificationsActions.fetchCertifications());
  }

  async onSubmitCertification(values) {
    const {
      dispatch,
      history,
      match: {
        params: { certification },
      },
    } = this.props;

    const creatingNewCertification = certification === 'newCertification';

    // Modify fields if needed
    try {
      if (values.certInUse && !values.enabled) throw new Error();
      if (creatingNewCertification) {
        await dispatch(CertificationsActions.createCertification(values));
      } else {
        await dispatch(CertificationsActions.updateCertificationById(values.id, values));
      }
      dispatch(
        ModalsActions.showModal('UPDATE_CERTIFICATION_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: { message: 'Successfully modified certification' },
        }),
      );
      history.push('/admin/certifications');
    } catch (error) {
      const message =
        values.certInUse && !values.enabled
          ? 'Certification associated with course(s) cannot be inactivated - please update course(s) to proceed.'
          : 'Error submitting certification';
      dispatch(
        ModalsActions.showModal('UPDATE_CERTIFICATIONS_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { message },
        }),
      );
    }
  }

  render() {
    const {
      dispatch,
      history,
      match: {
        params: { certification },
      },
      certifications: { data: certificationsData },
    } = this.props;
    const selectedCertification = _.find(certificationsData, { id: certification });
    return (
      <SettingsDetailsFrame
        title="CERTIFICATIONS SETTINGS"
        backBtnTitle="Back to Certifications"
        backPath="/admin/certifications"
        formName="CertificationSettingsForm"
      >
        <CertificationSettingsForm
          onSubmit={(values) => this.onSubmitCertification(values)}
          initialValues={selectedCertification}
        />
      </SettingsDetailsFrame>
    );
  }
}

const mapStateToProps = (state) => ({
  certifications: state.certifications,
});

export default _.flow([withRouter, connect(mapStateToProps)])(CertificationDetailsView);
