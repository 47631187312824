import React from 'react';
import Radio from '@material-ui/core/Radio';
import { COLORS } from '../../../utils/consts/index';

const FASRadio = ({ ...props }) => (
  <Radio
    style={{
      color: props.disabled ? COLORS.CINTAS_DARK_GRAY : COLORS.CINTAS_BLUE,
    }}
    {...props}
  />
);

export default FASRadio;
