import _ from 'lodash';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import React from 'react';

import MDTextInputField from '../FormComponents/MDTextInput/MDTextInputField';

const ForgotPasswordForm = ({ onConfirm, enteredEmail }) => (
  <div style={{ marginTop: 16 }}>
    <Field
      id="email"
      name="email"
      variant="outlined"
      component={MDTextInputField}
      placeholder="Email Address"
      label="Email Address"
      required
      autoComplete="email"
      autoFocus
    />
    <Button
      type="button"
      fullWidth
      variant="contained"
      color="primary"
      onClick={() => onConfirm(enteredEmail)}
      style={{ marginTop: 24, marginBottom: 16 }}
    >
      Reset password
    </Button>
  </div>
);

const selector = formValueSelector('ForgotPasswordForm');
export default _.flow([
  connect((state) => {
    const enteredEmail = selector(state, 'email');

    return {
      enteredEmail,
    };
  }),
  reduxForm({
    form: 'ForgotPasswordForm',
  }),
])(ForgotPasswordForm);
