/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import React from 'react';
import { useDispatch } from 'react-redux';
import { changeNotificationToRead } from '../../../redux/actions/notifications.actions';
import { COLORS } from '../../../utils/consts';
import { NOTIFICATION_TYPE, NOTIFICATION_TYPE_ICONS } from '../../../utils/consts/notifications.consts';
import { buildNotificationBody, buildNotificationDetails } from '../../../utils/notification.utils';
import EventSummaryButton from '../../Button/EventSummaryButton';
import SimpleText from '../../Text/SimpleText';
import PanelTile from '../PanelTile';
import EscalationSummaryButton from '../../Button/EscalationSummaryButton';

const NotificationTile = ({
  notification,
  listIndex,
  disableExpand,
  // dismissPanel
}) => {
  const dispatch = useDispatch();
  const TypeIcon = NOTIFICATION_TYPE_ICONS[notification.type];
  const unread = !notification.read;
  const detailsBlocks = buildNotificationDetails(notification);
  const orderID = notification?.details?.orderID;
  const linkedTask = notification?.details?.linkedTask;
  const showOrderSummary = orderID && ![NOTIFICATION_TYPE.ESCALATED_EVENT].includes(notification?.type);
  const showEscalationSummary = [NOTIFICATION_TYPE.ESCALATED_EVENT].includes(notification?.type) && linkedTask;
  const page = notification?.page;

  const markNotificationRead = () => {
    dispatch(changeNotificationToRead(page, listIndex, notification.notificationID));
  };

  const details = () =>
    detailsBlocks.map((block) => (
      <SimpleText txt={block} divStyle={{ marginBottom: 18, fontSize: 12, overflowX: 'none' }} />
    ));

  return (
    <PanelTile
      unread={unread}
      noExpanding={disableExpand || !detailsBlocks}
      icon={<img src={TypeIcon} alt={notification.type} style={{ height: 30, width: 30, color: COLORS.CINTAS_BLUE }} />}
      header={notification?.header ?? ''}
      subheader={buildNotificationBody(notification.type, notification.body, notification.effectiveDate)}
      onExpansionChange={(val) => (unread && val ? markNotificationRead() : null)}
    >
      {disableExpand || !detailsBlocks ? undefined : details()}
      {showOrderSummary && (
        <EventSummaryButton
          orderId={orderID}
          // clickCallback={dismissPanel}
          tzOverride={notification?.body?.tz}
          // hideRepeatBookingButton
        />
      )}
      {showEscalationSummary && (
        <EscalationSummaryButton taskID={linkedTask} resolutionDate={notification?.creationDate} />
      )}
    </PanelTile>
  );
};

export default NotificationTile;
