/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import { saveDataToLocalStorage } from '../../utils/helpers';
import { SET_AUTH, UPDATE_AUTH, RESET_AUTH } from './actionTypes/auth';
import * as baseInstance from '../../api/instances/baseInstance';
import * as Firebase from '../../utils/firebase';
import * as AuthAPI from '../../api/auth.api';
import * as EventsActions from './events.actions';
import * as UtilsActions from './utils.actions';
import * as UsersActions from './users.actions';
import { ACCESS_MATRIX_VALIDATION, EMAIL_VALIDATION_ERRORS, OK_STATUS_CODES } from '../../utils/consts';

export const setUserAuth = (userAuth) => ({
  type: SET_AUTH,
  userAuth,
});

export const editUserAuthPhoto = (imgUrl) => ({
  type: UPDATE_AUTH,
  field: 'photoURL',
  value: imgUrl,
});

const resetAuth = () => ({
  type: RESET_AUTH,
});

const resetState = () => (dispatch) => {
  dispatch(UsersActions.resetState());
  dispatch(EventsActions.resetState());
  dispatch(UtilsActions.setInitialDataLoaded(false));
};

export const logout = () => async (dispatch) => {
  await Firebase.auth.signOut();
  saveDataToLocalStorage('authenticated', false);
  if (localStorage?.sessionTimeout) {
    clearTimeout(localStorage?.sessionTimeout);
  }
  localStorage.clear();
  baseInstance.ejectRequestInterceptors();
  baseInstance.ejectResponseInterceptors();
  dispatch(resetAuth());
  dispatch(resetState());
};

export const changePassword = (password) => async () => {
  try {
    const response = await AuthAPI.changePassword({
      password: password && password.trim(),
    });
    if (OK_STATUS_CODES.includes(response.status)) {
      return response;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = (email) => async () => {
  try {
    const userEmail = email && email.trim();
    const response = await AuthAPI.forgotPassword(userEmail);
    if (OK_STATUS_CODES.includes(response.status)) {
      return response;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const retry = async (error, dispatch) => {
  const originalRequest = error.config;
  if (error?.response?.status === 401 && !originalRequest.retry) {
    originalRequest.retry = true;
    baseInstance.ejectRequestInterceptors();
    try {
      const token = await Firebase.auth.currentUser.getIdToken(true);
      baseInstance.setInstanceToken(token);
      dispatch(
        setUserAuth({
          ...Firebase.auth.currentUser.toJSON(),
          role: await Firebase.getUserRole(),
        }),
      );
      return baseInstance.baseInstance(originalRequest);
    } catch (e) {
      dispatch(logout());
    }
  }
  if (error?.response?.status === 401 && originalRequest.retry) {
    dispatch(logout());
  }
  throw error;
};

export const verifyEmail = (email) => async () => {
  try {
    const response = await AuthAPI.verifyEmail(email);
    if (response) {
      return Number(response.data ?? EMAIL_VALIDATION_ERRORS.UNAUTHORIZED);
    }
    throw Error();
  } catch (e) {
    throw e;
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    baseInstance.ejectRequestInterceptors();
    const { user: userAuth } = await Firebase.auth.signInWithEmailAndPassword(email, password);
    const token = await Firebase.auth.currentUser.getIdToken();
    baseInstance.setInstanceToken(token);
    saveDataToLocalStorage('authenticated', true);
    const userInfo = await dispatch(UsersActions.fetchCurrentUser());
    if (userInfo?.error && userInfo?.code === ACCESS_MATRIX_VALIDATION.MISSING) {
      await dispatch(logout());
      throw userInfo;
    }
    const role = userInfo.org_role;
    const displayRole = role;
    if (!role) {
      throw new Error();
    }
    saveDataToLocalStorage('role', role);
    const auth = {
      ...userAuth.toJSON(),
      role,
      displayRole,
    };
    dispatch(setUserAuth(auth));
    baseInstance.setResponseErrorInterceptor((error) => retry(error, dispatch));
    return auth;
  } catch (error) {
    await dispatch(logout());
    throw error;
  }
};

export const signInWithAzureID = () => async (dispatch) => {
  try {
    baseInstance.ejectRequestInterceptors();
    const { user: userAuth } = await Firebase.auth.signInWithPopup(Firebase.provider);
    await dispatch(verifyEmail(userAuth.email));
    const token = await Firebase.auth.currentUser.getIdToken();
    baseInstance.setInstanceToken(token);
    const authProviders = await Firebase.auth.fetchSignInMethodsForEmail(userAuth.email);
    if (authProviders?.includes('password')) {
      await Firebase.auth.currentUser.unlink('password');
    }
    saveDataToLocalStorage('authenticated', true);
    const userInfo = await dispatch(UsersActions.fetchCurrentUser());
    if (userInfo?.error && userInfo?.code === ACCESS_MATRIX_VALIDATION.MISSING) {
      await dispatch(logout());
      throw userInfo;
    }
    const role = userInfo.org_role;
    const displayRole = role;
    if (!role) {
      throw new Error();
    }
    saveDataToLocalStorage('role', role);
    const auth = {
      ...userAuth.toJSON(),
      role,
      displayRole,
    };
    dispatch(setUserAuth(auth));
    baseInstance.setResponseErrorInterceptor((error) => retry(error, dispatch));
    return auth;
  } catch (err) {
    await dispatch(logout());
    throw err;
  }
};

export const linkAzureID = async (email, password, azureCredentials) => {
  try {
    baseInstance.ejectRequestInterceptors();
    await Firebase.signInWithEmailPassword(email, password);
    const result = await Firebase.auth.currentUser.linkWithCredential(azureCredentials);
    if (result?.user) {
      await Firebase.auth.currentUser.unlink('password');
    }
    return result;
  } catch (error) {
    throw error;
  }
};
