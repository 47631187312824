import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../EventDialogControl/AddonItem';
import AddonItemImage from '../EventDialogControl/AddonItemImage';

const MaterialCategoryTile = ({ category, onClick }) => {
  const classes = useStyles();
  const box = ({ children, ...rest }) => (
    <div className={classes.box} {...(rest ?? {})}>
      {children}
    </div>
  );

  const image = () => (
    <AddonItemImage
      addonItem={{
        id: category.id,
        image: category.imgURL,
      }}
      classes={classes}
    />
  );

  const title = () => (
    <div>
      <Tooltip title={`${category.title ?? ''}`.toUpperCase()}>
        <Typography className={classes.addonname}>{`${category.title ?? ''}`.toUpperCase()}</Typography>
      </Tooltip>
    </div>
  );

  return (
    <div className={classes.container}>
      {box({
        onClick,
        style: {
          cursor: onClick ? 'pointer' : 'default',
        },
        children: [image(), title()],
      })}
    </div>
  );
};

export default MaterialCategoryTile;
