/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

import { SAVE_COMMISSIONLEVELS } from './actionTypes/commissionLevels';

import * as CommissionLevelsAPI from '../../api/commissionLevels.api';

const saveCommissionLevels = (payload) => ({
  type: SAVE_COMMISSIONLEVELS,
  payload,
});

export const createCommissionLevels = (payload) => async (dispatch) => {
  try {
    const response = await CommissionLevelsAPI.createCommissionLevels(payload);
    if (response && response.data) {
      await dispatch(saveCommissionLevels(response.data));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchCommissionLevels = () => async (dispatch) => {
  try {
    const response = await CommissionLevelsAPI.getCommissionLevels();
    if (response && response.data) {
      const cl = _.max(response.data, (el) => new Date(el.created).getTime());
      dispatch(saveCommissionLevels(cl));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};
