import Slider from '@material-ui/core/Slider';
import React from 'react';

const MDSlider = ({ input, min, max, disabled }) => (
  <Slider
    aria-label="Always visible"
    step={1}
    onChange={(event, value) => {
      input.onChange(value);
    }}
    marks={[
      {
        value: min,
        label: min,
      },
      {
        value: max,
        label: max,
      },
    ]}
    defaultValue={max}
    valueLabelDisplay={disabled ? 'off' : 'on'}
    min={min}
    max={max}
    disabled={disabled}
    value={input.value ?? parseInt(max, 10)}
    style={{ marginLeft: 4, marginTop: !disabled && 40 }}
  />
);

export default MDSlider;
