/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { NOTIFICATION_EXPIRATION_DAYS } from '../utils/consts';
import { TASKS_LABELS } from '../utils/consts/tasks.consts';
import { getBrowserTimezone } from '../utils/helpers';
import { formatName, NotificationContact, NotificationCustomer } from './Notification';

export default class Task {
  constructor({
    taskID,
    type,
    subType,
    assigneeID,
    expirationDate,
    effectiveDate,
    creationDate,
    resolved = false,
    read = false,
    resolutionValue,
    header,
    body,
    details,
    description,
    contact,
    customer,
  }) {
    this.taskID = taskID;
    this.type = type;
    this.subType = subType;
    this.resolved = resolved;
    this.resolutionValue = resolutionValue;
    this.read = read;
    this.assigneeID = assigneeID;
    this.creationDate = moment.utc(creationDate);
    this.expirationDate = expirationDate
      ? moment.utc(expirationDate)
      : this.creationDate.clone().add(NOTIFICATION_EXPIRATION_DAYS, 'days');
    this.effectiveDate = effectiveDate ?? moment.utc();
    this.header = header ?? TASKS_LABELS?.[this.type] ?? '';
    this.body = new TaskBody(body);
    this.details = new TaskDetails({
      ...(details ?? {}),
      description,
      contact: new NotificationContact(contact),
      customer: new NotificationCustomer(customer),
    });
  }

  static fromOrderDetails(type, subType, effectiveDate, initiator, tz, orderSummary, actionInfo) {
    return new Task({
      type,
      subType,
      effectiveDate,
      body: TaskBody.fromOrder(initiator, tz, orderSummary),
      details: TaskDetails.fromOrder(orderSummary, actionInfo),
      contact: NotificationContact.fromOrder(orderSummary),
      customer: NotificationCustomer.fromOrder(orderSummary),
    });
  }
}

class TaskBody {
  constructor({ title, createdBy, creatorID, text, datetime, tz }) {
    this.title = title ?? '';
    this.datetime = moment.utc(datetime);
    this.createdBy = createdBy ?? '';
    this.creatorID = creatorID ?? '';
    this.text = text ?? '';
    this.tz = tz ?? getBrowserTimezone();
  }

  static fromOrder(initiator, tz, orderSummary) {
    return new TaskBody({
      title: formatName(orderSummary?.customerObj?.name),
      createdBy: formatName(`${initiator?.firstName ?? ''} ${initiator?.lastName ?? ''} `),
      creatorID: initiator?.uid,
      datetime: orderSummary?.events?.[0]?.startTime,
      tz,
    });
  }
}

class TaskDetails {
  constructor({ orderID, fee, waiveReason, waiveNotes, instructor, location, description, contact, customer }) {
    this.description = description ?? '';
    this.orderID = orderID;
    this.fee = fee;
    this.waiveReason = waiveReason;
    this.waiveNotes = waiveNotes;
    this.location = new TaskLocationDetails(location ?? {});
    this.contact = contact;
    this.customer = customer;
    this.instructor = instructor;
  }

  static fromOrder(orderSummary, actionInfo) {
    return new TaskDetails({
      orderID: orderSummary?.id,
      location: {
        city: orderSummary?.events?.[0]?.locationDisplay,
        zip: orderSummary?.customerObj?.postal_code,
        locID: orderSummary?.customerObj?.sales_office,
      },
      fee: actionInfo?.fee,
      waiveReason: actionInfo?.reason,
      waiveNotes: actionInfo?.notes,
      instructor: TaskInstructor.fromOrder(orderSummary),
    });
  }
}

class TaskLocationDetails {
  constructor({ city, zip, locID, territoryCode }) {
    this.city = city;
    this.zip = zip;
    this.locID = locID;
    this.territoryCode = territoryCode;
  }
}

class TaskInstructor {
  constructor({ id, firstName, lastName }) {
    this.id = id ?? '';
    this.firstName = firstName ?? '';
    this.lastName = lastName ?? '';
  }

  static fromOrder(orderSummary) {
    const instructorInfo = (orderSummary.events ?? [])?.[0]?.instructor ?? {};
    return new TaskInstructor({
      id: orderSummary.instructor,
      firstName: instructorInfo.firstName,
      lastName: instructorInfo.lastName,
    });
  }
}
