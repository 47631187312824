/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { notificationsConfigSelector, tasksConfigSelector } from './settings.selectors';

export const panelTypes = {
  notification: 'notifications',
  task: 'tasks',
};

export const openPanelSelector = (state, type) => state?.notifications?.[type]?.openPanel ?? false;
export const showRedDotSelector = (state, type) => state?.notifications?.[type]?.showNoticeDot ?? false;

export const unreadNotificationsSelector = (state) => state?.notifications?.[panelTypes.notification]?.unread ?? {};
export const pastNotificationsSelector = (state) => state?.notifications?.[panelTypes.notification]?.pastData ?? {};
export const checkNotificationEnabledSelector = (state, type) => {
  const settingsOfType = notificationsConfigSelector(state)?.[type];
  if (!settingsOfType) return true;
  return Boolean(settingsOfType.inApp);
};

export const unsolvedTasksSelector = (state) => state?.notifications?.[panelTypes.task]?.unsolved ?? {};
export const unsolvedTasksListSelector = (state) => {
  const unsolved = _.cloneDeep(unsolvedTasksSelector(state));
  return _.flatten(Object.keys(unsolved).map((type) => _.flatten(Object.values(unsolved[type]))));
};
export const solvedTasksSelector = (state) => state?.notifications?.[panelTypes.task]?.solved ?? {};
export const checkTaskEnabledSelector = (state, type) => Boolean(tasksConfigSelector(state)?.[type]?.inApp);
