/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import React from 'react';
import NotificationsIcon from '@material-ui/icons/NotificationsRounded';
import TasksIcon from '@material-ui/icons/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import TopBarButton from '../TopBar/TopBarButton';
import { COLORS } from '../../utils/consts';
import { openPanelOfType, showNoticeDot } from '../../redux/actions/notifications.actions';
import { showRedDotSelector, panelTypes } from '../../redux/selectors/notifications.tasks.selector';

const NotificationsIconButton = ({ type = panelTypes.notification }) => {
  const dispatch = useDispatch();
  const showRedDot = useSelector((state) => showRedDotSelector(state, type));

  const openPanel = () => {
    dispatch(openPanelOfType(type, true));
    dispatch(showNoticeDot(type, false));
  };

  const redDotOverlay = ({ children }) => (
    <div>
      {children}
      <div
        style={{
          position: 'absolute',
          right: '22%',
          top: '22%',
          height: 10,
          width: 10,
          background: COLORS.CINTAS_RED,
          borderRadius: '50%',
        }}
      />
    </div>
  );

  const icon = type === panelTypes.notification ? <NotificationsIcon /> : <TasksIcon />;

  return (
    <TopBarButton
      key="notifPanel"
      index={0}
      title={type === panelTypes.notification ? 'Notifications' : 'Tasks'}
      icon={showRedDot ? redDotOverlay({ children: [icon] }) : icon}
      navOverride={openPanel}
    />
  );
};

export default _.flow()(NotificationsIconButton);
