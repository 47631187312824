/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const getRecurringSeries = (seriesID) => baseInstance.get(`/recurringSeries/${seriesID}`);

export const unlinkSeries = (eventID) => baseInstance.put(`/unlinkSeries?eventID=${eventID}`);

export const updateSeries = (seriesID, startDate, payload) =>
  baseInstance.put(`/updateSeries?seriesID=${seriesID}&startDate=${startDate}`, payload);

export const deleteSeries = (seriesID, startDate) =>
  baseInstance.put(`/deleteSeries?seriesID=${seriesID}&startDate=${startDate}`);
