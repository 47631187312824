import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  content: {
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    alignItems: 'center',
    color: '#404040',
    wordWrap: 'break-word',
    maxHeight: '88px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  small: {
    fontFamily: 'proxima-nova, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    alignItems: 'center',
    color: '#404040',
    wordWrap: 'break-word',
  },
}));

const PopperFormContent = (props) => {
  const { content, small, ...rest } = props;
  const classes = useStyles();

  return (
    <Typography className={small ? classes.small : classes.content} {...rest} component="p">
      {content}
    </Typography>
  );
};

export default PopperFormContent;
