/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Page, Text, View, Font, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import _ from 'lodash';
import logo from '../../assets/images/pdf_footer_logo.png';
import roboto from '../../assets/fonts/Roboto/Roboto-Regular.ttf';
import robotoBold from '../../assets/fonts/Roboto/Roboto-Bold.ttf';
import robotoLight from '../../assets/fonts/Roboto/Roboto-Light.ttf';
import { MATERIAL_TYPE, SUBCONTRACTED_INSTRUCTOR_LABEL } from '../../utils/consts';

const sliceParticipants = (participants) => {
  const parts = participants.map((participant, i) => ({ ...participant, rowNumber: i + 1 }));
  const res = [];
  const firstPageThresh = 10;
  const otherPageTresh = 17;

  if (participants.length <= firstPageThresh) {
    return [parts];
  }

  res.push(parts.slice(0, firstPageThresh));
  for (let i = firstPageThresh; i < parts.length; i += otherPageTresh) {
    res.push(parts.slice(i, i + otherPageTresh));
  }

  return res;
};

// Create styles
const styles = StyleSheet.create({
  titleContainer: {
    height: '12%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    padding: '8 24',
  },
  titleText: {
    color: 'white',
    fontSize: 40,
  },
  sectionTitleContainer: {
    backgroundColor: 'gray',
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 24,
  },
  sectionTitleText: {
    color: 'white',
    fontSize: 12,
  },
  sectionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '8 24',
  },
  sectionRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10 0',
  },
  sectionItem: {
    flex: 1,
    borderBottom: '0.5px solid black',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  sectionItemText: {
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: 4,
  },
  sectionItemHeader: {
    fontWeight: 'light',
    fontSize: 12,
    color: 'black',
  },
  tableHeaderText: {
    padding: '4 0',
    color: 'white',
    textAlign: 'center',
    fontSize: 10,
    fontWeight: 'bold',
  },
  tableBodyText: {
    padding: '8 4',
    fontSize: 10,
  },
  tableBorder: {
    borderColor: 'grey',
    borderWidth: 0,
  },
  footer: {
    height: '10%',
    width: '100%',
    padding: '0 24',
    marginBottom: 18,
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerText: {
    color: 'grey',
    fontSize: 8,
  },
});

const defaultParticipants = Array.from({ length: 10 }, () => ({
  firstName: '',
  lastName: '',
  email: '',
  employeeID: '',
}));

const isAHA = (familyId, certifications) => {
  if (Array.isArray(certifications)) {
    const familyCert = certifications.filter((cert) => cert.id === familyId);
    return familyCert[0]?.title === 'AHA' ?? false;
  }
  return false;
};

const dateFormat = 'MM/DD/yyyy h:mm a';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: roboto,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: robotoBold,
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
    {
      src: robotoLight,
      fontStyle: 'normal',
      fontWeight: 'light',
    },
  ],
});

const Roster = ({ events, customer, certifications, timezone }) => (
  <Document style={{ fontFamily: 'Roboto' }}>
    <Page size="TABLOID" wrap orientation="landscape">
      {events.map((evt, index) => {
        const { courseObj: course, eventParticipants, instructor, subcontracted } = evt;
        const { recertFreq } = course;
        const aha = isAHA(course.family, certifications);
        const participants = sliceParticipants(eventParticipants || defaultParticipants);
        const instructorLabel = subcontracted
          ? SUBCONTRACTED_INSTRUCTOR_LABEL
          : `${instructor.firstName} ${instructor.lastName}`;
        return evt.type !== MATERIAL_TYPE.SKILL_CHECK ? (
          <>
            <View style={[styles.titleContainer, { backgroundColor: aha ? '#AE2534' : '#E6773E' }]} break={index > 0}>
              <Text style={styles.titleText}>{aha ? 'AHA CLASS ROSTER' : 'SAFETY CLASS ROSTER'}</Text>
            </View>
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitleText}>CUSTOMER INFORMATION</Text>
            </View>
            <View style={styles.sectionContainer}>
              <View style={styles.sectionRow}>
                <View style={styles.sectionItem}>
                  <Text style={styles.sectionItemHeader}>Business Name:</Text>
                  <Text style={styles.sectionItemText}>{_.startCase((customer.name ?? '').toLowerCase())}</Text>
                </View>
                <View style={{ flex: 0.03 }} />
                <View style={styles.sectionItem}>
                  <Text style={styles.sectionItemHeader}>Cintas Customer #:</Text>
                  <Text style={styles.sectionItemText}>{evt.customerID}</Text>
                </View>
              </View>
              <View style={styles.sectionRow}>
                <View style={styles.sectionItem}>
                  <Text style={styles.sectionItemHeader}>Site Address:</Text>
                  <Text style={styles.sectionItemText}>
                    {`${customer.street} ${customer.city}, ${customer.region}`}
                  </Text>
                </View>
              </View>
            </View>
            {participants.map((chunk, i) => (
              <>
                <View style={styles.sectionTitleContainer} break={i > 0}>
                  <Text style={styles.sectionTitleText}>COURSE INFORMATION</Text>
                </View>
                <View style={styles.sectionContainer}>
                  <View style={styles.sectionRow}>
                    <View style={styles.sectionItem}>
                      <Text style={styles.sectionItemHeader}>Course:</Text>
                      <Text style={styles.sectionItemText}>{course.title}</Text>
                    </View>
                    <View style={{ flex: 0.03 }} />
                    <View style={styles.sectionItem}>
                      <Text style={styles.sectionItemHeader}>Date / Time of Class:</Text>
                      <Text style={styles.sectionItemText}>
                        {moment.utc(evt.startTime).tz(timezone).format(dateFormat)}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.sectionRow}>
                    <View style={styles.sectionItem}>
                      <Text style={styles.sectionItemHeader}>Lead Instructor:</Text>
                      <Text style={styles.sectionItemText}>{instructorLabel}</Text>
                    </View>
                    <View style={{ flex: 0.03 }} />
                    <View style={styles.sectionItem}>
                      <Text style={styles.sectionItemHeader}>Expiration Date:</Text>
                      <Text style={styles.sectionItemText}>
                        {recertFreq
                          ? moment.utc(evt.startTime).tz(timezone).add(recertFreq, 'months').format(dateFormat)
                          : ''}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.sectionRow}>
                    <View style={styles.sectionItem}>
                      <Text style={styles.sectionItemHeader}>Lead Instructor ID#:</Text>
                      <Text style={styles.sectionItemText}>{/* {evt.instructorID} */}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.sectionTitleContainer}>
                  <Text style={styles.sectionTitleText}>PARTICIPANTS</Text>
                </View>
                <View style={{ padding: '16 24' }}>
                  <Table data={chunk}>
                    <TableHeader
                      even
                      evenRowColor="grey"
                      includeLeftBorder={false}
                      includeRightBorder={false}
                      includeTopBorder={false}
                      includeBottomBorder={false}
                      textAlign="center"
                    >
                      <TableCell
                        includeLeftBorder={false}
                        includeRightBorder={false}
                        includeTopBorder={false}
                        includeBottomBorder={false}
                        style={[styles.tableBorder]}
                      >
                        <Text style={styles.tableHeaderText}>NAME</Text>
                      </TableCell>
                      <TableCell
                        includeLeftBorder={false}
                        includeRightBorder={false}
                        includeTopBorder={false}
                        includeBottomBorder={false}
                        style={[
                          styles.tableBorder,
                          { borderLeftWidth: 0.5, borderRightWidth: 0.5, borderColor: 'white' },
                        ]}
                      >
                        <Text style={styles.tableHeaderText}>EMPLOYEE ID</Text>
                      </TableCell>
                      <TableCell
                        includeLeftBorder={false}
                        includeRightBorder={false}
                        includeTopBorder={false}
                        includeBottomBorder={false}
                        style={[styles.tableBorder]}
                      >
                        <Text style={styles.tableHeaderText}>EMAIL</Text>
                      </TableCell>
                    </TableHeader>
                    <TableBody
                      includeLeftBorder={false}
                      includeRightBorder={false}
                      includeTopBorder={false}
                      includeBottomBorder={false}
                      renderTopBorder={false}
                    >
                      <DataTableCell
                        includeLeftBorder={false}
                        includeRightBorder={false}
                        includeTopBorder={false}
                        includeBottomBorder={false}
                        style={[
                          styles.tableBodyText,
                          styles.tableBorder,
                          { borderBottomWidth: 0.5, borderRightWidth: 0.5 },
                        ]}
                        getContent={(r) => `${r.rowNumber}.  ${r.firstName} ${r.lastName}`}
                      />
                      <DataTableCell
                        includeLeftBorder={false}
                        includeRightBorder={false}
                        includeTopBorder={false}
                        includeBottomBorder={false}
                        style={[styles.tableBodyText, styles.tableBorder, { borderBottomWidth: 0.5 }]}
                        getContent={(r) => r.employeeID}
                      />
                      <DataTableCell
                        includeLeftBorder={false}
                        includeRightBorder={false}
                        includeTopBorder={false}
                        includeBottomBorder={false}
                        style={[
                          styles.tableBodyText,
                          styles.tableBorder,
                          { borderBottomWidth: 0.5, borderLeftWidth: 0.5 },
                        ]}
                        getContent={(r) => r.email}
                      />
                    </TableBody>
                  </Table>
                </View>
              </>
            ))}
          </>
        ) : (
          <></>
        );
      })}
      <View style={styles.footer} fixed>
        <View style={{ flex: 1 }}>
          <Text
            style={{ fontSize: 10 }}
            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          />
        </View>
        <View style={{ flex: 6 }}>
          <Text style={styles.footerText}>
            The American Heart Association promotes knowledge and proficiency in CPR and has developed instructional
            material for this purpose. Use of these materials in an educational course does not represent course
            sponsorship by the American Heart Association and any fees charged for such a course does not represent
            income to the association.
          </Text>
        </View>
        <View style={{ flex: 4, paddingLeft: 20, justifyContent: 'flex-end' }}>
          <Image
            source={logo}
            style={{
              width: '100%',
              height: 68,
              textAlign: 'end',
              objectFit: 'scale-down',
            }}
          />
        </View>
      </View>
    </Page>
  </Document>
);

export default Roster;
