/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { change } from 'redux-form';

import { SAVE_MATERIALS, UPDATE_MATERIAL, UPDATE_MATERIAL_IMAGE_LINK, ADD_MATERIAL } from './actionTypes/materials';

import * as MaterialsAPI from '../../api/materials.api';
import { MATERIAL_TYPE } from '../../utils/consts';

export const saveMaterials = (materials) => ({
  type: SAVE_MATERIALS,
  materials,
});

const updateMaterial = (material) => ({
  type: UPDATE_MATERIAL,
  material,
});

const updateMaterialImageLink = (material, link, type) => ({
  type: UPDATE_MATERIAL_IMAGE_LINK,
  payload: {
    material,
    link,
    type,
  },
});

const addMaterial = (material) => ({
  type: ADD_MATERIAL,
  material,
});

export const createMaterial = (payload) => async (dispatch) => {
  try {
    const response = await MaterialsAPI.createMaterial(payload);
    if (response && response.data) {
      await dispatch(addMaterial(response.data));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const fetchMaterials = () => async (dispatch) => {
  try {
    const response = await MaterialsAPI.getAllMaterials();
    if (response) {
      const data = response.data || [];
      const mappedData = mapMaterials(data);
      dispatch(saveMaterials(mappedData));
      return mappedData;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const mapMaterials = (dataList) => {
  const mappedData = Object.values(MATERIAL_TYPE).reduce((red, type) => ({ ...red, [type]: {} }), {});
  mappedData.undefined = {};
  dataList.forEach((d) => {
    mappedData[`${d.type}`][d.id] = d;
  });
  return mappedData;
};

export const updateMaterialSlug = async (materialId, slug) => {
  const response = await MaterialsAPI.updateMaterialSlug(materialId, slug);
  if (response.data.includes('slug already exists')) {
    throw new Error('This slug is already in use by another material');
  }
};

export const updateMaterialById = (materialId, material) => async (dispatch) => {
  // Modify material object if needed
  const payload = {
    addons: material.addons,
    certGroup: material.certGroup,
    code: material.code,
    skillcheckID: material.skillcheckID,
    description: material.description,
    shortDescription: material.shortDescription,
    longDescription: material.longDescription,
    discount: parseFloat(material.discount),
    duration: parseInt(material.duration, 10),
    family: material.family,
    id: material.id,
    image: material.image,
    leadTime: parseInt(material.leadTime, 10),
    participants: {
      max: parseInt(material.participants?.max, 10),
      min: parseInt(material.participants?.min, 10),
    },
    category: material?.category,
    recertFreq: parseInt(material.recertFreq, 10),
    recertGroup: material.recertGroup,
    salesOrg: material.salesOrg,
    status: material.status,
    subcontractor: material.subcontractor,
    title: material.title,
    type: material.type,
    defaultParticipantsCount: material.defaultParticipantsCount ?? false,
    waiveCost: material.waiveCost,
    warehouseOverride: material.warehouseOverride || false,
    additionalParticipants: {
      id: material.additionalParticipants?.id,
      qty: parseInt(material.additionalParticipants?.qty, 10),
    },
    ...(material.media && {
      media: material.media?.map((item) => ({
        contentType: item.contentType,
        url: item.url,
      })),
    }),
    ...(material.mostPopularSortOrder && { mostPopularSortOrder: parseInt(material.mostPopularSortOrder, 10) }),
    ...(material.makeItADaySortOrder && { makeItADaySortOrder: parseInt(material.makeItADaySortOrder, 10) }),
    ...(material.feedbacks && { feedbacks: material.feedbacks }),
    ...(material.split && { split: material?.split }),
    slug: undefined,
  };

  try {
    const response = await MaterialsAPI.updateMaterial(materialId, payload);
    if (response && response.data) {
      dispatch(updateMaterial(response.data));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const uploadImage = (material, image, materialType) => async (dispatch) => {
  const formData = new FormData();
  formData.append('image', image);
  const payload = formData;
  try {
    const response = await MaterialsAPI.uploadImage(material, payload);
    if (response) {
      dispatch(change('CourseSettingsForm', 'image', response.data));
      dispatch(updateMaterialImageLink(material, response.data, materialType));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const halfUploadImage = (material, image) => async (dispatch) => {
  const formData = new FormData();
  formData.append('image', image);
  const payload = formData;
  try {
    const response = await MaterialsAPI.uploadImage(material, payload);
    if (response) {
      dispatch(change('CourseSettingsForm', 'image', response.data));
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};
