/* eslint-disable max-len */
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import React from 'react';
import MUIDataTable from 'mui-datatables';
import { COLORS, ORDER_BLOCKED } from '../../../../../../../utils/consts';

const getMuiTheme = (contentHeight) =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        style: {
          height: contentHeight,
        },
        responsiveScroll: {
          maxHeight: 'none',
          minHeight: contentHeight * 0.5,
        },
      },
      MUIDataTableHeadCell: {
        data: {
          color: COLORS.CINTAS_WHITE,
          fontSize: 12,
          textTransform: 'uppercase',
          textAlign: 'start',
        },
        fixedHeader: {
          backgroundColor: COLORS.CINTAS_BLUE,
          color: COLORS.CINTAS_WHITE,
        },
        sortActive: {
          color: COLORS.CINTAS_WHITE,
        },
      },
      MuiTableSortLabel: {
        root: {
          color: COLORS.CINTAS_WHITE,
          '&:hover': {
            color: COLORS.CINTAS_WHITE,
            '&& $icon': {
              opacity: 1,
              color: COLORS.CINTAS_WHITE,
            },
          },
          '&$active': {
            color: COLORS.CINTAS_WHITE,
            '&& $icon': {
              opacity: 1,
              color: COLORS.CINTAS_WHITE,
            },
          },
        },
      },
      MuiTableRow: {
        root: {
          '&$selected': {
            '&:hover': {
              backgroundColor: COLORS.CINTAS_LIGHT_GRAY,
              '&& $icon': {
                opacity: 1,
                color: COLORS.CINTAS_WHITE,
              },
            },
          },
        },
      },
      MUIDataTableToolbarSelect: {
        title: {
          visibility: 'hidden',
        },
      },
    },
  });

// MARK: this function is passed to React.memo() to compare
// the previous state with the new one and asseess whether they are the same (should-NOT rerender) or not (should rerender)
const areStatesEqual = (prevProps, nextProps) => {
  if (
    (prevProps.rows ?? []).length !== (nextProps.rows ?? []).length ||
    ((prevProps.rows ?? []).length && (nextProps.rows ?? []).length && prevProps.rows[0][1] !== nextProps.rows[0][1]) ||
    prevProps.customerSelection?.rowIndex !== nextProps.customerSelection?.rowIndex
  ) {
    return false;
  }
  return true;
};

// MARK: using React.memo() here to avoid unecessary re-renders of the table component
const CustomersTable = React.memo(
  ({
    customers,
    rows,
    initialSorting,
    customerSelection,
    onDoubleClick,
    onClick,
    columns,
    tableBodyHeight,
    contentHeight,
  }) => {
    const [currentSorting, setCurrentSorting] = React.useState({
      name: initialSorting,
      direction: 'asc',
    });

    const setRowProps = (row, dataIndex) => {
      const buildStyle = (overrides) => ({
        onDoubleClick: () => {
          if (customers[dataIndex].customer_blocked_for_orders === ORDER_BLOCKED.allowed) onDoubleClick(dataIndex);
        },
        style: { cursor: 'pointer', ...(overrides ?? {}) },
      });
      if (customers[dataIndex]) {
        if (customers[dataIndex].customer_blocked_for_orders === ORDER_BLOCKED.denied) {
          return buildStyle({ backgroundColor: COLORS.CINTAS_RED_OPAQUE_RGBA });
        }
      }
      if (customerSelection) {
        if (dataIndex === customerSelection.rowIndex) {
          return buildStyle({ backgroundColor: COLORS.CINTAS_LIGHT_BLUE });
        }
      }
      return buildStyle();
    };

    const onTableChange = (action, newTableState) => {
      switch (action) {
        case 'sort':
          setCurrentSorting(newTableState.sortOrder);
          break;
        default:
      }
    };

    const table = () => (
      <MUIDataTable
        key="table-customers"
        data={rows}
        columns={columns}
        options={{
          filter: false,
          download: false,
          print: false,
          responsive: 'scroll',
          tableBodyHeight,
          search: false,
          isRowSelectable: (dataIndex) => customers[dataIndex].customer_blocked_for_orders === ORDER_BLOCKED.allowed,
          selectableRows: 'single',
          selectableRowsHideCheckboxes: true,
          selectableRowsOnClick: true,
          onRowSelectionChange: onClick,
          viewColumns: true,
          setRowProps,
          sortOrder: currentSorting,
          onTableChange,
          customToolbarSelect: () => {},
        }}
        width="100%"
      />
    );

    return <MuiThemeProvider theme={getMuiTheme(contentHeight)}>{table()}</MuiThemeProvider>;
  },
  areStatesEqual,
);

export default CustomersTable;
