/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React from 'react';
import SimpleText from '../../../../Text/SimpleText';

const DeliveryStepValidationFailure = ({ step, steps, navToStep }) => {
  const stepLabel = _.startCase((steps?.[step] ?? '').toLowerCase());

  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;

  return (
    <div style={{ padding: 16 }}>
      {text(`The submission for the ${stepLabel} step is invalid.\nGo there to adjust values.`, {
        style: { fontSize: 16 },
      })}
    </div>
  );
};

export default DeliveryStepValidationFailure;
