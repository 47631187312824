import _ from 'lodash';

import { CLOSING_CALLBACK, HIDE_MODAL, REMOVE_CLOSING_CALLBACK, SHOW_MODAL } from './actionTypes/modals';

export const show = (id, data) => ({
  type: SHOW_MODAL,
  id,
  data,
});

export const showModal = (id, data) => (dispatch, getState) => {
  const {
    modals: { data: modalsData },
  } = getState();
  if (!_.find(modalsData, { id })) {
    dispatch(show(id, data));
  }
};

export const hideModal = (id) => ({
  type: HIDE_MODAL,
  id,
});

export const registerModalClosingValidation = (id, callback) => ({
  type: CLOSING_CALLBACK,
  id,
  callback,
});

export const unregisterModalClosingValidation = (id) => ({
  type: REMOVE_CLOSING_CALLBACK,
  id,
});
