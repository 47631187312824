/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import { change, Field, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';
import LoginWireFrame from './LoginWireFrame';
import MDTextInputField from '../../../components/Forms/FormComponents/MDTextInput/MDTextInputField';
import { formName } from '../../../components/Forms/LoginForm/LoginForm';
import SimpleText from '../../../components/Text/SimpleText';
import { COLORS } from '../../../utils/consts';
import { signupUser } from '../../../redux/actions/users.actions';
import { showModal } from '../../../redux/actions/modals.actions';

const fields = {
  email: 'email',
  password: 'password',
  confirmPassword: 'confirmPassword',
};

const validate = (values) => {
  const errors = {};

  Object.values(fields).forEach((field) => {
    if (!values[field]) {
      errors[field] = '';
    }
  });

  if (
    values[fields.password] &&
    values[fields.confirmPassword] &&
    values[fields.password] !== values[fields.confirmPassword]
  ) {
    errors[fields.confirmPassword] = "Passwords don't match";
  }

  if (values[fields.password] && values[fields.password].length < 6) {
    errors[fields.confirmPassword] = 'Password must be at least 6 digits';
  }

  return errors;
};

const SignUp = ({
  match: {
    params: { email },
  },
  errors,
  values,
  history,
}) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(change(formName, fields.email, email));
  }, []);

  const onSubmit = async () => {
    const success = await dispatch(signupUser(values));
    if (success) {
      history.push('/');
    }
    showToast(success);
  };

  const showToast = (success) => {
    dispatch(
      showModal('UPDATE_ORDER_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: success ? 'Sign up complete!' : 'Something went wrong completing sign up. Please, try again!',
        },
      }),
    );
  };

  const field = ({ id, label, disabled, autocomplete, type }) => (
    <Field
      id={id}
      name={id}
      type={type}
      variant="outlined"
      component={MDTextInputField}
      placeholder={label}
      label={label}
      required
      noHelperText
      disabled={disabled}
      autoComplete={autocomplete}
    />
  );

  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;

  const emailField = () =>
    field({
      id: 'email',
      label: 'Email Address',
      autocomplete: 'email',
      disabled: true,
    });

  const passField = () =>
    field({
      id: fields.password,
      type: 'password',
      label: 'Password',
      autoComplete: 'current-password',
      error: errors.password,
    });

  const confirmPassField = () =>
    field({
      id: fields.confirmPassword,
      label: 'Confirm Password',
      autoComplete: 'confirm-password',
      type: 'password',
      error: errors.confirmPassword,
    });

  const signUpBtn = (classes) => (
    <div style={{ margin: 8, width: '100%' }}>
      <Grid item xs={12}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={Object.values(errors ?? {}).length > 0}
          className={classes.submit}
          onClick={onSubmit}
        >
          Sign Up
        </Button>
      </Grid>
    </div>
  );

  return (
    <LoginWireFrame
      title="Sign Up"
      renderChildren={(classes) => (
        <>
          <Grid container spacing={2} style={{ width: '100%', marginTop: 50, padding: 0 }}>
            <Grid item xs={12}>
              {emailField()}
              {passField()}
              {confirmPassField()}
              {errors[fields.confirmPassword] &&
                text(errors[fields.confirmPassword], { color: COLORS.CINTAS_RED, padding: 0 })}
            </Grid>
          </Grid>
          {signUpBtn(classes)}
        </>
      )}
    />
  );
};

export default _.flow([
  connect((state) => ({
    errors: getFormSyncErrors(formName)(state),
    values: getFormValues(formName)(state),
  })),
  reduxForm({
    form: formName,
    validate,
  }),
  withRouter,
])(SignUp);
