/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { FormControlLabel, MenuItem } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { Field, getFormValues, initialize, reduxForm } from 'redux-form';
import BoldTitle from '../../Text/BoldTitle';
import MDRadioGroupField from '../../Forms/FormComponents/MDRadioGroup/MDRadioGroupField';
import FASRadio from '../../Forms/CustomFormComponents/FASRadio';
import StyledButton from '../../Button/StyledButton';
import Spinner from '../../SpinnerOverlay/Spinner';
import { COLORS, ORDERS_FILTERS_ENUM, ORDERS_FILTERS_LABELS, ORDERS_VIEWS } from '../../../utils/consts';
import {
  currentUserLocationsSelector,
  reportingTcisAllLocations,
  userInfoSelector,
} from '../../../redux/selectors/user.selectors';
import Container from '../../LayoutBuilders/Container';
import ContainerItem from '../../LayoutBuilders/ContainerItem';
import MDTextInputField from '../../Forms/FormComponents/MDTextInput/MDTextInputField';
import FASSelectField from '../../Forms/CustomFormComponents/FASSelectField';
import { regionsInfoSelector } from '../../../redux/selectors/settings.selectors';

const formName = 'OrdersFilters';

const multiSelectFields = [
  ORDERS_FILTERS_ENUM.cancelled,
  ORDERS_FILTERS_ENUM.tempContact,
  ORDERS_FILTERS_ENUM.unsigned,
];

const FASOrdersFilters = (props) => {
  const {
    loading,
    onFilter,
    regionLocationData,
    userLocIds,
    // new props
    filterInitialValues,
    minValues,
    maxValues,
    disabledFilters = [],
    hiddenFilters = [],
    formValues,
    newLineIdxs,
    view,
  } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!filterInitialValues) return;

    dispatch(initialize(formName, filterInitialValues));
  }, []);

  React.useEffect(() => {}, [formValues.region]);

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const header = (h) => <BoldTitle title={h} fontSize={18} />;

  const submitHandler = async () => {
    const startOfDay = new Date(formValues?.startTime);
    const endOfDay = new Date(moment.utc(formValues?.endTime).endOf('day').toISOString());

    const requestObj = {
      startTime: startOfDay,
      endTime: endOfDay,
      cancelled: formValues?.filterValue === 'cancelled',
      tempContact: formValues?.filterValue === 'tempContact',
      unsigned: formValues?.filterValue === 'unsigned',
      account: formValues?.account,
      customer: formValues?.customer,
    };

    if (regionLocationData) {
      const regionObj = _.find(regionLocationData, { region_code: formValues?.region });
      const locationObj = _.find(regionLocationData, { loc_id: formValues?.locations });
      let defaultLocs = regionObj?.region_code ? null : userLocIds;
      if (_.isEmpty(defaultLocs)) {
        defaultLocs = null;
      }
      requestObj.region = regionObj?.region_code;
      requestObj.locations =
        formValues?.locations !== 'All' && Boolean(locationObj?.loc_id) ? [locationObj?.loc_id] : defaultLocs;
    }
    onFilter(requestObj);
  };

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20,
        width: 20,
        padding: 0,
        margin: 0,
        color: COLORS.CINTAS_BLUE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        padding: 0,
      }}
    />
  );

  const entry = ({ label, child }) =>
    container({
      direction: 'column',
      children: [item({ flex: 12, children: [header(label)] }), item({ flex: 12, children: [child] })],
    });

  const input = (id) => (
    <Field
      id={id}
      name={id}
      component={MDTextInputField}
      size="small"
      type="text"
      variant="outlined"
      noBorderRadius
      noErrorLabel
      initiallyValidate
      disabled={loading || disabledFilters.includes(id)}
    />
  );

  const dateInput = (id) => (
    <Field
      id={id}
      name={id}
      component={MDTextInputField}
      inputProps={{ width: '100%', min: minValues?.[id], max: maxValues?.[id] }}
      type="date"
      size="small"
      variant="outlined"
      placeholder=""
      required
      noErrorLabel
      autoComplete="date"
      noBorderRadius
      disabled={loading || disabledFilters.includes(id)}
    />
  );

  const select = (id, options, disabled) => (
    <Field
      variant="outlined"
      placeholder="Printed Name"
      inputProps={{ width: '100%' }}
      id={id}
      name={id}
      component={FASSelectField}
      noErrorLabel
      displayEmpty
      style={{ textAlign: 'start', borderRadius: 0 }}
      disabled={loading || disabled || disabledFilters.includes(id)}
    >
      {options.map((t) => (
        <MenuItem key={t.key} value={t.key}>
          {t.label}
        </MenuItem>
      ))}
    </Field>
  );

  const multiSelectField = () => (
    <Field
      id="filterValue"
      variant="outlined"
      name="filterValue"
      required
      component={MDRadioGroupField}
      withHelperText={false}
      row
    >
      {_.map(
        [...multiSelectFields, 'None'].filter((k) => !hiddenFilters.includes(k)),
        (key) => (
          <FormControlLabel key={key} value={key} control={<FASRadio />} label={ORDERS_FILTERS_LABELS[key] ?? 'None'} />
        ),
      )}
    </Field>
  );

  const disableSubmit = () => {
    switch (view) {
      case ORDERS_VIEWS.nationalAccount:
      case ORDERS_VIEWS.ordersPanel:
        return !formValues?.region && !formValues?.account;
      case ORDERS_VIEWS.upcomingCustomer:
        return !formValues?.endTime;
      case ORDERS_VIEWS.pastCustomer:
        return !formValues?.startTime;
      default:
        return false;
    }
  };

  const submitButton = () =>
    item({
      flex: 4,
      children: [
        <StyledButton
          style={{
            width: '100%',
            height: 40,
            fontSize: 18,
            flex: 12,
          }}
          variant="contained"
          color="primary"
          buttonContent="Fetch Orders"
          handleButton={submitHandler}
          disabled={loading || disableSubmit()}
          endIcon={loading && spinner()}
        />,
      ],
    });

  const getSelectOptions = (key) => {
    if (![ORDERS_FILTERS_ENUM.region, ORDERS_FILTERS_ENUM.locations].includes(key)) return [];

    // national Account
    if (key === ORDERS_FILTERS_ENUM.region) {
      const uniqueRegions = {};
      regionLocationData.forEach((obj) => {
        if (!uniqueRegions[obj.region_code]) {
          uniqueRegions[obj.region_code] = { label: obj.region_name, key: obj.region_code };
        }
      });
      const listOfUniqueRegions = _.cloneDeep(Object.values(uniqueRegions));
      if (listOfUniqueRegions.length > 1) listOfUniqueRegions.unshift({ label: 'All', key: 'All' });
      return listOfUniqueRegions;
    }

    if (key === ORDERS_FILTERS_ENUM.locations) {
      const locationOptions = (regionLocationData ?? [])
        .filter((loc) => (formValues?.region === 'All' ? true : loc.region_code === formValues?.region))
        .map((loc) => ({ label: loc.loc_name, key: loc.loc_id }));

      if (locationOptions.length > 1) {
        return [{ label: 'All', key: 'All' }, ...locationOptions];
      }

      return locationOptions;
    }

    return [];
  };

  const getFilterComponent = (filterKey) => {
    if (hiddenFilters.includes(filterKey)) return <></>;

    switch (filterKey) {
      // text entry
      case ORDERS_FILTERS_ENUM.account:
        return input(ORDERS_FILTERS_ENUM[filterKey]);
      // date picker
      case ORDERS_FILTERS_ENUM.startTime:
      case ORDERS_FILTERS_ENUM.endTime:
        return dateInput(ORDERS_FILTERS_ENUM[filterKey]);
      // select field
      case ORDERS_FILTERS_ENUM.region:
      case ORDERS_FILTERS_ENUM.locations:
        return select(
          ORDERS_FILTERS_ENUM[filterKey],
          getSelectOptions(filterKey),
          filterKey === ORDERS_FILTERS_ENUM.locations && !formValues.region,
        );
      default:
        return <></>;
    }
  };

  const showMultiSelectField = multiSelectFields.filter((k) => !hiddenFilters.includes(k)).length > 0;
  const filterEntries = Object.keys(ORDERS_FILTERS_ENUM).filter(
    (k) => !hiddenFilters.includes(k) && !multiSelectFields.includes(k),
  );

  if (newLineIdxs) {
    newLineIdxs.forEach((idx) => filterEntries.splice(idx, 0, ''));
  }

  return container({
    style: { padding: 16, width: '98%', alignItems: 'end' },
    spacing: 2,
    children: [
      // !hiddenFilters.
      ...filterEntries.map((filterKey) =>
        item({
          flex: 4,
          children: entry({ label: ORDERS_FILTERS_LABELS[filterKey], child: getFilterComponent(filterKey) }),
        }),
      ),
      showMultiSelectField && item({ flex: 4, children: entry({ label: 'Filter', child: multiSelectField() }) }),
      submitButton(),
    ],
  });
};

export default _.flow([
  connect((state) => {
    const user = userInfoSelector(state);
    const reportingTciLocations = reportingTcisAllLocations(state);
    const regions = Object.values(regionsInfoSelector(state));
    const userLocIdsFromState = currentUserLocationsSelector(state);

    const userLocations = user?.loc_ids ?? [];
    const allLocations = _(reportingTciLocations).concat(userLocations).flatten().uniq().value();

    const filteredRegions = _.filter(regions, (reg) => _.includes(allLocations, reg.loc_id));

    return {
      regionLocationData: filteredRegions,
      userLocIds: userLocIdsFromState,
      formValues: getFormValues(formName)(state),
    };
  }),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    initialValues: {},
  }),
])(FASOrdersFilters);
