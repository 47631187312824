import React from 'react';
import WaiveFeeIcon from '@mui/icons-material/MoneyOffRounded';
import TerritoryChangeIcon from '@mui/icons-material/ExploreOutlined';
import MissingTrainerIcon from '@mui/icons-material/PersonRemoveRounded';
import { YES_NO } from './notifications.consts';
import { COLORS } from '.';
// import WaiveFeeIcon from '@mui/icons-material/PriceCheck';
// import MissingTrainerIcon from '@mui/icons-material/TransferWithinAStationRounded';
// import TerritoryChangeIcon from '@mui/icons-material/PersonPinCircle';

/* eslint-disable import/prefer-default-export */
export const TASK_TYPE = {
  WAIVE_FEE: 'WAIVE_FEE',
  TERRITORY_CHANGE: 'TERRITORY_CHANGE',
  MISSING_TRAINER: 'MISSING_TRAINER',
};

export const TASKS_LABELS = {
  [TASK_TYPE.WAIVE_FEE]: 'Waive Fee',
  [TASK_TYPE.TERRITORY_CHANGE]: 'Territory Change',
  [TASK_TYPE.MISSING_TRAINER]: 'Missing Trainer',
};

export const TASKS_HEADERS = TASKS_LABELS;

export const TASK_RESOLUTIONS = {
  [TASK_TYPE.WAIVE_FEE]: { ACCEPTED: 'ACCEPTED', REJECTED: 'REJECTED' },
};

export const TASK_SUBTYPE = {
  RESCHEDULE: 'RESCHEDULE',
  CANCEL: 'CANCEL',
};

export const TASK_SUBTYPE_LABELS = {
  [TASK_SUBTYPE.RESCHEDULE]: 'Rescheduled Event',
  [TASK_SUBTYPE.CANCEL]: 'Cancelled Event',
};

export const TASK_ADMIN_SETTINGS = {
  manager: YES_NO,
  whenSent: 'input::number',
  inApp: YES_NO,
  email: YES_NO,
};

export const TASK_ADMIN_SETTINGS_DEFAULTS = {
  manager: TASK_ADMIN_SETTINGS.manager.YES,
  whenSent: 0,
  inApp: TASK_ADMIN_SETTINGS.inApp.YES,
  email: TASK_ADMIN_SETTINGS.email.YES,
};

export const TASK_ADMIN_SETTINGS_LABEL = {
  manager: 'Manager',
  whenSent: 'When sent (Hr)',
  inApp: 'In App',
  email: 'Email',
};

const iconStyle = (size) => ({
  fontSize: size ?? 32,
  color: COLORS.CINTAS_BLUE,
});

export const TASK_TYPE_ICONS = {
  [TASK_TYPE.WAIVE_FEE]: <WaiveFeeIcon style={iconStyle(36)} />,
  [TASK_TYPE.TERRITORY_CHANGE]: <TerritoryChangeIcon style={iconStyle()} />,
  [TASK_TYPE.MISSING_TRAINER]: <MissingTrainerIcon style={iconStyle()} />,
};

export const TASK_RECEIVER_TYPES = {
  CREATOR: 'CREATOR',
  INSTRUCTOR: 'INSTRUCTOR',
  MANAGER: 'MANAGER',
};
