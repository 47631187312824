/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import _ from 'lodash';
import { Field, reduxForm, submit, getFormValues, change, arrayPush } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, connect, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import React from 'react';
// import AddIcon from '@material-ui/icons/Add';
import { Typography, Box } from '@material-ui/core';
import Container from '../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../LayoutBuilders/ContainerItem';
import {
  EVENT_TYPE,
  BOOKING_MODE,
  MATERIAL_TYPE,
  DEFAULT_CURRENCY,
  ROLE_ACCESSES, // NOTES_DISPLAY_BORDER,
} from '../../../../../utils/consts/index';
import AddonItem from '../EventDialogControl/AddonItem';
import CartSummaryForm from './FormComponent/CartSummaryForm';
import CheckboxField from '../../../../Forms/FormComponents/Checkbox/CheckboxField';
import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';
import FASTitle from '../../../../Forms/CustomFormComponents/FASTtle';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import AddCustomAddonButton from '../../../../Button/AddCustomAddonButton';
import { listingsSelector } from '../../../../../redux/selectors/sap.selectors';
import { materialsDataSelector } from '../../../../../redux/selectors/materials.selector';
import { hasUserAccessSelector } from '../../../../../redux/selectors/user.selectors';
// import NationalAccountCourseDisplay from '../../../../NationalAccountDashboard/NationalAccountCourseDisplay';
// import { NATIONAL_ACCOUNT_NOTE_TYPE } from '../../../../../utils/consts/nationalAccount.consts';

const formName = 'AddEventDialog';
// const addIconSize = 40;
const useStyles = makeStyles(() => ({
  rightGird: {
    borderLeft: '1px solid #DEDEDE',
  },
  scrollBox: {
    display: 'flex',
    minWidth: '300px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#d6dee1',
      borderRadius: '20px',
      border: '3px solid transparent',
      backgroundClip: 'content-box',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a8bbbf',
    },
  },
}));

const getPricing = (pricing, customerDistributionChannel) => {
  switch (customerDistributionChannel) {
    case '10':
      return parseFloat(pricing.Location_Price).toFixed(2);
    case '11':
      return parseFloat(pricing.Regional_Price).toFixed(2);
    case '12':
      return parseFloat(pricing.National_Price).toFixed(2);
    case '90':
      return parseFloat(pricing.Inter_Company_Price).toFixed(2);
    default:
      return parseFloat(pricing.Location_Price).toFixed(2);
  }
};

const AddOnPageForm = ({ eventType, mode, curEvent }) => {
  const { customer } = curEvent;
  const classes = useStyles();
  const dispatch = useDispatch();
  const materialsInfo = useSelector(materialsDataSelector);
  const addOnPricing = useSelector((state) => state.pricing.addons);
  const additionalParticipantsPricing = useSelector((state) => state.pricing.additionalParticipants);
  const hasEditAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.bookEvent]));

  const buildCourseIdentifier = (onsite, idx) => `${onsite.course.id}${idx}`;
  const listingsData = useSelector(listingsSelector);
  // const addonsMaterials = materials.filter((m) => m.type === MATERIAL_TYPE.SKILL_CHECK).map((m) => m.code);
  // console.log('TEST', listingsData.filter((d) => !d.inlcuded && addonsMaterials.includes(d.Material)));

  const loadAddons = (addonData, materialOptionals) =>
    _.reduce(addonData, (tot, addon) => tot.concat(materialsInfo[MATERIAL_TYPE.ADD_ON][addon] ?? {}), []).map(
      (addon) => ({
        ...(addon ?? {}),
        ...(materialOptionals ? { isCustom: !materialOptionals.find((mId) => mId === addon?.id) } : {}),
        noAvailableListing: !((_.find(listingsData, { Material: addon.code }) ?? {}).inlcuded ?? true),
      }),
    );

  const loadAllAddOns = () => {
    const loaded = {};
    if (!curEvent?.courses) return {};
    curEvent.courses.forEach((onsite, idx) => {
      if (onsite.course.type === MATERIAL_TYPE.COURSE) {
        let materialObjectOptionals;
        if (mode !== BOOKING_MODE.booking) {
          materialObjectOptionals = onsite?.courseObj?.addons?.optional ?? [];
        }
        loaded[buildCourseIdentifier(onsite, idx)] = {
          required: loadAddons(onsite.course.addons && onsite.course.addons.required),
          optional: loadAddons(onsite.course.addons && onsite.course.addons.optional, materialObjectOptionals),
        };
      }
    });
    return loaded;
  };

  const loadAdditionalParticipants = (data) =>
    _.reduce(
      data,
      (tot, item) => tot.concat(materialsInfo[MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS][item?.id ?? ''] ?? {}),
      [],
    ).map((item) => ({
      ...(item ?? {}),
    }));

  const loadAllAdditionalParticipants = () => {
    const loaded = {};
    if (!curEvent?.courses) return {};
    curEvent.courses.forEach((onsite, idx) => {
      if (onsite.course.type === MATERIAL_TYPE.COURSE && onsite.additional_participants?.[0]?.id) {
        loaded[buildCourseIdentifier(onsite, idx)] = loadAdditionalParticipants([onsite.additional_participants[0]]);
      }
    });

    return loaded;
  };

  const loadedAddons = loadAllAddOns();
  const loadedAdditionalParticipants = loadAllAdditionalParticipants();

  const onAddCustomAddon = (courseIdx, addon) => {
    if (mode !== BOOKING_MODE.booking) {
      const alreadyAdded = (curEvent.courses[courseIdx]?.addons ?? []).map((a) => a.id);
      if (alreadyAdded.includes(addon.id)) {
        const indx = alreadyAdded.indexOf(addon.id);
        if (indx !== -1) {
          dispatch(change(formName, `courses[${courseIdx}].addons[${indx}]`, addon));
        }
      } else {
        const currOptional = (curEvent.courses[courseIdx]?.addons ?? []).find((a) => a.id === addon.id);
        const optional = (curEvent.courses[courseIdx]?.courseObj?.addons?.optional ?? []).find((a) => a === addon.id);
        if (optional && !currOptional) {
          dispatch(
            change(formName, `courses[${courseIdx}].addons`, [...(curEvent.courses[courseIdx]?.addons ?? []), addon]),
          );
        }
      }
    }
    dispatch(
      change(
        formName,
        `courses[${courseIdx}].course.addons.optional`,
        _.uniq([...(curEvent.courses[courseIdx]?.course?.addons?.optional ?? []), addon.id]),
      ),
    );
  };

  const title = (txt) => <FASTitle title={txt} />;
  const fieldTitle = (txt, style) => <FASFieldTitle title={txt} style={style} />;

  const addCustomAddonBtn = (onsite, courseIdx) => (
    <AddCustomAddonButton
      onAdd={(addon) => onAddCustomAddon(courseIdx, addon)}
      saveData={(data) => {
        dispatch(arrayPush(formName, 'customAddonOptions', data));
      }}
      dataPath={`${formName}.values.customAddonOptions`}
      onsite={onsite}
      courseIdx={courseIdx}
      customer={customer}
      bookingMode={mode}
      eventType={eventType}
      disabled={eventType === EVENT_TYPE.OPEN_ENROLLMENT || !hasEditAccess}
      marginLeft={8}
      marginTop={
        (loadedAddons[buildCourseIdentifier(onsite, courseIdx)]?.required ?? []).length > 0 ||
        (loadedAddons[buildCourseIdentifier(onsite, courseIdx)]?.optional ?? []).length > 0 ||
        (loadedAdditionalParticipants[buildCourseIdentifier(onsite, courseIdx)] ?? []).length > 0
          ? '10%'
          : 0
      }
    />
  );

  const addOnItem = (onsite, addon, i, courseIdx, isOptional) => {
    const { pricing } = _.find(addOnPricing, { material: addon.code }) ?? {};
    const marketPricing = _.find(pricing, { Customer: '-1' });
    const customerPricing = _.find(pricing, { Customer: customer.sold_to });
    const selectedPricing = customerPricing || marketPricing || {};
    let price = Number(getPricing(selectedPricing, customer.distribution_channel) ?? 0);
    price = Number.isNaN(price) ? 0.0 : price;
    const currency = selectedPricing.Curr ?? DEFAULT_CURRENCY;
    const preexisting = onsite.addons && _.find(onsite.addons, { id: addon.id });
    const participants =
      Number(onsite.participants ?? '0') +
      (onsite.additional_participants ? Number(onsite.additional_participants[0]?.qty ?? '0') : 0);
    const priceValue = preexisting && preexisting.price ? preexisting.price.amount : price;

    return (
      Boolean(addon?.id) && (
        <ContainerItem key={`${courseIdx}${addon.id}${i}`}>
          <AddonItem
            eventType={eventType}
            key={`${courseIdx}${addon.id}${i}`}
            mode={mode}
            addonItem={{
              ...addon,
              require: !isOptional,
              courseidx: courseIdx,
              participants: preexisting ? preexisting.qty : participants,
              course: `${onsite.course.title}`,
              price: priceValue,
              currency,
            }}
            course={onsite}
            customer={customer}
            idx={i}
            price={priceValue}
            currency={currency}
            selected={!addon.noAvailableListing}
            notEditable={addon.noAvailableListing}
          />
        </ContainerItem>
      )
    );
  };

  const additionalParticipantItem = (onsite, item, i, courseIdx) => {
    if (onsite.additional_participants?.[0]?.qty > 0) {
      const { pricing } = _.find(additionalParticipantsPricing, { material: item.code }) ?? {};
      const marketPricing = _.find(pricing, { Customer: '-1' });
      const customerPricing = _.find(pricing, { Customer: customer.sold_to });
      const selectedPricing = customerPricing || marketPricing || {};
      let price = Number(getPricing(selectedPricing, customer.distribution_channel) ?? 0);
      price = Number.isNaN(price) ? 0.0 : price;
      const currency = selectedPricing.Curr ?? DEFAULT_CURRENCY;
      const preexisting =
        onsite.additional_participants &&
        _.find(onsite.additional_participants, { id: onsite.additional_participants[0].id });
      const priceValue = preexisting && preexisting.price ? preexisting.price.amount : price;
      const { qty } = onsite.additional_participants[0];

      return (
        <ContainerItem key={`${courseIdx}${item.id}${i}`}>
          <AddonItem
            eventType={eventType}
            key={`${courseIdx}${item.id}${i}`}
            mode={mode}
            addonItem={{
              ...item,
              require: true,
              courseidx: courseIdx,
              participants: qty ?? 0,
              qty: qty ?? 0,
              course: `${onsite.course.title}`,
              priceValue,
              currency,
            }}
            course={onsite}
            customer={customer}
            idx={i}
            price={priceValue}
            currency={currency}
            isAdditionalParticipants
            selected
          />
        </ContainerItem>
      );
    }
    return <></>;
  };

  const onSiteAddons = () => (
    <Grid item xs={12}>
      {title('Select course materials')}
      {_.map(curEvent.courses, (onsite, idx) => {
        const reqAddons = loadedAddons[buildCourseIdentifier(onsite, idx)].required;
        let optAddons = loadedAddons[buildCourseIdentifier(onsite, idx)].optional;

        const additionalParticipants = loadedAdditionalParticipants[buildCourseIdentifier(onsite, idx)];
        if (mode !== BOOKING_MODE.booking && !onsite.new) {
          // if we are not booking and we are editing instead,
          // addons not selected while booking should not be displayed.
          // filter out the addons that do not exist in the already-booked course
          optAddons = optAddons.map((a) => {
            if (a.isCustom) return a;
            const preexisting = onsite.addons && _.find(onsite.addons, { id: a.id });
            if (preexisting) return a;
            return '';
          });
        }

        return (
          <React.Fragment key={`${onsite.course.title}${idx}`}>
            {onsite.course && (
              <Typography variant="body1" style={{ fontWeight: 'bold' }} component="h6">
                {(onsite.course.title ?? '').toUpperCase()}
              </Typography>
            )}
            {reqAddons.length < 1 && optAddons.length < 1 && additionalParticipants?.length < 1 ? (
              <>
                {fieldTitle('No course materials', { marginLeft: 8 })}
                {eventType === EVENT_TYPE.OPEN_ENROLLMENT || !hasEditAccess ? <></> : addCustomAddonBtn(onsite, idx)}
                <Box height={30} />
              </>
            ) : (
              <Grid className={classes.scrollBox}>
                {_.map(additionalParticipants, (additionalParticipant, i) =>
                  additionalParticipantItem(onsite, additionalParticipant, i, idx),
                )}
                {_.map(reqAddons, (addon, i) => addOnItem(onsite, addon, i, idx))}
                {_.map(optAddons, (addon, i) => addOnItem(onsite, addon, i, idx, true))}
                {eventType === EVENT_TYPE.OPEN_ENROLLMENT || !hasEditAccess ? <></> : addCustomAddonBtn(onsite, idx)}
              </Grid>
            )}
          </React.Fragment>
        );
      })}
    </Grid>
  );

  const buildCheckboxField = ({ id, label, titleVal, containerStyle }) => (
    <ContainerItem flex={12} style={containerStyle}>
      {titleVal && title(titleVal)}
      <Field name={id} component={CheckboxField} label={label} />
    </ContainerItem>
  );

  const getConfirmationLabel = (confirmationSent, isSigned) => {
    if (confirmationSent) {
      if (isSigned) return 'A previous course confirmation was signed for this event.';
      return 'A previous course confirmation was sent for this event, but not yet signed.';
    }
    return 'A course confirmation has never been sent to the customer for this event.';
  };

  const confirmationStatus = () =>
    fieldTitle(getConfirmationLabel(curEvent.confirmationsentDate, curEvent.signature), { fontWeight: 'normal' });

  const externalCalendarCheckbox = () => (
    <>
      {buildCheckboxField({
        id: 'addExternalCalendar',
        label: 'Add event to my personal calendar',
      })}
    </>
  );

  const quoteStatusCheck = () =>
    buildCheckboxField({
      id: 'status',
      label: 'Active Quote',
    });

  return (
    <Container justify="space-evenly" spacing={4}>
      <ContainerItem flex={12}>
        {[EVENT_TYPE.ON_SITE, EVENT_TYPE.QUOTE].includes(eventType) && onSiteAddons()}
        {[EVENT_TYPE.ON_SITE, EVENT_TYPE.QUOTE].includes(eventType) && (
          <>
            {/* {courseConfirmationNote()} */}
            {mode !== BOOKING_MODE.quote
              ? [mode === BOOKING_MODE.editing && confirmationStatus(), externalCalendarCheckbox()]
              : [quoteStatusCheck()]}
            <ContainerItem flex={12}>
              {title('Internal Notes')}
              <Field
                id="notes"
                name="notes"
                component={MDTextInputField}
                multiline
                rows={4}
                size="small"
                type="text"
                variant="outlined"
                placeholder="No notes added"
                disabled={!hasEditAccess}
                label="Notes"
                autoComplete="notes"
                noBorderRadius
              />
            </ContainerItem>
          </>
        )}
      </ContainerItem>
      <ContainerItem className={classes.rightGird} flex={12} style={{ marginLeft: 16 }}>
        <CartSummaryForm eventType={eventType} isQuote={eventType.includes(EVENT_TYPE.QUOTE)} />
      </ContainerItem>
    </Container>
  );
};

// export default AddOnPageForm;
export default _.flow([
  connect((state) => {
    const curEvent = getFormValues(formName)(state);
    return {
      curEvent,
    };
  }),
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: submit,
  }),
])(AddOnPageForm);
