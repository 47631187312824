/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { connect, useSelector } from 'react-redux';
import React from 'react';
import FASMUIDataTable from '../CustomMUIDataTable/FASMUIDataTable';
import { ccyFormat, removeLeadingZeros } from '../../../utils/helpers';
import ConfirmationNavButton from '../../Button/ConfirmationNavButton';
import {
  COLORS,
  ORDERS_TABLE_COLUMNS,
  ORDERS_TABLE_COLUMNS_LABELS,
  ORDER_STATUS_DISPLAY,
  ROLE_ACCESSES,
  SUBCONTRACTED_INSTRUCTOR_LABEL,
} from '../../../utils/consts';
import DownloadRosterPDFButton from '../../Button/DownloadRosterPDFButton';
import { timezoneSelector, timezoneNameSelector } from '../../../redux/selectors/utils.selectors';
import { makeStatusDisplay } from '../Orders/OrdersTable';
import EventSummaryButton from '../../Button/EventSummaryButton';
import EditOrderButton from '../../Button/EditOrderButton';
import { buildSelectedEvent } from '../../../utils/event.utils';
import { hasUserAccessSelector, userInfoSelector } from '../../../redux/selectors/user.selectors';

const buttonsColumns = [
  ORDERS_TABLE_COLUMNS.eventSummaryButton,
  ORDERS_TABLE_COLUMNS.editButton,
  ORDERS_TABLE_COLUMNS.orderConfirmationButton,
];

const FASOrdersTable = ({
  regionLocationData,
  orders,
  isLoading,
  hasQueried,
  requestingCancelled,
  // new props
  hiddenColumns,
  // connect props
  hasEditAccess,
  hasOwnCalendar,
  user,
}) => {
  const emptyTableMessage = hasQueried
    ? isLoading
      ? 'Loading Orders. Please wait.'
      : 'Sorry, no matching records found'
    : '';
  const selectedTimezone = useSelector(timezoneSelector);
  const selectedTimezoneAbbrev = useSelector(timezoneNameSelector);

  const setRowProps = (row) => {
    const buildStyle = (overrides) => ({
      style: { ...(overrides ?? {}) },
    });
    if ([ORDER_STATUS_DISPLAY.CANCELLED, ORDER_STATUS_DISPLAY.RESCHEDULED].includes(row?.[11])) {
      return buildStyle({ fontStyle: 'italic', backgroundColor: COLORS.CINTAS_LIGHT_GRAY });
    }
    return buildStyle();
  };

  const options = {
    filter: true,
    filterType: 'dropdown',
    setRowProps,
    customSearch: (searchQuery, currentRow) => {
      let isFound = false;
      currentRow.forEach((col) => {
        if (col && col.toString().indexOf(searchQuery) >= 0) {
          isFound = true;
        }
      });
      return isFound;
    },
    selectableRows: 'none',
    sortOrder: {
      name: 'Start Date',
      direction: 'desc',
    },
    downloadOptions: {
      filename: 'Orders.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    textLabels: {
      body: {
        noMatch: emptyTableMessage,
      },
    },
  };

  const getTableColumns = () => {
    const columns = [];
    Object.keys(ORDERS_TABLE_COLUMNS).forEach((col) => {
      const isButton = buttonsColumns.includes(col);
      columns.push({
        name: ORDERS_TABLE_COLUMNS_LABELS[col],
        options: {
          display: !hiddenColumns.includes(col),
          filter: !isButton,
          searchable: !isButton,
          sort: !isButton,
          viewColumns: !isButton,
          download: !isButton,
        },
      });
    });
    return columns;
  };

  const orderSummaryButton = (id) => <EventSummaryButton orderId={id} />;

  const downloadRosterButton = (id) => <DownloadRosterPDFButton orderId={id} />;

  const editOrderButton = (order) => (
    <EditOrderButton
      orderId={order?.id}
      selectedEvent={{
        ...buildSelectedEvent(order, selectedTimezone),
        instructorObj: order?.instructor,
      }}
    />
  );

  const orderConfirmationButton = (id) => <ConfirmationNavButton orderId={id} />;

  const getCustomerName = (order) => {
    let customerName = 'Missing Customer';
    order?.events?.forEach((ev) => {
      customerName = ev.title || customerName;
    });
    return customerName;
  };

  const makeReasonDisplay = (order) => {
    if ((order?.cancelled ?? false) || (order?.rescheduledOrder ?? false)) {
      return order?.cancellationReason ?? 'Missing Reason';
    }
    return 'N/A';
  };

  const getDeliveredAmount = (order) => {
    // First check for cancellation fee
    if ((order.cancelled ?? false) || (order?.rescheduledOrder ?? false)) {
      return order?.cancellationFee ?? false ? order.cancellationFee : 0;
    }

    // If order hasn't been completed yet, return 0
    if (!order?.deliverySignature ?? false) {
      return 'N/A';
    }

    return -1;
  };

  const rows = _.map(orders, (order) => {
    const displayTime = order?.events?.[0]?.startTime
      ? moment.utc(order?.events?.[0]?.startTime).tz(selectedTimezone).format('hh:mm a')
      : 'Missing Time';
    const displayDate = order?.events?.[0]?.startTime
      ? moment.utc(order?.events?.[0]?.startTime).tz(selectedTimezone).format('MM/DD/YYYY')
      : 'Missing Date';
    const locationObject = _.find(regionLocationData, { loc_id: order?.locID ?? false });
    const regionDisplay = locationObject?.region_name ?? 'Missing Region';
    const locationDisplay = locationObject?.loc_name ?? 'Missing Location';
    const soldToDisplay = removeLeadingZeros(order?.customer) || 'Missing Customer';
    let instructorDisplay = order?.instructor
      ? `${order?.instructor?.firstName ?? ''} ${order?.instructor?.lastName}`
      : 'Missing Instructor Info';
    if (order?.events?.[0]?.instructorID?.startsWith('sub')) {
      // if the instructor is a subcontractor --> default to subcontractor instructor label (External Vendor)
      instructorDisplay = SUBCONTRACTED_INSTRUCTOR_LABEL.toUpperCase();
    }
    const orderStatus = makeStatusDisplay(order, requestingCancelled, undefined, undefined, true);
    const reasonDisplay = makeReasonDisplay(order);
    const customerName = getCustomerName(order);
    const contactObj = (order?.contact ?? false) || (order?.temporaryContact ?? {});
    const materialNumbers = order?.events.map((ev) => removeLeadingZeros(ev.courseCode)).join(', ');
    const scheduledAmountDisplay = order?.revenue ?? 'Missing Revenue';
    const deliveredAmount = getDeliveredAmount(order);
    const deliveredAmountDisplay =
      deliveredAmount === -1 ? scheduledAmountDisplay : deliveredAmount === 'N/A' ? 'N/A' : ccyFormat(deliveredAmount);
    const contactNames = (contactObj?.name ?? 'Missing Missing').split(' ');
    const createdByDisplay = order?.createdBy
      ? `${order?.createdBy?.firstName ?? ''} ${order?.createdBy?.lastName ?? ''}`
      : 'Missing';
    const complete = Boolean(order.deliverySignature);
    const showOrderSummaryIcon = order?.id && !order?.cancelled && !order?.rescheduledOrder;
    const showOrderConfirmationIcon = order.id && !complete;
    const canAccessThisOrder = !hasOwnCalendar || (hasOwnCalendar && order?.instructor?.instructorID === user.uid);
    const showEditButton =
      order.id && !complete && !order?.cancelled && !order?.rescheduledOrder && hasEditAccess && canAccessThisOrder;
    const showRosterPdfBtn = !showOrderConfirmationIcon && complete;
    const createdAt = moment.utc(order.createdAt).format('MM/DD/YYYY');

    return [
      regionDisplay,
      locationDisplay,
      instructorDisplay,
      customerName,
      soldToDisplay,
      order?.POnumber ?? 'N/A',
      materialNumbers,
      ccyFormat(scheduledAmountDisplay),
      deliveredAmountDisplay,
      displayDate,
      displayTime,
      selectedTimezoneAbbrev,
      orderStatus,
      reasonDisplay,
      order?.route ?? 'Missing Route',
      contactNames[0] ?? 'Missing First Name',
      contactNames[1] ?? 'Missing Last Name',
      _.isEmpty(contactObj?.Phones?.[0]?.Phone) ? 'Missing Phone' : contactObj?.Phones?.[0]?.Phone,
      contactObj.email ?? 'Missing Email',
      createdByDisplay,
      createdAt,
      // 'TODO: Store Display',
      order?.distribution_channel ?? 'N/A',
      showOrderSummaryIcon ? orderSummaryButton(order?.id) : '',
      showEditButton ? editOrderButton(order) : '',
      showOrderConfirmationIcon
        ? orderConfirmationButton(order?.id)
        : showRosterPdfBtn
          ? downloadRosterButton(order?.id)
          : '',
    ];
  });

  return (
    <FASMUIDataTable
      data={rows}
      columns={getTableColumns()}
      options={options}
      root={{
        padding: '5px 15px 5px',
      }}
    />
  );
};

export default _.flow([
  connect((state) => {
    const hasOwnCalendar = hasUserAccessSelector(state, [ROLE_ACCESSES.ownCalendar]);
    const hasEditAccess = hasUserAccessSelector(state, [ROLE_ACCESSES.editEvent]);
    const user = userInfoSelector(state);

    return {
      hasOwnCalendar,
      hasEditAccess,
      user,
    };
  }),
])(FASOrdersTable);
