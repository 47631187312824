import _ from 'lodash';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import * as dates from 'date-arithmetic';
import React from 'react';
import { CALENDAR_VIEWS } from '../../../utils/consts/index';
import Month from './Month';

import classes from './styles.module.css';

const Year = ({ date, ...props }) => {
  const firstMonth = dates.startOf(date, 'year');

  const navigateHandler = (dateClicked) => {
    props.onHandleNavigation(dateClicked.toDate(), CALENDAR_VIEWS.WEEK);
  };

  return (
    <div className={classes.year}>
      {_.map(_.range(0, 12), (i) => (
        <Month key={i + 1} date={dates.add(firstMonth, i, 'month')} onClick={navigateHandler} />
      ))}
    </div>
  );
};

Year.range = (date) => [dates.startOf(date, 'year')];

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -1, 'year');

    case navigate.NEXT:
      return dates.add(date, 1, 'year');

    default:
      return date;
  }
};

Year.title = (date, { localizer }) => localizer.format(date, 'YYYY');

export default Year;
