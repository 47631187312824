/* eslint-disable import/no-cycle */
import { Paper } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../../routes/MainViews/EventSummaryView/EventSummaryView';
import { COLORS } from '../../utils/consts';
import FASFieldTitle from '../Forms/CustomFormComponents/FASFieldTitle';

const Card = ({ title, titleStyle, style, elevation, children, ...rest }) => {
  const cardClasses = useStyles();

  const titleTxt = (txt, stl) => <FASFieldTitle title={txt} style={{ fontWeight: 'bold', ...(stl ?? {}) }} />;

  return (
    <Paper
      className={cardClasses.paper}
      elevation={elevation ?? 3}
      {...rest}
      style={{ width: '100%', ...(style ?? {}) }}
    >
      {title && titleTxt(title, { fontSize: 20, color: COLORS.CINTAS_BLUE, ...(titleStyle ?? {}) })}
      {children}
    </Paper>
  );
};

export default Card;
