/* eslint-disable import/no-cycle */
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StyledButton from '../../../components/Button/StyledButton';
import FASTitle from '../../../components/Forms/CustomFormComponents/FASTtle';
import { materialCategoriesSelector } from '../../../redux/selectors/settings.selectors';
import { newCertButtonStyle } from './CertificationsView';
import SettingsFrame from './SettingsFrame';
import MaterialClassificationTable from '../../../components/Settings/Classifications/MaterialClassificationTable';
import { fetchConfigs } from '../../../redux/actions/settings.actions';

const AddonClassificationsView = (props) => {
  const {
    materialCategories,
    match: { path },
    history,
  } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchConfigs());
  }, []);

  return (
    <SettingsFrame
      path={path}
      actions={[
        <StyledButton
          variant="contained"
          color="primary"
          type="submit"
          width="160px"
          buttonContent="Create New"
          handleButton={() => history.push('/admin/addonClassifications/newClassification')}
          style={newCertButtonStyle}
        />,
      ]}
    >
      <FASTitle title="Add-on Classifications" />
      <MaterialClassificationTable classifications={materialCategories} />
    </SettingsFrame>
  );
};

export default _.flow([
  connect((state) => ({
    materialCategories: materialCategoriesSelector(state),
  })),
  withRouter,
])(AddonClassificationsView);
