/* eslint-disable import/no-cycle */
import { IconButton } from '@material-ui/core';
import _ from 'lodash';
import SwapHorizRounded from '@material-ui/icons/SwapHorizRounded';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions/modals.actions';
import InstructorSwapDialog from '../Calendar/CalendarComponents/EventPopper/InstructorSwapDialog';

const SwapInstructorButton = ({ order, background, iconColor, size }) => {
  const dispatch = useDispatch();

  const requestSwitchInstructor = () => {
    const modalName = 'INSTRUCTOR_SWAP_ALERT';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 16 },
          hideCancel: true,
          disableBackdropClick: true,
          maxWidth: 'md',
          title: 'SELECT INSTRUCTOR',
          content: <InstructorSwapDialog modalName={modalName} preloadedOrder={order} />,
        },
      }),
    );
  };

  return (
    <IconButton color="primary" onClick={requestSwitchInstructor} style={{ padding: 2, backgroundColor: background }}>
      <SwapHorizRounded style={{ color: iconColor, height: size, width: size }} />
    </IconButton>
  );
};

export default _.flow()(SwapInstructorButton);
