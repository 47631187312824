/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { Avatar } from '@material-ui/core';
import React from 'react';
import CameraAltOutlined from '@material-ui/icons/CameraAltOutlined';
import { useDispatch } from 'react-redux';
import { COLORS, MAX_PROFILE_IMG_SIZE } from '../../utils/consts';
import ShapeFactory from '../Shapes/ShapeFactory';
import { showModal } from '../../redux/actions/modals.actions';
import { updateProfilePicture } from '../../redux/actions/users.actions';

const UserProfilePicture = ({ user, size, marginTop, marginBottom }) => {
  const dispatch = useDispatch();
  const [hovering, setHovering] = React.useState(false);
  const profileImgSrc = user?.auth?.photoURL;
  const fileInputRef = React.useRef(null);

  const onUpdateImage = async (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const imageFile = e.target.files[0];
    if (imageFile.size > MAX_PROFILE_IMG_SIZE) {
      showToast(false, 'Image file is too large in size. Please upload a smaller file');
      return;
    }
    const formData = new FormData();
    formData.append('profileImage', imageFile);
    try {
      const success = await dispatch(updateProfilePicture(formData));
      showToast(success);
    } catch (er) {
      showToast(false);
    }
  };

  const showToast = (success, msg) => {
    dispatch(
      showModal('UPDATE_IMG_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message:
            msg ??
            (success
              ? 'Profile picture successfully updated!'
              : 'Something went wrong updating the profile. Please, try again!'),
        },
      }),
    );
  };

  const overlay = () => (
    <ShapeFactory
      onClick={() => fileInputRef?.current?.click()}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      shape="circle"
      width={size}
      height={size}
      style={{
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        top: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        transition: '.5s ease',
        textAlign: 'center',
        opacity: hovering ? 1 : 0,
        backgroundColor: COLORS.CINTAS_BLACK_OPAQUE_RGBA,
      }}
    >
      <CameraAltOutlined style={{ margin: 'auto', color: COLORS.CINTAS_WHITE }} />
    </ShapeFactory>
  );

  const profileImage = () => (
    <div style={{ position: 'relative', textAlign: 'center' }}>
      <Avatar
        src={profileImgSrc}
        onClick={() => fileInputRef?.current?.click()}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          margin: 'auto',
          marginTop,
          marginBottom,
          height: size,
          width: size,
          opacity: 1,
          backgroundColor: profileImgSrc ? COLORS.CINTAS_WHITE : null,
        }}
      />
      {overlay()}
    </div>
  );

  const imgInput = () => (
    <input
      key="pdf-input-hidden"
      ref={fileInputRef}
      hidden
      type="file"
      accept=".jpg,.jpeg,.png"
      onChange={onUpdateImage}
    />
  );

  return (
    <div style={{ position: 'relative' }}>
      {imgInput()}
      {profileImage()}
    </div>
  );
};

export default UserProfilePicture;
