import { SET_SPINNER_VISIBILITY, SET_TIMEZONE, SET_INITIAL_DATA_LOADED } from './actionTypes/utils';

export const setSpinnerVisible = (spinnerVisible, title = null) => ({
  type: SET_SPINNER_VISIBILITY,
  spinnerVisible,
  title,
});

export const setTimezone = (tz) => ({
  type: SET_TIMEZONE,
  tz,
});

export const setInitialDataLoaded = (loaded) => ({
  type: SET_INITIAL_DATA_LOADED,
  loaded,
});
