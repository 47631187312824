/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { v4 } from 'uuid';
import * as ExternalAPI from '../../api/externalsite.api';

import { EVENT_TYPE, MATERIAL_TYPE } from '../../utils/consts';

export const currentEventsStateToSchedulingPayload = (orderId, instructorUid, eventsState) => {
  const onSiteCourses = eventsState?.courses?.filter(
    (item) => item?.course?.type === MATERIAL_TYPE.COURSE || item.course.type === MATERIAL_TYPE.SKILL_CHECK,
  );

  const items = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const course of onSiteCourses) {
    if (course.course.type === MATERIAL_TYPE.COURSE) {
      const startTime = moment.utc(course?.courseStartTime ?? course.startTime);
      const endTime = moment.utc(course?.courseEndTime ?? course.endTime);
      const courseToAppend = {
        id: course.id ?? v4(),
        durationInMinutes: course?.course?.duration,
        materialId: course?.course?.id,
        startTime: startTime.format(),
        endTime: endTime.format(),
      };
      let skillcheckToAppend;
      if (course.skillcheck && onSiteCourses.every((item) => item.course.id !== course?.skillcheck?.id)) {
        courseToAppend.endTime = moment
          .utc(endTime)
          .subtract(course.skillcheck.duration * course.skillcheckParticipants, 'minutes')
          .format();
        skillcheckToAppend = {
          id: course?.skillcheck?.eventId ?? v4(),
          durationInMinutes: course.skillcheck.duration * course.skillcheckParticipants,
          materialId: course?.skillcheck?.id,
          startTime: moment
            .utc(endTime)
            .subtract(course.skillcheck.duration * course.skillcheckParticipants, 'minutes')
            .format(),
          endTime: endTime.format(),
        };
      }
      items.push(courseToAppend);
      if (skillcheckToAppend) {
        items.push(skillcheckToAppend);
      }
      continue;
    }

    if (course.course.type === MATERIAL_TYPE.SKILL_CHECK) {
      items.push({
        id: course.id ?? v4(),
        durationInMinutes: course?.course?.duration * course?.participants_count,
        materialId: course?.course?.id,
        startTime: moment.utc(course?.startTime).format(),
        endTime: moment
          .utc(course?.startTime)
          .add(course?.course?.duration * course?.participants_count, 'minutes')
          .format(),
      });
    }
  }

  const payload = {
    customerId: eventsState?.customer?.sold_to,
    instructorUid,
    startTime: eventsState?.startTime || items?.[0]?.startTime,
    items,
    orderId,
  };

  return payload;
};

export const fromSchedulingResponseToCoursesCreationPayload = ({ schedulingResponse, eventsState, instructorId }) => {
  const newCourses = [];
  schedulingResponse?.scheduling?.forEach((scheduling) => {
    const correspondingCourse = eventsState.courses?.find((c) => c?.course?.id === scheduling?.materialId);
    const alreadyAddedCourse = newCourses.find(
      (c) => c?.course?.id === scheduling?.materialId && c.schedulingId === scheduling?.id,
    );
    if (correspondingCourse && !alreadyAddedCourse) {
      return newCourses.push({
        ...correspondingCourse,
        skillcheck: undefined,
        courseStartTime: scheduling?.startTime,
        courseEndTime: scheduling?.endTime,
        // id is the eventId
        id: eventsState?.courses?.find((c) => c?.id === scheduling?.id)?.id,
        schedulingId: scheduling?.id,
      });
    }
    if (correspondingCourse && alreadyAddedCourse) {
      alreadyAddedCourse.courseEndTime = scheduling?.endTime;
      return;
    }
    const correspondingSkillcheck = eventsState.courses?.find((c) => c?.skillcheck?.id === scheduling?.materialId);
    const alreadyAddedSkillcheck = newCourses.find(
      (c) => c?.skillcheck?.id === scheduling?.materialId && c.schedulingId === scheduling?.id,
    );
    if (correspondingSkillcheck && !alreadyAddedSkillcheck) {
      return newCourses.push({
        ...correspondingSkillcheck,
        ...correspondingSkillcheck?.skillcheckPrice,
        courseStartTime: scheduling?.startTime,
        courseEndTime: scheduling?.endTime,
        title: correspondingSkillcheck?.skillcheck?.title,
        course: correspondingSkillcheck?.skillcheck,
        participants: correspondingSkillcheck?.skillcheckParticipants,
        // id is the eventId
        id: eventsState?.courses?.find((c) => c?.id === scheduling?.id)?.id,
        schedulingId: scheduling?.id,
        selectedAddons: {},
      });
    }
    if (correspondingSkillcheck && alreadyAddedSkillcheck) {
      alreadyAddedSkillcheck.courseEndTime = scheduling?.endTime;
      return;
    }
    newCourses?.push({
      schedulingId: scheduling?.id,
      allDay: false,
      blockType: 'FIRM',
      eventType: EVENT_TYPE.TIME_BLOCK,
      instructorID: instructorId,
      reason: scheduling?.id,
      courseStartTime: scheduling?.startTime,
      courseEndTime: scheduling?.endTime,
      title: scheduling?.title,
      course: {
        type: MATERIAL_TYPE.TIME_BLOCK,
        id: scheduling?.id,
        duration: moment
          .duration(moment(scheduling?.courseStartTime).diff(moment(scheduling?.courseStartTime)))
          .asMinutes(),
      },
    });
  });
  return newCourses;
};

export const fromSchedulingResponseToCourseUpdatePayload = ({ schedulingResponse, eventsState, instructorId }) => {
  const newCourses = [];
  schedulingResponse?.scheduling?.forEach((scheduling) => {
    const correspondingCourse = eventsState.courses?.find(
      (c) => c?.course?.id === scheduling?.materialId && (c.id ? c.id === scheduling?.id : true),
    );
    const alreadyAddedCourse = newCourses.find(
      (c) => c?.course?.id === scheduling?.materialId && c.schedulingId === scheduling?.id,
    );
    if (correspondingCourse && !alreadyAddedCourse) {
      return newCourses.push({
        ...correspondingCourse,
        skillcheck: undefined,
        courseStartTime: scheduling?.startTime,
        courseEndTime: scheduling?.endTime,
        // id is the eventId
        id: eventsState?.courses?.find((c) => c?.id === scheduling?.id)?.id,
        schedulingId: scheduling?.id,
      });
    }
    if (correspondingCourse && alreadyAddedCourse) {
      alreadyAddedCourse.courseEndTime = scheduling?.endTime;
      return;
    }
    newCourses?.push({
      schedulingId: scheduling?.id,
      allDay: false,
      blockType: 'FIRM',
      eventType: EVENT_TYPE.TIME_BLOCK,
      instructorID: instructorId,
      reason: scheduling?.id,
      courseStartTime: scheduling?.startTime,
      courseEndTime: scheduling?.endTime,
      title: scheduling?.title,
      course: {
        type: MATERIAL_TYPE.TIME_BLOCK,
        id: scheduling?.id,
        duration: moment
          .duration(moment(scheduling?.courseStartTime).diff(moment(scheduling?.courseStartTime)))
          .asMinutes(),
      },
    });
  });
  return newCourses;
};

export const getUpdatedEventsStateFromSchedulingResponse = (instructorUid, eventsState, schedulingResponse, mode) => {
  const fees = {
    afterHoursFee: {
      Total: schedulingResponse?.afterHoursFee,
      AfterHours: schedulingResponse?.afterHours,
      PricePerAfterHour: schedulingResponse?.pricePerAfterHour,
      AfterHoursMaterialCode: schedulingResponse?.afterHoursMaterialCode,
    },
    travelFee: {
      DistanceInMiles: schedulingResponse.travel.distanceInMiles,
      DurationInMinutes: schedulingResponse.travel.durationInMinutes,
      Fee: schedulingResponse.travel.fee,
      MilesOverTheLimit: schedulingResponse.travel.milesOverTheLimit,
      OriginalDurationInMinutes: schedulingResponse.travel.originalDurationInMinutes,
      OverFee: schedulingResponse.travel.overFee,
      SetupTime: schedulingResponse.travel.setupTime,
      TotalFee: schedulingResponse.travel.totalFee,
      FlatFeeMaterialCode: schedulingResponse.travel.flatFeeMaterialCode,
      OverFeeMaterialCode: schedulingResponse.travel.overFeeMaterialCode,
      PricePerMile: schedulingResponse.travel.pricePerMile,
    },
  };
  const payload = {
    schedulingResponse,
    eventsState,
    instructorId: instructorUid,
  };
  return {
    ...eventsState,
    courses:
      mode === 'create'
        ? fromSchedulingResponseToCoursesCreationPayload(payload)
        : fromSchedulingResponseToCourseUpdatePayload(payload),
    fees,
  };
};

// eslint-disable-next-line consistent-return
export const calculateScheduling = async (orderId, instructorUid, curEvent, mode) => {
  try {
    const payload = currentEventsStateToSchedulingPayload(orderId, instructorUid, curEvent);

    const res = await ExternalAPI.calculateScheduling(payload);

    if (res?.data) {
      return getUpdatedEventsStateFromSchedulingResponse(instructorUid, curEvent, res.data, mode);
    }
  } catch (error) {
    throw error;
  }
};

export const getFeesFromEventsState = (eventsState) => ({
  afterHoursFee: {
    hours: eventsState?.fees?.afterHoursFee?.AfterHours,
    total: eventsState?.fees?.afterHoursFee?.Total,
    price: {
      amount: eventsState?.fees?.afterHoursFee?.PricePerAfterHour,
    },
    pricingMaterialCode: eventsState?.fees?.afterHoursFee?.AfterHoursMaterialCode,
  },
  travelInfo: {
    durationInMinutes: eventsState?.fees?.travelFee?.DurationInMinutes,
    distanceInMiles: eventsState?.fees?.travelFee?.DistanceInMiles,
    fee: eventsState?.fees?.travelFee?.Fee,
    milesOverTheLimit: eventsState?.fees?.travelFee?.MilesOverTheLimit,
    originalDurationInMinutes: eventsState?.fees?.travelFee?.OriginalDurationInMinutes,
    overFee: eventsState?.fees?.travelFee?.OverFee,
    setupTime: eventsState?.fees?.travelFee?.SetupTime,
    totalFee: eventsState?.fees?.travelFee?.TotalFee,
    overFeeMaterialCode: eventsState?.fees?.travelFee?.OverFeeMaterialCode,
    flatFeeMaterialCode: eventsState?.fees?.travelFee?.FlatFeeMaterialCode,
    pricePerMile: eventsState?.fees?.travelFee?.PricePerMile,
  },
});
