/* eslint-disable max-len */

import { BOOKING_MODE } from '..';

export const excludeModes = (toExclude) => Object.values(BOOKING_MODE).filter((bm) => !toExclude.includes(bm));
export const allModes = () => Object.values(BOOKING_MODE);

export const buildRule = ({ editable, visible }) => ({
  visible: visible ?? allModes(),
  editable: editable ?? allModes(),
});

export const isVisible = (rule, mode) => (rule.visible ?? []).includes(mode);
export const isEditable = (rule, mode) => (rule.editable ?? []).includes(mode);
