import Immutable from 'seamless-immutable';

import { SET_SPINNER_VISIBILITY, SET_TIMEZONE, SET_INITIAL_DATA_LOADED } from '../actions/actionTypes/utils';

const initialState = Immutable({
  spinner: {
    isVisible: false,
    title: null,
  },
  tz: {},
  initialDataLoaded: false,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SPINNER_VISIBILITY:
      return state.setIn(['spinner', 'isVisible'], action.spinnerVisible).setIn(['spinner', 'title'], action.title);
    case SET_TIMEZONE:
      return state.set('tz', action.tz);
    case SET_INITIAL_DATA_LOADED:
      return state.set('initialDataLoaded', action.loaded);
    default:
      return state;
  }
};
