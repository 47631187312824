import Immutable from 'seamless-immutable';
import { unitsOfTime } from '../../utils/consts';

import {
  UPDATE_USER_LOCATION,
  UPDATE_CONFIGS,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  SAVE_REGIONS,
} from '../actions/actionTypes/settings';

const initialState = Immutable({
  location: [],
  regions: [],
  configs: {
    participantsEditTime: {
      duration: 7,
      unit: unitsOfTime.days,
    },
    materialCategories: [],
    cancelReasons: [],
    defaultOfficeHours: {},
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_LOCATION:
      return state.set('location', action.payload);
    case UPDATE_CONFIGS:
      return state.set('configs', action.configs);
    case CREATE_CATEGORY:
      return state.setIn(
        ['configs', 'materialCategories'],
        [...(state.configs?.materialCategories ?? []), action.category],
      );
    case UPDATE_CATEGORY:
      return state.setIn(
        ['configs', 'materialCategories'],
        (state?.configs?.materialCategories ?? []).map((c, i) => (i === action.index ? action.category : c)),
      );
    case SAVE_REGIONS:
      return state.set('regions', action.data);
    default:
      return state;
  }
};
