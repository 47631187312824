/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-use-before-define */
import React, { useRef } from 'react';
import queryString from 'query-string';
import _ from 'lodash';
import { connect } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import PrintIcon from '@material-ui/icons/Print';
import { withRouter } from 'react-router-dom';
import { Paper, Box, Divider, TextField, IconButton, Typography } from '@material-ui/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import ReactToPrint from 'react-to-print';
import CartSummaryForm, {
  cleanRows,
} from '../../components/Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CartSummaryForm';
import Spinner from '../../components/SpinnerOverlay/Spinner';
import * as MaterialActions from '../../redux/actions/materials.actions';
import * as ModalsActions from '../../redux/actions/modals.actions';
import * as CustomerActions from '../../redux/actions/customers.actions';
import * as OrderActions from '../../redux/actions/orders.actions';
// import EventOverview from '../../components/Calendar/CalendarComponents/EventDetailsDialog/DialogSubcomponents/EventOverview';
// import AddOnTable from './EventSummaryView/AddOnTable';
import { useStyles, getOSEventInfo } from './EventSummaryView/EventSummaryView';
import Container from '../../components/LayoutBuilders/Container';
import ContainerItem from '../../components/LayoutBuilders/ContainerItem';
import FASTitle from '../../components/Forms/CustomFormComponents/FASTtle';
import SimpleText from '../../components/Text/SimpleText';
import LegalText from '../../utils/consts/StaticText/LegalText';
import StyledButton from '../../components/Button/StyledButton';
import FASCheckboxField from '../../components/Forms/CustomFormComponents/FASCheckboxField/FASCheckboxField';
import logo from '../../assets/images/logo_ready_for_workday.png';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import { COLORS, DATE_SHORT_FORMAT, QUOTES_STATUS, QUOTE_EXPIRATION_DAYS, TIME_FORMAT } from '../../utils/consts';
import CintasSignPad from '../../components/SignaturePad/CintasSignPad';
import { prepareCustomerData } from '../../redux/reducers/customers.reducer';
import { fetchCustomerQuoteById } from '../../redux/actions/customers.actions';
import Quote from '../../models/Quote';
import { calcMaxParticipants } from '../../utils/event.utils';

const OrderConfirmationView = ({
  match: {
    params: { orderId, customerId, quoteId },
  },
  history,
  dispatch,
  location,
  tz,
}) => {
  const isQuote = Boolean(quoteId);
  const sigRef = React.useRef();
  const searchQuery = queryString.parse(location.search ?? '') ?? {};
  const readOnlyUrlParam = searchQuery.ronly;
  const userVersionUrlParam = searchQuery.usrv;
  const readOnly = readOnlyUrlParam === 'true' || isQuote;
  const isAnonymousQuote = Boolean(isQuote && userVersionUrlParam !== 'true');
  const tableClasses = useStyles();
  const componentRef = useRef();
  const [inactiveQuote, setInactiveQuote] = React.useState(false);
  const [order, setOrder] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [confirming, setConfirming] = React.useState(false);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [titleInput, setTitleInput] = React.useState(order?.titleconfirm ?? '');
  const [titleError, setTitleError] = React.useState(false);
  const [signatureBlob, setSignatureBlob] = React.useState();
  const underline = (color) => (
    <Divider style={{ margin: 0, padding: 0, background: color ?? COLORS.CINTAS_DARK_GRAY }} />
  );
  const contact = order?.contact ? order?.contact : order?.temporaryContact;

  React.useEffect(async () => {
    if (!isQuote) {
      await loadOrder();
    } else {
      await loadQuote();
    }
    return () => {};
  }, []);

  const getSigPad = () => sigRef?.current ?? { isEmpty: () => {} };

  const prepareEvent = (e) => {
    const { courseObj } = e;
    const additionalParticipantsEntered = e.additional_participants ?? [];
    const maxoutRegularParticipants =
      !_.isEmpty(courseObj?.additionalParticipants ?? {}) && !_.isEmpty(additionalParticipantsEntered);
    let nonExtraParticipants = e.participants_count;
    if (maxoutRegularParticipants) {
      const courseMax = Number(calcMaxParticipants(courseObj, !_.isEmpty(e.secondaryTci ?? ''), 0));
      nonExtraParticipants = courseMax;
    }

    return {
      ...e,
      title: (e.courseObj ?? {}).title ?? '',
      participants_count: nonExtraParticipants,
    };
  };

  const loadOrder = async () => {
    const orderResp = await dispatch(OrderActions.fetchOrderConfirmationOrSummary(orderId ?? '', !readOnly));
    if (!orderResp || !orderResp?.events) return;
    const materials = MaterialActions.mapMaterials((orderResp.events ?? []).map((e) => ({ ...(e.courseObj ?? {}) })));
    const contactTitle = orderResp.titleconfirm ? orderResp.titleconfirm : '';
    setTitleInput(contactTitle);
    await dispatch(MaterialActions.saveMaterials(materials));
    await dispatch(
      CustomerActions.saveCustomers(
        prepareCustomerData([{ ...(orderResp.customerObj ?? {}), id: orderResp.customer }]),
      ),
    );
    cleanRows();
    const fees = { travelFee: orderResp.travelInfo, afterHoursFee: orderResp.afterHoursFee };
    getOSEventInfo(orderResp.events ?? [], materials ?? [], undefined, true, fees);
    setOrder({
      id: orderId,
      ...orderResp,
      events: (orderResp.events ?? []).map(prepareEvent),
    });
    setLoading(false);
  };

  const loadQuote = async () => {
    try {
      const quoteResp = await dispatch(fetchCustomerQuoteById(customerId, quoteId, isAnonymousQuote));
      if (!quoteResp || !quoteResp?.data) return;
      if (quoteResp.status === QUOTES_STATUS.inactive || new Quote(quoteResp).isExpired) {
        setInactiveQuote(quoteResp);
        return;
      }
      const materials = MaterialActions.mapMaterials((quoteResp.data ?? []).map((e) => ({ ...(e.courseObj ?? {}) })));
      await dispatch(MaterialActions.saveMaterials(materials));
      await dispatch(
        CustomerActions.saveCustomers(prepareCustomerData([{ ...(quoteResp.customerObj ?? {}), id: customerId }])),
      );
      cleanRows();
      const fees = { travelFee: quoteResp.travelInfo };
      getOSEventInfo(quoteResp.data ?? [], materials ?? {}, undefined, true, fees);
      const parsedQuote = Quote.toEventData({
        quote: quoteResp,
        customer: quoteResp.customerObj ?? {},
        materialsInfo: materials ?? {},
        maintainAddons: true,
      });
      setOrder({
        id: quoteId,
        cintasOrderID: quoteId,
        ...parsedQuote,
        events: (parsedQuote.events ?? []).map(prepareEvent),
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmOrder = async () => {
    setConfirming(true);
    if (titleInput.length < 1) {
      showErorr('Please enter a title before confirming order!');
      setTitleError(true);
      setConfirming(false);
      return;
    }
    const contactName = contact?.FirstName.concat(' ', contact?.LastName ?? '');
    const payload = {
      namewhoconfirm: contactName,
      emailwhoconfirm: _.toLower(contact?.Email ?? ''),
      dateconfirm: moment().utc().toISOString(),
      titleconfirm: titleInput,
    };
    const formdata = new FormData();
    formdata.append('namewhoconfirm', payload.namewhoconfirm);
    formdata.append('emailwhoconfirm', payload.emailwhoconfirm);
    formdata.append('dateconfirm', payload.dateconfirm);
    formdata.append('titleconfirm', payload.titleconfirm);
    formdata.append('confirmationSignature', signatureBlob);

    const neworder = { ...order, ...payload };

    const confirmed = await dispatch(OrderActions.confirmOrder(orderId, formdata, neworder));
    setConfirming(false);
    setOrder({ ...neworder, signature: confirmed, confirmationSignature: URL.createObjectURL(signatureBlob) });
    if (!confirmed) showErorr('Something went wrong confirming the order. Order has NOT been confirmed.');
  };

  const showErorr = (msg) =>
    dispatch(
      ModalsActions.showModal('CONFIRM_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: { message: msg },
      }),
    );

  const handleAcceptedTerms = (val) => {
    setAcceptedTerms(val);
  };

  const handleTitleChange = (event) => {
    setTitleInput(event.target.value);
    if (event.target.value.length > 0) {
      setTitleError(false);
    }
  };

  const spinner = (style) => <Spinner customStyle={{ marginTop: '30%' }} {...(style ?? {})} />;
  const title = (txt, size, secondary, align) => (
    <FASTitle
      title={txt}
      customStyle={{
        fontSize: size ?? 24,
        padding: 0,
        margin: 0,
        color: secondary ? '#565656' : null,
        textAlign: align,
      }}
    />
  );
  const text = (txt, s) => <SimpleText txt={txt} style={{ fontSize: 14, ...s }} />;
  const PaddedContainer = ({ children, leftPadding }) => (
    <ContainerItem flex={12} style={{ marginTop: 22, paddingLeft: leftPadding ? 10 : 0 }}>
      {children}
    </ContainerItem>
  );

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const cancellationTerms = (noPadding) => (
    <PaddedContainer leftPadding={!noPadding}>
      {title(LegalText.cancellationTerms.title, 16)}
      {text(LegalText.cancellationTerms.body, { marginTop: 10 })}
    </PaddedContainer>
  );

  const quoteExpirationWarning = () => (
    <PaddedContainer leftPadding>
      {title('Expiration Details', 16)}
      {text(
        `This quote is valid for a total of ${QUOTE_EXPIRATION_DAYS} days and it will expire on the expiration date listed above.`,
        { marginTop: 10 },
      )}
    </PaddedContainer>
  );

  const checkBox = () => (
    <FASCheckboxField
      hideErrorHelper
      value={acceptedTerms}
      onChange={handleAcceptedTerms}
      style={{ margin: 0, padding: 0, paddingLeft: 10 }}
    />
  );

  const termsAndConditions = () => (
    <PaddedContainer>
      {text(LegalText.termsAndConditionsAcknowledgement.body, { marginBottom: 10 })}
      <Container justifyContent="start" spacing={1}>
        {!order.signature && <ContainerItem flex="auto">{checkBox()}</ContainerItem>}
        {!order.signature && <ContainerItem flex="auto">{text('Accept')}</ContainerItem>}
        <ContainerItem flex="auto">
          <a href="/termsAndConditions" target="_blank">
            {text(`${LegalText.termsAndConditions.title}`)}
          </a>
        </ContainerItem>
      </Container>
    </PaddedContainer>
  );

  const customerInfoRow = ({ slot1, slot2 }) =>
    container({
      spacing: 1,
      style: { justifyContent: 'space-between' },
      children: [
        item({
          flex: 8,
          children: slot1,
        }),
        item({
          flex: 4,
          children: slot2,
        }),
        item({
          flex: 12,
          style: { padding: 0, margin: 0 },
          children: underline(),
        }),
      ],
    });

  const customerInformation = () => (
    <PaddedContainer>
      {customerInfoRow({
        slot1: text(
          `Customer Name: ${_.capitalize(contact?.FirstName ?? '')} ${_.capitalize(contact?.LastName ?? '')}`,
        ),
        slot2: container({
          style: { justifyContent: 'start' },
          children: [
            item({
              flex: 'auto',
              children: text('Title* : '),
            }),
            item({
              flex: 'auto',
              style: { paddingLeft: 10 },
              children: (
                <TextField
                  hiddenLabel
                  error={titleError}
                  disabled={order.signature}
                  InputProps={{ disableUnderline: true, placeholder: order.signature ? 'Title' : 'Insert Title' }}
                  id="Title"
                  defaultValue={titleInput}
                  onBlur={handleTitleChange}
                  variant="standard"
                  size="small"
                />
              ),
            }),
          ],
        }),
      })}
      <Box height={12} />
      {customerInfoRow({
        slot1: text(`Customer Email : ${_.toLower(contact?.Email ?? '')}`),
        slot2: text(`Date : ${moment().utc().tz(tz).format(DATE_SHORT_FORMAT)}`),
      })}
    </PaddedContainer>
  );

  const whoSigned = (selectedOrder) => (
    <>
      <PaddedContainer leftPadding>
        {title('Signed by:', 16)}
        {text(
          `Order electronically signed by ${_.startCase(selectedOrder.namewhoconfirm.toLowerCase())}, ${selectedOrder.titleconfirm}, on ${moment.utc(selectedOrder.dateconfirm).tz(tz).format('LL')} at ${moment.utc(selectedOrder.dateconfirm).tz(tz).format('LTS')}`,
        )}
      </PaddedContainer>
    </>
  );

  const noSignature = () => (
    <>
      <PaddedContainer leftPadding>
        {title('Signed by:', 16)}
        {text('Order confirmation has not been signed')}
      </PaddedContainer>
    </>
  );

  const signaturePad = () => (
    <div style={{ width: '100%', marginTop: 20 }}>
      {text('Signature', { marginBottom: 12 })}
      <CintasSignPad
        noMargin
        width="100%"
        imgUrl={order?.signature && order?.confirmationSignature}
        setSigPad={(sp) => {
          sigRef.current = sp;
        }}
        getSigPad={getSigPad}
        onCleared={() => setSignatureBlob(undefined)}
        clearConfItemType="signature"
        onEnd={() => {
          getSigPad()
            .getCanvas()
            .toBlob((blob) => {
              setSignatureBlob(blob);
            });
        }}
      />
    </div>
  );

  const confirmBtn = () => (
    <ContainerItem flex={12} style={{ textAlign: 'center', paddingTop: 20 }}>
      <StyledButton
        buttonContent={
          confirming
            ? spinner({
                spinnerStyle: { height: 14, width: 14, color: 'white' },
                customStyle: {
                  maxHeight: 14,
                  maxWidth: 14,
                  marginTop: 5,
                  marginBottom: 5,
                },
              })
            : 'Confirm'
        }
        handleButton={confirming ? null : confirmOrder}
        variant="contained"
        color="primary"
        disabled={!acceptedTerms || !signatureBlob || getSigPad()?.isEmpty()}
        style={{
          width: '100%',
          borderRadius: 2,
        }}
      />
    </ContainerItem>
  );

  const confirmationMsg = () => (
    <ContainerItem flex={12} style={{ textAlign: 'center', paddingTop: 35 }}>
      {title('Order has been Confirmed', { textAlign: 'center' })}
    </ContainerItem>
  );

  const logoImg = () => (
    <ContainerItem flex={12} style={{ textAlign: 'center', marginBottom: 40 }}>
      <img src={logo} alt="Cintas Logo" width="20%" />
    </ContainerItem>
  );

  const paper = ({ children, style }) => (
    <Paper className={tableClasses.paper} elevation={3} style={{ width: '100%', ...(style ?? {}) }}>
      {children}
    </Paper>
  );

  const body = () => (
    <div ref={componentRef}>
      <Container className={tableClasses.root} maxWidth="md" style={{ justifyContent: 'center' }}>
        <Container style={{ justifyContent: 'center', padding: '5%', paddingTop: '3%' }}>
          {paper({
            style: { marginBottom: 0 },
            children: [
              logoImg(),
              <Container style={{ justifyContent: 'space-between', paddingTop: 10, paddingLeft: 16 }} spacing={0}>
                {title(order.signature ? 'Order Confirmed' : `${isQuote ? 'Quote Details' : 'Course Confirmation'}`)}
                <ContainerItem flex="auto">
                  {title(`${isQuote ? 'Quote' : 'Order'} Number:`, 16, true, 'right')}
                  {title(`${order.cintasOrderID ?? ''}`, 16, true, 'right')}
                </ContainerItem>
              </Container>,
              <OrderSummary
                order={order}
                isQuote={isQuote}
                history={history}
                dispatch={dispatch}
                customerFacing
                showMaterialNumbers
              />,
              <Box height={24} />,
            ],
          })}
          {paper({
            style: { marginTop: 14 },
            children: [<CartSummaryForm isQuote={isQuote} eventSummary customerFacing />],
          })}
          {readOnly
            ? [
                !isQuote && (order?.signature ? whoSigned(order) : noSignature()),
                !isQuote &&
                  (order?.signature && !order.createdByCustomer ? (
                    container({ style: { padding: 0, paddingLeft: 12, paddingRight: 12 }, children: [signaturePad()] })
                  ) : (
                    <></>
                  )),
                !isQuote && cancellationTerms(),
                isQuote && quoteExpirationWarning(),
              ]
            : [
                paper({
                  style: { marginTop: 14 },
                  children: [cancellationTerms(true), termsAndConditions(), customerInformation(), signaturePad()],
                }),
                !order.signature && confirmBtn(),
                order.signature && confirmationMsg(),
              ]}
        </Container>
      </Container>
    </div>
  );

  const printButton = () => (
    <ReactToPrint
      trigger={() => (
        <DialogActions style={{ marginRight: '5%', marginTop: '3%', padding: 0 }}>
          <IconButton style={{ backgroundColor: COLORS.CINTAS_BLUE }}>
            <PrintIcon style={{ color: COLORS.CINTAS_WHITE }} />
          </IconButton>
        </DialogActions>
      )}
      content={() => componentRef.current}
    />
  );

  const inactiveQuoteMessage = () => (
    <div style={{ textAlign: 'center', marginTop: '30%' }}>
      {logoImg()}
      <Typography style={{ fontWeight: 'bold' }}>
        {new Quote(inactiveQuote).isExpired
          ? `This quote expired ${moment.utc(order?.expirationDate).format(DATE_SHORT_FORMAT)} at ${moment.utc(order?.expirationDate).format(TIME_FORMAT)}`
          : 'This quote is no longer available'}
      </Typography>
    </div>
  );

  const cancelledOrderMessage = () => (
    <div style={{ textAlign: 'center', marginTop: '30%' }}>
      {logoImg()}
      <Typography style={{ fontWeight: 'bold' }}>This order is no longer available</Typography>
    </div>
  );

  return loading ? (
    spinner()
  ) : (
    <div style={{ width: '60%', marginLeft: '20%' }}>
      {readOnly && !inactiveQuote && printButton()}
      {isQuote && inactiveQuote ? inactiveQuoteMessage() : order?.cancelled ? cancelledOrderMessage() : body()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tz: state.utils.tz.value,
});

export default _.flow([withRouter, connect(mapStateToProps)])(OrderConfirmationView);
