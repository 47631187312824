/* eslint-disable max-len */
import { MenuItem } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import FASSelectField from '../../Forms/CustomFormComponents/FASSelectField';
import RowCell from '../../LayoutBuilders/RowCell';
import RowLayout from '../../LayoutBuilders/RowLayout';
import MDTextInputField from '../../Forms/FormComponents/MDTextInput/MDTextInputField';

const FasRegionLocationInputs = ({
  loading,
  trailing,
  regionLocationData,
  onChange,
  regionFieldId = 'region',
  locationFieldId = 'location',
  higherLevelAccount,
}) => {
  const [region, setRegion] = React.useState(false);
  const [location, setLocation] = React.useState(false);

  const regionOptions = _(regionLocationData)
    .map((r) => r.region_name)
    .uniq()
    .value();

  const locationOptions = _(regionLocationData)
    .filter((loc) => (higherLevelAccount || region === 'All' ? true : loc.region_name === region))
    .map((loc) => loc[higherLevelAccount ? 'label' : 'loc_name'])
    .uniq()
    .value();

  const handleRegionChange = (selected) => {
    const newLoc = 'All';
    setRegion(selected);
    setLocation(newLoc);
    if (onChange) onChange(selected, newLoc);
  };

  const handleLocationChange = (selected) => {
    setLocation(selected);
    if (onChange) onChange(region, selected);
  };

  const row = ({ children, withPadding = false, style, key, ...rest }) => (
    <RowLayout key={key} {...rest} style={{ paddingTop: withPadding ? 14 : 0, ...(style ?? {}) }}>
      {children}
    </RowLayout>
  );

  const rowCell = ({ child, flex, key, ...rest }) => (
    <RowCell key={key} flex={flex} {...(rest ?? {})}>
      {child}
    </RowCell>
  );

  const regionSelectField = () => (
    <FASSelectField
      variant="outlined"
      placeholder="Select Region"
      id={regionFieldId}
      name={regionFieldId}
      meta
      input={{
        value: region,
        onChange: handleRegionChange,
      }}
      displayEmpty
      style={{ textAlign: 'start', borderRadius: 0 }}
      disabled={loading}
    >
      {regionOptions.length > 1 ? (
        <MenuItem key="All" value="All">
          All
        </MenuItem>
      ) : (
        <></>
      )}
      {regionOptions.sort().map((r) => (
        <MenuItem key={r} value={r}>
          {r}
        </MenuItem>
      ))}
    </FASSelectField>
  );

  const higherLevelAccountField = () => (
    <MDTextInputField
      size="small"
      variant="outlined"
      noBorderRadius
      placeholder="Higher Level Account"
      id={regionFieldId}
      name={regionFieldId}
      meta
      input={{
        value: higherLevelAccount,
        onChange: handleRegionChange,
      }}
      style={{ textAlign: 'start', borderRadius: 0 }}
      disabled
    />
  );

  const locationSelectField = () => (
    <FASSelectField
      variant="outlined"
      placeholder="Select location"
      id={locationFieldId}
      name={locationFieldId}
      meta
      input={{
        value: location,
        onChange: handleLocationChange,
      }}
      displayEmpty
      style={{ textAlign: 'start', borderRadius: 0 }}
      disabled={(!region && !higherLevelAccount) || loading}
    >
      {[
        locationOptions.length > 1 ? (
          <MenuItem key="All" value="All">
            All
          </MenuItem>
        ) : (
          <></>
        ),
        ...locationOptions.sort().map((r) => (
          <MenuItem key={r} value={r}>
            {r}
          </MenuItem>
        )),
      ]}
    </FASSelectField>
  );

  return row({
    children: [
      // Region dropdown
      rowCell({
        key: 0,
        child: higherLevelAccount ? higherLevelAccountField() : regionSelectField(),
      }),
      // Location dropdown
      rowCell({ key: 1, child: locationSelectField() }),
      trailing,
    ],
  });
};

export default FasRegionLocationInputs;
