/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, connect } from 'react-redux';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';
import Typography from '@material-ui/core/Typography';

import { MATERIAL_TYPE, TIME_FORMAT } from '../../../../utils/consts';
import { materialSelectorOfMultipleTypes } from '../../../../redux/selectors/materials.selector';

const useStyles = makeStyles(() => ({
  root: {
    width: 250,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    flex: 1,
    flexWrap: 'wrap',
  },
  daytitle: {
    fontWeight: 'bold',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
    paddingBottom: '10px',
  },
  hourtime: {
    color: '#454545',
    display: 'flex',
    fontWeight: 'bold',
  },
  customer: {
    display: 'flex',
    fontWeight: 'bold',
    color: '#000000',
  },
  classinfo: {
    color: '#000000',
  },
}));

const UpcomingOrder = (props) => {
  const { order, customer, materialsInfo } = props;
  const classes = useStyles({ props });
  const selectedTimezone = useSelector((state) => state.utils.tz.value);
  const selectedTimezoneAbbrev = useSelector((state) => state.utils.tz.abbrev);

  const eventContact = order
    ? order.contact
      ? order.contact
      : order.temporaryContact
        ? { ...order.temporaryContact, isTemporary: true }
        : {}
    : {};

  return (
    <CardContent>
      <Typography className={classes.customer} variant="body2" color="textSecondary" component="p">
        {_.startCase(_.toLower(customer?.name))}
      </Typography>
      {eventContact && (
        <Typography className={classes.classinfo} variant="body2" color="textSecondary" component="p">
          {`${_.capitalize(eventContact.FirstName)} ${_.capitalize(eventContact.LastName)}`}
        </Typography>
      )}
      <Typography className={classes.classinfo} variant="body2" color="textSecondary" component="p">
        {customer?.street}
      </Typography>
      <Typography className={classes.classinfo} variant="body2" color="textSecondary" component="p" gutterBottom>
        {`${customer?.city}, ${customer?.region} ${customer?.postal_code}`}
      </Typography>
      {_.map(order.courses, (course) => {
        const material = materialSelectorOfMultipleTypes({
          materialsInfo,
          types: [MATERIAL_TYPE.COURSE, MATERIAL_TYPE.SKILL_CHECK],
          id: course?.course,
        });

        return (
          <div key={course?.course}>
            <Typography className={classes.hourtime} variant="body2" color="textSecondary" component="p">
              {`${moment(course?.startTime).tz(selectedTimezone).format(TIME_FORMAT)} - ${moment(course?.endTime).tz(selectedTimezone).format(TIME_FORMAT)} (${selectedTimezoneAbbrev})`}
            </Typography>
            <Typography className={classes.classinfo} variant="body2" color="textSecondary" component="p" gutterBottom>
              {material?.title}
            </Typography>
          </div>
        );
      })}
    </CardContent>
  );
};

export default _.flow([connect()])(UpcomingOrder);
