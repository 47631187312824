import * as FeedbackAPI from '../../api/feedback.api';
import { ADD_FEEDBACK, DELETE_FEEDBACK, SAVE_FEEDBACK, UPDATE_FEEDBACK } from './actionTypes/feedback';

export const saveFeedback = (feedback) => ({
  type: SAVE_FEEDBACK,
  feedback,
});

export const addFeedback = (feedback) => ({
  type: ADD_FEEDBACK,
  feedback,
});

export const updateFeedback = (feedback) => ({
  type: UPDATE_FEEDBACK,
  feedback,
});

export const deleteFeedback = (uid) => ({
  type: DELETE_FEEDBACK,
  uid,
});

export const fetchMaterialFeedback = (materialID) => async (dispatch) => {
  try {
    const response = await FeedbackAPI.getFeedback(materialID);
    if (response && response.data) {
      dispatch(saveFeedback(response.data));
    }
  } catch (error) {
    throw error;
  }
};

export const createCustomerFeedback = (feedback) => async (dispatch) => {
  try {
    const response = await FeedbackAPI.createFeedback(feedback);
    if (response && response.data) {
      dispatch(addFeedback(response.data));
    }
  } catch (error) {
    throw error;
  }
};

export const updateCustomerFeedback = (uid, feedback) => async (dispatch) => {
  try {
    const response = await FeedbackAPI.updateFeedback(uid, feedback);
    if (response && response.data) {
      dispatch(updateFeedback(response.data));
    }
  } catch (error) {
    throw error;
  }
};

export const deleteCustomerFeedback = (uid) => async (dispatch) => {
  try {
    const response = await FeedbackAPI.deleteFeedback(uid);
    if (response.status === 204) {
      dispatch(deleteFeedback(uid));
    }
  } catch (error) {
    throw error;
  }
};
