import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rectangle: {
    display: ({ display }) => display ?? 'flex',
    width: ({ width }) => width ?? '100px',
    height: ({ height }) => height ?? '100px',
    backgroundColor: ({ color }) => color,
  },
  square: {
    display: ({ display }) => display ?? 'flex',
    width: ({ width }) => width ?? '100px',
    height: ({ width }) => width ?? '100px',
    backgroundColor: ({ color }) => color,
  },
  circle: {
    display: ({ display }) => display ?? 'flex',
    width: ({ width }) => width ?? '100px',
    height: ({ height }) => height ?? '100px',
    backgroundColor: ({ color }) => color,
    borderRadius: '50%',
  },
}));

const ShapeFactory = ({ shape, children, color, width, height, style, display, ...rest }) => {
  const classes = useStyles({
    color,
    width,
    height,
    display,
    ...rest,
  });

  return (
    <div className={classes[shape]} style={style} {...rest}>
      {children}
    </div>
  );
};

export default ShapeFactory;
