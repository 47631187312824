/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';
import { changeTaskToRead } from '../../../redux/actions/tasks.actions';
import { COLORS } from '../../../utils/consts';
import { TASK_TYPE, TASK_TYPE_ICONS } from '../../../utils/consts/tasks.consts';
import { buildTaskBody } from '../../../utils/tasks.utils';
import EventSummaryButton from '../../Button/EventSummaryButton';
import PanelTile from '../PanelTile';
import SwitchInstructorTaskAction from './SwitchInstructorTaskAction';
import WaiveFeeActions from './WaiveFeeActions';

const TaskTile = ({ task }) => {
  const dispatch = useDispatch();
  const {
    type,
    details: { orderID },
    body: { tz: orderTz },
  } = task;
  const TypeIcon = TASK_TYPE_ICONS[type];
  const unread = !task.read;

  const markTaskRead = () => {
    dispatch(changeTaskToRead(task.type, task.page, task.i, task.taskID));
  };

  React.useEffect(() => {}, [task?.taskID, task?.i]);

  // const container = ({ children, ...rst }) => <Container {...(rst ?? {})}>{children}</Container>;
  // const item = ({ children, ...rst }) => <ContainerItem {...(rst ?? {})}>{children}</ContainerItem>;

  const actions = () => (
    <div>
      <EventSummaryButton
        orderId={orderID}
        fontSize={14}
        marginLeft={20}
        hideRepeatBookingButton
        tzOverride={orderTz}
      />
      <div
        style={{
          width: '100%',
          minHeight: 38,
          marginTop: 10,
          backgroundColor: COLORS.CINTAS_GRAY_OPAQUE_RGBA,
          borderBottomRightRadius: 4,
          borderBottomLeftRadius: 4,
        }}
      >
        {type === TASK_TYPE.WAIVE_FEE && <WaiveFeeActions task={task} />}
        {[TASK_TYPE.MISSING_TRAINER, TASK_TYPE.TERRITORY_CHANGE].includes(type) && (
          <SwitchInstructorTaskAction task={task} />
        )}
      </div>
    </div>
  );

  return (
    <PanelTile
      unread={unread}
      icon={TypeIcon} // <img src={TypeIcon} alt={type} style={{ height: 30, width: 30, color: COLORS.CINTAS_BLUE }} />}
      header={task?.header ?? ''}
      // headerActions={[<EventSummaryButton orderId={orderID} fontSize={14} />]}
      subheader={buildTaskBody(task)}
      onExpansionChange={(val) => (unread && val ? markTaskRead() : null)}
      noContentPadding
    >
      {actions()}
    </PanelTile>
  );
};

export default TaskTile;
