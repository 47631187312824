/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import { IconButton, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { fetchCustomerQuoteById } from '../../api/customers.api';
import Quote from '../../models/Quote';
import { createEvent, fetchEventById } from '../../redux/actions/events.actions';
import { hideModal, showModal } from '../../redux/actions/modals.actions';
import { fetchPricingForMaterials } from '../../redux/actions/pricing.actions';
import { resetSelectedTCI, setSelectedTCI } from '../../redux/actions/users.actions';
import { materialsDataSelector, materialSelectorOfMultipleTypes } from '../../redux/selectors/materials.selector';
import {
  hasUserAccessSelector,
  reportingTcis,
  reportingTcisAllLocations,
  userInfoSelector,
} from '../../redux/selectors/user.selectors';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import {
  BOOKING_MODE,
  COLORS,
  EVENT_TYPE,
  MATERIAL_STATUS,
  MATERIAL_TYPE,
  ORDER_BLOCKED,
  ROLE_ACCESSES,
} from '../../utils/consts';
import {
  normalizeInstructor,
  prepareOnSiteEditingForm,
  showAddToCalendarPopup,
  showSendConfirmationPopup,
} from '../../utils/event.utils';
import { groupByZip } from '../../utils/instructors.helpers';
import SimpleListSelectionDialog from '../Calendar/CalendarComponents/EventDetailsDialog/DialogSubcomponents/SimpleListSelectionDialog';
import EventDialog from '../Calendar/CalendarComponents/EventDialog/EventDialog';
import { getPricing } from '../Calendar/CalendarComponents/EventDialog/EventDialogControl/CourseSelect';
import { cleanRows } from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CartSummaryForm';
import Spinner from '../SpinnerOverlay/Spinner';

const BookQuoteButton = ({ user, tcis, customer, quoteID, preloaded, materialsInfo, tz }) => {
  const [quote, setQuote] = React.useState();
  const [tci, setTci] = React.useState();
  const bookablePartner = useSelector((state) =>
    hasUserAccessSelector(state, [ROLE_ACCESSES.ownCalendar, ROLE_ACCESSES.tciProfile]),
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const loadQuote = async () => {
    if (!preloaded) {
      const loadedQ = await dispatch(fetchCustomerQuoteById(customer.sold_to, quoteID));
      setQuote(
        Quote.toEventData({
          quote: loadedQ,
          customer,
          materialsInfo,
        }),
      );
    } else {
      setQuote(
        Quote.toEventData({
          quote: preloaded,
          customer,
          materialsInfo,
        }),
      );
    }
  };

  React.useEffect(loadQuote, [quoteID]);

  const requestTciSelection = async ({ options, callback }) => {
    const dialogName = 'TCI_SELECT_DIALOG';
    dispatch(
      showModal(dialogName, {
        modalType: 'FAS_INPUT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 16 },
          padding: 0,
          hideCancel: true,
          disableBackdropClick: true,
          maxWidth: 'sm',
          title: 'SELECT TCI',
          content: (
            <SimpleListSelectionDialog
              options={(options ?? []).map((t) => ({
                label: `${t.pernr} - ${t.first_name ?? ''} ${t.last_name ?? ''}`,
                id: t.uid,
              }))}
              dialogName={dialogName}
              selectable
              onSelection={async (t, setLoadingState) => {
                setTci(t);
                dispatch(hideModal(dialogName));
                if (callback) callback(t);
              }}
            />
          ),
        },
      }),
    );
  };

  const loadQuoteTci = ({ bookAfter }) => {
    let tciToBook;
    if (bookablePartner) tciToBook = user;
    else {
      const { primary, secondary } = groupByZip(
        tcis,
        customer.sales_office,
        (customer.postal_code ?? '').split('-')[0],
      );
      if (primary) tciToBook = primary;
      else {
        requestTciSelection({
          options: secondary ?? [],
          callback: (result) => (bookAfter && result ? onBookQuote(result) : null),
        });
        return;
      }
    }
    setTci(tciToBook);
    if (bookAfter) onBookQuote(tciToBook);
  };

  const getQuoteMaterials = (q) =>
    (q.events ?? []).reduce((prev, e) => {
      const courseMat = materialSelectorOfMultipleTypes({
        id: e.course ?? '',
        materialsInfo,
        types: [MATERIAL_TYPE.COURSE, MATERIAL_TYPE.SKILL_CHECK],
      });

      // avoid adding unused add participants addon into list to avoid prices comparisson issues
      const filteredAddParticipants = (e.additional_participants ?? []).filter((add) => add.qty);
      const addonsMats = [...(e.addOns ?? []), ...filteredAddParticipants].map((add) =>
        materialSelectorOfMultipleTypes({
          id: add.id ?? '',
          materialsInfo,
          types: [MATERIAL_TYPE.ADD_ON, MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS],
        }),
      );
      return [...prev, courseMat, ...addonsMats];
    }, []);

  const adjustEvents = (q, tciToBook) => {
    const adjustedQuote = { ...q, instructorID: tciToBook?.id };
    for (let i = 0; i < adjustedQuote.events.length; i++) {
      adjustedQuote.events[i].type = EVENT_TYPE.ON_SITE;
      adjustedQuote.events[i].instructorID = tciToBook?.id;
    }

    return adjustedQuote;
  };

  const adjustMaterials = (q) => {
    const adjustedQuote = { ...q };
    const relevantMats = getQuoteMaterials(q);

    const inactiveMaterials = relevantMats.filter((f) => f.status === MATERIAL_STATUS.HIDDEN);
    if (!_.isEmpty(inactiveMaterials)) {
      inactiveMaterials.forEach((mat) => {
        if ([MATERIAL_TYPE.COURSE, MATERIAL_TYPE.SKILL_CHECK].includes(mat.type)) {
          const i = (adjustedQuote.events ?? []).findIndex((e) => e.course === mat.id);
          adjustedQuote.events.splice(i, 1);
        }
        if ([MATERIAL_TYPE.ADD_ON, MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS].includes(mat.type)) {
          (adjustedQuote.events ?? []).forEach((ev, evIdx) => {
            if (!ev.addOns && mat.type === MATERIAL_TYPE.ADD_ON) return;
            if (!ev.additional_participants && mat.type === MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS) return;
            if (mat.type === MATERIAL_TYPE.ADD_ON) {
              const i = ev.addOns.findIndex((add) => add.id === mat.id);
              adjustedQuote.events[evIdx]?.addOns?.splice(i, 1);
            } else {
              const i = ev.additional_participants.findIndex((add) => add.id === mat.id);
              adjustedQuote.events[evIdx]?.additional_participants?.splice(i, 1);
            }
          });
        }
      });
      showToast(true, 'Some materials were removed due to availability changes', true);
    }
    return adjustedQuote;
  };

  const findQuoteMaterialPricing = (q, code, materials) => {
    const mat = materials.filter((m) => m.code === code)?.[0];
    if (!mat) return [0.0];
    const prices = [];
    if ([MATERIAL_TYPE.COURSE, MATERIAL_TYPE.SKILL_CHECK].includes(mat.type)) {
      (q.events ?? []).forEach((ev) => {
        if (ev.course === mat.id && ev.price?.amount) {
          prices.push(ev.price?.amount);
        }
      });
    }

    if ([MATERIAL_TYPE.ADD_ON, MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS].includes(mat.type)) {
      (q.events ?? []).forEach((ev) => {
        if (!ev.addOns && !ev.additional_participants) return;
        [...(ev.addOns ?? []), ...(ev.additional_participants ?? [])].forEach((add) => {
          if (add.id === mat.id && add.price?.amount) {
            prices.push(add.price?.amount);
          }
        });
      });
    }

    return !_.isEmpty(prices) ? prices : [0.0];
  };

  const validateZnatZregPricing = async (adjstQ) => {
    const adjustedQuote = { ...(adjstQ ?? {}) };
    if ((customer.regional_customer ?? '').length === 0 && (customer.national_customer ?? '').length === 0)
      return adjustedQuote;
    const mats = getQuoteMaterials(adjustedQuote);
    const pricings = await dispatch(
      fetchPricingForMaterials(
        customer,
        mats.map((mat) => mat.code ?? ''),
      ),
    );
    const matsInfo = [...Object.keys(pricings)].reduce(
      (prev, matCode) => ({
        ...prev,
        [matCode]: {
          pricingInfo: pricings?.[matCode] ?? [],
          bookedPricing: findQuoteMaterialPricing(adjustedQuote, matCode, mats),
        },
      }),
      {},
    );

    const materialCodes = Object.keys(matsInfo);
    for (let i = 0; i < materialCodes.length; i++) {
      const matCode = materialCodes[i];
      const matInf = matsInfo[matCode];
      const customerPricing = _.find(matInf.pricingInfo, { Customer: customer.sold_to });
      if (customerPricing) {
        const { bookedPricing } = matInf;
        const predeterminedPrice = getPricing(customerPricing, customer.distribution_channel);
        const different = bookedPricing.some((p) => Number(p) !== Number(predeterminedPrice));
        if (different) return undefined;
      }
    }

    return adjustedQuote;
  };

  const adjustQuote = async (qt, tciToBook) => {
    let adjustedQuote = { ...(qt ?? {}) };
    delete adjustedQuote.data;
    // 1. check listings inclusion/exclusion -- automatically done by EventDialog ?
    // 2. check if customer is order blocked
    if (customer?.customer_blocked_for_orders === ORDER_BLOCKED.denied) {
      showToast(false, 'Cannot book course for order-blocked customer');
      // prevent any further flow by returning undefined;
      return undefined;
    }
    // 3. check if courses are order blocked for customer -- automatically done by EventDialog ?
    // 4. remove materials that have been inactivated (status = HIDDEN):
    adjustedQuote = { ...adjustMaterials(adjustedQuote) };
    // 5. if customer has ZNAT or ZREG value AND preset customerPricing AND pricing != current pricing, throw error. no booking possible.
    adjustedQuote = await validateZnatZregPricing(adjustedQuote);
    if (!adjustedQuote) {
      showToast(false, 'Cannot book course from existing quote due to changes');
    }
    // 6. refresh addons -- automatically done by EventDialog on 'Next' click
    // 7. adjust events data to prepare for booking (eventType, instructorID, ...)
    adjustedQuote = { ...adjustEvents(adjustedQuote, tciToBook) };
    adjustedQuote.fees = {
      travelFee: qt.travelInfo,
    };
    return adjustedQuote;
  };

  const onSubmitCourse = async (values) => {
    const start = moment.utc(values.startTime);
    const end = moment.utc(values.endTime);

    try {
      dispatch(submit('AddEventDialog'));
      const response = await dispatch(createEvent(start, end, { ...values }));
      dispatch(hideModal('ADD_EVENT_DIALOG'));
      cleanRows();
      showToast(true, 'Event successfully created!');
      const eventIds = response?.data.eventIDs ?? [];
      const eventId = (_.isEmpty(eventIds) ? [''] : eventIds)[0];
      const orderId = (await dispatch(fetchEventById(eventId, true)))?.order;
      showSendConfirmationPopup(dispatch, response?.data?.emailToken, orderId, values.bookingMode);
      if (values.addExternalCalendar) {
        showAddToCalendarPopup(orderId, dispatch);
      }
    } catch (error) {
      showToast(false, 'Something happened while creating the event. Please, try again!');
    } finally {
      dispatch(resetSelectedTCI());
    }
  };

  const showEventCreationDialog = (adjustedQuote) => {
    const modalName = 'ADD_EVENT_DIALOG';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          disableBackdropClick: true,
          maxWidth: 'lg',
          title: 'ADD EVENT',
          nestedScrolling: true,
          content: (
            <EventDialog
              modalName={modalName}
              onSubmit={onSubmitCourse}
              updatedEvent={{ desc: { eventType: EVENT_TYPE.ON_SITE } }}
              bookingQuote
              mode={BOOKING_MODE.editing}
              initialData={adjustedQuote}
            />
          ),
        },
      }),
    );
  };

  const onBookQuote = async (tciToBook) => {
    try {
      setLoading(true);
      if (!tciToBook) {
        loadQuoteTci({ bookAfter: true });
        return;
      }
      const adjustedQuote = await adjustQuote(quote, tciToBook);
      if (!adjustedQuote) return;
      const tciID = tciToBook.uid || tciToBook.id;
      const instructorObj = tcis.find((t) => t.uid === tciID);
      dispatch(setSelectedTCI(instructorObj));
      await prepareOnSiteEditingForm({
        dispatch,
        tz,
        formName: 'AddEventDialog',
        noDefaultDateTime: true,
        initialData: {
          desc: { eventType: EVENT_TYPE.ON_SITE, ...adjustedQuote },
          instructorObj: normalizeInstructor(instructorObj),
        },
        preloaded: {
          ...adjustedQuote,
          customerObj: customer,
          customer: customer.sold_to,
          eventType: EVENT_TYPE.ON_SITE,
          instructor: instructorObj?.uid,
        },
      });
      showEventCreationDialog(adjustedQuote);
    } finally {
      setLoading(false);
    }
  };

  const showToast = (success, msg, warning) => {
    dispatch(
      showModal('BOOK_QUOTE_STATUS', {
        modalType: warning ? 'WARNING_ALERT' : success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: msg ?? '',
        },
      }),
    );
  };

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20,
        width: 20,
        padding: 0,
        margin: 0,
        color: COLORS.CINTAS_BLUE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        padding: 0,
      }}
    />
  );

  return (
    <Tooltip title="Book Quote">
      <IconButton onClick={() => onBookQuote()} style={{ margin: 'auto', padding: 4 }}>
        {loading
          ? spinner()
          : [
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,1,200"
              />,
              <span style={{ color: COLORS.CINTAS_BLUE }} className="material-symbols-outlined">
                calendar_add_on
              </span>,
            ]}
      </IconButton>
    </Tooltip>
  );
};

export default _.flow([
  connect((state) => ({
    user: userInfoSelector(state),
    tcis: reportingTcis(state),
    locations: reportingTcisAllLocations(state),
    materialsInfo: materialsDataSelector(state),
    tz: timezoneSelector(state),
  })),
])(BookQuoteButton);
