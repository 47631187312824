/* eslint-disable import/prefer-default-export */
import { baseInstance } from './instances/baseInstance';

export const fetchUsers = () => baseInstance.get('/users');

export const fetchLocationsExportData = () => baseInstance.get('/exportTerritories2');
export const fetchTCIs = () => baseInstance.get('/showTCIs2');
export const fetchTciInfo = (uid) => baseInstance.get(`/showTCIs/${uid}`);
export const getTciManager = (tciUid, zip) => baseInstance.get(`/getTciManager?tci=${tciUid}&zip=${zip}`); // this only works with TCIs
export const getSupervisor = (uid, zip) => baseInstance.get(`/getSupervisor?uid=${uid}&zip=${zip}`); // this works with any org role mapped to a manager in admin settings org-role-relations mapping
export const getTciByZip = (zip) => baseInstance.get(`/tciByZip?zip=${zip}`);

export const updateUser = (userInfo) => baseInstance.put('/updateUser', userInfo);
export const updateUserById = (id, userInfo) => baseInstance.put(`/updateUser/${id}`, { certifications: userInfo });

export const getInstructorByID = (instructorID) => baseInstance.get(`/users/${instructorID}`);

export const updateProfilePic = (img) => baseInstance.post('/user/uploadImage', img);
export const updateDefaultTruckNumber = (truckNum) =>
  baseInstance.put(`/user/defaultTruckNumber?defaultTruckNumber=${truckNum}`);
export const updateDefaultTimezone = (tz) => baseInstance.put('/user/timezone', tz);
export const signupUser = (userInfo) => baseInstance.post('/createUser', userInfo);

export const getOrgStructure = () => baseInstance.get('/orgStructure2');

export const getRegionsLocations = () => baseInstance.get('/regions/locations');

export const fetchCurrentUser = () => baseInstance.get('/userData');

export const fetchUserWorkingHours = (uid) => baseInstance.get(`/users/${uid}/office-hours`);
export const updateUserWorkingHours = (uid, payload) => baseInstance.post(`/users/${uid}/office-hours`, payload);
export const resetUserWorkingHours = (uid) => baseInstance.delete(`/users/${uid}/office-hours`);

// date format YYYY-MM-DD
export const unblockDay = (uid, date, payload) => baseInstance.put(`users/${uid}/unblocked-days/${date}`, payload);
export const blockDay = (uid, date) => baseInstance.delete(`users/${uid}/unblocked-days/${date}`);
