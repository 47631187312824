/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */

import { Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import _ from 'lodash';
// import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { connect, useSelector } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import UpcomingEvent from '../../../Cards/UpcomingEvent';
import { BOOKING_MODE, EVENT_TYPE, MATERIAL_TYPE } from '../../../../../../utils/consts';
import DateUtils, {
  getAllDayEventsForDate,
  getDayEvents,
  getIntervalDatesEvents,
  getNewDateFromString,
} from '../../../../../../utils/dateUtils';
import { materialsDataSelector } from '../../../../../../redux/selectors/materials.selector';
import { allEventsListSelector } from '../../../../../../redux/selectors/events.selectors';
import Spinner from '../../../../../SpinnerOverlay/Spinner';
import * as EventsActions from '../../../../../../redux/actions/events.actions';
import { instructorIdSelector, reportingTciById } from '../../../../../../redux/selectors/user.selectors';
// import { allEventsListSelector } from '../../../../../../redux/selectors/events.selectors';

const renderFromHelper = ({ touched, error }) => {
  if (touched || error) {
    return <FormHelperText style={{ color: '#BA0C2F' }}>{error}</FormHelperText>;
  }
  return '';
};

const SelectedDayEvents = (props) => {
  const {
    utils: {
      tz: { value: selectedTimezone },
    },
    selectedDay,
    selectedEnd,
    meta: { error },
    mode,
    selectedEvent,
    input,
    dispatch,
    ...rest
  } = props;
  const materialsInfo = useSelector(materialsDataSelector);
  const calendarEvents = useSelector(allEventsListSelector);
  const [events, setEvents] = useState(calendarEvents ?? []);
  const [loadedInterval, setLoadedInterval] = useState();
  const [loading, setLoading] = useState(false);
  const primaryTci = useSelector(instructorIdSelector);
  const secondaryTci = selectedEvent?.secondaryTci;
  selectedEvent.instructorID = _.find(events, (ev) => ev.id === selectedEvent.id)?.instructorID; // Assigning instructor ID to selected event (block type doesn't have it)
  const eventsData =
    mode === BOOKING_MODE.booking || BOOKING_MODE.duplicate
      ? events ?? []
      : (events ?? []).filter(
          (ev) => ev.instructorID === selectedEvent?.instructorID || ev.instructorID === secondaryTci,
        );

  const getIntervalInfo = () => {
    const startMoment = moment.utc(selectedDay).add(-2, 'day').startOf('day');
    const endMoment = moment
      .utc(selectedEnd || selectedDay)
      .add(2, 'day')
      .endOf('day');
    return {
      intervalStart: startMoment.toISOString(),
      intervalEnd: endMoment.toISOString(),
    };
  };

  const loadEventsForInterval = async () => {
    if (!selectedDay) return;

    /*
    // selectedDay and selectedEnd comes in format yyyy-mm-dd
    // selectedEnd only comes for allDay events, if selectedEnd is not there, end will be +- 2 days from selectedDay
   */

    try {
      const { intervalStart, intervalEnd } = getIntervalInfo();
      if (loadedInterval && loadedInterval.start === intervalStart && loadedInterval.end === intervalEnd) {
        return;
      }
      setLoading(true);
      const intervalEvents = await dispatch(EventsActions.fetchSelectedDayEvents(intervalStart, intervalEnd));
      if (secondaryTci) {
        const secondaryIntervalEvents = await dispatch(
          EventsActions.fetchSelectedDayEvents(intervalStart, intervalEnd, secondaryTci),
        );
        intervalEvents.push(...(secondaryIntervalEvents ?? []));
      }
      setLoadedInterval({ start: intervalStart, end: intervalEnd });
      setEvents(intervalEvents);
    } finally {
      setLoading(false);
    }
  };

  const loadSecondaryTciEvents = async () => {
    try {
      setLoading(true);
      const { intervalStart, intervalEnd } = getIntervalInfo();
      const primaryEvents = (events ?? []).filter(
        (ev) => ev.instructorID === primaryTci || ev.secondaryTci === primaryTci,
      );
      if (!secondaryTci) {
        setEvents(primaryEvents);
        return;
      }
      const secondaryIntervalEvents = await dispatch(
        EventsActions.fetchSelectedDayEvents(intervalStart, intervalEnd, secondaryTci),
      );
      primaryEvents.push(...secondaryIntervalEvents);
      setEvents(primaryEvents);
    } finally {
      setLoading(false);
    }
  };

  const loadDayEvents = () => {
    let filterFn;
    if (mode !== BOOKING_MODE.booking || mode !== BOOKING_MODE.duplicate) {
      filterFn = (ev) =>
        ev.id !== selectedEvent.id && (selectedEvent.order == null || ev.order !== selectedEvent.order);
    } else {
      filterFn = () => true;
    }
    // const timedEvents = (getDayEvents(selectedDay, eventsData, selectedTimezone, true) ?? []).filter((ev) => filterFn(ev) && !ev.allDay);
    const timedEvents = (
      getIntervalDatesEvents(selectedEvent.startTime, selectedEvent.endTime, eventsData, selectedTimezone, true) ?? []
    ).filter((ev) => filterFn(ev) && !ev.allDay);
    const allDayEvents = getAllDayEventsForDate(getNewDateFromString(selectedDay), eventsData, selectedTimezone).filter(
      (ev) => filterFn(ev),
    );
    const dayEvents = [...timedEvents, ...allDayEvents].sort((a, b) => Date(b.startTime) - Date(b.endTime));
    return dayEvents;
  };

  // Get events for selected day
  const curDayEvents = loadDayEvents();
  let conflicts = {};
  const [, setEventConflict] = React.useState(false);

  const conflictHandler = (conflict, id) => {
    setEventConflict(conflict);
    conflicts[id] = conflict;
    const isOverlap = _.reduce(conflicts, (sum, e) => e || sum, false);
    input.onChange(isOverlap);
  };

  React.useEffect(() => {
    if (_.isEmpty(curDayEvents)) {
      conflicts = {};
      input.onChange(false);
    }
  }, [curDayEvents.length]);

  React.useEffect(() => {
    loadEventsForInterval();
  }, [selectedDay, selectedEnd]);

  React.useEffect(() => {
    loadSecondaryTciEvents();
  }, [secondaryTci]);

  React.useEffect(() => {
    loadEventsForInterval();
    return () => {};
  }, []); // initial state setup

  const spinner = () => {
    const size = 28;
    return (
      <div style={{ height: '100%' }}>
        <Spinner
          margin="auto"
          spinnerProps={{ color: 'primary' }}
          customStyle={{
            maxHeight: size,
            maxWidth: size,
            margin: 'auto',
            marginTop: '15%',
          }}
          spinnerStyle={{ height: size, width: size }}
        />
      </div>
    );
  };

  return (
    <div {...rest} value={input.value}>
      {!loading && selectedDay && curDayEvents?.length < 1 && <Typography>No Events</Typography>}
      {!loading &&
        _.map(_.sortBy(curDayEvents, 'startTime'), (event) => (
          <UpcomingEvent
            checkConflict={
              !(event.eventType === EVENT_TYPE.TIME_BLOCK && event.blockType === 'OPTIONAL') &&
              selectedEvent?.blockType !== 'OPTIONAL' &&
              !event.subcontracted
            }
            key={event.id}
            event={event}
            showSecondaryLabel={
              secondaryTci &&
              (event.instructorID === secondaryTci ||
                (event.secondaryTci === secondaryTci && event.instructorID !== primaryTci))
            }
            course={event.eventType === EVENT_TYPE.OPEN_ENROLLMENT && materialsInfo[MATERIAL_TYPE.COURSE][event.course]}
            selectedDay={selectedDay}
            onConflict={conflictHandler}
          />
        ))}
      {loading && spinner()}
      {renderFromHelper({ error })}
    </div>
  );
};
const mapStateToProps = (state) => ({
  utils: state.utils,
  form: state.form,
});

export default connect(mapStateToProps)(SelectedDayEvents);
