/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const fetchNationalAccountById = (id) => baseInstance.get(`/nationalAccounts?id=${id}`);

export const editNationalAccountNotes = (id, payload) =>
  baseInstance.post(`/editNationalAccountNotes?id=${id}`, payload);

export const editRosterConfig = (id, type, required) =>
  baseInstance.put(`/nationalAccounts/${id}/rosterConfig/${type}?required=${required}`);

export const getNationalAccountLocations = (id) => baseInstance.get(`/nationalAccountLocations?account=${id}`);

export const getNationalAccountOrders = (id, locID) =>
  baseInstance.get(`/miniNationalAccountOrders?account=${id}${locID ? `&locID=${locID}` : ''}`);
