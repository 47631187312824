import Immutable from 'seamless-immutable';
import { MATERIAL_TYPE } from '../../utils/consts';

import { SAVE_SAP_MATERIALS, SAVE_SAP_CUSTOMERS, SAVE_SAP_LISTINGS } from '../actions/actionTypes/sap';

const initialState = Immutable({
  materials: { data: Object.values(MATERIAL_TYPE).reduce((red, t) => ({ ...red, [t]: {} }), {}) },
  customers: [], // {},
  listings: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SAP_MATERIALS:
      return state.set('materials', action.payload);
    case SAVE_SAP_CUSTOMERS:
      return state.set('customers', action.payload);
    case SAVE_SAP_LISTINGS:
      return state.set('listings', action.payload);
    default:
      return state;
  }
};
