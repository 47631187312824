/* eslint-disable max-len */
import Immutable from 'seamless-immutable';

import { SAVE_CUSTOMERS, SET_CURRENT_CUSTOMER } from '../actions/actionTypes/customers';

const initialState = Immutable({
  data: [],
  currentCustomer: undefined,
});

export const prepareCustomerData = (customers) =>
  customers.reduce((red, customer) => ({ ...red, [customer.sold_to]: customer }), {});

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CUSTOMERS:
      return state.set('data', action.customers);
    case SET_CURRENT_CUSTOMER:
      return state.set('currentCustomer', action.customer);
    default:
      return state;
  }
};
