/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { useDispatch, connect } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/CloseRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { change, Field, getFormValues, initialize, reduxForm, submit } from 'redux-form';
import { IconButton } from '@material-ui/core';
import MDAutoComplete from '../../../../components/Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import MDTextInputField from '../../../../components/Forms/FormComponents/MDTextInput/MDTextInputField';
import Card from '../../../../components/LayoutBuilders/Card';
import Container from '../../../../components/LayoutBuilders/Container';
import ContainerItem from '../../../../components/LayoutBuilders/ContainerItem';
import { COLORS, ORG_STRUCTURE_ROLES, ROLE_ACCESSES, ROLE_ACCESSES_LABELS } from '../../../../utils/consts';

export const roleAccessInfoFormName = 'RoleAccessInfo';

export const validateRoleAccessForm = (values, props) => {
  const { defaultUserRoleMatrix } = props;
  const errors = {};

  if (!values.role || _.isEmpty(values.role) || _.toLower(values.role) === _.toLower(defaultUserRoleMatrix.role)) {
    errors.role = 'Invalid Role';
  }
  return errors;
};

const RoleAccessInfoModal = ({
  initialMatrix,
  formVals,
  handleSubmit,
  onDelete,
  noDelete,
  notEditable,
  noRoleInput,
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (initialMatrix) {
      dispatch(initialize(roleAccessInfoFormName, initialMatrix));
    }
    return () => {};
  }, []);

  const onAccessEdited = async (accessType, value) => {
    dispatch(change(roleAccessInfoFormName, accessType, value));
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const grid = ({ children }) =>
    container({
      spacing: 1,
      style: { justifyContent: 'center' },
      children: children.map((child) =>
        item({
          flex: 'auto',
          children: [child],
        }),
      ),
    });

  const roleInput = () => (
    <Field
      name="role"
      component={MDTextInputField}
      noHelperText
      noErrorLabel
      withEnterTrigger
      required
      disabled={notEditable}
      type="text"
      size="small"
      margin="none"
      label="Role"
      style={{ margin: 0, padding: 0 }}
    />
  );

  const appRoleInput = () => (
    <Field
      variant="outlined"
      name="appRole"
      label="Org Role"
      component={MDAutoComplete}
      displayEmpty
      options={Object.keys(ORG_STRUCTURE_ROLES)}
      style={{ textAlign: 'start', borderRadius: 0 }}
      noErrorLabel
      disabled={notEditable}
      inputProps={{
        style: { margin: 0, paddingTop: 0, height: 40 },
        size: 'small',
      }}
    />
  );

  const deleteAccessBtn = () => (
    <IconButton style={{ color: COLORS.CINTAS_RED }} onClick={() => onDelete()}>
      <DeleteIcon />
    </IconButton>
  );

  const checkIcon = () => (
    <CheckIcon
      style={{
        padding: 0,
        margin: 0,
        marginTop: 4,
        height: 22,
        color: COLORS.CINTAS_GREEN,
      }}
    />
  );

  const noAccessIcon = () => (
    <CloseIcon
      style={{
        padding: 0,
        margin: 0,
        marginTop: 4,
        height: 22,
        color: COLORS.CINTAS_GRAY,
      }}
    />
  );

  const accessTile = (access) => (
    <Card
      onClick={notEditable ? null : () => onAccessEdited(access, !formVals?.[access])}
      style={{
        padding: 0,
        paddingLeft: 4,
        paddingRight: 10,
        minWidth: 240,
        maxWidth: 240,
        cursor: notEditable ? null : 'pointer',
        boxShadow: 'none',
        fontWeight: 'bold',
        color: formVals?.[access] ? null : COLORS.CINTAS_GRAY,
        border: `2px solid ${formVals?.[access] ? COLORS.CINTAS_GREEN : COLORS.CINTAS_GRAY}`,
      }}
    >
      {container({
        spacing: 1,
        style: { justifyContent: 'flex-start', alignItems: 'center' },
        children: [
          item({
            flex: 2,
            children: [formVals?.[access] ? checkIcon() : noAccessIcon()],
          }),
          item({
            flex: 10,
            children: [ROLE_ACCESSES_LABELS[access]],
          }),
        ],
      })}
    </Card>
  );

  return (
    <form onSubmit={() => handleSubmit(submit)}>
      <div style={{ padding: 12, paddingTop: 16, width: 762 }}>
        {container({
          style: { justifyContent: 'flex-start', alignItems: 'center', marginBottom: 20 },
          spacing: 1,
          children: [
            !noRoleInput && item({ flex: 8, children: [roleInput()] }),
            !noRoleInput && item({ flex: 3, children: [appRoleInput()] }),
            !noDelete && item({ flex: 'auto', children: [deleteAccessBtn()] }),
          ],
        })}
        {grid({
          children: [...Object.keys(ROLE_ACCESSES)]
            .filter((acc) => acc !== ROLE_ACCESSES.appRole)
            .map((access) => accessTile(access)),
        })}
      </div>
    </form>
  );
};

export default _.flow([
  connect((state) => ({
    formVals: getFormValues(roleAccessInfoFormName)(state),
  })),
  reduxForm({
    form: roleAccessInfoFormName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    onSubmit: submit,
    autovalidate: true,
    validate: validateRoleAccessForm,
  }),
])(RoleAccessInfoModal);
