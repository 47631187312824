/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '0px',
      },
    },
  },
});

const MDTextInputView = (props) => {
  const classes = useStyles();
  const {
    size,
    variant,
    value,
    meta: { touched, invalid, error },
    helperText,
    initiallyValidate,
    noErrorLabel,
    max,
    min,
    type,
    withEnterTrigger,
  } = props;

  useEffect(() => {
    if (type === 'number' && String(min) && Number(value) < Number(min)) {
      const { onChange, onBlur } = props;
      if (onChange) onChange(min);
      if (onBlur) onBlur(min);
    }
  }, []);

  const { noBorderRadius, ...otherProps } = props;

  const handleChange = (event) => {
    event.preventDefault();
    const newValue = (event.currentTarget.value ?? '').replaceAll('#', '');
    const { onChange } = props;
    if (onChange) onChange(newValue);
  };

  const defaultToMinMax = () => {
    let newValue = value;
    if (type === 'number' && String(max || min)) {
      if (String(max)) newValue = Number(newValue) > Number(max) ? max : newValue;
      if (String(min)) newValue = Number(newValue) < Number(min) ? min : newValue;
    }
    const { onBlur, onChange } = props;
    if (onChange) onChange(newValue);
    if (onBlur) onBlur(newValue);
  };

  return (
    <TextField
      classes={{
        root: noBorderRadius && classes.root,
      }}
      size={size || 'medium'}
      variant={variant || 'outlined'}
      fullWidth
      value={value}
      InputProps={{ inputProps: { min, max, ...otherProps.inputProps } }}
      {...otherProps}
      error={(initiallyValidate || touched) && invalid}
      helperText={((touched && error) || helperText) ?? noErrorLabel ? undefined : ' '}
      onChange={(event) => handleChange(event)}
      onKeyDown={withEnterTrigger ? (event) => (event.keyCode === 13 ? event.target?.blur() : undefined) : undefined}
      onBlur={() => defaultToMinMax()}
    />
  );
};

export default MDTextInputView;
