import { makeStyles } from '@material-ui/core/styles';
import { RadioGroup } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 220,
    width: '100%',
  },
}));

const MDRadioGroupField = (props) => {
  const classes = useStyles();
  const {
    input,
    meta: { touched, error },
    row,
    withHelperText = true,
    ...rest
  } = props;

  return (
    <FormControl component="fieldset" error={!!(touched && error)} className={classes.formControl}>
      <RadioGroup
        {...input}
        {...rest}
        row={row}
        value={input.value}
        onChange={(event, value) => {
          input.onChange(value);
          input.onBlur(value);
        }}
      />
      {withHelperText && <FormHelperText>{(touched && error) || ' '}</FormHelperText>}
    </FormControl>
  );
};

export default MDRadioGroupField;
