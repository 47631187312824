import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import AppContainer from './AppContainer';
import TopBar from '../../components/TopBar/TopBar';
import DrawerPanel from '../MainViews/Drawer/DrawerPanel';
import { panelTypes } from '../../redux/selectors/notifications.tasks.selector';

const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(1, 1.5),
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  container: {
    // height: '83vh',
    // paddingBottom: 48,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

const AppLayout = (props) => {
  const { children, maxWidth } = props;
  const classes = useStyles();
  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <main className={classes.content}>
        <TopBar />
        <div className={classes.toolbar} />
        <AppContainer>
          <Container maxWidth={maxWidth || 'xl'} className={classes.container}>
            {children}
          </Container>
        </AppContainer>
        <DrawerPanel type={panelTypes.notification} />
        <DrawerPanel type={panelTypes.task} />
      </main>
    </div>
  );
};

export default AppLayout;
