/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchContactsByCustomerId } from '../../redux/actions/contacts.actions';
import { COLORS } from '../../utils/consts';
import Card from '../LayoutBuilders/Card';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import Spinner from '../SpinnerOverlay/Spinner';
import SimpleText from '../Text/SimpleText';

const CustomerContactsList = ({ customer }) => {
  const dispatch = useDispatch();
  const [contacts, setContacts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getAddress = (contact) => {
    if ((contact?.Addresses ?? []).length === 0) return undefined;

    const addresses = contact.Addresses;
    const address = addresses[0];

    if (
      address.AddressLine1 ||
      address.AddressLine2 ||
      address.AddressLine3 ||
      address.AddressLine4 ||
      address.City ||
      address.State ||
      address.PostalCode
    )
      return address;

    return undefined;
  };

  const loadContacts = async () => {
    try {
      setLoading(true);
      const data = await dispatch(fetchContactsByCustomerId(customer.sold_to, true));
      setContacts(data);
    } catch (e) {
      setContacts([]);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadContacts();
    return () => {};
  }, []);

  const card = ({ children, style, title }) => (
    <Card style={style} title={title}>
      {children}
    </Card>
  );

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt, style, align) => (
    <SimpleText
      txt={txt}
      style={{ ...(style ?? {}) }}
      divStyle={{ display: 'flex', justifyContent: align }}
      align={align}
      textAlign={align}
    />
  );

  const contactTile = (contact) => {
    const address = getAddress(contact);

    return container({
      style: {
        paddingTop: 20,
        paddingBottom: 20,
        borderBottom: `1px solid ${COLORS.CINTAS_DARK_GRAY}`,
      },
      children: [
        item({
          flex: address ? 7 : 12,
          children: [
            container({
              children: [
                item({
                  flex: 12,
                  children: [
                    text(formatString(`${contact?.FirstName ?? ''} ${contact?.LastName ?? ''}`), {
                      fontWeight: 'bold',
                    }),
                  ],
                }),
                item({ flex: 12, children: [text(`${contact?.Email} `)] }),
                item({
                  flex: 12,
                  children: [
                    text(
                      (contact?.Phones ?? [])
                        .filter((p) => Boolean(p.Phone))
                        .map((p) => p.Phone)
                        .join(' | '),
                    ),
                  ],
                }),
              ],
            }),
          ],
        }),
        address &&
          item({
            flex: 5,
            children: [
              container({
                style: { justifyContent: 'flex-end' },
                children: [
                  item({ flex: 12, children: [text('Address', { fontWeight: 'bold' }, 'right')] }),
                  (address.AddressLine1 || address.AddressLine2 || address.AddressLine3 || address.AddressLine4) &&
                    item({
                      flex: 12,
                      children: [
                        text(
                          joinStrings([
                            formatString(address.AddressLine1),
                            formatString(address.AddressLine2),
                            formatString(address.AddressLine3),
                            formatString(address.AddressLine4),
                          ]),
                          null,
                          'right',
                        ),
                      ],
                    }),
                  (address.City || address.State || address.PostalCode) &&
                    item({
                      flex: 12,
                      children: [
                        text(
                          joinStrings(
                            [formatString(address.City), joinStrings([address.State, address.PostalCode], ' ')],
                            ', ',
                          ),
                          null,
                          'right',
                        ),
                      ],
                    }),
                ],
              }),
            ],
          }),
      ],
    });
  };

  const joinStrings = (listStrs, delimiter) => listStrs.filter((line) => Boolean(line)).join(delimiter ?? '\n');
  const formatString = (str) => _.startCase(str.toLowerCase());

  const spinner = () => <Spinner customStyle={{ margin: '10%' }} />;
  const noData = () => (
    <Typography style={{ textAlign: 'center', paddingTop: '10%', color: COLORS.CINTAS_DARK_GRAY }}>
      No contacts available
    </Typography>
  );

  return card({
    title: 'Contacts',
    style: { maxHeight: 300, height: '100%', overflowY: 'auto' },
    children: loading
      ? [spinner()]
      : [...((contacts ?? []).length === 0 ? [noData()] : contacts.map((c) => contactTile(c)))],
  });
};

export default _.flow([connect()])(CustomerContactsList);
