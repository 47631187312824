/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prefer-stateless-function */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Typography, Container, Grid, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';

import CourseSettingsForm from '../../../components/Forms/SettingsForms/CourseSettingsForm';

import * as CertificationsActions from '../../../redux/actions/certifications.actions';
import * as MaterialsActions from '../../../redux/actions/materials.actions';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import * as PricingActions from '../../../redux/actions/pricing.actions';
import * as SAPActions from '../../../redux/actions/sap.actions';
import StyledButton from '../../../components/Button/StyledButton';
import { DATE_TIME_YYYY_SHORT_FORMAT, MATERIAL_STATUS, PRICING_REF_LOCATION } from '../../../utils/consts';
import { removeLeadingZeros } from '../../../utils/helpers';
import { allMaterialsListSelector } from '../../../redux/selectors/materials.selector';

const titleStyle = {
  fontFamily: 'proxima-nova',
  fontWeight: 'Bold',
  display: 'flex',
  paddingTop: '24px',
};

const submitButtonStyle = {
  marginTop: 32,
  marginBottom: 32,
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
};

class CourseDetailsView extends React.Component {
  async componentDidMount() {
    const { dispatch } = this.props;
    await dispatch(MaterialsActions.fetchMaterials());
    await dispatch(SAPActions.fetchSAPMaterials());
    await dispatch(CertificationsActions.fetchCertifications());
  }

  async onSubmitMaterial(values) {
    const { dispatch, history } = this.props;
    // Modify fields if needed
    if (values.status !== MATERIAL_STATUS.HIDDEN) {
      const templateCustomer = {
        sales_organization: values.salesOrg,
        sold_to: '-1',
        sales_office: PRICING_REF_LOCATION[values.salesOrg],
      };
      try {
        const res = await dispatch(PricingActions.fetchPricing(templateCustomer, values.code));
        const pricing = _.find(res, { Customer: '-1' });
        if (!pricing) throw new Error();
      } catch (error) {
        const message = `Market pricing for this material not available in reference location ${PRICING_REF_LOCATION[values.salesOrg]}. Please, update pricing before submitting material with status Customer-Facing.`;
        dispatch(
          ModalsActions.showModal('UPDATE_MATERIAL_ERROR', {
            modalType: 'ERROR_ALERT',
            modalProps: { message },
          }),
        );
        return;
      }
    }

    if (values.newImage) {
      try {
        await dispatch(MaterialsActions.uploadImage(values.id, values.newImage, values.type));
      } catch (error) {
        dispatch(
          ModalsActions.showModal('UPLOAD_IMAGE_ERROR_MODAL', {
            modalType: 'ERROR_ALERT',
            modalProps: { message: 'Something happened while uploading the image. Please, try again!' },
          }),
        );
      }
    }

    try {
      await dispatch(MaterialsActions.updateMaterialById(values.id, values));
      if (values.imageObj) {
        await dispatch(MaterialsActions.uploadImage(values.id, values.imageObj, values.type));
      }
      if (values.slug) {
        try {
          await MaterialsActions.updateMaterialSlug(values.id, values.slug);
        } catch (error) {
          dispatch(
            ModalsActions.showModal('UPDATE_MATERIAL_ERROR', {
              modalType: 'ERROR_ALERT',
              modalProps: { message: error?.message ?? 'Error updating slug' },
            }),
          );
          return;
        }
      }
      dispatch(
        ModalsActions.showModal('UPDATE_MATERIAL_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: { message: 'Successfully modified material' },
        }),
      );
      history.push('/admin/materials');
    } catch (error) {
      const message = 'Error submitting material';
      dispatch(
        ModalsActions.showModal('UPDATE_MATERIAL_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { message },
        }),
      );
    }
  }

  render() {
    const {
      dispatch,
      history,
      match: {
        params: { material, salesOrg, isDeleted },
      },
      materials: { data: materialsData },
      sapMaterials,
      tz,
    } = this.props;

    const materialObject = _.find(materialsData, { code: material, salesOrg });

    const selectedMaterial = _.find(sapMaterials, {
      Material: material,
      Sales_Organization: salesOrg,
    });

    return (
      <Container maxWidth="lg" style={{ paddingBottom: 64 }}>
        <Button
          // variant="outlined"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => history.push('/admin/materials')}
        >
          Back to Materials
        </Button>
        <Grid container spacing={4} alignItems="center" justify="center">
          <Grid item xs={12}>
            <Typography style={titleStyle} color="primary" variant="h4" gutterBottom>
              MATERIAL SETTINGS
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">SAP Material Number: {removeLeadingZeros(material)}</Typography>
            <Typography>Sales Organization: {salesOrg}</Typography>
            <Typography>SAP Material Description: {selectedMaterial?.Material_Description}</Typography>
          </Grid>
          <Grid item xs={8}>
            {materialObject?.firstnameofwhoupdatedmaterial && materialObject?.lastnameofwhoupdatedmaterial && (
              <div>
                <Typography variant="body1">Last updated by:</Typography>
                <Typography variant="body1">
                  {`${materialObject?.firstnameofwhoupdatedmaterial} ${materialObject?.lastnameofwhoupdatedmaterial}`}
                </Typography>
                <Typography variant="body1">
                  {`${moment.utc(materialObject?.updatedmaterial).tz(tz)}, ${moment.utc(materialObject?.updatedmaterial).tz(tz).format(DATE_TIME_YYYY_SHORT_FORMAT)}`}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
        <CourseSettingsForm
          onSubmit={(values) => this.onSubmitMaterial(values)}
          initialValues={materialObject}
          isDeleted={isDeleted}
        />
        <StyledButton
          variant="contained"
          color="primary"
          type="submit"
          width="160px"
          buttonContent="Submit"
          handleButton={() => dispatch(submit('CourseSettingsForm'))}
          style={submitButtonStyle}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  materials: { data: allMaterialsListSelector(state) },
  sapMaterials: state.sap.materials,
  tz: state.utils.tz.value,
});

export default _.flow([withRouter, connect(mapStateToProps)])(CourseDetailsView);
