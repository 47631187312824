/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Box } from '@material-ui/core';
import {
  adminTypeSelector,
  hasUserAccessSelector,
  selectedTciSelector,
  userInfoSelector,
} from '../../redux/selectors/user.selectors';
import { DATE_TIME_ISO, EVENT_TYPE, ROLE_ACCESSES } from '../../utils/consts';
import EventDetailsDialog from '../Calendar/CalendarComponents/EventDetailsDialog/EventDetailsDialog';
import StyledButton from './StyledButton';
import { getStatusLabel } from '../Settings/Orders/OrdersTable';
import { showModal } from '../../redux/actions/modals.actions';
import { updateOrderDelivery } from '../../redux/actions/orders.actions';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import { defaultTimeZone } from '../../utils/dateUtils';

const OrderStatusButton = ({
  selectedOrder,
  selectedEvent,
  disableEditing,
  loadOrder,
  reloadOrder,
  closePopperHandler,
}) => {
  const eventIsTimeBlock = selectedEvent?.desc?.eventType === EVENT_TYPE.TIME_BLOCK;
  const dispatch = useDispatch();
  const canStartDelivery = useSelector(
    (state) => hasUserAccessSelector(state, [ROLE_ACCESSES.canDeliver]) && selectedEvent,
  );
  const isBasicAdmin = useSelector((state) => adminTypeSelector(state) === ROLE_ACCESSES.basicAdmin);
  const isAdvancedAdmin = useSelector((state) => adminTypeSelector(state) === ROLE_ACCESSES.advancedAdmin);
  const selectedTimezone = useSelector(timezoneSelector);
  const bookableUser = useSelector((state) =>
    hasUserAccessSelector(state, [ROLE_ACCESSES.tciProfile, ROLE_ACCESSES.ownCalendar]),
  );
  const currentUserTimezone =
    useSelector(!bookableUser ? selectedTciSelector : userInfoSelector)?.tz?.value ?? defaultTimeZone;
  const subcontracted = selectedOrder?.subcontracted || selectedOrder?.events?.[0]?.subcontracted;

  const showEventDetailsDialog = (fromStartDelivery) => {
    if (closePopperHandler) closePopperHandler();
    const modalName = 'EVENT_DETAILS_DIALOG';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          disableBackdropClick: true,
          maxWidth: 'lg',
          title: 'EVENT DETAILS',
          content: (
            <EventDetailsDialog
              openedFromStartDelivery={fromStartDelivery}
              modalName={modalName}
              dispatch={dispatch}
              orderId={selectedEvent?.desc?.order}
              reloadOrder={reloadOrder}
            />
          ),
        },
      }),
    );
  };

  const startCourseHandler = async (orderId) => {
    // console.log(courseIdx, orderId);
    /**
     * When the button is pressed, the course goes into “delivery mode”. That means,
     * the customer signature can be collected.
     * A course started flag will be saved in the event object
     */
    try {
      await dispatch(updateOrderDelivery(orderId));
      dispatch(
        showModal('CREATE_EVENT_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: { message: 'Event successfully started' },
        }),
      );
      showEventDetailsDialog(true);
    } catch (error) {
      dispatch(
        showModal('DELIVERY_ERROR_MODAL', {
          modalType: 'ERROR_ALERT',
          modalProps: { message: 'Something happened while moving to delivery. Please, try again!' },
        }),
      );
    } finally {
      await loadOrder();
    }
  };

  const startDeliveryBtn = () => (
    <>
      <Box height={30} />
      <StyledButton
        variant="outlined"
        buttonContent="START DELIVERY"
        onClick={() => startCourseHandler(selectedOrder?.id ?? '')}
      />
    </>
  );

  const inDeliveryBtn = () => (
    <>
      <Box height={30} />
      <StyledButton
        buttonContent={selectedOrder.deliverySignature ? getStatusLabel(selectedOrder) : 'In Progress'}
        variant={selectedOrder.deliverySignature ? 'outlined' : 'contained'}
        disabled={selectedOrder.deliverySignature}
        color="primary"
        handleButton={() => showEventDetailsDialog()}
      />
    </>
  );

  const startTime = _(selectedOrder?.events)
    .map((e) => e.startTime)
    .min();

  const sameTimezone = selectedTimezone === currentUserTimezone;
  const deliveryStartTime =
    ['production'].includes(process.env.REACT_APP_ENV) && !isAdvancedAdmin
      ? moment().format(DATE_TIME_ISO) >
        moment.utc(startTime).tz(selectedTimezone).subtract(1, 'hour').format(DATE_TIME_ISO)
      : true;
  const shouldDisableEditing = disableEditing && disableEditing(selectedOrder);

  return (
    <>
      {((isBasicAdmin || isAdvancedAdmin) &&
        !eventIsTimeBlock &&
        !selectedOrder?.deliverySignature &&
        !selectedOrder?.delivery &&
        !subcontracted &&
        !selectedOrder?.isRental &&
        startDeliveryBtn()) ||
        (!shouldDisableEditing &&
          selectedOrder &&
          !selectedOrder.delivery &&
          canStartDelivery &&
          deliveryStartTime &&
          sameTimezone &&
          !subcontracted &&
          !selectedOrder?.isRental &&
          startDeliveryBtn())}
      {selectedOrder && selectedOrder.delivery && inDeliveryBtn()}
    </>
  );
};

export default _.flow()(OrderStatusButton);
