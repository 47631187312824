export const RESET_EVENTS_STATE = 'RESET_EVENTS_STATE';
export const SAVE_EVENTS = 'SAVE_EVENTS';
export const SAVE_DATE_REVENUE = 'SAVE_DATE_REVENUE';
export const ADD_EVENT = 'ADD_EVENT';
export const SAVE_UPCOMING_EVENTS = 'SAVE_UPCOMING_EVENTS';
export const DELETE_TIMEBLOCK_EVENT = 'DELETE_TIMEBLOCK_EVENT';
export const DELETE_UPCOMING_TIMEBLOCK = 'DELETE_UPCOMING_TIMEBLOCK';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_UPCOMING_EVENT = 'UPDATE_UPCOMING_EVENT';
export const SAVE_CANCELLED_EVENTS_COUNT = 'SAVE_CANCELLED_EVENTS_COUNT';
export const RESET_CANCELLED_EVENTS_COUNT = 'RESET_CANCELLED_EVENTS_COUNT';

export const SAVE_UNBLOCKED_DAYS = 'SAVE_UNBLOCKED_DAYS';
export const UNBLOCK_DAY = 'UNBLOCK_DAY';
export const BLOCK_DAY = 'BLOCK_DAY';
