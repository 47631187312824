/* eslint-disable import/no-cycle */
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import { change } from 'redux-form';
import StyledButton from '../../../components/Button/StyledButton';
import { getPricing } from '../../../components/Calendar/CalendarComponents/EventDialog/EventDialogControl/CourseSelect';
import { fetchPricing } from '../../../redux/actions/pricing.actions';
import FASBlackFont from '../../../components/Forms/CustomFormComponents/FASBlackFont';
import Container from '../../../components/LayoutBuilders/Container';

const RefreshPriceBtn = ({
  formName,
  customer,
  materialNum,
  updatePriceFn,
  listingsPath,
  classes,
  width = 'auto',
  height = 'auto',
}) => {
  const dispatch = useDispatch();
  const text = (txt, className, weight) => (
    <FASBlackFont size="15px" weight={weight ?? 500} className={className} title={txt} />
  );
  const container = ({ children, ...rest }) => (
    <Container justify="space-between" alignItems="flex-start" spacing={3} {...(rest ?? {})}>
      {children}
    </Container>
  );

  const refreshPrice = async () => {
    const priceListings = await dispatch(fetchPricing(customer, materialNum));
    if (!priceListings) return;
    await dispatch(change(formName, listingsPath, priceListings));
    const newCustomerPrice = _.find(priceListings ?? [], { Customer: customer.sold_to });
    const newMarketPrice = _.find(priceListings ?? [], { Customer: '-1' });
    if (newCustomerPrice != null || newMarketPrice != null) {
      // 0 is accepted as a valid new price
      const newPrice = getPricing(newCustomerPrice ?? newMarketPrice, customer.distribution_channel);
      if (newPrice != null) {
        // 0 is accepted as a valid new price
        updatePriceFn({
          amount: newPrice,
          currency: (newCustomerPrice ?? newMarketPrice).Curr,
        });
      }
    }
  };

  return (
    <StyledButton
      buttonContent={container({
        justifyContent: 'flex-start',
        alignItems: 'center',
        children: [
          // eslint-disable-next-line max-len
          <RefreshIcon className={classes.blueText} style={{ marginRight: 3 }} />,
          text('refresh'.toLowerCase(), classes.blueText),
        ],
      })}
      disableCaps
      handleButton={() => refreshPrice()}
      variant="text"
      color="secondary"
      style={{ padding: '4px 16px', width, height }}
    />
  );
};

export default RefreshPriceBtn;
