/* eslint-disable max-len */
import { buildSubcontractorId } from '../../utils/event.utils';
import { locationDefaultTruckNumSelector, locationDefaultTzSelector } from './settings.selectors';
import { currentUserAvailableLocationsSelector } from './user.selectors';

/* eslint-disable import/prefer-default-export */
export const instuctorSearchQuerySelector = (state) =>
  ((state.form.CalendarTable ?? {}).values ?? {}).instructorSearchQuery ?? '';
export const subcontractorForLoc = (state, locId) => {
  const id = buildSubcontractorId(locId);
  return {
    uid: id,
    pernr: id,
    usrid_long: id,
    subcontractor: true,
    first_name: 'Subcontracted',
    last_name: ' ',
    tz: locationDefaultTzSelector(state, locId),
    defaultTruckNumber: locationDefaultTruckNumSelector(state, locId),
    locations: {
      [locId]: {
        loc_id: locId,
        location_desc: locId,
      },
    },
  };
};
export const subcontractorsSelector = (state) =>
  (currentUserAvailableLocationsSelector(state) ?? []).map((locId) => subcontractorForLoc(state, locId));
