/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import CertTile from './CertTile';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import Spinner from '../SpinnerOverlay/Spinner';
import UserAccountCard from './UserAccountCard';

import { fetchCertifications } from '../../redux/actions/certifications.actions';
import { userInfoSelector } from '../../redux/selectors/user.selectors';

const useStyles = makeStyles({
  title: {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: 'auto',
    marginTop: 24,
  },
  smalltitle: {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '0 0 0',
    fontSize: '0.85rem',
  },
});

const TCICertifications = ({ user, minHeight }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getTitle = () => 'Certifications';
  const [loading, setLoading] = React.useState(true);
  const [certifications, setCertifications] = React.useState();

  React.useEffect(async () => {
    await loadCertifications();
  }, []);

  const item = ({ children, style, ...rest }) => (
    <ContainerItem
      {...(rest ?? {})}
      style={{
        textAlign: 'center',
        ...(style ?? {}),
        wordWrap: 'break-word',
        wordBreak: 'break-all',
      }}
    >
      {children}
    </ContainerItem>
  );

  const spinner = () => <Spinner />;

  const loadCertifications = async () => {
    setLoading(true);
    const resp = await dispatch(fetchCertifications());
    setCertifications(resp);
    setLoading(false);
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;

  const noCerts = () => (
    <div style={{ overflow: 'scroll', width: '100%', display: 'flex' }}>
      <Typography className={classes.title} variant="body2" component="p">
        No certifications assigned
      </Typography>
    </div>
  );

  const certificationsListView = () => (
    <div style={{ overflow: 'scroll', width: '100%' }}>
      {user?.certifications?.map((entry) => (
        <CertTile entry={_.find(certifications, { id: entry })?.title} />
      ))}
    </div>
  );

  return (
    <UserAccountCard title={getTitle()} minHeight={minHeight} maxHeight={minHeight}>
      {container({
        direction: 'column',
        style: { justifyContent: 'start', width: '100%', alignItems: 'stretch' },
        spacing: 0,
        children: loading
          ? item({ flex: 12, children: spinner() })
          : user?.certifications
            ? certificationsListView()
            : noCerts(),
      })}
    </UserAccountCard>
  );
};

export default _.flow([
  connect((state) => ({
    user: userInfoSelector(state) ?? {},
  })),
])(TCICertifications);
