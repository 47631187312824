import _ from 'lodash';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import { Grid, Button, makeStyles } from '@material-ui/core';
import React from 'react';

import MDTextInputField from '../FormComponents/MDTextInput/MDTextInputField';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validate = (values) => {
  // eslint-disable-next-line prefer-const
  let errors = {};
  let requiredFields = [];

  requiredFields = ['password', 'repeatPassword'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.password !== values.repeatPassword) {
    errors.repeatPassword = 'Password do not match.';
  }

  return errors;
};

const PasswordResetForm = (props) => {
  const { dispatch } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={2} style={{ width: '100%', marginTop: 50, padding: 0 }}>
      <Grid item xs={12}>
        <Field
          id="password"
          name="password"
          variant="outlined"
          component={MDTextInputField}
          placeholder="New Password"
          label="New Password"
          required
          autoComplete="password"
          type="password"
          autoFocus
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          id="repeatPassword"
          name="repeatPassword"
          variant="outlined"
          component={MDTextInputField}
          placeholder="Repeat Password"
          label="Repeat Password"
          required
          autoComplete="password"
          type="password"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={() => dispatch(submit('PasswordResetForm'))}
        >
          Reset password
        </Button>
      </Grid>
    </Grid>
  );
};

export default _.flow([
  connect(),
  reduxForm({
    form: 'PasswordResetForm',
    validate,
  }),
])(PasswordResetForm);
