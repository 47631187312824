/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/destructuring-assignment */
// import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import * as dates from 'date-arithmetic';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import { Grid, Divider, CardContent, Typography } from '@material-ui/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { makeStyles } from '@material-ui/core/styles';
import MonthInDay from './MonthInDay';
import PopoverHeader from '../CalendarComponents/EventPopper/EventPopperHeader';
import EventPopperForm from '../CalendarComponents/EventPopper/EventPopperForm';
import * as OrdersActions from '../../../redux/actions/orders.actions';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import { EVENT_TYPE } from '../../../utils/consts';
import ConfirmationNavButton from '../../Button/ConfirmationNavButton';
import { copyLocalDateToTimezone } from '../../../utils/dateUtils';
import { customersListSelector } from '../../../redux/selectors/customers.selectors';
import EventSummaryButton from '../../Button/EventSummaryButton';
import OrderStatusButton from '../../Button/OrderStatusButton';
import DuplicateEventButton from '../../Button/DuplicateEventButton';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inherit',
    overflow: 'hidden',
    justifyContent: 'center',
  },
  dayView: {
    display: 'inherit',
    width: '68%',
    overflow: 'auto',
  },
  detailGrid: {
    minWidth: '380px',
    width: '380px',
    height: '100%',
    maxHeight: 795,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    overflow: 'auto',
  },
  eventDetail: {
    padding: '30px 10px',
  },
  eventTitle: {
    verticalAlign: 'middle',
    fontWeight: 'bold',
  },
  event: {
    textAlign: 'left',
    padding: '10px',
  },
  noEvent: {
    verticalAlign: 'middle',
    color: '#6E6E6E',
    padding: '50px 10px',
  },
}));

const DayView = ({ date, dispatch, disableEditing, ...props }) => {
  const range = DayView.range(date);
  const firstMonth = dates.startOf(date, 'year');
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [selectedOrder, setSelectedOrder] = React.useState();
  const switchDay =
    selectedEvent && moment.utc(selectedEvent.start).format('YYYY-MM-DD') !== moment.utc(date).format('YYYY-MM-DD');
  const customers = useSelector(customersListSelector);
  const classes = useStyles();
  const selectedTimezone = useSelector((state) => state.utils.tz.value);

  useEffect(() => {
    if (switchDay) {
      setSelectedEvent(null);
    }
  }, [date]);

  const loadOrder = async () => {
    try {
      if (selectedEvent?.desc?.eventType !== EVENT_TYPE.TIME_BLOCK) {
        const orderObj = await dispatch(OrdersActions.fetchOrderSummary(selectedEvent?.desc?.order ?? ''));
        setSelectedOrder(orderObj);
      }
    } catch (error) {
      const errMessage = 'Error Loading Event Details. Please contact an Admin';
      dispatch(
        ModalsActions.showModal('SHOW_EVENT_POPPER_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { errMessage },
        }),
      );
    }
  };

  useEffect(async () => {
    if (selectedEvent) {
      await loadOrder();
    }
  }, [selectedEvent]);

  const selectEventHandler = (selected) => {
    setSelectedEvent({
      ...selected,
      start: copyLocalDateToTimezone(selected.start, selectedTimezone).utc(),
      end: copyLocalDateToTimezone(selected.end, selectedTimezone).utc(),
    });
  };

  const navigateHandler = (dateClicked) => {
    props.onHandleNavigation(dateClicked.toDate());
  };

  const confirmationNavBtn = () => <ConfirmationNavButton orderId={selectedOrder?.id} />;

  return (
    <Grid className={classes.root}>
      <Grid item className={classes.dayView}>
        <TimeGrid {...props} range={range} eventOffset={10} onSelectEvent={selectEventHandler} />
      </Grid>
      <Grid item className={classes.detailGrid}>
        <CardContent>
          <MonthInDay
            key={`dayview${date.getMonth()}`}
            date={dates.add(firstMonth, date.getMonth(), 'month')}
            curDay={date}
            onClick={navigateHandler}
          />
          <Divider />
          <div className={classes.eventDetail}>
            {selectedEvent ? (
              <Grid item xs={12}>
                <PopoverHeader
                  dayView
                  selectedEvent={selectedEvent}
                  onEventEdited={props.onEventEdited}
                  order={selectedOrder}
                  onClosePopper={() => setSelectedEvent(null)}
                  customers={customers}
                >
                  {selectedEvent.title}
                </PopoverHeader>
                {selectedEvent?.desc?.eventType !== EVENT_TYPE.TIME_BLOCK && (
                  <EventSummaryButton marginLeft={10} orderId={selectedEvent?.desc?.order} />
                )}
                <Divider />
                <Grid className={classes.event} container spacing={2}>
                  <EventPopperForm selectedEvent={selectedEvent} order={selectedOrder} />
                </Grid>
                {selectedEvent?.desc?.eventType !== EVENT_TYPE.TIME_BLOCK && (
                  <DuplicateEventButton size={30} order={selectedOrder} selectedEvent={selectedEvent} />
                )}
                <OrderStatusButton
                  selectedOrder={selectedOrder}
                  selectedEvent={selectedEvent}
                  disableEditing={disableEditing}
                  loadOrder={loadOrder}
                  reloadOrder={setSelectedOrder}
                />
                {selectedOrder && confirmationNavBtn()}
              </Grid>
            ) : (
              <Typography variant="h6" className={classes.noEvent} gutterBottom>
                No Course Selected
              </Typography>
            )}
          </div>
        </CardContent>
      </Grid>
    </Grid>
  );
};

// DayView.propTypes = {
//   date: PropTypes.instanceOf(Date).isRequired,
// };

DayView.defaultProps = TimeGrid.defaultProps;

DayView.range = (date) => [dates.startOf(date, 'day')];
DayView.scrollToTime = moment('7:00am', 'h:mma').toDate();

DayView.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -1, 'day');

    case navigate.NEXT:
      return dates.add(date, 1, 'day');

    default:
      return date;
  }
};

DayView.title = (date, { localizer }) => localizer.format(date, 'dayHeaderFormat');

export default connect()(DayView);
