import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: ({ margin }) => margin ?? 20,
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 40,
  },
  title: {
    color: 'white',
    margin: 0,
  },
}));

const Spinner = ({ title, spinnerStyle, titleStyle, logo, customStyle, spinnerProps, margin }) => {
  const classes = useStyles({ margin });
  return (
    <div className={classes.container} style={{ ...customStyle }}>
      {logo && <img src={logo} alt="logo" className={classes.logo} />}
      <CircularProgress style={{ ...spinnerStyle }} {...spinnerProps} />
      {title && (
        <h4 className={classes.title} style={{ ...titleStyle }}>
          {title}
        </h4>
      )}
    </div>
  );
};

export default Spinner;
