import _ from 'lodash';
import React from 'react';
import BaseEventWrapper from '../WeekView/BaseEventWrapper';

const SubcontractedEventWrapper = ({ event }) => {
  const { title } = event;
  const numEvents = (event.event?.desc?.data ?? []).length;
  return (
    <BaseEventWrapper title={title}>
      {numEvents > 1 && (
        <div>
          <span style={{ fontWeight: 400 }}>{`(${numEvents} events)`}</span>
        </div>
      )}
    </BaseEventWrapper>
  );
};

export default _.flow()(SubcontractedEventWrapper);
