/* eslint-disable import/no-cycle */
import { Box, Grid } from '@material-ui/core';
import React from 'react';
import SimpleText from '../Text/SimpleText';
import BrowserCompabilityDialogWrapper from './BrowserCompabilityDialogWrapper';
import BrowserIcon from './BrowserIcon/BrowserIcon';
import { ChromeIcon } from '../SVGIcons/SVGIcons';
import { ReactComponent as EdgeIcon } from '../../assets/images/edgeIcon.svg';
import { ReactComponent as FirefoxIcon } from '../../assets/images/firefoxIcon.svg';
import { ReactComponent as SafariIcon } from '../../assets/images/safariIcon.svg';
import { ReactComponent as OperaIcon } from '../../assets/images/operaIcon.svg';
import { COMPATIBLE_BROWSERS } from '../../utils/consts';

export const BROWSERS = {
  chrome: <ChromeIcon />,
  edge: <EdgeIcon />,
  firefox: <FirefoxIcon />,
  safari: <SafariIcon />,
  opera: <OperaIcon />,
};

const BrowserCompabilityDialog = () => {
  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;

  return (
    <BrowserCompabilityDialogWrapper>
      {text('Your current browser is incompatible, here is a list of the recommended browsers:')}
      <Box height={20} />
      <Grid container spacing={4}>
        {COMPATIBLE_BROWSERS.map((browser) => (
          <Grid item xs={6}>
            <BrowserIcon name={browser.name} version={browser.version} date={browser.date}>
              {BROWSERS[browser.name.toLowerCase()]}
            </BrowserIcon>
          </Grid>
        ))}
      </Grid>
    </BrowserCompabilityDialogWrapper>
  );
};

export default BrowserCompabilityDialog;
