/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
import { MenuItem } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Field, initialize, reduxForm } from 'redux-form';
import FASSelectField from '../../../../components/Forms/CustomFormComponents/FASSelectField';
import MDTextInputField from '../../../../components/Forms/FormComponents/MDTextInput/MDTextInputField';
import Container from '../../../../components/LayoutBuilders/Container';
import ContainerItem from '../../../../components/LayoutBuilders/ContainerItem';
import SettingsTile from '../../../../components/Settings/Other/SettingsTile';
import SimpleText from '../../../../components/Text/SimpleText';
import { updateNotificationAdminSettings } from '../../../../redux/actions/settings.actions';
import { notificationsConfigSelector } from '../../../../redux/selectors/settings.selectors';
import {
  NOTIFICATIONS_LABELS,
  NOTIFICATION_ADMIN_SETTINGS,
  NOTIFICATION_ADMIN_SETTINGS_DEFAULTS,
  NOTIFICATION_ADMIN_SETTINGS_LABEL,
  NOTIFICATION_TYPE,
  YES_NO,
} from '../../../../utils/consts/notifications.consts';

const formName = 'NotificationsAdminSettingsForm';
const NotificationsAdminSettings = ({ initialState }) => {
  const dispatch = useDispatch();

  const updateNotificationsSetting = (formVals) => {
    const payload = JSON.parse(
      JSON.stringify(formVals ?? {})
        .replaceAll('YES', true)
        .replaceAll('NO', false),
    );

    dispatch(updateNotificationAdminSettings(payload));
  };

  React.useEffect(() => {
    let initial = { ...(initialState ?? {}) };
    Object.keys(NOTIFICATION_TYPE).forEach((settingKey) => {
      if (!initial.hasOwnProperty(settingKey)) {
        initial[settingKey] = Object.keys(NOTIFICATION_ADMIN_SETTINGS).reduce(
          (red, settingProp) => ({ ...red, [settingProp]: NOTIFICATION_ADMIN_SETTINGS_DEFAULTS[settingProp] }),
          {},
        );
      }
    });
    initial = JSON.parse(
      JSON.stringify(initial ?? {})
        .replaceAll('true', YES_NO.YES.toString())
        .replaceAll('false', YES_NO.NO.toString()),
    );
    dispatch(initialize(formName, initial));
    // if (initialState && _.isEmpty(initialState)) {
    //   // not notifications settings object setup at all yet
    //   updateNotificationsSetting(initial);
    // }
  }, []);

  const onChangeNotificationSetting = (settingKey, settingProp, value) => {
    updateNotificationsSetting({
      [settingKey]: {
        [settingProp]: value,
      },
    });
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;

  const menuItem = (opt) => (
    <MenuItem key={opt.key} value={opt.value}>
      {opt.label}
    </MenuItem>
  );

  const dropdown = (settingKey, settingProp) => {
    const fieldId = `${settingKey}.${settingProp}`;
    const options = Object.keys(NOTIFICATION_ADMIN_SETTINGS[settingProp]).map((optKey) => ({
      key: optKey,
      value: NOTIFICATION_ADMIN_SETTINGS[settingProp][optKey],
      label: optKey,
    }));
    return item({
      flex: 'auto',
      style: {
        height: '100%',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 6,
      },
      children: (
        <Field
          variant="outlined"
          label={NOTIFICATION_ADMIN_SETTINGS_LABEL[settingProp]}
          id={fieldId}
          name={fieldId}
          component={FASSelectField}
          displayEmpty
          noErrorLabel
          style={{ textAlign: 'start', borderRadius: 0 }}
          onChange={(val) => onChangeNotificationSetting(settingKey, settingProp, val)}
        >
          {options.map((opt) => menuItem(opt))}
        </Field>
      ),
    });
  };

  const settingKeyLabel = (key) =>
    item({
      flex: 2,
      children: text(NOTIFICATIONS_LABELS[key] ?? '', {
        style: {
          fontWeight: 'bold',
          fontSize: 16,
        },
      }),
    });

  const manualInput = (settingKey, settingProp) => {
    const fieldId = `${settingKey}.${settingProp}`;
    const label = NOTIFICATION_ADMIN_SETTINGS_LABEL[settingProp];
    const inputType = NOTIFICATION_ADMIN_SETTINGS[settingProp].split('::')?.[1] ?? 'text';
    return item({
      flex: 1,
      style: {
        height: '100%',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 6,
      },
      children: (
        <Field
          variant="outlined"
          label={label}
          id={fieldId}
          name={fieldId}
          component={MDTextInputField}
          noErrorLabel
          style={{ textAlign: 'start', borderRadius: 0 }}
          size="small"
          type={inputType}
          normalize={(val) => (inputType === 'number' ? Number(val ?? 0) ?? 0 : val)}
          noBorderRadius
          placeholder={label}
          min={0}
          onChange={(val) =>
            onChangeNotificationSetting(settingKey, settingProp, inputType === 'number' ? Number(val ?? 0) ?? 0 : val)
          }
        />
      ),
    });
  };

  return (
    <SettingsTile title="Notifications Settings">
      {container({
        style: { padding: 20, paddingTop: 0 },
        children: [
          Object.keys(NOTIFICATION_TYPE).map((settingKey) =>
            item({
              flex: 12,
              children: container({
                style: { justifyContent: 'flex-start', alignItems: 'center', height: '100%' },
                spacing: 1,
                children: [
                  settingKeyLabel(settingKey),
                  ...Object.keys(NOTIFICATION_ADMIN_SETTINGS).map((settingProp) =>
                    String(NOTIFICATION_ADMIN_SETTINGS[settingProp]).startsWith('input')
                      ? manualInput(settingKey, settingProp)
                      : dropdown(settingKey, settingProp),
                  ),
                ],
              }),
            }),
          ),
        ],
      })}
    </SettingsTile>
  );
};

export default _.flow([
  connect((state) => ({
    initialState: notificationsConfigSelector(state),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  }),
])(NotificationsAdminSettings);
