/* eslint-disable max-len */
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { getFormValues } from 'redux-form';
import { unitsOfTime } from '../../utils/consts';

/* eslint-disable import/prefer-default-export */
export const configurationsSelector = (state) => state.settings?.configs ?? {};
export const cancellationReasonsSelector = (state) => configurationsSelector(state).CancelReasons ?? [];
export const showRevenueDefaultSelector = (state) => state.settings?.configs?.defaultShowRevenue ?? false;
export const showRevenueSelector = (state) => getFormValues('OptionToolbar')(state)?.showRevenue ?? false;
export const materialCategoriesSelector = (state) => configurationsSelector(state).materialCategories ?? [];
export const notificationsConfigSelector = (state) => configurationsSelector(state).notificationsConfigs ?? {};
export const tasksConfigSelector = (state) => configurationsSelector(state).taskConfigs ?? {};
export const orgRolesRelationsSelector = (state) => configurationsSelector(state).orgRelations ?? {};
export const locationDefaultsSelector = (state) => configurationsSelector(state).locationDefaults ?? {};
export const holidaysSelector = (state) => configurationsSelector(state).holidays ?? {};
export const defaultWorkingHoursSelector = (state) => configurationsSelector(state).defaultOfficeHours ?? {};
export const locationDefaultTzSelector = (state, loc) => locationDefaultsSelector(state)?.[loc]?.tz;
export const locationDefaultTruckNumSelector = (state, loc) => locationDefaultsSelector(state)?.[loc]?.truckNumber;

export const regionsInfoSelector = (state) => state.settings?.regions ?? [];

/*
 * this function takes the date of order finalization and returns
 * TRUE if the time to edit expired,
 * FALSE if the time has not expired yet
 */
export const participantsEditTimeExpired = (finalizedDate, subcontracted) => (state) => {
  if (!finalizedDate) return false;
  const configs = configurationsSelector(state);
  const expiration = finalizedDate
    .utc()
    .clone()
    .add(
      configs?.[subcontracted ? 'subcontractorParticipantsEditTime' : 'participantsEditTime']?.duration ??
        (subcontracted ? 7 : 0),
      configs?.[subcontracted ? 'subcontractorParticipantsEditTime' : 'participantsEditTime']?.unit ??
        (subcontracted ? unitsOfTime.days : unitsOfTime.minutes),
    );
  const now = moment().utc();
  return now.isAfter(expiration);
};
