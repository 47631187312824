/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Field, change, getFormValues, reduxForm } from 'redux-form';
import { Typography } from '@material-ui/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsTile from './SettingsTile';
import Container from '../../../utils/LayoutBuilders/Container';
import ContainerItem from '../../../utils/LayoutBuilders/ContainerItem';
import { adminTypeSelector } from '../../../redux/selectors/user.selectors';
import { ROLE_ACCESSES } from '../../../utils/consts';
import { defaultWorkingHoursSelector } from '../../../redux/selectors/settings.selectors';
import StyledButton from '../../Button/StyledButton';
import * as SettingsActions from '../../../redux/actions/settings.actions';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import MDAutoComplete from '../../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';

const StyledTimePicker = styled(MDAutoComplete)`
  & .rc-time-picker-input {
    background: #f1f3f4;
    border: none;
    text-align: center;
    border-radius: 8;

    &:disabled {
      cursor: auto;
      background: #f1f3f4;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #000;
      opacity: 1; /* Firefox */
    }
  }
`;

const formName = 'WorkingHoursDefaultsForm';
const startTimeFieldID = 'defaultStartTime';
const endTimeFieldID = 'defaultEndTime';

const validate = () => {
  const errors = [];

  return errors;
};

const WorkingHoursDefault = ({ isSuperUser, data: { startTime, endTime }, formData }) => {
  const dispatch = useDispatch();

  React.useEffect(async () => {
    const initStartTime = startTime ?? '7:00 AM';
    const initEndTime = endTime ?? '5:00 PM';
    dispatch(change(formName, startTimeFieldID, initStartTime));
    dispatch(change(formName, endTimeFieldID, initEndTime));
    return () => {};
  }, []);

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt) => <Typography style={{}}>{txt}</Typography>;

  const getOptions = () => {
    const currentTime = moment('12:00 AM', 'h:mm A');
    return Array.from(Array(48)).map((__, i) =>
      currentTime
        .clone()
        .add(30 * i, 'minutes')
        .format('h:mm A'),
    );
  };

  const timePicker = (id) => (
    <Field
      id={id}
      name={id}
      component={StyledTimePicker}
      options={getOptions()}
      required
      size="small"
      allowEmpty={false}
      autoComplete="date"
      noHelperText
      noErrorLabel
      popupIcon={<ArrowDropDownIcon color="primary" />}
      disableClearable
    />
  );

  const showToast = (success, msg, warning) => {
    dispatch(
      ModalsActions.showModal('DEFAULT_WORKING_HOURS_STATUS', {
        modalType: warning ? 'WARNING_ALERT' : success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: msg ?? '',
        },
      }),
    );
  };

  const saveButtonHandler = async () => {
    const payload = { startTime: formData?.defaultStartTime, endTime: formData?.defaultEndTime };

    try {
      await dispatch(SettingsActions.updateDefaultOfficeHours(payload));
      showToast(true, 'Updated Default Working Hours');
    } catch (error) {
      showToast(false, 'Error updating Default Working Hours');
    }
  };

  const saveButton = () => (
    <StyledButton variant="contained" color="primary" buttonContent="SAVE" handleButton={saveButtonHandler} />
  );

  const workingHoursSelectors = () =>
    container({
      style: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 10,
        marginBotton: 10,
      },
      spacing: 1,
      children: [
        item({ flex: 2, children: timePicker(startTimeFieldID) }),
        item({ flex: 1, style: { paddingLeft: 40 }, children: text('to') }),
        item({ flex: 2, children: timePicker(endTimeFieldID) }),
        item({ flex: 1, children: saveButton() }),
      ],
    });

  return isSuperUser ? <SettingsTile title="Default Working Hours">{workingHoursSelectors()}</SettingsTile> : <></>;
};

export default _.flow([
  connect((state) => ({
    data: defaultWorkingHoursSelector(state),
    isSuperUser: adminTypeSelector(state) === ROLE_ACCESSES.superUser,
    formData: getFormValues(formName)(state),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  }),
])(WorkingHoursDefault);
