/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import SwapHorizRounded from '@material-ui/icons/SwapHorizRounded';
import StyledButton from '../../Button/StyledButton';
import Container from '../../LayoutBuilders/Container';
import ContainerItem from '../../LayoutBuilders/ContainerItem';
import { showModal } from '../../../redux/actions/modals.actions';
import InstructorSwapDialog from '../../Calendar/CalendarComponents/EventPopper/InstructorSwapDialog';
import { fetchOrderSummary } from '../../../redux/actions/orders.actions';
import Spinner from '../../SpinnerOverlay/Spinner';
import { COLORS, ROLE_ACCESSES } from '../../../utils/consts';
import { changeTaskToResolved } from '../../../redux/actions/tasks.actions';
import { hasUserAccessSelector } from '../../../redux/selectors/user.selectors';

const SwitchInstructorTaskAction = ({ task }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const canEditEvents = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.editEvent]));
  const canCancelEvents = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.canCancel]));

  const {
    resolved,
    resolutionValue,
    body: { tz: orderTz },
    details: { orderID },
  } = task ?? {};

  const onAction = async () => {
    try {
      setLoading(true);
      const order = await dispatch(fetchOrderSummary(orderID));
      openDialog(order);
    } finally {
      setLoading(false);
    }
  };

  const resolveTask = async (resolution) => {
    try {
      setLoading(true);
      const success = await dispatch(changeTaskToResolved(task, resolution));
      showToast(success);
    } finally {
      setLoading(false);
    }
  };

  const showToast = (success) => {
    dispatch(
      showModal('TASK_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: success ? 'Task Completed!' : 'Something went wrong completing this task!',
        },
      }),
    );
  };

  const openDialog = (order) => {
    const modalName = 'INSTRUCTOR_SWAP_ALERT';
    dispatch(
      showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 16 },
          hideCancel: true,
          disableBackdropClick: true,
          maxWidth: 'md',
          title: 'SELECT INSTRUCTOR',
          content: (
            <InstructorSwapDialog
              hideSecondary
              modalName={modalName}
              preloadedOrder={order}
              onDone={resolveTask}
              tzOverride={orderTz}
              allowCancel={canCancelEvents}
              allowEdit={canEditEvents}
            />
          ),
        },
      }),
    );
  };

  const container = ({ children, ...rst }) => <Container {...(rst ?? {})}>{children}</Container>;
  const item = ({ children, ...rst }) => <ContainerItem {...(rst ?? {})}>{children}</ContainerItem>;

  const switchButton = () => (
    <StyledButton
      variant="text"
      color="primary"
      handleButton={onAction}
      buttonContent="REASSIGN INSTRUCTOR"
      startIcon={<SwapHorizRounded />}
      style={{ width: '100%' }}
    />
  );

  const resolvedValueDisplay = () => {
    const parts = (resolutionValue ?? '').split('::');
    const newAssignee = parts[parts.length - 1] ?? '';
    return (
      <StyledButton
        disabled
        variant="text"
        color="primary"
        buttonContent={`${newAssignee.toUpperCase()}`}
        startIcon={<SwapHorizRounded />}
        style={{ width: '100%' }}
      />
    );
  };

  const spinner = () => (
    <Spinner
      spinnerStyle={{
        height: 20,
        width: 20,
        padding: 0,
        margin: 0,
        color: COLORS.CINTAS_BLUE,
      }}
      customStyle={{
        maxHeight: 20,
        maxWidth: 20,
        margin: 0,
        marginTop: '2%',
        padding: 0,
      }}
    />
  );

  return container({
    style: { justifyContent: 'center', alignItems: 'center', textAlign: 'center' },
    children: loading
      ? spinner()
      : [
          item({
            flex: 12,
            children: [!resolved && switchButton(), resolved && resolvedValueDisplay()],
          }),
        ],
  });
};

export default _.flow([connect()])(SwitchInstructorTaskAction);
