/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { Divider } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React from 'react';
import { connect } from 'react-redux';
import { timezoneSelector } from '../../../../redux/selectors/utils.selectors';
import { COLORS, DEFAULT_CURRENCY, EVENT_TYPE, TIME_FORMAT } from '../../../../utils/consts';
import { ccyFormat } from '../../../../utils/helpers';
import ConfirmationNavButton from '../../../Button/ConfirmationNavButton';
import EventSummaryButton from '../../../Button/EventSummaryButton';
import Container from '../../../LayoutBuilders/Container';
import ContainerItem from '../../../LayoutBuilders/ContainerItem';

export class BaseEventsListEntry {
  constructor({ title, time, price, order, data, isTimeBlock }) {
    this.title = title ?? '';
    this.time = time;
    this.order = order ?? '';
    this.data = data ?? {};
    this.price = price ?? { amount: 0 };
    this.isTimeBlock = Boolean(isTimeBlock);
  }

  static fromEvent(event) {
    return new BaseEventsListEntry({
      title: !_.isEmpty(event.title) ? event.title : event.reason,
      order: event.order,
      data: event,
      time: event.startTime,
      isTimeBlock: event.eventType === EVENT_TYPE.TIME_BLOCK,
      price: {
        ...(event.price ?? {}),
        amount:
          event.cancellationFee !== null && event.cancellationFee !== undefined
            ? event.cancellationFee
            : event.price?.amount,
      },
    });
  }
}

const BaseEventsListTile = ({
  entry,
  clickCallback,
  tz,
  hidePrice, // allowEditing, onEventEdited,
}) => {
  const { price, title, time, order } = entry;
  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = ({ txt, style, ...rest }) => (
    <p style={{ padding: 0, margin: 0, ...style }} {...rest}>
      {txt}
    </p>
  );

  const cancellationDetails = () => (
    <div>
      {item({
        flex: 9,
        children: [text({ txt: _.startCase((title ?? '').toLowerCase()), style: { fontWeight: 'bold' } })],
      })}
      {container({
        style: { justifyContent: 'space-between' },
        children: [
          item({ flex: 3, children: [text({ txt: moment.utc(time).tz(tz).format(TIME_FORMAT) })] }),
          price?.amount && price?.amount > 0 && !hidePrice ? (
            item({
              flex: 2,
              style: { textAlign: 'end' },
              children: [
                text({
                  txt: ccyFormat(price?.amount, price?.currency ?? DEFAULT_CURRENCY),
                  style: { fontSize: 14, color: COLORS.CINTAS_DARK_GRAY },
                }),
              ],
            })
          ) : (
            <></>
          ),
        ],
      })}
    </div>
  );

  const actions = () =>
    container({
      style: { justifyContent: 'space-between', verticalAlign: 'end', marginTop: 10 },
      children: [
        // ...(allowEditing ? [
        //   item({
        //     flex: 2,
        //     children: [<EditOrderButton orderId={order} callback={clickCallback} onEventEdited={onEventEdited} />],
        //   }),
        // ] : []),
        item({
          flex: 10,
          style: { marginTop: 'auto' },
          children: [<EventSummaryButton orderId={order} clickCallback={clickCallback} />],
        }),
        item({ flex: 2, children: [<ConfirmationNavButton orderId={order} size={14} />] }),
      ],
    });

  return (
    <div style={{ marginTop: 24 }}>
      {cancellationDetails(entry)}
      {order && actions(entry)}
      <Divider style={{ marginTop: 10 }} />
    </div>
  );
};

export default _.flow([
  connect((state) => ({
    tz: timezoneSelector(state),
  })),
])(BaseEventsListTile);
