/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import { change, getFormValues, reduxForm } from 'redux-form';
import { Typography } from '@material-ui/core';
import {
  currentUserAvailableLocationsSelector,
  currentUserLocationsSelector,
} from '../../redux/selectors/user.selectors';
import { COLORS, CUSTOMER_SEARCH_RESULTS_TRESHOLD } from '../../utils/consts';
import CustomerSearchFilters, {
  fieldsNames,
  filtersFields,
} from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CustomerAdvancedSearch/CustomerSearchFilters';
import SimpleText from '../Text/SimpleText';
import * as CustomersActions from '../../redux/actions/customers.actions';
import Spinner from '../SpinnerOverlay/Spinner';
import CustomersTable from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CustomerAdvancedSearch/CustomersTable';
import { createDisplayData } from '../Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CustomerAdvancedSearch/CustomerAdvancedSearch';
import { showTresholdReachedPopup } from '../../utils/event.utils';
// import MDAutoComplete from '../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';

const formName = 'CustomerDashboardSearch';
const contentHeight = 620;
const tableBodyHeight = 460;

const SearchCustomerDashboardStep = ({
  label,
  onCustomerSelected,
  dispatch,
  values,
  regions,
  locationsOptions,
  userSalesOffices,
}) => {
  const [customers, setCustomers] = React.useState();
  const [selectedCustomer, setSelectedCustomer] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const selectedRegion = values?.filters?.[fieldsNames.fasRegion];
  const getLocationLabel = (loc) => `${loc} - ${regions?.[loc]?.loc_name ?? ''}`;
  const userRegions = [...Object.values(regions)].filter((val) => (locationsOptions ?? []).includes(val.loc_id));
  const locationInputProps = {
    label: 'FAS Location',
    id: [fieldsNames.salesOffice],
    backendField: fieldsNames.salesOffice,
    hide_column: true,
    row: 0,
    type: 'select',
    flex: 3,
    style: { marginBottom: 20 },
    // initialValue: locationsOptions?.[0] ? { label: getLocationLabel(locationsOptions[0]), id: fieldsNames.salesOffice, key: locationsOptions[0] } : undefined,
    disableInput: !selectedRegion,
    // disableClear: true,
    options: [...locationsOptions]
      .filter((offi) => regions?.[offi]?.region_code === selectedRegion?.key)
      .map((offi) => ({ label: getLocationLabel(offi), id: fieldsNames.salesOffice, key: offi })),
  };
  const regionInputProps = {
    label: 'FAS Region',
    id: [fieldsNames.fasRegion],
    backendField: fieldsNames.fasRegion,
    hide_column: true,
    row: 0,
    type: 'select',
    flex: 3,
    style: { marginBottom: 20 },
    initialValue: selectedRegion,
    options: Object.values(_.groupBy(userRegions, (val) => val.region_code)).map((locInfos) => ({
      id: fieldsNames.fasRegion,
      key: locInfos?.[0].region_code,
      label: locInfos?.[0].region_name,
    })),
  };

  const extraFilters = [regionInputProps, locationInputProps];
  const columns = filtersFields(extraFilters)
    .filter((f) => !f.hide_column)
    .map((h) => h.label);

  React.useEffect(() => {
    dispatch(change(formName, `filters.${fieldsNames.salesOffice}`, undefined));
  }, [selectedRegion?.key]);

  const getRegionLocationIds = (regionCode) => {
    const inRegion = userRegions.filter((reg) => reg.region_code === regionCode);
    return _.uniq(inRegion.map((info) => info.loc_id));
  };

  const getValueLabel = (field) =>
    (field.id ?? []).length > 0
      ? field.type === 'select'
        ? ((values?.filters ?? {})[field.id[0]] ?? {}).label
        : (values?.filters ?? {})[field.id[0]] ?? null
      : undefined;

  const getValue = (field) => (field.id ? (values?.filters ?? {})[field.id] ?? undefined : undefined);

  const calcRows = (data, filters) => {
    const newData = data.map((c) => createDisplayData(c));
    const newRows = newData.map((c) =>
      (filters ?? filtersFields()).filter((f) => !f.hide_column).map((h) => c[h.id] ?? ''),
    );
    setRows(newRows);
  };

  const loadCustomers = async (filtersPayload, filters) => {
    try {
      setLoading(true);
      let data;
      if (filtersPayload) {
        const resultsCount = await dispatch(CustomersActions.searchCustomerCount(filtersPayload));
        if (resultsCount > CUSTOMER_SEARCH_RESULTS_TRESHOLD) {
          showTresholdReachedPopup(dispatch);
          return;
        }
        data = await dispatch(CustomersActions.searchCustomer(filtersPayload));
      } else {
        data = undefined;
      }
      setCustomers(data);
      setSelectedCustomer();
      onCustomerSelected();
      calcRows(data ?? [], filters);
    } finally {
      setLoading(false);
    }
  };

  const handleApplyFilters = (filters) => {
    const filtersToApply = filters.filter((f) => f.applied && f.type !== 'submit' && f.type !== 'clear');
    if (filtersToApply.length === 0) {
      loadCustomers(); // reset the customers by passing empty payload
      return;
    }
    const payload = filtersToApply.reduce((red, f) => {
      const value = (f.type === 'select' ? (getValue(f) ?? {}).key : f.applied ?? getValue(f)) ?? '';
      return { ...red, [f.backendField?.toLowerCase()]: value };
    }, {});

    payload[fieldsNames.salesOffice] = payload[fieldsNames.salesOffice]
      ? [payload[fieldsNames.salesOffice]]
      : payload[fieldsNames.fasRegion]
        ? getRegionLocationIds(payload[fieldsNames.fasRegion])
        : userSalesOffices;
    loadCustomers(payload, filters);
  };

  const handleRowClick = (__, rowState, ___, force) => {
    const selection =
      rowState.length > 0
        ? { ...(customers[rowState[0].dataIndex] ?? {}), rowIndex: rowState[0].dataIndex }
        : undefined;
    setSelectedCustomer(selection);
    onCustomerSelected(selection, force);
    return selection;
  };

  const onDoubleClick = (dataIndex) => {
    handleRowClick(null, [{ dataIndex }], null, true);
  };

  const header = () => (
    <SimpleText
      txt={label ?? ''}
      fontSize={22}
      style={{ fontWeight: 'bold', color: COLORS.CINTAS_BLUE, paddingTop: 10 }}
    />
  );

  const filters = () => (
    <div style={{ padding: 20 }}>
      <CustomerSearchFilters
        key={selectedRegion?.key}
        hideClearAll
        noEmptyValues
        handleApplyFilters={handleApplyFilters}
        extraFields={extraFilters}
        dispatch={dispatch}
        formName={formName}
        getValue={getValue}
        getValueLabel={getValueLabel}
      />
    </div>
  );

  const customersTable = () => (
    <div style={{ padding: 22 }}>
      <CustomersTable
        key="table-customers"
        initialSorting={filtersFields(extraFilters)[1].label}
        onClick={handleRowClick}
        onDoubleClick={onDoubleClick}
        columns={columns}
        rows={rows}
        tableBodyHeight={tableBodyHeight}
        contentHeight={contentHeight}
        customers={customers}
        customerSelection={selectedCustomer}
      />
    </div>
  );

  const infoText = (txt) => <Typography style={{ padding: 22, paddingTop: 30 }}>{txt}</Typography>;

  const spinner = () => <Spinner customStyle={{ margin: '20%' }} />;

  return (
    <div style={{ position: 'relative' }}>
      {header()}
      {filters()}
      {loading
        ? spinner()
        : !customers
          ? infoText('Input filters to search for a customer')
          : customers.length === 0
            ? infoText('No results found')
            : customersTable()}
    </div>
  );
};

export default _.flow(
  connect((state) => ({
    values: getFormValues(formName)(state),
    userSalesOffices: currentUserLocationsSelector(state),
    locationsOptions: [...currentUserAvailableLocationsSelector(state)].sort(),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    asyncBlurFields: [],
    initialValues: {
      filters: {},
    },
  }),
  // withRouter(),
)(SearchCustomerDashboardStep);
