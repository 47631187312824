/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const fetchPostgresTablesNames = () => baseInstance.get('/postgresTableNames');

export const fetchPostgresColumnNames = (tableName) => baseInstance.get(`/postgresColumnNames?table=${tableName}`);

export const fetchPostgresDataCount = (tableName, payload) =>
  baseInstance.post(`/postgresDataCount?table=${tableName}`, payload);

export const fetchPostgresDataLookup = (tableName, payload) =>
  baseInstance.post(`/postgresDataLookup?table=${tableName}`, payload);
