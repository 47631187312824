import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import MDSnackbarContent from './MDSnackbarContent';
import { COLORS } from '../../utils/consts/index';

const styles = (theme) => ({
  snackbarContent: {
    backgroundColor: COLORS.CINTAS_YELLOW,
  },
  close: {
    padding: theme.spacing(1 / 2),
    color: COLORS.CINTAS_YELLOW_DARK,
  },
  alertMessage: {
    color: COLORS.CINTAS_YELLOW_DARK,
  },
});

const SnackbarContentWrapper = withStyles(styles)(MDSnackbarContent);

const MDWarningAlert = (props) => {
  const handleClose = (event, reason) => {
    const { hideModal } = props;
    if (reason === 'clickaway') {
      return;
    }
    hideModal();
  };

  const { anchorOrigin, message, duration } = props;

  return (
    <div>
      <Snackbar anchorOrigin={anchorOrigin} open autoHideDuration={duration ?? 5000} onClose={handleClose} {...props}>
        <SnackbarContentWrapper onClose={handleClose} message={message} />
      </Snackbar>
    </div>
  );
};

MDWarningAlert.defaultProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
};

export default MDWarningAlert;
