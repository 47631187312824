/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { initialize } from 'redux-form';
import React from 'react';
import CommissionLevelEditTable from '../../../components/Settings/Other/CommissionLevelEditTable';
import LocationSettings from '../../../components/Settings/Other/LocationSettings';

import * as CommissionLevelsActions from '../../../redux/actions/commissionLevels.actions';
import * as MaterialsActions from '../../../redux/actions/materials.actions';
import * as SAPActions from '../../../redux/actions/sap.actions';
import ParticipantSettings from '../../../components/Settings/Other/ParticipantSettings';
import SettingsFrame from './SettingsFrame';
import { allMaterialsListSelector } from '../../../redux/selectors/materials.selector';
import CancelReasonsSettings from '../../../components/Settings/Other/CancelReasonsSettings';
import { hasAdminAccessSelector } from '../../../redux/selectors/user.selectors';
import { ADMIN_ACCESSES } from '../../../utils/consts';

const MaterialsView = ({ dispatch, settings: settingsData, match: { path } }) => {
  const initialLoad = async () => {
    await dispatch(SAPActions.fetchSAPMaterials());
    await dispatch(MaterialsActions.fetchMaterials());
    await dispatch(CommissionLevelsActions.fetchCommissionLevels());
    dispatch(initialize('LocationSettings', settingsData));
  };

  React.useEffect(() => {
    initialLoad();
    return () => {};
  }, []);

  const canEditCommissions = useSelector((state) => hasAdminAccessSelector(state, [ADMIN_ACCESSES.commissions]));

  return (
    <SettingsFrame path={path}>
      {canEditCommissions && <CommissionLevelEditTable />}
      <LocationSettings />
      <ParticipantSettings />
      <CancelReasonsSettings />
    </SettingsFrame>
  );
};

const mapStateToProps = (state) => ({
  sapMaterials: state.sap.materials,
  materials: { data: allMaterialsListSelector(state) },
  settings: state.settings,
});

export default _.flow([connect(mapStateToProps)])(MaterialsView);
