import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import React from 'react';

import Spinner from './Spinner';

const useStyles = makeStyles(() => ({
  spinnerOverlay: {
    position: 'fixed',
    zIndex: 10000,
    overflow: 'show',
    margin: 'auto',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  innerSpinnerOverlay: {
    margin: 'auto',
  },
}));

const SpinnerOverlay = () => {
  const classes = useStyles();
  const spinner = useSelector((state) => state.utils.spinner);

  return (
    <span>
      {spinner.isVisible && (
        <div className={classes.spinnerOverlay}>
          <Spinner className={classes.innerSpinnerOverlay} title={spinner.title} />
        </div>
      )}
    </span>
  );
};

export default SpinnerOverlay;
