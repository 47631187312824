/* eslint-disable no-empty */
/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { change, getFormValues, reduxForm } from 'redux-form';
import { CUSTOMER_ORDERS_TABS, ORDERS_VIEWS } from '../../utils/consts';
import * as CustomersActions from '../../redux/actions/customers.actions';
import TabView from '../TabView/TabView';
import CustomerQuotesTable from './CustomerQuotesTable';
import FASFilteredOrdersTable from '../Settings/FASOrdersFilteredTable.jsx/FASFilteredOrdersTable';

export const formName = 'CustomerOrdersForm';

const CustomerOrdersTabView = ({ customer, orders, page, totalPages, reloadOrders }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState(Object.values(CUSTOMER_ORDERS_TABS).indexOf(CUSTOMER_ORDERS_TABS.upcoming));
  const getTab = (tIdx) => Object.values(CUSTOMER_ORDERS_TABS)[tIdx];
  const getTabPagingNumber = (tIdx, pagingInfo) => pagingInfo?.[getTab(tIdx)] ?? 0;
  // TODO: Delete this and unused if not needed
  // const hasEditAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.editEvent]));

  // const showToast = (success, message) => {
  //   dispatch(showModal('LOAD_ORDERS_STATUS', {
  //     modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
  //     modalProps: {
  //       message,
  //     },
  //   }));
  // };

  const loadOrders = async (t, pagingInfo, force) => {
    try {
      const tabName = getTab(t);
      const pagingNumber = getTabPagingNumber(t, pagingInfo);
      if (!(orders[tabName]?.[pagingNumber]?.loaded ?? false) || force) {
        setLoading(true);
        const isQuotes = tabName === CUSTOMER_ORDERS_TABS.quotes;
        if (!isQuotes) {
          // const isUpcoming = tabName === CUSTOMER_ORDERS_TABS.upcoming;
          // const result = await dispatch(CustomersActions.fetchCustomerOrders({
          //   formName,
          //   upcoming: isUpcoming,
          //   customer: customer.sold_to,
          //   page: pagingNumber,
          // }));
          // if (result?.error) {
          //   showToast(false, 'Something went wrong loading orders!');
          // }
          return;
        }
        await dispatch(
          CustomersActions.fetchCustomerQuotes({
            formName,
            customer: customer.sold_to,
            page: pagingNumber,
          }),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const softPageReload = async (t, pagingInfo) => {
    const tabName = getTab(t);
    const pagingNumber = getTabPagingNumber(t, pagingInfo);
    const isQuotes = tabName === CUSTOMER_ORDERS_TABS.quotes;
    if (isQuotes) {
      await dispatch(
        CustomersActions.fetchCustomerQuotes({
          formName,
          customer: customer.sold_to,
          page: pagingNumber,
        }),
      );
    }
    // else {
    //   await dispatch(CustomersActions.fetchCustomerOrders({
    //     formName,
    //     upcoming: tabName === CUSTOMER_ORDERS_TABS.upcoming,
    //     customer: customer.sold_to,
    //     page: pagingNumber,
    //   }));
    // }
  };

  const onTabChange = (t) => {
    setTab(t);
    loadOrders(t, page);
  };

  const getTabOrders = () => {
    const pagedOrdersForTab = orders[getTab(tab)] ?? {};
    // const currTabPage = getTabPagingNumber(tab, page);
    // if (currTabPage === 0) {
    //   return (pagedOrdersForTab[currTabPage] ?? {}).data ?? {};
    // }
    const pageStart = Object.keys(pagedOrdersForTab);
    let ordersDataToPage = {};
    pageStart.forEach((i) => {
      const pageData = (pagedOrdersForTab[i] ?? {}).data ?? {};
      ordersDataToPage = { ...ordersDataToPage, ...pageData };
    });
    // while (pageStart <= currTabPage) {
    //   const pageData = (pagedOrdersForTab[pageStart] ?? {}).data ?? {};
    //   ordersDataToPage = { ...ordersDataToPage, ...pageData };
    //   pageStart += 1;
    // }
    return ordersDataToPage;
  };

  React.useEffect(() => {
    softPageReload(tab, page);
  }, [reloadOrders]);

  const ordersTable = (view) => <FASFilteredOrdersTable customerNumber={customer?.sold_to} view={view} />;

  const quotesTable = () => (
    <CustomerQuotesTable
      quotesInfo={getTabOrders()}
      customer={customer}
      page={page[getTab(tab)] ?? 0}
      totalRecords={totalPages[getTab(tab)]}
      loading={loading}
      reloadPageData={() => softPageReload(tab, page)}
      onChangePage={(newPage) => {
        const newPaging = { ...page };
        newPaging[getTab(tab)] = newPage;
        dispatch(change(formName, 'page', newPaging));
        loadOrders(tab, newPaging);
      }}
    />
  );

  return (
    <div style={{ marginTop: 24, marginBottom: 24 }}>
      <TabView
        tabs={Object.values(CUSTOMER_ORDERS_TABS)}
        initialTab={tab}
        alignment="flex-start"
        spacing={20}
        onTabChange={onTabChange}
        tabsContent={[
          ordersTable(ORDERS_VIEWS.upcomingCustomer),
          ordersTable(ORDERS_VIEWS.pastCustomer),
          quotesTable(),
        ]}
      />
    </div>
  );
};

export default _.flow([
  connect((state) => {
    const values = getFormValues(formName)(state);
    return {
      orders: values.orders,
      page: values.page,
      totalPages: values.totalPages,
    };
  }),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    initialValues: {
      totalPages: {
        [CUSTOMER_ORDERS_TABS.upcoming]: 0,
        [CUSTOMER_ORDERS_TABS.past]: 0,
        [CUSTOMER_ORDERS_TABS.quotes]: 0,
      },
      page: {
        [CUSTOMER_ORDERS_TABS.upcoming]: 0,
        [CUSTOMER_ORDERS_TABS.past]: 0,
        [CUSTOMER_ORDERS_TABS.quotes]: 0,
      },
      orders: {
        [CUSTOMER_ORDERS_TABS.upcoming]: {
          0: { loaded: false, data: {} },
        },
        [CUSTOMER_ORDERS_TABS.past]: {
          0: { loaded: false, data: {} },
        },
        [CUSTOMER_ORDERS_TABS.quotes]: {
          0: { loaded: false, data: {} },
        },
      },
    },
  }),
])(CustomerOrdersTabView);
