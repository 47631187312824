/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  adminTypeSelector,
  hasAdminAccessSelector,
  hasUserAccessSelector,
  userAccessMatrixSelector,
} from '../../redux/selectors/user.selectors';

import { ADMIN_ACCESSES, ADMIN_ROLE_ACCESSES, COLORS, ROLE_ACCESSES } from '../../utils/consts';
import { findLandingAdminSection } from '../../routes/sections';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    paddingTop: 24,
    paddingBottom: 32,
  },
  button: {
    fontFamily: 'proxima-nova, sans-serif',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '22px',
    whiteSpace: 'nowrap',
    borderRadius: 0,
    marginRight: 26,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 4,
    borderBottom: '3px solid transparent',
    backgroundColor: 'transparent',
    color: COLORS.CINTAS_BLUE,
    cursor: 'pointer',
  },
  active: {
    borderBottom: `3px solid ${COLORS.CINTAS_RED}`,
    color: COLORS.CINTAS_BLUE,
  },
});

const landingPagePaths = ['/admin', '/admin/'];

const settingsSections = (landingKey = 'orders') => [
  {
    key: 'orders',
    title: 'ORDERS',
    paths: ['/admin/orders', ...(landingKey && landingKey === 'orders' ? landingPagePaths : [])],
    matrixAccesskeys: [ROLE_ACCESSES.viewOrdersPanel],
    adminAccessKeys: [],
  },
  {
    key: 'materials',
    title: 'MATERIALS',
    paths: ['/admin/materials', ...(landingKey && landingKey === 'materials' ? landingPagePaths : [])],
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.materials],
  },
  {
    key: 'courseCategories',
    title: 'COURSE CATEGORIES',
    paths: ['/admin/courseCategories', ...(landingKey && landingKey === 'courseCategories' ? landingPagePaths : [])],
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.categories],
  },
  {
    key: 'training',
    title: 'TRAINING SITES',
    paths: ['/admin/sites', ...(landingKey && landingKey === 'training' ? landingPagePaths : [])],
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.createTrainingSites],
  },
  {
    key: 'certifications',
    title: 'CERTIFICATIONS',
    paths: ['/admin/certifications', ...(landingKey && landingKey === 'certifications' ? landingPagePaths : [])],
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.tciCert],
  },
  {
    key: 'addonsClassification',
    title: 'ADD-ON CLASSIFICATIONS',
    paths: [
      '/admin/addonClassifications',
      ...(landingKey && landingKey === 'addonsClassification' ? landingPagePaths : []),
    ],
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.categories],
  },
  {
    key: 'dataLookUp',
    title: 'DATA LOOK UP',
    paths: ['/admin/dataLookUp', ...(landingKey && landingKey === 'dataLookUp' ? landingPagePaths : [])],
    matrixAccesskeys: [ROLE_ACCESSES.advancedAdmin],
    adminAccessKeys: [],
  },
  {
    key: 'matrix',
    title: 'ACCESS MATRIX',
    paths: ['/admin/accessMatrix', ...(landingKey && landingKey === 'matrix' ? landingPagePaths : [])],
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.matrix],
  },
  {
    key: 'notificationAdminSettings',
    title: 'Notifications & Tasks'.toUpperCase(),
    paths: [
      '/admin/notificationSettings',
      ...(landingKey && landingKey === 'notificationAdminSettings' ? landingPagePaths : []),
    ],
    matrixAccesskeys: [ROLE_ACCESSES.advancedAdmin],
    adminAccessKeys: [],
  },
  {
    key: 'defaults',
    title: 'DEFAULTS',
    paths: ['/admin/adminDefaults', ...(landingKey && landingKey === 'defaults' ? landingPagePaths : [])],
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [ADMIN_ACCESSES.matrix],
  },
  {
    key: 'other',
    title: 'OTHER SETTINGS',
    paths: ['/admin/other', ...(landingKey && landingKey === 'other' ? landingPagePaths : [])],
    matrixAccesskeys: ADMIN_ROLE_ACCESSES,
    adminAccessKeys: [],
  },
];

const AdminNav = (props) => {
  const classes = useStyles();
  const { path, history } = props;
  const matrix = useSelector(userAccessMatrixSelector);
  const adminType = useSelector(adminTypeSelector);
  const sections = useSelector((state) =>
    settingsSections(findLandingAdminSection(matrix)).filter((s) =>
      !adminType || s.adminAccessKeys.length === 0
        ? hasUserAccessSelector(state, s.matrixAccesskeys)
        : hasAdminAccessSelector(state, s.adminAccessKeys),
    ),
  );

  return (
    <div className={classes.root}>
      {_.map(sections, (section) => (
        <div
          key={section.title}
          className={clsx(classes.button, {
            [classes.active]: _.includes(section.paths, path),
          })}
          onClick={() => history.push(section.paths[0])}
        >
          {section.title}
        </div>
      ))}
    </div>
  );
};

export default withRouter(AdminNav);
