/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import _ from 'lodash';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Field, change, getFormValues, initialize, reduxForm, submit } from 'redux-form';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Container from '../../utils/LayoutBuilders/Container';
import ContainerItem from '../../utils/LayoutBuilders/ContainerItem';
import StyledButton from '../Button/StyledButton';
import SimpleText from '../Text/SimpleText';
import FASCheckboxField from '../Forms/CustomFormComponents/FASCheckboxField/FASCheckboxField';
import MDAutoComplete from '../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import { defaultWorkingHoursSelector } from '../../redux/selectors/settings.selectors';
import { userInfoSelector } from '../../redux/selectors/user.selectors';
import { resetUserWorkingHours, updateCurrentUserWorkingHours } from '../../redux/actions/users.actions';
import { hideModal, showModal } from '../../redux/actions/modals.actions';

const formName = 'TCIWorkingHoursForm';

const StyledTimePicker = styled(MDAutoComplete)`
  & .rc-time-picker-input {
    background: #f1f3f4;
    border: none;
    text-align: center;
    border-radius: 8;

    &:disabled {
      cursor: auto;
      background: #f1f3f4;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #000;
      opacity: 1; /* Firefox */
    }
  }
`;

const DAY_NAMES = {
  M: 'Monday',
  T: 'Tuesday',
  W: 'Wednesday',
  TH: 'Thursday',
  F: 'Friday',
};

const buildFormData = (values, defaultWorkingHours) =>
  Object.keys(DAY_NAMES).reduce(
    (prev, key) => ({
      ...prev,
      [key]: {
        startTime: _.isEmpty(values[key]?.startTime)
          ? defaultWorkingHours?.startTime ?? '7:00 AM'
          : values[key].startTime,
        endTime: _.isEmpty(values[key]?.endTime) ? defaultWorkingHours?.endTime ?? '5:00 PM' : values[key].endTime,
        blocked: values[key]?.blocked ?? false,
      },
    }),
    {},
  );

const getDefaultHoursInterval = (workingHours) => {
  const end = moment(workingHours.endTime, 'h:mm A');
  const start = moment(workingHours.startTime, 'h:mm A');
  const duration = moment.duration(end.diff(start));
  return duration.asHours();
};

const TCIWorkingHours = ({ defaultWorkingHours, formData, user }) => {
  const dispatch = useDispatch();
  const [hoursActive, setHoursActive] = useState(false);
  const { uid, officeHours } = user;
  const workingHoursIntervalSize =
    !defaultWorkingHours?.startTime || !defaultWorkingHours?.endTime
      ? 10
      : getDefaultHoursInterval(defaultWorkingHours);

  React.useEffect(async () => {
    setHoursActive(!_.isEmpty(officeHours));
    dispatch(initialize(formName, buildFormData(officeHours, defaultWorkingHours)));
    return () => {};
  }, []);

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const text = (txt, rest) => <SimpleText txt={txt} {...(rest ?? {})} />;

  const checkBox = () =>
    container({
      style: {
        alignItems: 'center',
      },
      spacing: 1,
      children: [
        item({
          flex: 1,
          children: <FASCheckboxField onChange={enableWorkingHours} value={hoursActive} hideErrorHelper />,
        }),
        item({
          flex: 'auto',
          children: text('Customize Working Hours', { fontSize: '1rem', fontWeight: 600 }),
        }),
      ],
    });

  const headers = () =>
    container({
      style: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 10,
      },
      spacing: 1,
      children: [
        item({
          flex: 12,
          children: text('Setting working hours will inform people your availablity for event scheduling.', {
            fontSize: '1rem',
            fontWeight: 500,
          }),
        }),
        item({ flex: 12, children: checkBox() }),
      ],
    });

  const getOptions = (field) => {
    const currentTime = moment(
      defaultWorkingHours[field] ? defaultWorkingHours[field] : field === 'startTime' ? '7:00 AM' : '5:00 PM',
      'h:mm A',
    ).subtract(60, 'minutes');
    return Array.from(Array(5)).map((__, i) =>
      currentTime
        .clone()
        .add(30 * i, 'minutes')
        .format('h:mm A'),
    );
  };

  const adjustTime = (value, id, field) => {
    if (field === 'startTime') {
      const endTime = moment(value, 'h:mm A').add(workingHoursIntervalSize, 'hours').format('h:mm A');
      dispatch(change(formName, `${id}.endTime`, endTime));
    } else {
      const startTime = moment(value, 'h:mm A').subtract(workingHoursIntervalSize, 'hours').format('h:mm A');
      dispatch(change(formName, `${id}.startTime`, startTime));
    }
  };

  const timePicker = (id, field = 'startTime') => (
    <Field
      id={`${id}.${field}`}
      name={`${id}.${field}`}
      component={StyledTimePicker}
      options={getOptions(field)}
      size="small"
      variant="outlined"
      required
      onValueChange={(val) => adjustTime(val, id, field)}
      disabled={!hoursActive}
      allowEmpty={false}
      autoComplete="date"
      noHelperText
      noErrorLabel
      popupIcon={<ArrowDropDownIcon color="primary" />}
      disableClearable
    />
  );

  const timePickerEntry = (id) =>
    container({
      style: {
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      spacing: 0,
      children: [
        item({ flex: 5, children: timePicker(id, 'startTime') }),
        item({
          flex: 2,
          style: { paddingLeft: 20 },
          children: 'to',
        }),
        item({ flex: 5, children: timePicker(id, 'endTime') }),
      ],
    });

  const dayEntry = (label, id) =>
    container({
      style: {
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      spacing: 1,
      children: [
        item({ flex: 4, children: text(label, { fontWeight: 600 }) }),
        item({ flex: 8, children: timePickerEntry(id) }),
      ],
    });

  const form = () =>
    container({
      style: {
        paddingBottom: 40,
      },
      spacing: 2,
      children: [
        item({ flex: 4 }),
        item({
          flex: 3,
          children: text('Start Time', { divStyle: { display: 'flex', justifyContent: 'center', paddingLeft: 8 } }),
        }),
        item({ flex: 2 }),
        item({ flex: 'auto', children: text('End Time', { divStyle: { display: 'flex', justifyContent: 'center' } }) }),
        Object.keys(DAY_NAMES).map((day) => item({ flex: 12, children: dayEntry(DAY_NAMES[day], day) })),
      ],
    });

  const showToast = (success, msg, warning) => {
    dispatch(
      showModal('WORKING_HOURS_UPDATE', {
        modalType: warning ? 'WARNING_ALERT' : success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: msg ?? '',
        },
      }),
    );
  };

  const enableWorkingHours = async (value) => {
    if (!value) {
      const reset = await dispatch(resetUserWorkingHours(uid));
      if (reset) {
        Object.keys(DAY_NAMES).forEach((key) => {
          dispatch(change(formName, `${key}.startTime`, defaultWorkingHours.startTime));
          dispatch(change(formName, `${key}.endTime`, defaultWorkingHours.endTime));
        });
        setHoursActive(value);
      } else {
        showToast(false, 'Error resetting working hours');
      }
    } else setHoursActive(value);
  };

  const onSave = async () => {
    try {
      dispatch(submit(formName));
      const result = await dispatch(updateCurrentUserWorkingHours(uid, formData));
      if (result) {
        showToast(true, 'Working Hours Updated');
        dispatch(hideModal('WORKING_HOURS_DIALOG'));
      } else throw new Error();
    } catch (error) {
      showToast(false, 'Error updating working hours');
    }
  };

  const saveBtn = () => (
    <StyledButton
      color="primary"
      variant="contained"
      handleButton={onSave}
      disabled={!hoursActive}
      type="submit"
      buttonContent="SAVE"
      style={{
        width: 160,
        marginTop: 40,
        padding: '10px 0',
        fontWeight: 600,
      }}
    />
  );

  const layout = () =>
    container({
      style: { justifyContent: 'center' },
      children: [
        headers(),
        item({
          flex: 10,
          children: form(),
        }),
        item({
          flex: 12,
          children: <Divider />,
        }),
        item({
          flex: 12,
          style: { textAlign: 'center' },
          children: saveBtn(),
        }),
      ],
    });

  return <div style={{ marginTop: 10 }}>{layout()}</div>;
};

export default _.flow([
  connect((state) => ({
    defaultWorkingHours: defaultWorkingHoursSelector(state),
    formData: getFormValues(formName)(state),
    user: userInfoSelector(state),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    onSubmit: submit,
  }),
])(TCIWorkingHours);
