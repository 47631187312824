/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import { reduxForm, submit } from 'redux-form';
import { connect } from 'react-redux';
import OnsiteSubForm from './OnsiteSubForm';
import AddOnPageForm from './AddonPageForm';
import { BOOKING_MODE, EVENT_TYPE } from '../../../../../utils/consts';

const OnsiteForm = ({ activeStep, mode }) => {
  // const [activeStep, setActiveStep] = React.useState(0);
  const getOnsitCourseContent = (step) => {
    switch (step) {
      case 0:
        return <OnsiteSubForm mode={mode} />;
      case 1:
        return (
          <AddOnPageForm mode={mode} eventType={mode === BOOKING_MODE.quote ? EVENT_TYPE.QUOTE : EVENT_TYPE.ON_SITE} />
        );
      default:
        return 'Unknown step';
    }
  };
  return getOnsitCourseContent(activeStep);
};

// export default OnsiteForm;
export default _.flow([
  connect(),
  reduxForm({
    form: 'AddEventDialog',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    // validate,
    initialValues: {},
    onSubmit: submit,
  }),
])(OnsiteForm);
