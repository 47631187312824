import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../../utils/consts';

const UploadMediaInput = ({ onUpload, disabled }) => (
  <Grid item sm={12} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
    <input
      accept="image/*,video/*"
      style={{ display: 'none' }}
      id="raised-button-file"
      type="file"
      multiple
      onChange={(e) => onUpload(e)}
    />
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label htmlFor="raised-button-file" style={{ marginTop: 10 }}>
      <Button
        variant="contained"
        disabled={disabled}
        style={{
          backgroundColor: disabled ? COLORS.CINTAS_GRAY : COLORS.CINTAS_BLUE,
          border: 'hidden',
          color: 'white',
          borderRadius: '0px',
          fontFamily: 'proxima-nova, sans-serif',
          minWidth: '20px',
        }}
        component="span"
      >
        Upload media
      </Button>
    </label>
  </Grid>
);

export default UploadMediaInput;
