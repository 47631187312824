import React from 'react';
import _ from 'lodash';
import { reduxForm, submit } from 'redux-form';
import { connect } from 'react-redux';
import OpenEnrollmentSubForm from './OpenEnrollmentSubForm';
import AddOnPageForm from './AddonPageForm';
import { EVENT_TYPE } from '../../../../../utils/consts';

const OpenEnrollmentForm = ({ activeStep }) => {
  const getOpenEnrollmentCourseContent = (step) => {
    switch (step) {
      case 0:
        return <OpenEnrollmentSubForm />;
      case 1:
        return <AddOnPageForm eventType={EVENT_TYPE.OPEN_ENROLLMENT} />;
      default:
        return 'Unknown step';
    }
  };

  return getOpenEnrollmentCourseContent(activeStep);
};

export default _.flow([
  connect(),
  reduxForm({
    form: 'AddEventDialog',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    // validate,
    initialValues: {},
    onSubmit: submit,
  }),
])(OpenEnrollmentForm);
