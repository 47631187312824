/* eslint-disable import/prefer-default-export */

import { baseInstance } from './instances/baseInstance';

export const getFeedback = (materialID) => baseInstance.get(`/feedbacksByMaterial/${materialID}`);

export const createFeedback = (payload) => baseInstance.post('/feedbacks', payload);

export const updateFeedback = (uid, payload) => baseInstance.put(`/feedbacks/${uid}`, payload);

export const deleteFeedback = (uid) => baseInstance.delete(`/feedbacks/${uid}`);
