/* eslint-disable max-len */
import { Box } from '@material-ui/core';
import React from 'react';
import { COLORS, TIME_FORMAT } from '../../../../utils/consts';
import Container from '../../../LayoutBuilders/Container';
import ContainerItem from '../../../LayoutBuilders/ContainerItem';
import SimpleText from '../../../Text/SimpleText';

const ParticipantsMinDialog = ({ data }) => {
  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const row = ({ children }) =>
    container({
      spacing: 1,
      style: { justifyContent: 'space-between', width: '100%' },
      children: children.map((c, i) => item({ flex: i === 0 ? 5 : 2, children: c })),
    });

  const msg = (txt, color) => <SimpleText txt={`${txt}`} color={color} />;

  const h = (txt) => <SimpleText txt={txt} style={{ fontWeight: 'bold', color: COLORS.CINTAS_BLUE }} />;

  const headers = () =>
    row({
      children: [h('Course'), h('Start Time'), h('Minimum'), h('Provided')],
    });

  const verticalSpacer = () => <Box height={20} />;

  return (
    <div style={{ padding: 20, minWidth: 600, textAlign: 'start' }}>
      {msg("The following courses don't meet the minimum number of participants")}
      {verticalSpacer()}
      {headers()}
      {data.map((d) =>
        row({
          children: [
            msg(d.courseTitle),
            msg(d.courseTime.format(TIME_FORMAT)),
            msg(d.min),
            msg(d.num, COLORS.CINTAS_RED),
          ],
        }),
      )}
    </div>
  );
};

export default ParticipantsMinDialog;
