import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
}));

const MDSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ref,
  required,
  noBorderRadius,
  size,
  initiallyValidate,
  noErrorLabel,
  disabled = false,
  ...custom
}) => {
  // const [selected, SetSelected] = React.useState('');
  const classes = useStyles();
  const handleChange = (event) => {
    input.onChange(event.target.value);
    // SetSelected(event.target.value);
  };
  const value = input.value ?? '';

  return (
    <FormControl
      required={required}
      error={!!((initiallyValidate || touched) && error)}
      className={classes.formControl}
      variant="outlined"
      size={size}
    >
      <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
      <Select
        style={noBorderRadius && { borderRadius: '0px' }}
        ref={ref}
        fullWidth
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        {...input}
        {...custom}
        inputProps={{
          name: input.name,
          id: 'outlined-age-native-simple',
        }}
        value={value}
        onChange={handleChange}
        label={label}
        disabled={disabled}
      >
        {children}
      </Select>
      {!noErrorLabel && <FormHelperText>{((initiallyValidate || touched) && error) || ' '}</FormHelperText>}
    </FormControl>
  );
};

export default MDSelectField;
