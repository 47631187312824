/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Box } from '@material-ui/core';
import React from 'react';
import Card from '../LayoutBuilders/Card';
import Container from '../LayoutBuilders/Container';
import ContainerItem from '../LayoutBuilders/ContainerItem';
import Spinner from '../SpinnerOverlay/Spinner';
import SimpleText from '../Text/SimpleText';

const AssignedCustomerTciCard = ({ tci, isLoading }) => {
  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const text = (txt, style, align) => (
    <SimpleText
      txt={txt}
      style={{ ...(style ?? {}) }}
      divStyle={{ display: 'flex', justifyContent: align }}
      align={align}
      textAlign={align}
    />
  );

  const card = ({ children, style, title }) => (
    <Card style={style} title={title}>
      {children}
    </Card>
  );

  const spinner = () => <Spinner customStyle={{ margin: '10%' }} />;

  return container({
    direction: 'column',
    style: { maxHeight: 300 },
    children: [
      card({
        title: 'Assigned TCI',
        style: {
          minHeight: 130,
          maxHeight: 130,
          height: '100%',
          overflowY: 'auto',
          padding: 14,
        },
        children: isLoading ? [spinner()] : [text(tci?.name ?? 'Open', { fontWeight: 'bold' }), text(tci?.email ?? '')],
      }),
      <Box height={24} />,
      card({
        title: 'Assigned ISR',
        style: {
          minHeight: 130,
          maxHeight: 130,
          height: '100%',
          overflowY: 'auto',
          padding: 14,
        },
        children: isLoading
          ? [spinner()]
          : [
              isLoading
                ? [spinner()]
                : [text(tci?.manager?.name ?? 'Open', { fontWeight: 'bold' }), text(tci?.manager?.email ?? '')],
            ],
      }),
    ],
  });
};

export default AssignedCustomerTciCard;
