/* eslint-disable react/jsx-filename-extension */
import '@fontsource/roboto';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/roboto/500.css';

import AppRouter from './routes/AppRouter';
import AuthProvider from './components/AuthProvider/AuthProvider';
import store from './redux/store';

import './index.css';
import { COLORS } from './utils/consts';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.CINTAS_BLUE,
    },
    secondary: {
      main: COLORS.CINTAS_RED,
    },
    info: {
      main: COLORS.CINTAS_WHITE,
    },
    error: {
      main: COLORS.CINTAS_RED,
    },
  },
  typography: {
    fontFamily: [
      'proxima-nova',
      'Roboto',
      '-apple-system',
      'Oxygen',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </AuthProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
