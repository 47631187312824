/* eslint-disable no-empty */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import _ from 'lodash';
import {
  SAVE_USERS,
  RESET_USERS_STATE,
  SAVE_CURRENT_USER,
  SAVE_TCIS,
  SAVE_CURR_TCI,
  SET_LOADING_TCI_EVENTS,
} from './actionTypes/users';

import * as UserAPI from '../../api/users.api';
import * as SettingsActions from './settings.actions';
import { ACCESS_MATRIX_VALIDATION, OK_STATUS_CODES, ROLE_ACCESSES } from '../../utils/consts';
import { editUserAuthPhoto } from './auth.actions';
import { fetchTciOrders, resetTciOrders } from './orders.actions';
import { saveCustomers } from './customers.actions';
import * as EventActions from './events.actions';
import { getBrowserTimezone } from '../../utils/helpers';
import { adminTypeSelector, hasUserAccessSelector, userInfoSelector } from '../selectors/user.selectors';
import { setTimezone } from './utils.actions';
import { showModal } from './modals.actions';
import { runConflictCheckForUsers } from '../../api/tasks.api';

const saveUsers = (users) => ({
  type: SAVE_USERS,
  users,
});

const saveCurrentUser = (user) => ({
  type: SAVE_CURRENT_USER,
  user,
});

export const saveReportingTCIs = (tcis) => ({
  type: SAVE_TCIS,
  tcis,
});

const saveSelectedTCI = (tci) => ({
  type: SAVE_CURR_TCI,
  tci,
});

const setLoadingTciEvents = (loading) => ({
  type: SET_LOADING_TCI_EVENTS,
  loading,
});

export const resetState = () => ({
  type: RESET_USERS_STATE,
});

// export const getInstructName = async (instructorID) => {
export const getInstructName = (instructorID) => async () => {
  try {
    const response = await UserAPI.getInstructorByID(instructorID);
    if (response && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const checkUsersConflicts =
  ({ orderId, uids, startTime, endTime, defaultTz }) =>
  async () => {
    try {
      const response = await runConflictCheckForUsers({
        uids,
        startTime,
        endTime,
        defaultTz,
        orderId,
      });
      if (response && response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      return [];
    }
  };

export const fetchUsers = () => async (dispatch) => {
  try {
    const response = await UserAPI.fetchUsers();
    if (response && response.data && response.data.content) {
      dispatch(saveUsers(response.data.content));
      return response.data.content;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const getTciByZip = (zip) => async () => {
  try {
    const response = await UserAPI.getTciByZip(zip);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (e) {
    return {};
  }
};

export const getTciManagerUid = (tciUid, zip) => async () => {
  try {
    const response = await UserAPI.getTciManager(tciUid, zip);
    if (response && !_.isEmpty(response.data?.tcim_id ?? '')) {
      return response.data?.tcim_id;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const getSupervisorUid = (uid, zip) => async () => {
  try {
    const response = await UserAPI.getSupervisor(uid, zip);
    if (response && !_.isEmpty(response.data ?? '')) {
      return response.data;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const fetchOrgStructure = () => async () => {
  try {
    const response = await UserAPI.getOrgStructure();
    if (response && response.data) {
      // dispatch(saveUserOrgStructure(response.data.content));
      return response.data;
    }
    throw new Error();
  } catch (e) {
    throw e;
  }
};

export const getRegionsLocations = (indexed) => async () => {
  try {
    const response = await UserAPI.getRegionsLocations();
    if (response && response.data) {
      if (indexed) {
        return (response.data ?? []).reduce(
          (prev, curr) => ({
            ...prev,
            [curr.loc_id]: { ...curr, region_label: (curr.region_name ?? '').split('-').pop().trim() },
          }),
          {},
        );
      }
      return response.data;
    }
    throw new Error();
  } catch (e) {
    throw e;
  }
};

export const getTCIs = () => async (dispatch) => {
  try {
    const response = await UserAPI.fetchTCIs();
    if (response && response.data) {
      const reduced = (response.data ?? []).reduce((prev, d) => {
        if (!d.loc_id || !d.pernr) return prev;
        const alreadyAdded = prev[d.pernr] && prev[d.pernr].locations?.[d.loc_id];
        if (alreadyAdded) return prev;

        return {
          ...prev,
          [d.pernr]: {
            ...d,
            loc_id: undefined,
            loc_ids: undefined,
            location_desc: undefined,
            zip_codes: undefined,
            locations: {
              ...(prev[d.pernr]?.locations ?? {}),
              [d.loc_id]: {
                loc_id: d.loc_id,
                location_desc: d.location_desc ?? '',
                zip_codes: d.zip_codes ?? [],
              },
            },
          },
        };
      }, {});
      const toSave = [...Object.values(reduced)];
      dispatch(saveReportingTCIs(toSave));
      return toSave;
    }
    throw Error();
  } catch (error) {
    throw error;
  }
};

export const getTciById = (uid) => async () => {
  try {
    const response = await UserAPI.fetchTciInfo(uid);
    if (response?.data) {
      return response.data;
    }
    throw Error();
  } catch (error) {
    throw error;
  }
};

export const fetchCurrentUser = () => async (dispatch, getState) => {
  try {
    const response = await UserAPI.fetchCurrentUser();
    const copy = { ...(response?.data ?? {}) };
    delete copy.UserDetails;
    const user = {
      ...(response?.data?.UserDetails ?? {}),
      ...copy,
    };
    if (Object.keys(user.roleMatrix ?? {}).length === 0) {
      // If there is no role access matrix, return an error to the user
      return {
        error: true,
        code: ACCESS_MATRIX_VALIDATION.MISSING,
        orgRole: user.org_role ?? '',
        argName: user.agr_name ?? '',
      };
    }
    const role = user.org_role?.toUpperCase() ?? ''; // roleSapMapper(user.org_role?.toUpperCase() ?? '');
    dispatch(
      saveCurrentUser({
        ...(user ?? {}),
        org_role: role,
        displayRole: (user.org_role ?? '').toUpperCase(),
        // TODO: remove after testing
        // roleMatrix: {
        //   ...(user.roleMatrix ?? {}),
        //   ...(hasUserAccessSelector(null, ADMIN_ROLE_ACCESSES, user.roleMatrix) ? { adminMatrix: defaultAdminMatrix } : {}),
        // }
        // ///////////////////////////
      }),
    );

    const adminType = adminTypeSelector(getState());
    if (adminType === ROLE_ACCESSES.advancedAdmin) {
      const tcis = await dispatch(getTCIs());
      try {
        const TCIsLocations = _.uniq(
          (tcis ?? []).reduce((prev, tci) => [...prev, ...[Object.keys(tci.locations ?? {})]], []),
        );
        dispatch(SettingsActions.updateLocation(TCIsLocations));
      } catch (e) {}
    } else {
      dispatch(SettingsActions.updateLocation(_.uniq(user.loc_ids)));
    }

    const hasOwnCalendar = hasUserAccessSelector(getState(), [ROLE_ACCESSES.ownCalendar]);
    if (hasOwnCalendar) {
      const defaultTz = userInfoSelector(getState())?.tz;
      if (defaultTz) {
        dispatch(setTimezone(defaultTz));
      }
    }
    return user;
  } catch (error) {
    throw error;
  }
};

export const getLocationsExportData = () => async () => {
  try {
    const response = await UserAPI.fetchLocationsExportData();
    if (response && response.data) {
      return response.data;
    }
    throw Error();
  } catch (error) {
    throw error;
  }
};

export const setSelectedTCI = (t) => async (dispatch) => {
  try {
    const tci = { ...t };
    await dispatch(saveSelectedTCI(tci));
    const defaultTciTz = tci?.tz;
    dispatch(setTimezone(_.isEmpty(defaultTciTz ?? {}) ? getBrowserTimezone() : defaultTciTz));
    if (!defaultTciTz && tci?.uid) {
      dispatch(
        showModal('NO_TCI_TZ_ALERT', {
          modalType: 'WARNING_ALERT',
          modalProps: { message: 'Partner does not have default time zone saved to profile' },
        }),
      );
    }
    await dispatch(setLoadingTciEvents(true));
    // await dispatch(fetchCustomers(Object.keys(tci.locations)));
    const events = await dispatch(fetchTciOrders(tci.uid));
    return events;
  } catch (error) {
    return false;
  } finally {
    await dispatch(setLoadingTciEvents(false));
  }
};

export const resetSelectedTCI = () => async (dispatch) => {
  try {
    await dispatch(resetTciOrders());
    await dispatch(saveSelectedTCI(undefined));
    await dispatch(setTimezone(getBrowserTimezone()));
    await dispatch(saveCustomers({}));
    return false;
  } catch (error) {
    return false;
  }
};

export const updateProfilePicture = (formData) => async (dispatch) => {
  try {
    const response = await UserAPI.updateProfilePic(formData);
    if (OK_STATUS_CODES.includes(response.status)) {
      await dispatch(editUserAuthPhoto(response.data.photoUrl));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updateDefaultTruckNumber = (truckNum) => async (dispatch, getState) => {
  try {
    const response = await UserAPI.updateDefaultTruckNumber(truckNum);
    if (OK_STATUS_CODES.includes(response.status)) {
      const currentUser = getState()?.users?.data?.currentUser ?? {};
      dispatch(
        saveCurrentUser({
          ...currentUser,
          defaultTruckNumber: truckNum,
        }),
      );
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updateDefaultTimezone = (tz) => async (dispatch, getState) => {
  try {
    const response = await UserAPI.updateDefaultTimezone(tz);
    if (OK_STATUS_CODES.includes(response.status)) {
      const user = userInfoSelector(getState());
      await dispatch(saveCurrentUser({ ...(user ?? {}), tz }));
      await dispatch(setTimezone(tz));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const updateUserInfo = (newInfo) => async (dispatch) => {
  try {
    const response = await UserAPI.updateUser(newInfo);
    if (OK_STATUS_CODES.includes(response.status)) {
      await dispatch(saveCurrentUser(newInfo));
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const updateUserById = (id, newInfo) => async () => {
  try {
    const response = await UserAPI.updateUserById(id, newInfo);
    if (OK_STATUS_CODES.includes(response.status)) {
      // await dispatch(saveCurrentUser(newInfo));
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const signupUser = (signupData) => async () => {
  try {
    const response = await UserAPI.signupUser(signupData);
    if (OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const fetchUserWorkingHours = async (uid) => {
  try {
    const result = await UserAPI.fetchUserWorkingHours(uid);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      return result.data;
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const updateCurrentUserWorkingHours = (uid, payload) => async (dispatch) => {
  try {
    const result = await UserAPI.updateUserWorkingHours(uid, payload);
    if (OK_STATUS_CODES.includes(result.status)) {
      dispatch(fetchCurrentUser());
      return true;
    }
    throw new Error();
  } catch (e) {
    throw e;
  }
};

export const resetUserWorkingHours = (uid) => async (dispatch) => {
  try {
    const result = await UserAPI.resetUserWorkingHours(uid);
    if (result.status === 204) {
      dispatch(fetchCurrentUser());
      return true;
    }
    throw new Error();
  } catch (e) {
    throw e;
  }
};

export const blockDay = (uid, date) => async (dispatch) => {
  try {
    const response = await UserAPI.blockDay(uid, date);
    if (response.status === 204) {
      await dispatch(EventActions.blockDay(date));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const unblockDay = (uid, date, payload) => async (dispatch) => {
  try {
    const response = await UserAPI.unblockDay(uid, date, payload);
    if (OK_STATUS_CODES.includes(response.status) && response.data) {
      await dispatch(EventActions.unblockDay(response.data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
