import _ from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';

import React from 'react';
import { submit } from 'redux-form';
import StyledButton from '../../../components/Button/StyledButton';
import CourseSettingsForm from '../../../components/Forms/SettingsForms/CourseSettingsForm';
import NewMaterialModalWrapper from './NewMaterialModalWrapper';
import * as MaterialsActions from '../../../redux/actions/materials.actions';
import * as ModalsActions from '../../../redux/actions/modals.actions';

const NewMaterialActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}))(MuiDialogActions);

const NewMaterialModal = (props) => {
  const { dispatch } = props;
  const history = useHistory();

  const onSubmitMaterial = async (values) => {
    try {
      await dispatch(MaterialsActions.createMaterial(values));
      dispatch(ModalsActions.hideModal('NEW_MATERIAL_MODAL'));
      dispatch(
        ModalsActions.showModal('CREATE_MATERIAL_SUCCESS', {
          modalType: 'SUCCESS_ALERT',
          modalProps: { message: 'Material successfully created' },
        }),
      );
      history.push(`/admin/materials/${values.salesOrg}/${values.code}`);
    } catch (error) {
      const message = 'Error creating new Material. Please try again!';
      dispatch(
        ModalsActions.showModal('CREATE_MATERIAL_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: { message },
        }),
      );
    }
  };

  return (
    <>
      <NewMaterialModalWrapper>
        <CourseSettingsForm isInitial onSubmit={onSubmitMaterial} />
      </NewMaterialModalWrapper>
      <NewMaterialActions>
        <StyledButton
          variant="contained"
          color="primary"
          type="submit"
          width="160px"
          handleButton={() => dispatch(submit('CourseSettingsForm'))}
          buttonContent="Submit"
        />
      </NewMaterialActions>
    </>
  );
};

export default _.flow([connect()])(NewMaterialModal);
