import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, Card } from '@material-ui/core';
import Spinner from '../../../../SpinnerOverlay/Spinner';
import SimpleText from '../../../../Text/SimpleText';
import { fetchContactsByCustomerId } from '../../../../../redux/actions/contacts.actions';
import StyledButton from '../../../../Button/StyledButton';
import { hideModal } from '../../../../../redux/actions/modals.actions';
import { COLORS } from '../../../../../utils/consts';

const ContactSelectionDialog = ({ onConfirm, customerId, modalName }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [contacts, setContacts] = React.useState([]);
  const [selectedContact, setSelectedContact] = React.useState();

  const loadContacts = async () => {
    setLoading(true);
    try {
      const resp = await dispatch(fetchContactsByCustomerId(customerId));
      setContacts(resp);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(async () => {
    await loadContacts();
  }, []);

  const dismissDialog = () => dispatch(hideModal(modalName));

  const spinner = () => <Spinner customStyle={{ margin: '30%' }} />;
  const grid = ({ children, ...rest }) => <Grid {...(rest ?? {})}>{children}</Grid>;
  const container = ({ children, ...rest }) => grid({ children, container: true, ...(rest ?? {}) });
  const btn = ({ label, disabled, onClick, ...rest }) => (
    <StyledButton
      variant="contained"
      buttonContent={label}
      handleButton={onClick}
      disabled={disabled}
      {...(rest ?? {})}
    />
  );

  const footer = () =>
    container({
      style: { justifyContent: 'end', paddingTop: 10, borderTop: `1px solid ${COLORS.CINTAS_LIGHT_GRAY}` },
      children: [
        btn({
          label: 'CANCEL',
          onClick: dismissDialog,
          variant: 'outlined',
        }),
        <Box width="5%" />,
        btn({
          label: 'SELECT',
          disabled: !selectedContact,
          color: 'primary',
          onClick: () => {
            onConfirm(selectedContact);
            dismissDialog();
          },
        }),
      ],
    });

  const listOfContacts = () => (
    <div style={{ padding: 10, maxHeight: 400, overflow: 'scroll' }}>
      {!_.isEmpty(contacts) ? (
        contacts.map((contact) => (
          <Card
            onClick={() => setSelectedContact(contact)}
            style={{
              padding: 12,
              marginBottom: 8,
              elevation: 10,
              backgroundColor:
                selectedContact && selectedContact.solt_to === contact.sold_to ? COLORS.CINTAS_LIGHT_BLUE : 'white',
            }}
          >
            <SimpleText
              withSubtitle
              subtitleFontSize={10}
              subtitleStyle={{ paddingLeft: 2 }}
              txt={`${_.capitalize(contact.FirstName)} ${_.capitalize(contact.LastName)}\n${(contact.Phones ?? [{}])[0].Phone ?? ''}${contact.Phones && contact.Phones[0] && contact.Phones[0].Phone ? ' - ' : ''}${(contact.Email ?? '').toLowerCase()}`}
            />
          </Card>
        ))
      ) : (
        <SimpleText txt="No SAP contact available" />
      )}
    </div>
  );

  return (
    <div style={{ width: 500 }}>
      {loading ? spinner() : listOfContacts()}
      {footer()}
    </div>
  );
};

export default ContactSelectionDialog;
