/* eslint-disable max-len */
import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import CopyIcon from '@material-ui/icons/FileCopy';
import { fetchCustomerQuoteById } from '../../redux/actions/customers.actions';
import CreateCustomerQuoteButton from './CreateCustomerQuoteButton';
import { materialsDataSelector } from '../../redux/selectors/materials.selector';
import Quote from '../../models/Quote';

const CopyQuoteButton = ({ customer, quoteID, preloaded, materialsInfo, onCopied }) => {
  const [quote, setQuote] = React.useState();

  const createNewQuoteRef = React.useRef(null);
  const dispatch = useDispatch();
  const loadQuote = async () => {
    if (!preloaded) {
      const loadedQ = await dispatch(fetchCustomerQuoteById(customer.sold_to, quoteID));
      setQuote(Quote.toEventData({ quote: loadedQ, customer, materialsInfo }));
    } else {
      setQuote(Quote.toEventData({ quote: preloaded, customer, materialsInfo }));
    }
  };

  React.useEffect(async () => loadQuote(), [quoteID]);

  const onCopyQuote = async () => {
    createNewQuoteRef.current?.click();
  };

  return (
    <>
      {/* The CreateCustomerQuoteButton below is hidden and used to create the new copied quote by accessing its onClick by reference in onCopyQuote() */}
      <CreateCustomerQuoteButton
        style={{ visibility: 'hidden', display: 'none' }}
        reference={createNewQuoteRef}
        appendEmptyEvent={false}
        customer={customer}
        initialData={quote}
        onCreated={onCopied}
      />
      <Tooltip title="Copy Quote">
        <IconButton onClick={onCopyQuote} style={{ margin: 'auto', padding: 4 }}>
          <CopyIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default _.flow([
  connect((state) => ({
    materialsInfo: materialsDataSelector(state),
  })),
])(CopyQuoteButton);
