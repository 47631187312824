import _ from 'lodash';
import { Field, reduxForm, submit } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MDAutoComplete from '../../../../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';
import OpenEnrollmentCourseSelect from '../EventDialogControl/OpenEnrollmentCourseSelect';
import FASFieldTitle from '../../../../Forms/CustomFormComponents/FASFieldTitle';
import { formatLocation } from '../../../../../utils/helpers';

const OpenEnrollmentSubForm = () => {
  const locations = useSelector((state) => state.locations.data);
  const openEnrollmentLocations = _.filter(locations, { active: true });

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FASFieldTitle title="Training Site" />
          <Field
            options={openEnrollmentLocations || []}
            variant="outlined"
            placeholder="Enter name, city/town, state, or zip code"
            getLabel={(option) => `${option.name} - ${formatLocation(option)}`}
            id="location"
            name="location"
            required
            component={MDAutoComplete}
            size="small"
            label="Training Site"
            disableClearable
            popupIcon={<KeyboardArrowDownIcon color="primary" />}
            noBorderRadius
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <OpenEnrollmentCourseSelect name="openEnrollmentCourse" />
        </Grid>
      </Grid>
    </div>
  );
};
export default _.flow([
  connect(),
  reduxForm({
    form: 'AddEventDialog',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    // validate,
    onSubmit: submit, // a unique identifier for this form
    initialValues: {},
  }),
])(OpenEnrollmentSubForm);
