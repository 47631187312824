/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import React from 'react';
import SimpleText from '../../Text/SimpleText';
import { BOOKING_MODE, NOTES_DISPLAY_BORDER } from '../../../utils/consts';
import { getHigherAccountNumber } from '../../../utils/event.utils';
import { NATIONAL_ACCOUNT_NOTE_TYPE } from '../../../utils/consts/nationalAccount.consts';
import FASFieldTitle from '../../Forms/CustomFormComponents/FASFieldTitle';
import NationalAccountCourseDisplayHook from '../../NationalAccountDashboard/NationalAccountCourseDisplay';

const ConfirmationEmailPopup = ({ token, mode, customer }) => {
  const nationalAccountNumber = getHigherAccountNumber(customer);
  const update = [BOOKING_MODE.editing, BOOKING_MODE.rescheduling].includes(mode);
  const natAccCourseNote = NationalAccountCourseDisplayHook({
    noteType: NATIONAL_ACCOUNT_NOTE_TYPE.COURSE,
    account: nationalAccountNumber,
  });

  const fieldTitle = (txt, style) => <FASFieldTitle title={txt} style={style} />;

  const courseConfirmationNote = () => (
    <div style={{ border: NOTES_DISPLAY_BORDER, padding: '0 8px 8px 8px', marginTop: 10 }}>
      {fieldTitle(natAccCourseNote.label)}
      {natAccCourseNote.noteComponent()}
    </div>
  );

  return (
    <div style={{ padding: 21 }}>
      <SimpleText
        txt={
          token
            ? `Send ${update ? 'an updated' : 'new'} course confirmation email to the customer contact`
            : 'A new course confirmation signature is not required'
        }
      />
      {nationalAccountNumber && natAccCourseNote.hasNotes && courseConfirmationNote()}
    </div>
  );
};

export default ConfirmationEmailPopup;
