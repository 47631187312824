/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { materialSelectorOfMultipleTypes } from '../redux/selectors/materials.selector';
import { MATERIAL_TYPE } from '../utils/consts';

export default class Quote {
  constructor({ contact, customer, data, createdBy, creationDate, expirationDate, quoteID, status }) {
    this.contact = contact ?? {};
    this.customer = customer ?? {};
    this.data = data ?? [];
    this.createdBy = createdBy ?? '';
    this.creationDate = creationDate;
    this.expirationDate = expirationDate;
    this.quoteID = quoteID;
    this.status = status;
  }

  get isExpired() {
    return moment.utc(this.expirationDate).isBefore(moment.utc());
  }

  static toEventData({ quote, customer, materialsInfo, maintainAddons = true }) {
    if (!quote || !customer || !materialsInfo) return {};
    const tempContact =
      quote?.contact && (quote?.contact?.isTemporary || !quote?.contact?.contactID) ? quote?.contact : undefined;

    const events = (quote?.data ?? []).map((evData) => {
      const material = materialSelectorOfMultipleTypes({
        materialsInfo,
        types: [MATERIAL_TYPE.COURSE, MATERIAL_TYPE.SKILL_CHECK],
        id: evData.course,
      });
      const addons = evData.addOns ?? [];
      const requiredAddons = material?.addons?.required ?? [];
      const optionalAddons = material?.addons?.optional ?? [];

      return {
        ...evData,
        courseObj: {
          ...(material ?? {}),
          ...(maintainAddons
            ? {
                addons: {
                  required: requiredAddons,
                  optional: _.uniq([...optionalAddons, ...addons.map((ad) => ad.id)]).filter(
                    (id) => !requiredAddons.includes(id),
                  ),
                },
              }
            : {}),
        },
      };
    });

    for (let i = 0; i < events.length; i++) {
      const ev = { ...events[i] };
      delete ev.startTime;
      delete ev.endTime;
      events.splice(i, 1, ev);
    }

    return {
      ...quote,
      events,
      customerObj: customer,
      customer: customer?.sold_to,
      contactID: tempContact ? undefined : quote?.contact?.contactID,
      contact: tempContact ? undefined : quote?.contact,
      temporaryContact: tempContact
        ? {
            FirstName: (tempContact.name ?? '').split(' ')[0],
            LastName: (tempContact.name ?? '').split(' ')?.[1] ?? '',
            Email: tempContact.email ?? '',
            Phone: tempContact.Phone ?? '',
            isTemporary: true,
          }
        : undefined,
    };
  }
}
