/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { change } from 'redux-form';
import { Grid } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as ModalsActions from '../../../../redux/actions/modals.actions';
import FASTitle from '../../CustomFormComponents/FASTtle';
import { COLORS } from '../../../../utils/consts';
import CustomerFeedbackForm from './CustomerFeedbackForm';
import RowLayout from '../../../LayoutBuilders/RowLayout';
import RowCell from '../../../LayoutBuilders/RowCell';
import * as FeedbackActions from '../../../../redux/actions/feedback.actions';
import HTMLVisualizer from '../../../HTMLComponents/HTMLVisualizer';
import { feedbackSelector } from '../../../../redux/selectors/feedback.selectors';

const CustomerFeedbackList = ({ materialID, dispatch, feedbacksIDs }) => {
  const feedback = useSelector(feedbackSelector);
  const [elements, setElements] = useState(feedbacksIDs ?? []);
  const [deletedFeedback, setDeletedFeedback] = useState([]);
  const [expanded, setExpanded] = useState(null);

  const showToast = (success, msg) => {
    dispatch(
      ModalsActions.showModal('FEEDBACK_STATUS', {
        modalType: success ? 'SUCCESS_ALERT' : 'ERROR_ALERT',
        modalProps: {
          message: msg,
        },
      }),
    );
  };

  useEffect(() => {
    if (feedback.length > elements.length) {
      // detect added feedback
      const newElements = [...elements, feedback[feedback.length - 1].ID];
      setElements(newElements);
      dispatch(change('CourseSettingsForm', 'feedbacks', newElements));
    }
  }, [feedback]);

  useEffect(async () => {
    try {
      await dispatch(FeedbackActions.fetchMaterialFeedback(materialID));
    } catch (err) {
      showToast(false, 'Error when fetching customer feedback');
    }
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedElements = Array.from(elements);
    const [removed] = updatedElements.splice(result.source.index, 1);
    updatedElements.splice(result.destination.index, 0, removed);

    setElements(updatedElements);
    dispatch(change('CourseSettingsForm', 'feedbacks', updatedElements));
  };

  const showFeedbackModal = (feedbackItem) => {
    dispatch(
      ModalsActions.showModal('FEEDBACK_MODAL', {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          disableBackdropClick: true,
          maxWidth: 'md',
          title: `${feedback ? 'EDIT' : 'ADD NEW'} CUSTOMER FEEDBACK`,
          content: <CustomerFeedbackForm feedback={feedbackItem} dispatch={dispatch} showToast={showToast} />,
        },
      }),
    );
  };

  const showDeleteModal = (uid) => {
    dispatch(
      ModalsActions.showModal('DELETE_FEEDBACK_MODAL', {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: false,
          confirmText: 'DELETE',
          cancelText: 'CANCEL',
          maxWidth: 'sm',
          title: 'DELETE CUSTOMER FEEDBACK',
          content: <div style={{ padding: '20px 0 0 5px' }}>Are you sure you want to delete this feedback?</div>,
          onConfirm: () => {
            const newDeletedFeedback = [...deletedFeedback, uid];
            setDeletedFeedback(newDeletedFeedback);
            const newElements = _.filter(elements, (el) => el !== uid);
            setElements(newElements);
            dispatch(change('CourseSettingsForm', 'deletedFeedback', newDeletedFeedback));
            dispatch(change('CourseSettingsForm', 'feedbacks', newElements));
            dispatch(ModalsActions.hideModal('DELETE_FEEDBACK_MODAL'));
          },
        },
      }),
    );
  };

  return (
    <>
      {/* Customer feedback */}
      <Grid item sm="auto">
        <FASTitle customStyle={{ marginBottom: 0 }} title="Customer feedback" />
      </Grid>
      <Grid item sm="auto">
        <IconButton
          style={{ backgroundColor: COLORS.CINTAS_BLUE, marginTop: 15 }}
          size="small"
          onClick={() => showFeedbackModal()}
        >
          <AddIcon sx={{ color: COLORS.CINTAS_WHITE }} />
        </IconButton>
      </Grid>
      <Grid item sm={12}>
        <DragDropContext onDragStart={() => setExpanded(null)} onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable-feedback">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {elements?.map((el, index) => {
                  const feedbackInfo = _.find(feedback, (f) => f.ID === el);
                  return (
                    feedbackInfo && (
                      <Draggable key={el} draggableId={el} index={index}>
                        {(item) => (
                          <Accordion
                            expanded={expanded === el}
                            onChange={(__, open) => setExpanded(open ? el : null)}
                            ref={item.innerRef}
                            sx={{ marginBottom: 1, padding: 1 }}
                            {...item.draggableProps}
                            {...item.dragHandleProps}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ width: '100%' }}>
                              <RowLayout spacing={1} style={{ alignItems: 'center' }}>
                                <RowCell flex={11}>
                                  <HTMLVisualizer content={feedbackInfo?.Header} />
                                </RowCell>
                                <RowCell flex="auto">
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      showFeedbackModal(feedbackInfo);
                                    }}
                                  >
                                    <EditIcon sx={{ color: COLORS.CINTAS_BLUE }} />
                                  </IconButton>
                                </RowCell>
                                <RowCell flex="auto">
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      showDeleteModal(el);
                                    }}
                                  >
                                    <DeleteIcon sx={{ color: COLORS.CINTAS_RED }} />
                                  </IconButton>
                                </RowCell>
                              </RowLayout>
                            </AccordionSummary>
                            <AccordionDetails>
                              <HTMLVisualizer content={feedbackInfo?.Body} />
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </Draggable>
                    )
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </>
  );
};

export default CustomerFeedbackList;
