/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field, change } from 'redux-form';
import { MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { COLORS } from '../../../../../utils/consts';
import FASSelectField from '../../../../Forms/CustomFormComponents/FASSelectField';
import MDTextInputField from '../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import SimpleText from '../../../../Text/SimpleText';
import Container from '../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../LayoutBuilders/ContainerItem';
import CintasSignPad from '../../../../SignaturePad/CintasSignPad';

const useStyles = makeStyles({
  body: {
    color: '#404040',
    textAlign: 'left',
    marginBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  alignedCenter: {
    width: '80%',
    margin: 'auto',
  },
});

export default function SignatureTab({
  customerId,
  order,
  setSigPad,
  getSigPad,
  signatureImg,
  fieldNames,
  values,
  formName,
  dispatch,
}) {
  const classes = useStyles();
  const [img] = useState(signatureImg);
  const [otherField, setOtherField] = useState(
    values[fieldNames.printedName] && values[fieldNames.printedName] === 'Other',
  );
  const otherContacts = useSelector((state) =>
    (state.contacts.data ?? []).filter((c) => c.SAPCustomerID === customerId),
  ).map((c) => `${c.FirstName ?? ''} ${c.LastName ?? ''}`);
  const forcedDelivery = Boolean(order?.manual_invoice);
  const [printedNameOptions] = useState(
    Array.from(
      new Set([
        ...(order.contact ? [`${order.contact.FirstName} ${order.contact.LastName}`] : []),
        ...(otherContacts ?? []),
        'Other',
      ]),
    ),
  );

  const isSigned = Boolean(img);
  const changeSignature = () => {
    getSigPad()
      .getCanvas()
      .toBlob((blob) => {
        dispatch(change(formName, 'deliverySignature', blob));
      });
  };

  const handleNameSelect = (selection) => {
    if (selection === 'Other') {
      setOtherField(true);
    } else {
      setOtherField(false);
    }
  };

  const printedNameSelect = () => (
    <Field
      variant="outlined"
      placeholder="Printed Name"
      id={fieldNames.printedName}
      name={fieldNames.printedName}
      component={FASSelectField}
      displayEmpty
      style={{ textAlign: 'start', borderRadius: 0 }}
      onChange={handleNameSelect}
    >
      {printedNameOptions.map((t) => (
        <MenuItem key={t} value={t}>
          {t}
        </MenuItem>
      ))}
    </Field>
  );

  const text = (txt, padding, align, color) => (
    <SimpleText txt={txt} style={{ textAlign: align ?? 'center', padding, color }} />
  );
  const container = ({ children, ...rest }) => <div {...rest}>{children}</div>;
  const inputField = () => (
    <Field
      id={fieldNames.otherName}
      name={fieldNames.otherName}
      component={MDTextInputField}
      size="small"
      type="text"
      disabled={false}
      inputProps={{ width: '100%', style: { textAlign: 'start', marginLeft: 5 } }}
      variant="outlined"
      required
      label="Printed Name"
      noBorderRadius
      style={{ width: '100%' }}
      placeholder=""
    />
  );

  return container({
    style: { marginTop: 16, width: '98%', height: '90%' },
    children: [
      <CintasSignPad
        key={1}
        imgUrl={isSigned ? img : null}
        setSigPad={setSigPad}
        getSigPad={getSigPad}
        onEnd={changeSignature}
        onCleared={() => dispatch(change(formName, 'deliverySignature', null))}
      />,
      forcedDelivery &&
        container({
          key: 0,
          className: classes.alignedCenter,
          style: { textAlign: 'left', alignContent: 'left' },
          children: text('This order has been manually invoiced', undefined, 'left', COLORS.CINTAS_RED),
        }),
      container({
        key: 3,
        className: classes.alignedCenter,
        style: {
          marginTop: '1vh',
          textAlign: 'left',
          alignContent: 'left',
        },
        children: isSigned ? <></> : <SimpleText txt="Select Printed Name:" style={{ textAlign: 'center' }} />,
      }),
      <Container
        key={4}
        spacing={1}
        className={classes.alignedCenter}
        style={{ marginTop: '1vh', textAlign: 'left', alignContent: 'left' }}
      >
        <>
          {isSigned
            ? text(values[fieldNames.printedName], '12px 10px')
            : [
                <ContainerItem flex="auto">{printedNameSelect()}</ContainerItem>,
                <ContainerItem flex="auto">{otherField && inputField()}</ContainerItem>,
              ]}
        </>
      </Container>,
    ],
  });
}
