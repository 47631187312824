/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import styled from 'styled-components';
import { COLORS, TIME_FORMAT } from '../../../../utils/consts';

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  & .rc-time-picker-clear,
  & .rc-time-picker-clear-icon:after {
    font-size: 20px;
    margin: 3px;
  }

  & .rc-time-picker-input:disabled {
    color: rgba(0, 0, 0, 0.38);
    background: white;
  }

  & .rc-time-picker-panel-select,
  & .rc-time-picker-input,
  & .rc-time-picker-panel-input {
    font-size: 16px;
    border-radius: 0px;
    height: 40px;
    color: black;
    border: 1px solid ${({ invalid }) => (invalid ? '#BA0C2F' : '#d9d9d9')};
    &:focus {
      border-radius: 0px;
      outline: 2px solid #012169;
    }
  }
`;

const renderFromHelper = ({ touched, error }) => {
  if (touched || error) {
    return (
      <FormHelperText style={{ color: COLORS.CINTAS_RED, margin: '0', height: '0' }}>{touched && error}</FormHelperText>
    );
  }
  return '';
};

const FASTimePickerField = ({
  input: { onChange, onBlur, value },
  meta: { touched, invalid, error },
  // format,
  initialFocusedDate,
  minuteStep = 15,
  allowEmpty = true,
  ...rest
}) => {
  const selectedTimezone = useSelector((state) => state.utils.tz.value);

  const timeChangeHandler = (time) => {
    const selected =
      time != null && initialFocusedDate?.isAfter(moment.tz(time, selectedTimezone).utc())
        ? moment.tz(`${initialFocusedDate.format('YYYY-MM-DD')} ${time.format('HH:mm')}`, selectedTimezone).utc()
        : time == null
          ? null
          : moment.tz(time, selectedTimezone).utc();
    onBlur(selected);
    onChange(selected);
  };
  // console.log('inputttt:', value && value.format('YYYY-MM-DD HH:mm:ss'));
  return (
    <>
      <StyledTimePicker
        {...rest}
        use12Hours={allowEmpty}
        allowEmpty
        invalid={touched && invalid}
        minuteStep={minuteStep}
        value={!value ? null : moment.utc(value).tz(selectedTimezone)} // cannot be String, must be Moment type
        onChange={timeChangeHandler}
        format={TIME_FORMAT}
        placeholder="hh : mm aa"
        showSecond={false}
      />
      {renderFromHelper({ touched, error })}
    </>
  );
};

export default FASTimePickerField;
