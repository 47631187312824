/* eslint-disable max-len */
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import queryString from 'query-string';
import * as baseInstance from '../../api/instances/baseInstance';
import * as Firebase from '../../utils/firebase';
import AppRoute from './AppRoute';
import requireAuthentication from '../../hoc/requireAuthentication';
import * as AuthActions from '../../redux/actions/auth.actions';
import { timeoutSession } from '../../utils/helpers';

const checkAuth = async (props) => {
  const { dispatch, history, whitelist, location } = props;

  const emailToken = (queryString.parse(location.search ?? '') ?? {}).token;
  // console.log('TEST - emailToken:', emailToken);

  const isInitialized = async () =>
    new Promise((resolve) => {
      const unsubscribe = Firebase.auth.onAuthStateChanged(async (userAuth) => {
        unsubscribe();
        baseInstance.ejectRequestInterceptors();
        if (userAuth) {
          const token = await Firebase.auth.currentUser.getIdToken();
          // Check if there should be a session timeout
          const shouldTimeout = await timeoutSession(userAuth);
          if (shouldTimeout) {
            resolve(false);
            return;
          }
          baseInstance.setInstanceToken(token);
          dispatch(
            AuthActions.setUserAuth({
              ...userAuth.toJSON(),
              role: await Firebase.getUserRole(),
            }),
          );
          baseInstance.setResponseErrorInterceptor((error) => AuthActions.retry(error, dispatch));
          resolve(true);
        }
        resolve(false);
      });
    });

  try {
    /**
     * If auth.currentUser is not null, then the user is signed in.
     *
     * If auth.currnetUser is null, it could be either that the user
     * is not logged or Firebase hasn't been initialized yet (e.g. on
     * page refresh).
     *
     * For this reason, we wait for Firebase to be initialized, after
     * that, if we can retrieve currentUser, then the user is authenticated,
     * otherwise, we return false, execute the logout procedure, and
     * redirect to the login page.
     */
    if (!Firebase.auth.currentUser) {
      const firebaseInit = await isInitialized();

      if (!firebaseInit) {
        if (emailToken) {
          baseInstance.setInstanceToken(emailToken);
          return true;
        }

        await dispatch(AuthActions.logout());
        history.push('/');
        localStorage.clear();
        return false;
      }
      if (emailToken) {
        // if email token exists, then user needs to be authenticated using that token --> log the user out
        await dispatch(AuthActions.logout());
        baseInstance.setInstanceToken(emailToken);
        return true;
      }
    }

    const userRole = await Firebase.getUserRole();
    if (whitelist && !_.includes(whitelist, userRole)) {
      history.push('/home');
      return false;
    }

    return true;
  } catch (error) {
    dispatch(AuthActions.logout());
    history.push('/');
    return false;
  }
};

export default withRouter(requireAuthentication(checkAuth)(AppRoute));
