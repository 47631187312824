/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { baseInstance } from './instances/baseInstance';

export const createSignedURLs = (payload) => baseInstance.post('/media/createSignedURLs', payload);

export const uploadMedia = (url, file) =>
  axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
  });

export const deleteMedia = (payload) => baseInstance.post('/media/removeByURL', payload);
