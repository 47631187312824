/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
// import BookIcon from '@mui/icons-material/ReceiptLongRounded';
// import CancelIcon from '@mui/icons-material/EventBusyRounded';
// import RescheduleIcon from '@mui/icons-material/RestoreRounded';
// import UpcomingIcon from '@mui/icons-material/UpcomingRounded';
// import CompletedIcon from '@mui/icons-material/EventAvailableRounded';
// import EditedIcon from '@mui/icons-material/EditNotificationsRounded';
// import RecFailIcon from '@mui/icons-material/SyncProblemRounded';
// import ConfirmSignIcon from '@mui/icons-material/HistoryEduRounded';
// import OutTerritoryIcon from '@mui/icons-material/WrongLocationRounded';
// import EscalatedIcon from '@mui/icons-material/WarningRounded';
// import NotStartedIcon from '@mui/icons-material/HourglassEmptyRounded';
// import SecondaryIcon from '@mui/icons-material/GroupAddRounded';
// import ReviewIcon from '@mui/icons-material/FactCheckRounded';
import BookIcon from '../../assets/images/notifications_icons/Booked_Event.svg';
import CancelIcon from '../../assets/images/notifications_icons/Cancelled_Event.svg';
import RescheduleIcon from '../../assets/images/notifications_icons/Rescheduled_Event.svg';
import UpcomingIcon from '../../assets/images/notifications_icons/Upcoming_Event.svg';
import CompletedIcon from '../../assets/images/notifications_icons/Completed_Event.svg';
import EditedIcon from '../../assets/images/notifications_icons/Edited_Event.svg';
import RecFailIcon from '../../assets/images/notifications_icons/Recurring_Failed.svg';
import ConfirmSignIcon from '../../assets/images/notifications_icons/Confirmation_Sign.svg';
import OutTerritoryIcon from '../../assets/images/notifications_icons/Outside_Territory.svg';
import EscalatedIcon from '../../assets/images/notifications_icons/Escalated_Event.svg';
import NotStartedIcon from '../../assets/images/notifications_icons/Event_Not_Started.svg';
import SecondaryIcon from '../../assets/images/notifications_icons/Secondary_Added.svg';
import ReviewIconListing from '../../assets/images/notifications_icons/COE_Review_Listing.svg';
import ReviewMatDeactivatedIcon from '../../assets/images/notifications_icons/Review_Mat_Deactivated.svg';
import ReviewOrderBlockIcon from '../../assets/images/notifications_icons/COE_Review_Order_Block.svg';

export const NOTIFICATION_TYPE = {
  BOOKED_EVENT: 'BOOKED_EVENT',
  CANCELED_EVENT: 'CANCELED_EVENT',
  RESCHEDULED_EVENT: 'RESCHEDULED_EVENT',
  UPCOMING_EVENT: 'UPCOMING_EVENT',
  COMPLETED_EVENTS: 'COMPLETED_EVENTS',
  EDITED_EVENTS: 'EDITED_EVENTS',
  RECURRING_FAILED: 'RECURRING_FAILED',
  CONFIRMATION_SIGN: 'CONFIRMATION_SIGN',
  E_OUTSIDE_LOCATION: 'E_OUTSIDE_LOCATION',
  ESCALATED_EVENT: 'ESCALATED_EVENT',
  EVENT_NOT_STARTED: 'EVENT_NOT_STARTED',
  SECONDARY_ADDED: 'SECONDARY_ADDED',
  COE_REVIEW_LISTING: 'COE_REVIEW_LISTING',
  REVIEW_MAT_DEACTIVATED: 'REVIEW_MAT_DEACTIVATED',
  COE_REVIEW_ORDER_BLOCK: 'COE_REVIEW_ORDER_BLOCK',
};

export const NOTIFICATIONS_LABELS = {
  [NOTIFICATION_TYPE.BOOKED_EVENT]: 'New event booked',
  [NOTIFICATION_TYPE.CANCELED_EVENT]: 'Event Cancelled',
  [NOTIFICATION_TYPE.RESCHEDULED_EVENT]: 'Event Rescheduled',
  [NOTIFICATION_TYPE.UPCOMING_EVENT]: 'Upcoming Event',
  [NOTIFICATION_TYPE.COMPLETED_EVENTS]: 'Completed Event',
  [NOTIFICATION_TYPE.EDITED_EVENTS]: 'Change notification',
  [NOTIFICATION_TYPE.RECURRING_FAILED]: 'Recurring event failed',
  [NOTIFICATION_TYPE.CONFIRMATION_SIGN]: 'Confirmation Signed',
  [NOTIFICATION_TYPE.E_OUTSIDE_LOCATION]: 'Event booked outside territory',
  [NOTIFICATION_TYPE.ESCALATED_EVENT]: 'Escalated Event',
  [NOTIFICATION_TYPE.EVENT_NOT_STARTED]: 'Event not started',
  [NOTIFICATION_TYPE.SECONDARY_ADDED]: 'Secondary instructor added',
  [NOTIFICATION_TYPE.COE_REVIEW_LISTING]: 'Course Review Listings (COE)',
  [NOTIFICATION_TYPE.REVIEW_MAT_DEACTIVATED]: 'Course Review Material Deactivated (COE)',
  [NOTIFICATION_TYPE.COE_REVIEW_ORDER_BLOCK]: 'Course Review Order Block (COE)',
};

export const NOTIFICATIONS_HEADERS = {
  [NOTIFICATION_TYPE.BOOKED_EVENT]: 'New event booked',
  [NOTIFICATION_TYPE.CANCELED_EVENT]: 'Event Cancelled',
  [NOTIFICATION_TYPE.RESCHEDULED_EVENT]: 'Event Rescheduled',
  [NOTIFICATION_TYPE.UPCOMING_EVENT]: 'Upcoming Event',
  [NOTIFICATION_TYPE.COMPLETED_EVENTS]: 'Completed Event',
  [NOTIFICATION_TYPE.EDITED_EVENTS]: 'Change notification',
  [NOTIFICATION_TYPE.RECURRING_FAILED]: 'Recurring event failed',
  [NOTIFICATION_TYPE.CONFIRMATION_SIGN]: 'Confirmation Signed',
  [NOTIFICATION_TYPE.E_OUTSIDE_LOCATION]: 'Event booked outside territory',
  [NOTIFICATION_TYPE.ESCALATED_EVENT]: 'Escalated Event',
  [NOTIFICATION_TYPE.EVENT_NOT_STARTED]: 'Event not started',
  [NOTIFICATION_TYPE.SECONDARY_ADDED]: 'Secondary instructor added',
  [NOTIFICATION_TYPE.COE_REVIEW_LISTING]: 'Course review required',
  [NOTIFICATION_TYPE.REVIEW_MAT_DEACTIVATED]: 'Course review required',
  [NOTIFICATION_TYPE.COE_REVIEW_ORDER_BLOCK]: 'Course review required',
};

export const NOTIFICATION_TYPE_ICONS = {
  [NOTIFICATION_TYPE.BOOKED_EVENT]: BookIcon,
  [NOTIFICATION_TYPE.CANCELED_EVENT]: CancelIcon,
  [NOTIFICATION_TYPE.RESCHEDULED_EVENT]: RescheduleIcon,
  [NOTIFICATION_TYPE.UPCOMING_EVENT]: UpcomingIcon,
  [NOTIFICATION_TYPE.COMPLETED_EVENTS]: CompletedIcon,
  [NOTIFICATION_TYPE.EDITED_EVENTS]: EditedIcon,
  [NOTIFICATION_TYPE.RECURRING_FAILED]: RecFailIcon,
  [NOTIFICATION_TYPE.CONFIRMATION_SIGN]: ConfirmSignIcon,
  [NOTIFICATION_TYPE.E_OUTSIDE_LOCATION]: OutTerritoryIcon,
  [NOTIFICATION_TYPE.ESCALATED_EVENT]: EscalatedIcon,
  [NOTIFICATION_TYPE.EVENT_NOT_STARTED]: NotStartedIcon,
  [NOTIFICATION_TYPE.SECONDARY_ADDED]: SecondaryIcon,
  [NOTIFICATION_TYPE.COE_REVIEW_LISTING]: ReviewIconListing,
  [NOTIFICATION_TYPE.REVIEW_MAT_DEACTIVATED]: ReviewMatDeactivatedIcon,
  [NOTIFICATION_TYPE.COE_REVIEW_ORDER_BLOCK]: ReviewOrderBlockIcon,
};

export const YES_NO = {
  YES: true,
  NO: false,
};

export const NOTIFICATION_USER_SETTINGS = {
  actionedBy: {
    ME: 'ME',
    BOTH: 'BOTH',
    OTHER: 'OTHER',
  },
  notifyByEmail: YES_NO,
};

const USER_SET_ALL = {
  actionedBy: true,
  notifyByEmail: true,
};

const USER_SET_EMAIL = {
  actionedBy: false,
  notifyByEmail: true,
};

export const NOTIFICATION_ADMIN_SETTINGS = {
  createdBy: YES_NO,
  instructor: YES_NO,
  manager: YES_NO,
  whenSent: 'input::number',
  inApp: YES_NO,
  userMaintained: { ADMIN: 'ADMIN', USER: 'USER' },
};

export const NOTIFICATION_USER_SETTING_TYPES = {
  [NOTIFICATION_TYPE.BOOKED_EVENT]: USER_SET_ALL,
  [NOTIFICATION_TYPE.CANCELED_EVENT]: USER_SET_ALL,
  [NOTIFICATION_TYPE.RESCHEDULED_EVENT]: USER_SET_ALL,
  [NOTIFICATION_TYPE.UPCOMING_EVENT]: USER_SET_ALL,
  [NOTIFICATION_TYPE.COMPLETED_EVENTS]: USER_SET_ALL,
  [NOTIFICATION_TYPE.EDITED_EVENTS]: USER_SET_ALL,
  [NOTIFICATION_TYPE.RECURRING_FAILED]: USER_SET_ALL,
  [NOTIFICATION_TYPE.CONFIRMATION_SIGN]: USER_SET_ALL,
  [NOTIFICATION_TYPE.E_OUTSIDE_LOCATION]: USER_SET_ALL,
  [NOTIFICATION_TYPE.ESCALATED_EVENT]: USER_SET_EMAIL,
  [NOTIFICATION_TYPE.EVENT_NOT_STARTED]: USER_SET_EMAIL,
  [NOTIFICATION_TYPE.SECONDARY_ADDED]: USER_SET_EMAIL,
  [NOTIFICATION_TYPE.COE_REVIEW_LISTING]: USER_SET_EMAIL,
  [NOTIFICATION_TYPE.REVIEW_MAT_DEACTIVATED]: USER_SET_EMAIL,
  [NOTIFICATION_TYPE.COE_REVIEW_ORDER_BLOCK]: USER_SET_EMAIL,
};

export const NOTIFICATION_USER_SETTINGS_DEFAULTS = {
  actionedBy: NOTIFICATION_USER_SETTINGS.actionedBy.BOTH,
  notifyByEmail: NOTIFICATION_USER_SETTINGS.notifyByEmail.YES,
};

export const SCHEDULED_NOTIFICATIONS_TYPES = [NOTIFICATION_TYPE.UPCOMING_EVENT, NOTIFICATION_TYPE.EVENT_NOT_STARTED];

export const NOTIFICATION_ADMIN_SETTINGS_DEFAULTS = {
  createdBy: NOTIFICATION_ADMIN_SETTINGS.createdBy.YES,
  instructor: NOTIFICATION_ADMIN_SETTINGS.instructor.YES,
  manager: NOTIFICATION_ADMIN_SETTINGS.manager.YES,
  whenSent: 0,
  inApp: NOTIFICATION_ADMIN_SETTINGS.inApp.YES,
  userMaintained: NOTIFICATION_ADMIN_SETTINGS.userMaintained.USER,
};

export const NOTIFICATION_USER_SETTINGS_LABEL = {
  actionedBy: 'Actioned by',
  notifyByEmail: 'Email',
};

export const NOTIFICATION_ADMIN_SETTINGS_LABEL = {
  createdBy: 'Created by',
  instructor: 'Instructor',
  manager: 'Manager',
  whenSent: 'When sent (Hr)',
  inApp: 'In App',
  userMaintained: 'User Maintained',
};

export const NOTIFICATION_RECEIVER_TYPES = {
  CREATOR: 'CREATOR',
  INSTRUCTOR: 'INSTRUCTOR',
  MANAGER: 'MANAGER',
};
