import * as MediaAPI from '../../api/media.api';
import { OK_STATUS_CODES } from '../../utils/consts';

export const createSignedURLs = (payload) => async () => {
  try {
    const response = await MediaAPI.createSignedURLs(payload);
    if (OK_STATUS_CODES.includes(response.status) && response.data) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const uploadMedia = (url, file) => async () => {
  try {
    const response = await MediaAPI.uploadMedia(url, file);
    if (response && OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const deleteMedia = (url) => async () => {
  try {
    const response = await MediaAPI.deleteMedia({ url });
    if (OK_STATUS_CODES.includes(response.status)) {
      return true;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};
