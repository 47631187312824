/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { change, initialize } from 'redux-form';
import {
  UPDATE_USER_LOCATION,
  UPDATE_CONFIGS,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  SAVE_REGIONS,
} from './actionTypes/settings';
import * as SettingsApi from '../../api/settings.api';
import { OK_STATUS_CODES } from '../../utils/consts';
import { configurationsSelector } from '../selectors/settings.selectors';
import { fetchCurrentUser, getRegionsLocations } from './users.actions';
// import { defaultAdminMatrix, defaultMatrix } from '../../assets/mappings/defaultAccessMatrix';

export const updateLocation = (payload) => ({
  type: UPDATE_USER_LOCATION,
  payload,
});

export const saveConfigs = (configs) => ({
  type: UPDATE_CONFIGS,
  configs,
});

const addCategory = (category) => ({
  type: CREATE_CATEGORY,
  category,
});

const editCategory = (index, category) => ({
  type: UPDATE_CATEGORY,
  category,
  index,
});

const saveRegions = (regions) => ({
  type: SAVE_REGIONS,
  data: regions,
});

export const fetchRegionsInfo = () => async (dispatch) => {
  try {
    const result = await dispatch(getRegionsLocations(true));
    if (result) {
      dispatch(saveRegions(result));
      return result;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const fetchConfigs = () => async (dispatch) => {
  try {
    const result = await SettingsApi.fetchConfigs();
    if (result?.data) {
      dispatch(saveConfigs(result.data));
      dispatch(change('OptionToolbar', 'showRevenue', result.data?.defaultShowRevenue ?? false));
      dispatch(fetchRegionsInfo());
      return result.data;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const updateConfigs = (payload) => async (dispatch) => {
  try {
    const p = { ...(payload ?? {}) };
    delete p.materialCategories;
    const result = await SettingsApi.updateConfigs(p);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      dispatch(saveConfigs(payload));
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const updateLocationDefault = (loc, payload) => async (dispatch) => {
  try {
    const result = await SettingsApi.updateLocationDefault(loc, payload);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      return await dispatch(fetchConfigs());
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const updateHolidayDefault = (date, payload) => async (dispatch) => {
  try {
    const result = await SettingsApi.updateHolidayDefault({ [date]: { ...(payload ?? {}), date } });
    if (result && OK_STATUS_CODES.includes(result.status)) {
      return await dispatch(fetchConfigs());
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const deleteHolidayDefault = (date) => async (dispatch) => {
  try {
    const result = await SettingsApi.deleteHolidayDefault(date);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      dispatch(fetchConfigs());
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const updateCancelReasons = (reasonsList) => async (dispatch) => {
  try {
    const result = await SettingsApi.updateCancelReasons(reasonsList);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      dispatch(saveConfigs(result.data));
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const updateDefaultOfficeHours = (newHours) => async (dispatch) => {
  // newHours should be in form { startTime, endTime } with UTC datetime ISO strings
  try {
    const result = await SettingsApi.updateDefaultOfficeHours(newHours);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      dispatch(saveConfigs(result.data));
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const getAdminMatrix = (formName) => async (dispatch) => {
  try {
    const result = await SettingsApi.getAdminMatrix();
    if (result && OK_STATUS_CODES.includes(result.status)) {
      dispatch(
        initialize(
          formName,
          Object.keys(result.data).reduce(
            (red, key) => ({
              ...red,
              [key]: { role: key, ...(result.data[key] ?? {}) },
            }),
            {},
          ),
        ),
      );
      return true;
    }
    return false;
  } catch (e) {
    // // for testing only
    // dispatch(initialize(formName, Object.keys(defaultAdminMatrix).reduce(
    //   (red, key) => ({
    //     ...red,
    //     [key]: { role: key, ...(defaultAdminMatrix[key] ?? {}) },
    //   }),
    //   {},
    // )));
    // return true;
    return false;
  }
};

export const getRoleMatrix = (formName) => async (dispatch) => {
  try {
    const result = await SettingsApi.getRoleMatrix();
    if (result && OK_STATUS_CODES.includes(result.status)) {
      dispatch(
        initialize(
          formName,
          Object.keys(result.data).reduce((red, key) => {
            const role = key.split('--')[0];
            return {
              ...red,
              [key]: { role, ...(result.data[key] ?? {}) },
            };
          }, {}),
        ),
      );
      return true;
    }
    return false;
  } catch (e) {
    // // for testing only
    // dispatch(
    //   initialize(
    //     formName,
    //     Object.keys(defaultMatrix).reduce(
    //       (red, key) => {
    //         const role = key.split('--')[0];
    //         return ({
    //           ...red,
    //           [key]: { role, ...defaultMatrix[key] },
    //         });
    //       },
    //       {},
    //     ),
    //   ),
    // );
    // return true;
    return false;
  }
};

export const updateAdminMatrix = (matrix, formName) => async (dispatch) => {
  try {
    const result = await SettingsApi.updateAdminMatrix(matrix);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      dispatch(initialize(formName, matrix));
      dispatch(fetchCurrentUser());
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const updateRolesMatrix = (matrix, formName) => async (dispatch) => {
  try {
    const result = await SettingsApi.updateRoleMatrix(matrix);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      dispatch(initialize(formName, matrix));
      dispatch(fetchCurrentUser());
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const uploadMaterialCategoryImage = (blob) => async () => {
  try {
    const formData = new FormData();
    formData.append('image', blob);
    const payload = formData;
    const response = await SettingsApi.uploadMaterialCategoryImage(payload);
    if (response && response.data && OK_STATUS_CODES.includes(response.status)) {
      return response.data;
    }

    return '';
  } catch (e) {
    return '';
  }
};

export const createCategory =
  (values, saveImage = false) =>
  async (dispatch) => {
    try {
      if (_.isEmpty(values ?? {})) return false;
      let { imgURL } = values;
      const { imageObj } = values;
      if (saveImage && imageObj) {
        imgURL = await dispatch(uploadMaterialCategoryImage(imageObj));
      }
      const newCategory = {
        title: values.title,
        imgURL,
      };
      const result = await SettingsApi.createCategory(newCategory);
      if (result && OK_STATUS_CODES.includes(result.status) && result.data) {
        dispatch(addCategory(result.data));
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

export const updateCategory =
  (id, values, saveImage = false, deleteEntry = false) =>
  async (dispatch, getState) => {
    try {
      if (_.isEmpty(values ?? {}) && !deleteEntry) return false;
      let { imgURL } = values;
      const { imageObj } = values;
      if (saveImage && imageObj) {
        imgURL = await dispatch(uploadMaterialCategoryImage(imageObj));
      }
      const configs = configurationsSelector(getState());
      const materialCategories = [...(configs?.materialCategories ?? [])];
      const idx = materialCategories.findIndex((val) => val.id === id);
      if (idx === -1 && !deleteEntry) {
        return await dispatch(createCategory(values));
      }
      // // if (deleteEntry) {
      // //   materialCategories.splice(idx, 1);
      // // } else {
      const updatedCategory = {
        id: values.id,
        imgURL,
        title: values.title,
      };
      // console.log('TEST - updating to:', updatedCategory);
      const result = await SettingsApi.updateCategory(updatedCategory.id, updatedCategory);
      if (result && OK_STATUS_CODES.includes(result.status) && result.data) {
        dispatch(editCategory(idx, result.data));
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

export const deleteCategory = (/* id */) => async (/* dispatch */) => {
  // try {
  //   return await dispatch(updateCategory(id, {}, false, true));
  // } catch (e) {
  //   return false;
  // }
};

export const updateNotificationAdminSettings = (payload) => async (dispatch) => {
  try {
    const result = await SettingsApi.updateAdminNotificationSettings(payload);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      await dispatch(fetchConfigs());
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const updateCurrentUserNotificationsSettings = (payload) => async (dispatch) => {
  try {
    const result = await SettingsApi.updateUserNotificationsSettings(payload);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      await dispatch(fetchCurrentUser());
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const updateTaskAdminSettings = (payload) => async (dispatch) => {
  try {
    const result = await SettingsApi.updateAdminTaskSettings(payload);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      await dispatch(fetchConfigs());
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const updateOrgRelationsSettings = (payload) => async (dispatch) => {
  try {
    const result = await SettingsApi.updateOrgRelations(payload);
    if (result && OK_STATUS_CODES.includes(result.status)) {
      await dispatch(fetchConfigs());
    }
    return true;
  } catch (e) {
    return false;
  }
};
