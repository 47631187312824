/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Field, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import FASBlackFont from '../../../components/Forms/CustomFormComponents/FASBlackFont';
import { ccyFormat, removeLeadingZeros } from '../../../utils/helpers';
import { COLORS, ENTER_KEY_CODE, DEFAULT_TRUCK_NUMBER, MATERIAL_TYPE } from '../../../utils/consts';
import NO_IMAGE_AVAILABLE from '../../../assets/images/no_image_available.png';
import ContainerItem from '../../../components/LayoutBuilders/ContainerItem';
import Container from '../../../components/LayoutBuilders/Container';
import MDTextInputField from '../../../components/Forms/FormComponents/MDTextInput/MDTextInputField';
import FASCheckboxField from '../../../components/Forms/CustomFormComponents/FASCheckboxField/FASCheckboxField';
import { adjustRowPrice } from '../../../components/Calendar/CalendarComponents/EventDialog/EventDialogForm/FormComponent/CartSummaryForm';
import * as ModalsActions from '../../../redux/actions/modals.actions';
import CoursePriceDialog from '../../../components/Calendar/CalendarComponents/CoursePriceDialog/CoursePriceDialog';
import StyledButton from '../../../components/Button/StyledButton';
// import { selectedTciSelector, userInfoSelector } from '../../../redux/selectors/user.selectors';
import RefreshPriceBtn from './RefreshPriceBtn';
import { useStyles } from './EventSummaryView';
import { materialsDataSelector, materialSelectorOfMultipleTypes } from '../../../redux/selectors/materials.selector';
import { resetDeliverySignature } from '../../../utils/event.utils';
// import { useStyles } from '../../routes/MainViews/EventSummaryView/EventSummaryView';
// import { userInfoSelector } from '../../../redux/selectors/user.selectors';

export const calcTotalPrice = (unit, q) => Number(unit) * Number(q);
const priceListingsFormPath = 'priceListings';

const AddonTableItem = ({
  addon,
  customer,
  course,
  customerFacing,
  defaultQty,
  currency,
  i,
  idx,
  flexMap,
  showMaterialNumbers,
  allowEditing,
  formName,
  exclusionListings,
  isAdditionalParticipants,
  isBilling,
}) => {
  const [checked, setChecked] = useState(true);
  const addOnFormPath = `billingSummary.events[${i}].${isAdditionalParticipants ? 'additional_participants' : 'addOns'}[${idx}]`;
  const qtyFieldId = `${addOnFormPath}.qty`;
  const priceFieldId = `${addOnFormPath}.price`;
  const includeFieldId = `${addOnFormPath}.include`;
  const truckNumFieldId = `${addOnFormPath}.truckNumber`;

  const dispatch = useDispatch();
  // const userDefaultTruckNumber = useSelector((state) => (state.users?.data?.selectedTci || userInfoSelector(state)).defaultTruckNumber);
  const formAddOn = useSelector((state) => {
    if (!allowEditing) return null;
    const formVal = _.get(state.form[formName]?.values, addOnFormPath);
    if (isAdditionalParticipants && formVal) {
      return {
        ...(formVal ?? {}),
        include: true,
      };
    }
    return formVal;
  });
  // const selectedTci = useSelector(selectedTciSelector);
  // const defaultTruckNum = useSelector((state) => ((selectedTci || userInfoSelector(state)) ?? {}).defaultTruckNumber);
  const qty = allowEditing ? Number(formAddOn?.qty ?? defaultQty) : Number(addon.qty ?? defaultQty);
  const totalPrice = allowEditing
    ? calcTotalPrice(formAddOn?.price ? formAddOn?.price.amount ?? 0 : 0, qty)
    : calcTotalPrice(addon.price ? addon.price.amount ?? 0 : 0, addon.qty ?? defaultQty);
  const [lastTotalPrice, setLastTotalPrice] = React.useState(totalPrice);
  const totalPriceStr = ccyFormat(totalPrice, currency);
  const listPrice = allowEditing ? formAddOn?.price?.amount ?? 0 : addon.price?.amount ?? 0;
  const listPriceStr = `${ccyFormat(listPrice, currency)}`;
  const priceListing = formAddOn && formAddOn[priceListingsFormPath];
  const disablePriceEdits = Boolean(_.find(priceListing ?? [], { Customer: customer.sold_to }));
  const addonMaterial = useSelector((state) =>
    materialSelectorOfMultipleTypes({
      state,
      id: formAddOn?.id ?? addon.id,
      types: [MATERIAL_TYPE.ADD_ON, MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS],
    }),
  );

  const materialsInfo = useSelector(materialsDataSelector);
  const getMaterialInfo = (id, types) =>
    materialSelectorOfMultipleTypes({
      id,
      materialsInfo,
      types: types ?? [MATERIAL_TYPE.ADD_ON, MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS],
    });
  const getMaterialCode = (id, types) => getMaterialInfo(id, types)?.code ?? '';
  const getWarehouseOverride = (item, types) => getMaterialInfo(item.id, types)?.warehouseOverride;
  const notAvailable =
    (!formAddOn && allowEditing) ||
    (!allowEditing && !addon) ||
    !(isAdditionalParticipants
      ? formAddOn?.sap_material_number ?? addon?.sap_material_number
      : getMaterialCode(formAddOn?.id ?? addon?.id)) ||
    !(
      (
        _.find(exclusionListings ?? [], {
          Material: isAdditionalParticipants
            ? formAddOn?.sap_material_number
            : getMaterialCode(formAddOn?.id ?? addon?.id),
        }) ?? {}
      ).inlcuded ?? true
    );
  const canEdit = allowEditing && formAddOn.include;
  const classes = useStyles({ notAvailable: notAvailable && !customerFacing });

  React.useEffect(() => {
    // avoid default truck number overwrite if user is on billing screen
    if (!isBilling && formAddOn?.truckNumber && getWarehouseOverride(formAddOn)) {
      formAddOn.truckNumber = DEFAULT_TRUCK_NUMBER;
    }

    if (isAdditionalParticipants && formAddOn && !formAddOn?.include) {
      dispatch(change(formName, includeFieldId, true));
      if (!customerFacing) {
        handleIncludeExclude(true);
      }
    }
  }, [formAddOn]);

  const adjustSummaryPrice = ({ add, remove }) => {
    // if (isAdditionalParticipants) return;
    const pid = `${isAdditionalParticipants ? 'additionalParticipants' : 'addons'}${course.order}`;
    adjustRowPrice({
      pid,
      add: add ? Number(add) : undefined,
      subtract: remove ? Number(remove) : undefined,
    });
  };

  const onQtyUnfocus = () => {
    if (!formAddOn?.qty) {
      dispatch(change(formName, qtyFieldId, '0'));
    } else if (formAddOn?.qty && Number(formAddOn?.qty) < 0) {
      dispatch(change(formName, qtyFieldId, `${Math.abs(Number(formAddOn?.qty))}`));
    }
  };

  const onQtyChange = (input) => {
    let val = input ? Number(input) : 0;
    if (Number(val) < 0) {
      val = Math.abs(val);
    }
    const newTotal = calcTotalPrice(addon?.price?.amount ?? 0, val);
    adjustSummaryPrice({ remove: lastTotalPrice, add: newTotal });
    setLastTotalPrice(newTotal);
  };

  const updateAddonPrice = (newPrice) => {
    dispatch(change(formName, priceFieldId, { ...(newPrice ?? {}) }));
    resetDeliverySignature(formName, dispatch); // reset signature after addon price change
    const newTotal = calcTotalPrice(newPrice?.amount ?? 0, qty);
    adjustSummaryPrice({
      remove: lastTotalPrice,
      add: newTotal,
    });
    setLastTotalPrice(newTotal);
  };

  const handleIncludeExclude = (include) => {
    setChecked(include);
    if (include) {
      adjustSummaryPrice({ add: totalPrice });
      dispatch(change(formName, qtyFieldId, addon.qty ?? defaultQty));
    } else {
      adjustSummaryPrice({ remove: totalPrice });
      dispatch(change(formName, qtyFieldId, '0'));
    }
    resetDeliverySignature(formName, dispatch); // reset signature after include/exclude
  };

  const openCoursePricePopup = () => {
    const modalName = 'COURSE_PRICE_DIALOG';
    dispatch(
      ModalsActions.showModal(modalName, {
        modalType: 'FAS_CONFIRM_ALERT',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          confirmText: 'confirm',
          fullWidth: true,
          disableBackdropClick: true,
          maxWidth: 'sm',
          title: `${course.title ?? ''} Price`,
          content: (
            <CoursePriceDialog
              modalName={modalName}
              course={course}
              addonSpecific={allowEditing ? formAddOn : addon}
              customer={customer}
              dispatch={dispatch}
              onSubmit={updateAddonPrice}
            />
          ),
        },
      }),
    );
  };

  const text = (txt, className, rest) => (
    <FASBlackFont size="15px" weight={500} className={className} {...(rest ?? {})} title={txt} />
  );
  const container = ({ children, ...rest }) => (
    <Container justify="space-between" alignItems="flex-start" spacing={3} {...(rest ?? {})}>
      {children}
    </Container>
  );
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;
  const inputField = ({
    id,
    placeholder,
    type,
    textAlign,
    label,
    required,
    onChange,
    onDone,
    width,
    disabled,
    min,
    ...rest
  }) => (
    <Field
      id={id}
      name={id}
      component={MDTextInputField}
      size="small"
      type={type ?? 'number'}
      disabled={disabled}
      inputProps={{
        width: width ?? '50%',
        style: { textAlign: textAlign ?? 'center', marginLeft: 5 },
        ...(rest ?? {}),
      }}
      min={min}
      variant="outlined"
      required={required}
      label={label}
      noBorderRadius
      style={{ width: width ?? '55%' }}
      placeholder={placeholder}
      onHandleChange={onChange}
      onBlurCapture={() => (onDone ? onDone() : null)}
      onKeyDown={(k) => (k.code === ENTER_KEY_CODE && onDone ? onDone() : null)}
    />
  );

  const refreshPriceBtn = (materialNum, listingsPath) => (
    <RefreshPriceBtn
      formName={formName}
      customer={customer}
      classes={classes}
      listingsPath={listingsPath}
      materialNum={materialNum}
      updatePriceFn={updateAddonPrice}
    />
  );

  const priceBtn = ({ child, onClick }) => (
    <>
      <StyledButton
        buttonContent={child}
        handleButton={onClick}
        variant="outlined"
        style={{ color: 'black', boxShadow: 'none', border: `1px solid ${COLORS.CINTAS_GRAY}` }}
      />
    </>
  );

  const addOnImg = () => (
    <img
      className={classes.image}
      alt={`Addon${idx}Img${addon.title}`}
      src={(formAddOn?.image ?? addon?.image) || NO_IMAGE_AVAILABLE}
    />
  );

  const checkbox = () => (
    <div style={{ position: 'relative' }}>
      {!isAdditionalParticipants && (
        <Field
          className={classes.itemcheckbox}
          id={includeFieldId}
          name={includeFieldId}
          component={FASCheckboxField}
          disabled={formAddOn.required || notAvailable || isAdditionalParticipants}
          label=""
          hideErrorHelper
          isDelete
          onChange={(include) => handleIncludeExclude(include)}
        />
      )}
    </div>
  );

  const selectableImg = () => (
    <>
      {checkbox()}
      {addOnImg()}
    </>
  );

  const truckNumberInput = () => (
    <>
      <Box height={8} />
      {!notAvailable &&
        item({
          flex: 12,
          style: { textAlign: 'start' },
          children: inputField({
            id: truckNumFieldId,
            label: formAddOn?.truckNumber ? 'Truck Number *' : null,
            placeholder: formAddOn?.truckNumber ? null : 'Truck Number *',
            width: '90%',
            type: 'text',
            textAlign: 'start',
            disabled: notAvailable || getWarehouseOverride(addon),
            onChange: (value) => dispatch(change(formName, truckNumFieldId, value)),
          }),
        })}
    </>
  );

  const addOnImgTitle = () =>
    item({
      flex: flexMap.titleFlex,
      children: container({
        spacing: 2,
        justify: 'start',
        children: [
          item({
            flex: 'auto',
            className: classes.box,
            children: allowEditing && !notAvailable ? selectableImg() : addOnImg(),
          }),
          item({
            flex: 8,
            children: container({
              spacing: 0,
              direction: 'column',
              children: [
                text(
                  `${showMaterialNumbers && addon.sap_material_number ? `${removeLeadingZeros(addon.sap_material_number)} - ` : ''}${addon.title}${!customerFacing ? (!allowEditing && !notAvailable ? `\nTruck Num: ${addon.truckNumber ?? 'N/A'}` : '') : ''}`,
                  undefined,
                  {
                    style: {
                      color: notAvailable && !customerFacing ? COLORS.CINTAS_RED : 'black',
                      fontStyle: notAvailable && !customerFacing ? 'italic' : 'normal',
                    },
                  },
                ),
                allowEditing && formAddOn.include && !customerFacing && truckNumberInput(),
              ],
            }),
          }),
        ],
      }),
    });

  return container({
    key: `Addon${idx}${addon.title}${i}`,
    children: checked && [
      addOnImgTitle(),
      item({
        flex: flexMap.instructorFlex,
        children:
          canEdit && !disablePriceEdits
            ? [
                priceBtn({ child: listPriceStr, onClick: openCoursePricePopup }),
                refreshPriceBtn(addonMaterial?.code, `${addOnFormPath}.${priceListingsFormPath}`),
              ]
            : [
                text(listPriceStr, classes.textCenterAlign),
                ...(canEdit ? [refreshPriceBtn(addonMaterial?.code, `${addOnFormPath}.${priceListingsFormPath}`)] : []),
              ],
      }),
      item({
        flex: flexMap.participantsFlex,
        children:
          canEdit && !isAdditionalParticipants
            ? inputField({
                id: qtyFieldId,
                disabled: !formAddOn.include || notAvailable,
                min: '0',
                onChange: onQtyChange,
                onDone: onQtyUnfocus,
              })
            : text(`${Number.isNaN(qty) ? 0 : qty}`, classes.textCenterAlign),
      }),
      item({
        flex: flexMap.priceFlex,
        children: text(`${totalPriceStr}`, classes.textRightAlign),
      }),
    ],
  });
};

export default AddonTableItem;
