/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import MDAutoComplete from '../Forms/FormComponents/MDAutoComplete/MDAutoCompleteField';

const formName = 'CalendarTable';
const fields = {
  zipcodeQuery: 'zipcodeQuery',
};

const TciZipcodeSelect = ({ options }) => (
  <Field
    variant="outlined"
    id={fields.zipcodeQuery}
    name={fields.zipcodeQuery}
    component={MDAutoComplete}
    displayEmpty
    options={options}
    getLabel={(opt) => opt.zipcode}
    style={{ textAlign: 'start', borderRadius: 0 }}
    inputProps={{
      placeholder: 'Filter by postal code',
      style: { maxHeight: 40, padding: 0, maxWidth: '80%' },
    }}
  />
);

export default _.flow([
  connect((state) => ({
    options: (state.users?.data?.reportingTcis ?? []).reduce(
      (reduced, tci) => [
        ...reduced,
        ...Object.keys(tci?.locations ?? {})
          .map((locId) => ({
            locId,
            zipcodes: (tci?.locations[locId]?.zip_codes ?? []).filter((zip) => Boolean(zip)),
          }))
          .reduce(
            (prev, loc) => [...prev, ...loc.zipcodes.map((zip) => ({ zipcode: zip, locationId: loc.locId }))],
            [],
          ),
      ],
      [],
    ),
  })),
  reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
])(TciZipcodeSelect);
