/* eslint-disable quote-props */
/* eslint-disable object-curly-newline */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React from 'react';
import zipcodes from 'zipcodes';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IosShareIcon from '@mui/icons-material/IosShare';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import StyledButton from '../../components/Button/StyledButton';
import SimpleText from '../../components/Text/SimpleText';
import Container from '../../components/LayoutBuilders/Container';
import ContainerItem from '../../components/LayoutBuilders/ContainerItem';
import { COLORS, DATE_PICKER_FOMAT, DATE_STANDARD_FORMAT, ROLE_ACCESSES } from '../../utils/consts';
import UserContactInfo from '../../components/UserAccount/UserContactInfo';
import UserOrgStructure from '../../components/UserAccount/OrgStructure/UserOrgStructure';
import { hasUserAccessSelector } from '../../redux/selectors/user.selectors';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import { getLocationsExportData } from '../../redux/actions/users.actions';
import { showModal } from '../../redux/actions/modals.actions';
import CsvFactory from '../../utils/csvFactory';
import TCICertifications from '../../components/UserAccount/TCICertifications';
import { removeLeadingZeros } from '../../utils/helpers';
import UserNotificationSettings from '../../components/UserAccount/UserNotificationSettings';
import { fetchUnreadNotifications } from '../../redux/actions/notifications.actions';
import { fetchTasksData } from '../../redux/actions/tasks.actions';

const exportFileName = 'Territory Export $(date)';
const exportHeadersMap = {
  location_id: 'Location',
  tcpos: 'Territory',
  zip_codes: 'Zip Code',
  effective_date: 'Effective Date',
  pernr: 'Territory TCI',
  city: 'City',
  state: 'State',
};

const UserProfileView = () => {
  const dispatch = useDispatch();
  const userTz = useSelector(timezoneSelector);
  const seeOrgStructure = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.viewTrainersProfile]));
  const myTciProfile = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.tciProfile]));
  const allowExport = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.territoryExport]));
  const minCardsHeight = myTciProfile ? 950 : 700;

  React.useEffect(() => {
    dispatch(fetchUnreadNotifications(1));
    dispatch(fetchTasksData());
  }, []);

  const handleExport = async () => {
    const resp = await dispatch(getLocationsExportData());
    if (resp && resp.length > 0) {
      try {
        const data = buildCompleteData(resp);

        CsvFactory.downloadCsv({
          headers: Object.keys(data[0]).sort(
            (a, b) => Object.keys(exportHeadersMap).indexOf(a) - Object.keys(exportHeadersMap).indexOf(b),
          ),
          getHeaderLabel: (h) => exportHeadersMap[h] ?? h,
          dataList: data,
          fileName: exportFileName.replaceAll('$(date)', moment(Date.now()).format(DATE_PICKER_FOMAT)),
          forceStringHeaders: ['location_id', 'zip_codes'],
        });
      } catch (e) {
        showExportError();
      }
    } else {
      showExportError();
    }
  };

  const lookupCityAndState = (zip) => {
    const lookup = zipcodes.lookup((zip ?? '').trim());
    if (lookup) {
      let ct = lookup.city ?? '';
      if (ct.indexOf('(') !== -1 && ct.indexOf(')') !== -1) {
        const toRemove = ct.substring(ct.indexOf('('), ct.indexOf(')') + 1);
        ct = ct.replaceAll(toRemove, '');
      }
      return {
        city: ct,
        state: lookup.state ?? '',
      };
    }
    return {};
  };

  const inferCityAndState = (row) => {
    const desc = (row.location_desc ?? '').toLowerCase().split(' ');
    let city = '';
    let state = '';
    if (desc.length === 4) {
      city = _.startCase(desc[1].toLowerCase());
      state = desc[2].toUpperCase();
    } else if (desc.length > 4) {
      const fasIdx = desc.indexOf('fas');
      if (fasIdx !== -1 && fasIdx > 0) {
        state = (desc[fasIdx - 1] ?? '').toUpperCase();
        city = _.startCase(
          desc
            .slice(1, fasIdx - 1)
            .join(' ')
            .toLowerCase(),
        );
      }
    }

    return { city, state };
  };

  const buildCompleteData = (data) => {
    const fullData = [];
    const effectiveDate = moment().utc().tz(userTz).format(DATE_STANDARD_FORMAT);
    data.forEach((r) => {
      const row = { ...r };
      const { location_id: location, zip_codes: zipCode } = row;
      const paddedLocation = location.padStart(4, 0);
      const zipCodes = zipCode.split(',');
      zipCodes.forEach((z) => {
        const zip = z.trim();
        if (zip.length > 1) {
          let { city, state } = lookupCityAndState(zip);
          if (!city && !state && removeLeadingZeros(zip).length > 1) {
            const inferedData = inferCityAndState(row);
            city = inferedData.city;
            state = inferedData.state;
          }
          delete row.location_desc;
          fullData.push({
            ...row,
            location_id: `${paddedLocation}`,
            zip_codes: `${zip}`,
            city,
            state,
            effective_date: effectiveDate,
          });
        } else {
          delete row.location_desc;
          fullData.push({
            ...row,
            location_id: `${paddedLocation}`,
            city: '',
            state: '',
            effective_date: effectiveDate,
          });
        }
      });
    });

    return fullData;
  };

  // const joinUniqueList = (list, delimiter = ',') => list.reduce((a, b) => (a.includes(b) ? a : [...a, b]), []).join(delimiter);

  const showExportError = () => {
    dispatch(
      showModal('DOWNLOAD_ERROR_MODAL', {
        modalType: 'ERROR_ALERT',
        modalProps: { message: 'Something went wrong creating the CSV file. Please, try again.' },
      }),
    );
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const pageHeader = () => (
    <SimpleText
      txt="My Account"
      fontSize={30}
      style={{ fontWeight: 'bold', color: COLORS.CINTAS_BLUE, paddingTop: 10 }}
    />
  );

  const exportTerritoriesBtn = () => (
    <StyledButton
      variant="contained"
      color="primary"
      startIcon={<IosShareIcon />}
      buttonContent="Export Territories"
      style={{ padding: '8px 30px', maxHeight: 45, marginTop: '1%' }}
      onClick={handleExport}
    />
  );

  return container({
    spacing: 6,
    direction: 'column',
    children: [
      item({
        flex: 12,
        children: container({
          spacing: 1,
          style: { justifyContent: 'space-between' },
          children: [pageHeader(), allowExport ? exportTerritoriesBtn() : <></>],
        }),
      }),
      item({
        flex: 12,
        children: container({
          style: { justifyContent: 'center', textAlign: 'center', alignItems: 'stretch' },
          children: [
            item({ flex: 6, style: { height: '100%' }, children: <UserContactInfo minHeight={minCardsHeight} /> }),
            seeOrgStructure ? (
              item({ flex: 3, style: { height: '100%' }, children: <UserOrgStructure minHeight={minCardsHeight} /> })
            ) : (
              <></>
            ),
            myTciProfile ? (
              item({ flex: 3, style: { height: '100%' }, children: <TCICertifications minHeight={minCardsHeight} /> })
            ) : (
              <></>
            ),
            item({
              flex: 6 + (seeOrgStructure ? 3 : 0) + (myTciProfile ? 3 : 0),
              style: { justifyContent: 'center', textAlign: 'center', alignItems: 'stretch' },
              children: <UserNotificationSettings />,
            }),
          ],
        }),
      }),
    ],
  });
};

export default _.flow([withRouter, connect()])(UserProfileView);
