/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { DEFAULT_TRUCK_NUMBER, EVENT_TYPE, MATERIAL_TYPE } from '../../utils/consts';
import { customerZipToTzLabel } from '../../utils/dateUtils';
import {
  addSkillCheckCourses,
  getCustomerLocationDisplay,
  makeDayDiscountCalculator,
  makeDayPriceDiscountCalculator,
} from '../../utils/event.utils';
import { materialSelector } from '../selectors/materials.selector';
import { timezoneSelector } from '../selectors/utils.selectors';
import * as ExternalActions from '../actions/externalsite.actions';
import { userInfoSelector } from '../selectors/user.selectors';

export const updateOrderPayload = ({ secondaryTci, ...values }, instructor, eventType) => ({
  ...values,
  id: values.order,
  contact: values.customerContact,
  contactID: values.customerContact?.SAPContactID ?? values.contactID,
  temporaryContact: values.temporaryContact ? { ...values.temporaryContact } : undefined,
  courses: (values.combinedCourses ?? []).map((cc) => cc.eventId ?? cc.course?.eventId).filter((eId) => Boolean(eId)),
  customer: values.customerID,
  customerObj: values.customer,
  locID: values.customer?.sales_office,
  distribution_channel: values.distribution_channel,
  notes: values.notes,
  signature: false,
  instructor,
  instructorID: instructor,
  secondaryTci,
  fees: values.fees,
  events: (values.combinedCourses ?? []).map((cc, __, arr) => {
    const priceAmount = Number(cc.customerPrice ?? cc.price ?? cc.marketPrice);
    const discount = makeDayPriceDiscountCalculator(
      cc.course?.type,
      arr.filter((e) => e.course?.type === MATERIAL_TYPE.COURSE).length ?? 1,
      priceAmount,
      cc.marketPrice,
      cc.course?.discount ?? 0,
      values.customer,
    );
    const isSkillcheck = cc.course?.type === MATERIAL_TYPE.SKILL_CHECK;
    const selectedAddons = isSkillcheck
      ? []
      : Object.values(((values.courses ?? []).find((c) => c.id === cc.id) ?? {}).selectedAddons ?? {})
          .filter((a) => Boolean(a) && a.qty && Number(a.qty) >= 0)
          .map((a) => ({
            ...(a ?? {}),
            qty: Number(a.qty ?? 0),
            price: {
              amount: Number(a.price.amount ?? 0),
              ...(a.price ?? {}),
            },
          }));
    const additionalParticipants = isSkillcheck
      ? []
      : Object.values(((values.courses ?? []).find((c) => c.id === cc.id) ?? {}).additional_participants ?? {}).filter(
          (a) => Boolean(a) && a.qty && Number(a.qty) > 0,
        );

    return {
      id: cc?.id,
      additional_participants: additionalParticipants,
      addOns: selectedAddons,
      allDay: cc.allDay,
      locID: values.customer?.sales_office,
      title: values.customer?.name,
      contactID: values.customerContact?.SAPContactID ?? values.contactID,
      temporaryContact: values.temporaryContact ? { ...values.temporaryContact } : undefined,
      course: cc.course?.id ?? '',
      courseObj: cc.course,
      skillcheck: cc.skillcheck,
      skillcheckPrice: cc.skillcheckPrice,
      skillcheckParticipants: cc.skillcheckParticipants,
      customerID: values.customerID,
      distribution_channel: values.distribution_channel,
      startTime: cc.courseStartTime,
      endTime: cc.courseEndTime,
      eventParticipants: cc.eventParticipants ?? [],
      eventType: cc.eventType ?? eventType,
      ...(cc.new
        ? {}
        : {
            id: cc.eventId ?? cc.course?.eventId,
          }),
      ...(cc.course?.parentEventID
        ? {
            parentEventID: cc.course?.parentEventID,
          }
        : {}),
      instructor: cc.instructor,
      instructorID: instructor,
      secondaryTci,
      order: values.order,
      participants_count: parseInt(cc.participants ?? cc.participants_count, 10),
      price: {
        amount: discount.isMakeDayDiscount ? discount.adjustedPrice : priceAmount,
        currency: cc.currency,
        option: cc.customerPrice ? 'CUSTOMER' : 'MARKET',
        ...(discount.isMakeDayDiscount ? { makeDayDiscount: discount.makeDayDiscount } : {}),
      },
      sales_org: values.customer?.sales_organization,
      sap_material_number: cc.sap_material_number,
      truckNumber: cc.truckNumber,
    };
  }),
});

export const createOrderPayload = async (state, desc, selectedTci, defaultTruckNum, rest) => {
  const payload = [];
  const currentUser = userInfoSelector(state);
  const newEventState = await ExternalActions.calculateScheduling(
    undefined,
    selectedTci?.uid ?? currentUser?.uid,
    {
      ...desc,
    },
    'create',
  );
  _.forEach(newEventState.courses, (onsite) => {
    const addOns = _(onsite.selectedAddons ?? {})
      .values()
      .map((a) => {
        const mat = materialSelector({ state, type: MATERIAL_TYPE.ADD_ON, id: a.id });
        return {
          ...a,
          qty: Number(a.qty),
          truckNumber: mat?.warehouseOverride ? DEFAULT_TRUCK_NUMBER : defaultTruckNum,
        };
      })
      .filter((a) => a.qty && a.qty > 0)
      .value();
    const customerPrice = Number(onsite.customerPrice ?? 0);
    const courseTypes = [...(newEventState.courses ?? [])].filter((c) => c.course.type === MATERIAL_TYPE.COURSE);
    const discount = makeDayDiscountCalculator(onsite, courseTypes.length, desc.customer);
    const { afterHoursFee, travelInfo } = ExternalActions.getFeesFromEventsState(newEventState);
    const { typeOverride, unscheduled } = rest ?? {};
    if (onsite.eventType === EVENT_TYPE.TIME_BLOCK) {
      payload.push({
        afterHoursFee,
        travelInfo: desc?.fees?.travelFee || travelInfo,
        allDay: onsite.allDay,
        blockType: onsite.blockType,
        eventType: onsite.eventType,
        instructorID: onsite.instructorID,
        reason: onsite.reason,
        startTime: onsite?.courseStartTime,
        endTime: onsite?.courseEndTime,
        title: onsite?.title,
        route: desc?.customer?.Route ?? '',
        soldTo: desc?.customer?.sold_to ?? '',
        locID: desc?.customer?.sales_office ?? '',
      });
    } else {
      payload.push({
        afterHoursFee,
        travelInfo: desc?.fees?.travelFee || travelInfo,
        title: desc.customer.name,
        route: desc?.customer?.Route ?? '',
        soldTo: desc?.customer?.sold_to ?? '',
        locID: desc?.customer?.sales_office ?? '',
        locationDisplay: getCustomerLocationDisplay(desc.customer),
        startTime: unscheduled ? undefined : onsite.courseStartTime,
        endTime: unscheduled ? undefined : onsite.courseEndTime,
        allDay: false,
        eventType: typeOverride ?? 'ON-SITE',
        notes: desc.notes,
        customerId: desc.customer.sold_to,
        customertz: customerZipToTzLabel(desc.customer?.postal_code, { defaultVal: timezoneSelector(state) }),
        sales_org: desc.customer.sales_organization,
        distribution_channel: desc.customer.distribution_channel,
        // TODO: Make this the actual value where storeNum is stored
        storeNum: desc.customer?.storeNum,
        contactId: desc.customerContact ? desc.customerContact.SAPContactID : undefined,
        temporaryContact: desc.temporaryContact ? { ...desc.temporaryContact } : undefined,
        instructorID: selectedTci ? selectedTci.uid : undefined,
        secondaryTci: desc.secondaryTci,
        subcontracted: Boolean(selectedTci?.subcontractor),
        participants_count: parseInt(onsite.participants, 10),
        course: onsite.course.id,
        truckNumber: defaultTruckNum,
        isSkillcheck: onsite.course.type === MATERIAL_TYPE.SKILL_CHECK,
        price: {
          amount: discount.isMakeDayDiscount ? discount.adjustedPrice : customerPrice,
          ...(discount.isMakeDayDiscount ? { makeDayDiscount: discount.makeDayDiscount } : {}),
          currency: onsite.currency,
          option: 'MARKET',
        },
        addOns,
        additional_participants: onsite.additional_participants?.[0]
          ? [
              {
                ...onsite.additional_participants[0],
                truckNumber: materialSelector({
                  state,
                  type: MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS,
                  id: onsite.additional_participants[0].id,
                })?.warehouseOverride
                  ? DEFAULT_TRUCK_NUMBER
                  : defaultTruckNum,
              },
            ]
          : [],
      });
    }
  });
  return payload;
};

export const createCreateQuotePayload = (state, desc, selectedTci, defaultTruckNum, rest) => {
  const payload = [];
  const combinedCourses = addSkillCheckCourses(desc.courses);
  const { secondaryTci } = desc;
  const customertz = customerZipToTzLabel(desc.customer?.postal_code, { defaultVal: timezoneSelector(state) });
  const courseTypes = [...(combinedCourses ?? [])].filter((c) => c.course.type === MATERIAL_TYPE.COURSE);
  _.forEach(combinedCourses, (onsite) => {
    const addOns = _(onsite.selectedAddons ?? {})
      .values()
      .map((a) => {
        const mat = materialSelector({ state, type: MATERIAL_TYPE.ADD_ON, id: a.id });
        return {
          ...a,
          qty: Number(a.qty),
          truckNumber: mat?.warehouseOverride ? DEFAULT_TRUCK_NUMBER : defaultTruckNum,
        };
      })
      .filter((a) => a.qty && a.qty > 0)
      .value();
    const customerPrice = Number(onsite.customerPrice ?? 0);
    const discount = makeDayDiscountCalculator(onsite, courseTypes.length, desc.customer);
    const { typeOverride, unscheduled } = rest ?? {};

    payload.push({
      title: desc.customer.name,
      route: desc?.customer?.Route ?? '',
      soldTo: desc?.customer?.sold_to ?? '',
      locID: desc?.customer?.sales_office ?? '',
      locationDisplay: getCustomerLocationDisplay(desc.customer),
      startTime: unscheduled ? undefined : onsite.courseStartTime,
      endTime: unscheduled ? undefined : onsite.courseEndTime,
      allDay: false,
      eventType: typeOverride ?? 'ON-SITE',
      notes: desc.notes,
      customerId: desc.customer.sold_to,
      customertz,
      sales_org: desc.customer.sales_organization,
      distribution_channel: desc.customer.distribution_channel,
      // TODO: Make this the actual value where storeNum is stored
      storeNum: desc.customer?.storeNum,
      contactId: desc.customerContact ? desc.customerContact.SAPContactID : undefined,
      temporaryContact: desc.temporaryContact ? { ...desc.temporaryContact } : undefined,
      instructorID: selectedTci ? selectedTci.uid : undefined,
      secondaryTci,
      subcontracted: Boolean(selectedTci?.subcontractor),
      participants_count: parseInt(onsite.participants, 10),
      course: onsite.course.id,
      truckNumber: defaultTruckNum,
      isSkillcheck: onsite.course.type === MATERIAL_TYPE.SKILL_CHECK,
      price: {
        amount: discount.isMakeDayDiscount ? discount.adjustedPrice : customerPrice,
        ...(discount.isMakeDayDiscount ? { makeDayDiscount: discount.makeDayDiscount } : {}),
        currency: onsite.currency,
        option: 'MARKET',
      },
      addOns,
      additional_participants: onsite.additional_participants?.[0]
        ? [
            {
              ...onsite.additional_participants[0],
              truckNumber: materialSelector({
                state,
                type: MATERIAL_TYPE.ADDITIONAL_PARTICIPANTS,
                id: onsite.additional_participants[0].id,
              })?.warehouseOverride
                ? DEFAULT_TRUCK_NUMBER
                : defaultTruckNum,
            },
          ]
        : [],
    });
  });
  return payload;
};
