import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/consts';

export const styles = {
  containedButton: {
    background: (props) => (props.greyBackground ? '#ACACAC' : '#012169'),
    border: 'hidden',
    color: 'white',
    borderRadius: '0px',
    fontFamily: 'proxima-nova, sans-serif',
    width: (props) => props.width,
    minWidth: (props) => props.minWidth ?? '20px',
    textTransform: (props) => (props.disableCaps ? 'none' : null),
  },
  outlinedButton: {
    borderRadius: '0px',
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: 'bold',
    color: '#012169',
    border: ({ borderColor }) => `2px solid ${borderColor ?? '#012169'}`,
    width: (props) => props.width,
    minWidth: (props) => props.minWidth ?? '20px',
    textTransform: (props) => (props.disableCaps ? 'none' : null),
  },
  textButton: {
    fontFamily: 'proxima-nova, sans-serif',
    fontWeight: 'bold',
    color: (props) => (props.color ? props.color : COLORS.CINTAS_BLUE),
    width: (props) => props.width,
    minWidth: (props) => props.minWidth ?? '20px',
    textTransform: (props) => (props.disableCaps ? 'none' : null),
  },
};

const useStyles = makeStyles(() => styles);

const StyledButton = (props) => {
  const {
    handleButton,
    greyBackground,
    buttonContent,
    disableCaps,
    width,
    variant,
    startIcon,
    className,
    reference,
    ...rest
  } = props;
  const classes = useStyles(props);

  const classSwitch = (param) => {
    switch (param) {
      case 'contained':
        return classes.containedButton;
      case 'outlined':
        return classes.outlinedButton;
      case 'text':
        return classes.textButton;
      default:
        return classes.containedButton;
    }
  };

  return (
    <Button
      variant={variant}
      ref={reference}
      className={`${classSwitch(variant)} ${className}`}
      onClick={handleButton}
      startIcon={startIcon || ''}
      {...rest}
    >
      {buttonContent || null}
    </Button>
  );
};
export default StyledButton;
